import React, { useState, useEffect, useRef, Fragment } from "react";
import { useDispatch } from 'react-redux';
import { useDropzone } from "react-dropzone";
import { Grid, useTheme, withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import ReactPlayer from "react-player";

//Import Components
import { ChatInputComponent, Typography } from "../../components"
import { IconButton } from "@material-ui/core";

//Import Styles
import styles from "./styles";
import { SendIconNew1 } from "../../assets/svg";
import doc from "../../assets/icons/doc.svg"

//import actions
import { alertAction } from "../../redux/actions";
import CircularProgress from '@material-ui/core/CircularProgress';
import { isMobile } from "react-device-detect";
import CanvasImageEditor from "../CanvasImageEditor";
import { generateVideoThumbnails, isEditorFormat } from "../../utils";
import ChatInputStyles from '../../containers/Chatv2/ChatContainer/ChatInput/styles'
import useCapacitorKeyboard from "../../hooks/keyboard_listener";
import { Keyboard } from "@capacitor/keyboard";

const DropZoneComponent = (props) => {
    //define dispatch
    const dispatch = useDispatch();
    const ciRef = useRef(null)
    const chatInputRef = useRef(null);

    const theme = useTheme();

    // Get Props
    let { classes, accept, handleClose, dragFiles, gifFile, handleUploadFiles, handleFileUpload, pastedFile, id, type, userId, setIsMediaSent = () => { }, ...chatprops } = props;

    const [clearTimeout, setClearTimeout] = useState(null);

    useEffect(() => {
        if (pastedFile) {
            handleOnDrop(pastedFile, true)
        }
    }, [pastedFile])
    // Set State
    if (gifFile) {
        let gifFileObj = {
            type: "image/gif",
            data: { ...gifFile },
            name: gifFile.title,
            preview: gifFile.images.original.url,
            isUploadFile: false,
            iof: `${new Date().getTime()}_1`
        }
        dragFiles = [gifFileObj, ...dragFiles]
    }
    const [files, setFiles] = useState(dragFiles);
    const [previewFile, setPreviewFile] = useState(dragFiles.length > 0 ? dragFiles[dragFiles.length - 1] : null)
    const [fileUploading, setFileUploading] = useState(false)
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [chatText, setChatText] = useState('');
    const [keyBoardHeight, SetKeyBoardHeight] = useState(0);

    const isKeyboardVisible = useCapacitorKeyboard();

    useEffect(() => {
        Keyboard.setResizeMode({ mode: 'body' })
        window.addEventListener('keyboardDidShow', (event) => {
            const { keyboardHeight } = event;
            if (keyboardHeight !== keyBoardHeight) {
                SetKeyBoardHeight(keyboardHeight);
            }
        })
        window.addEventListener('keyboardDidHide', (event) => {
            if (keyBoardHeight !== 0) {
                SetKeyBoardHeight(0);
            }
        })
        return () => {
            Keyboard.setResizeMode({ mode: 'native' })
        }
    })

    useEffect(() => {
        const bottomContainer = document.getElementById("bottomcontainer")
        if (bottomContainer) {
            if (keyBoardHeight > 0) {
                bottomContainer.style.position = 'fixed';
                bottomContainer.style.bottom = `${keyBoardHeight}px`;
                bottomContainer.style.left = 0;
                bottomContainer.style.right = 0;
                bottomContainer.style.zIndex = 9;
                bottomContainer.style.width = '100%';
            } else {
                bottomContainer.style = {}
            }
        }
    }, [keyBoardHeight])

    useEffect(() => {
        if (files?.length === 0 && !pastedFile) {
            handleSelectNewFileForUpload();
        }
    }, [dispatch]);

    useEffect(() => {
        if (files?.length === 0) {
            setBtnDisabled(true)
        } else {
            setBtnDisabled(false)
        }
    }, [files]);

    //delete preview
    const deleteFile = async (index) => {
        let tmpFiles = [...files];
        tmpFiles.splice(index, 1)
        await setFiles(tmpFiles)
        await setPreviewFile(tmpFiles.length > 0 ? tmpFiles[tmpFiles.length - 1] : null)
    }

    const handleOnDrop = async (acceptedFiles, isPasted = false) => {
        let mediaTypes = ["image", "video"]
        let dropFiles = []
        if (!isPasted) {
            for (let i = 0; i < acceptedFiles.length; i++) {
                let file = acceptedFiles[i];
                let fileType = file.type.split("/");
                let preview = URL.createObjectURL(file);
                let thumbnail = preview
                try {
                    thumbnail = await generateVideoThumbnails(file, 0);
                    thumbnail = thumbnail[0];
                } catch (error) {
                    thumbnail = preview
                }
                if (!mediaTypes.includes(fileType[0])) {
                    preview = doc
                }
                dropFiles.push(Object.assign(file, {
                    preview: preview,
                    isUploadFile: true,
                    thumb: thumbnail,
                    iof: `${new Date().getTime()}_${files.length + i}`,
                    message_text: ''
                }));
            }
        } else {
            let preview = acceptedFiles.url
            const result = Object.assign(acceptedFiles.blob, {
                preview: preview,
                isUploadFile: true,
                thumb: preview,
                iof: `${new Date().getTime()}_${1 + 1}`
            })
            dropFiles.push(result)
        }


        let sizeCheck = dropFiles.filter(f => (f["size"] && f["size"] > 1073741824));

        if (sizeCheck.length > 0) {
            dispatch(alertAction.error("File size should be less than 1GB"))
        } else {
            const newFileSet = [...files, ...dropFiles];
            setFiles(newFileSet);
            setPreviewFile(newFileSet[newFileSet.length - 1])
            handleUploadFiles(newFileSet);
        }
    }

    const { getRootProps, getInputProps, open } = useDropzone({
        onDrop: async acceptedFiles => {
            await handleOnDrop(acceptedFiles)
        },
        accept: accept,
        noClick: true,
        noKeyboard: true
    });

    const onCISave = (CIFile) => {
        let updatedFiles = [...files].map(m => {
            if (m.iof === CIFile.iof) {
                return CIFile
            }
            return m
        })
        return updatedFiles
    }

    const getPreview = () => {
        if (previewFile) {

            let fileName = previewFile.name;
            let fileExtension = fileName.split('.').pop();

            let previewType = previewFile.type.split('/');
            let fileType = previewType[0]; let fileFormat = previewType[1];
            let previewUrl = previewFile.preview;
            return <CanvasImageEditor
                alt={"img"}
                src={previewUrl}
                file={previewFile}
                className={classes.thumbImg}
                id={`ciEditor_${fileName}_${previewFile.iof}`}
                key={`ciEditor_${fileName}_${previewFile.iof}`}
                ref={ciRef}
                onSave={onCISave}
                fileExtension={fileExtension}
                fileType={fileType}
                fileFormat={fileFormat}
                type={type}
            />
        }
    }
    const getThumbPreview = (file, idx) => {
        if (file) {
            //Get the file Extension 
            let fileName = file.name;
            let fileExtension = fileName.split('.').pop();

            let previewType = file.type.split('/');
            let fileType = previewType[0];
            let fileFormat = previewType[1];
            let previewUrl = file.preview;
            let previewGrid = fileType === "image" ? <img
                alt={"img"}
                src={previewUrl}
                className={classes.thumbImg}
            /> : (
                fileType === "video" ?
                    <ReactPlayer key={`${idx}-${previewUrl}`} controls={false} muted volume={0} playing={false} width={"44px"} height={"44px"} url={previewUrl} onReady={(p) => {
                        if (p.getCurrentTime() === 0)
                            p.seekTo(0.5, "seconds");
                    }} />
                    : fileExtension.length > 0 ? <>
                        <img style={{ width: "inherit" }} src={`./icons/${fileExtension}.svg`} alt={fileExtension} />
                    </> : <span>{file && file.name ? file.name : fileFormat}</span>);
            // : fileFormat === "pdf" ? <><img style={{ width: "inherit" }} src={pdfImg} alt={"pdf"} /></> : <span>{file && file.name ? file.name : fileFormat}</span>);
            return previewGrid;
        }
    }

    const handlePreviewFile = async (file) => {
        chatInputRef?.current?.onThumbnailClick(file?.message_text || '')
        if (isEditorFormat(previewFile.type)) {
            const updatedFiles = await ciRef?.current?.triggerSave(false)
            setFiles(updatedFiles)
        }
        setPreviewFile(file)
    }

    const handleSelectNewFileForUpload = () => {
        document.getElementById("addNewFileBtn").click();
    }

    const thumbs = files?.map((file, index) => (
        <>
            <div className={`${classes.thumb} ${previewFile?.name === file?.name ? 'active' : null}`} key={file.name} >
                <div className={classes.thumbInner} onClick={(e) => handlePreviewFile(file)}>
                    {getThumbPreview(file, index)}
                </div>
            </div>
            <div className={classes.thumbClose}>
                <IconButton className={classes.thumbCloseBtn} onClick={(e) => { deleteFile(index) }} >
                    <CloseIcon />
                </IconButton>
            </div>
        </>
    ));


    const handleDnDUpload = async () => {
        setIsMediaSent(true);
        if (localStorage.getItem('draft_messages').includes(chatText)) {
            let draftMessages = localStorage.getItem('draft_messages').split(',');
            draftMessages = draftMessages.filter(message => message !== chatText);
            localStorage.setItem('draft_messages', draftMessages.join(','));
        }
        setBtnDisabled(true);
        if (!btnDisabled) {
            let updatedFiles = await ciRef?.current?.triggerSave(false, true && isEditorFormat(previewFile.type))
            if (chatText) {
                updatedFiles.map((elem) => {
                    elem['message_text'] = chatText || '';
                    return elem;
                })
            }
            setFiles(updatedFiles)
            setFileUploading(true);
            chatInputRef?.current?.clearEditorState(id);
            handleFileUpload(updatedFiles);
            setIsMediaSent(false)
        }
    }

    const style = {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
    };

    const handleChatChange = (dataObj) => {
        setChatText(dataObj?.chatText || '')
        // const updatedFiles = files.map((elem) => {
        //     if (elem.iof === previewFile?.iof) {
        //         elem['message_text'] = dataObj?.chatText || '';
        //     }
        //     return elem;
        // })
        // if (clearTimeout) {
        //     clearTimeout(clearTimeout)
        // }
        // if (files?.length) {
        //     setClearTimeout(() => {
        //         setFiles(updatedFiles);
        //     }, 2000);
        // }
    }
    return (
        <>
            <div {...getRootProps({ className: 'chatDropZone', style })}>
                {!gifFile && <input {...getInputProps()} />}
                <Grid container className={classes.previewContainer} style={{ marginTop: type && !isMobile ? '60px' : "" }}>
                {window.innerWidth <= 599 ? (
                    <Grid item xs={12} className="previewClose smFlex justifyFlexEnd">
                        <IconButton title={"Cancel"} onClick={(e) => { handleClose(); }}><CloseIcon /></IconButton>
                    </Grid>
                    ) : (
                    <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start" xs={isMobile ? 11 : 12} className="previewClose">
                        <IconButton title={"Cancel"} onClick={(e) => { handleClose(); }}><CloseIcon /></IconButton>
                    </Grid>
                )}
                    <Grid item xs={12} className={classes.imageViewContainer}>
                        {/* <Grid className={classes.previewBody}> */}
                        <Grid className={`${classes.previewBox} previewBox`}>
                            {fileUploading ?
                                (
                                    <>
                                        <CircularProgress />
                                    </>
                                )
                                :
                                (previewFile ?
                                    (
                                        <div
                                            className={`previewImage ci_editor_gbcr ${classes.ci_editor_gbcr}`}
                                        >
                                            {getPreview()}
                                        </div>
                                    )
                                    :
                                    (
                                        <>
                                            <Typography variant="h6" className="colorGrey textCenter fs-16 fw-600 pr-1" >
                                                {"Drag files here"}
                                                <br />
                                                {"You can't upload more than 1GB size file"}
                                            </Typography>
                                        </>
                                    ))
                            }
                        </Grid>
                        {/* </Grid> */}
                    </Grid>
                    {
                        !isMobile ?
                            <Fragment>
                                <Grid item xs={12} className="dflex alignselfFlexEnd">
                                    <Grid container justifyContent="center">
                                            <Grid item xs={7}>
                                                <Grid container alignItems="center" direction="row" className={`${classes.ChatTypingArea} ChatTypingArea nowrap relative`} style={{ padding: 0 }}>
                                                    <Grid item xs={12} className="dflex">
                                                        <ChatInputComponent
                                                            ref={chatInputRef}
                                                            id={id}
                                                            type={type}
                                                            userId={userId}
                                                            className={`${classes.chatTypeField} ${"borderNone"}`}
                                                            handleChatSend={handleDnDUpload}
                                                            handleChatChange={handleChatChange}
                                                            handleAttachment={chatprops?.handleAttachment}
                                                            handleChatGif={chatprops?.handleChatGif}
                                                            userSuggestions={chatprops?.chatUsers && chatprops?.chatUsers.length > 0 ? chatprops?.chatUsers : []}
                                                            handleTyping={chatprops?.setTyping}
                                                            handleVoiceNoteMessage={chatprops?.handleVoiceNoteMessage}
                                                            infinityModeHandler={chatprops?.infinityModeHandler}
                                                            inputfocus={chatprops?.inputFocus}
                                                            typingUsers={chatprops?.typingUsers}
                                                            unsendMessages={chatprops?.unsendMessages}
                                                            updateDraftMessages={chatprops?.updateDraftMessages}
                                                            placeHolder={chatprops?.placeHolder}
                                                            actionIcons={['emoji']}
                                                            backgroundColor={isMobile ? `${theme.palette.containers.bg1}70` : theme.palette.containers.bg1}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        <Grid item xs={12} className="dflex">
                                            <Grid item xs={11} className="mr-1">
                                                <div className={classes.previewThumb}>
                                                    <aside className={classes.thumbsContainer}>
                                                        {thumbs}
                                                        <div className={`${classes.thumb} addNewFileBtn`} >
                                                            <div className={classes.thumbInner}>
                                                                <IconButton title={"Add Files"} id="addNewFileBtn" onClick={open}>
                                                                    <AddIcon />
                                                                </IconButton>
                                                            </div>
                                                        </div>
                                                    </aside>
                                                </div>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <IconButton
                                                    title={"Upload"}
                                                    className={classes.uploadBtn2}
                                                    disabled={false}
                                                    onClick={(e) => {
                                                        if (!btnDisabled) handleDnDUpload(e)
                                                    }}
                                                >
                                                    <SendIconNew1 />
                                                </IconButton>
                                            </Grid>

                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Fragment>
                            :
                            <Fragment>
                                {
                                    <Grid item xs={12}>
                                        <div className={classes.previewThumb}>
                                            <aside className={classes.thumbsContainer}>
                                                {thumbs}
                                                <div className={`${classes.thumb} addNewFileBtn`} >
                                                    <div className={classes.thumbInner}>
                                                        <IconButton title={"Add Files"} id="addNewFileBtn" onClick={open}>
                                                            <AddIcon />
                                                        </IconButton>
                                                    </div>
                                                </div>
                                            </aside>
                                        </div>
                                    </Grid>
                                }
                                <Grid item xs={12} id="bottomcontainer" className={classes.bottomContainer}>
                                    <Grid container >
                                        <Grid item xs={12} className="mt-2">
                                            <Grid container alignItems="center" className={`${!accept?.includes('image') ? 'justifyFlexEnd' : ''}`}>
                                                {
                                                    accept?.includes('image') &&
                                                    <Grid item xs={10}>
                                                        <Grid container alignItems="center" direction="row" className={`${classes.ChatTypingArea} ChatTypingArea nowrap relative`} style={{ padding: 0 }} >
                                                            <Grid item xs={12} className="dflex">
                                                                <ChatInputComponent
                                                                    ref={chatInputRef}
                                                                    id={id}
                                                                    type={type}
                                                                    userId={userId}
                                                                    className={`${classes.chatTypeField} ${"borderNone"}`}
                                                                    handleChatSend={handleDnDUpload}
                                                                    handleChatChange={handleChatChange}
                                                                    handleAttachment={chatprops?.handleAttachment}
                                                                    handleChatGif={chatprops?.handleChatGif}
                                                                    userSuggestions={chatprops?.chatUsers && chatprops?.chatUsers.length > 0 ? chatprops?.chatUsers : []}
                                                                    handleTyping={chatprops?.setTyping}
                                                                    handleVoiceNoteMessage={chatprops?.handleVoiceNoteMessage}
                                                                    infinityModeHandler={chatprops?.infinityModeHandler}
                                                                    inputfocus={chatprops?.inputFocus}
                                                                    typingUsers={chatprops?.typingUsers}
                                                                    unsendMessages={chatprops?.unsendMessages}
                                                                    updateDraftMessages={chatprops?.updateDraftMessages}
                                                                    placeHolder={chatprops?.placeHolder}
                                                                    actionIcons={['emoji']}
                                                                // backgroundColor={isMobile ? 'rgba(255, 255, 255, 0.73)' : "#FFF"}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                }
                                                <Grid item xs={2} className="relative textRight">
                                                    <IconButton
                                                        title={"Upload"}
                                                        className={`${classes.uploadBtnMobile}`}
                                                        disabled={false}
                                                        onClick={(e) => {
                                                            if (!btnDisabled) handleDnDUpload(e)
                                                        }}
                                                    >
                                                        <SendIconNew1 />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Fragment>
                    }
                </Grid>
            </div>
        </>
    );
};

DropZoneComponent.defaultProps = {
    importFileData: () => { },
    fileType: "media",
    accept: null, //"image/*, video/*",
    gifFile: null,
    handleClose: () => { },
};
DropZoneComponent.propTypes = {
    importFileData: PropTypes.func,
    accept: PropTypes.string,
    fileType: PropTypes.string,
    gifFile: PropTypes.object,
    handleClose: PropTypes.func,
};

// export component
export const ChatDropZone = withStyles(ChatInputStyles)(withStyles(styles)(DropZoneComponent));

import { baseAction } from "./baseAction";
import { projectConstant, alertConstant, loaderConstant, taskConstant, profileConstant, authConstant } from "../constants";
import { projectService } from "../services";
import { validateUserPlan } from "../helpers/projectHelpers";

// Import History
import history from "../../config/history";

const { request, success, failure } = baseAction;

// All login based actions
export const projectAction = {
  createProject,
  updateDND,
  getProjects,
  getProjectDetail,
  getProjectsFilter,
  removeProjectUser,
  deleteUserFromAgency,
  getAllTags,
  completeProject,
  deleteProject,
  archiveProject,

  getBrief,
  updateBrief,
  updateBriefDescription,
  saveDuplicateProject,

  getAllNotes,
  getNote,
  addNote,
  updateNote,
  shareNote,
  deleteNote,

  getTeamMembers,
  getActiveTeamMembers,
  getProjectTeamUsers,
  inviteNewUsers,
  inviteExistingUsers,
  newUserInvitationAccept,
  getProjectInviteData,

  createNewTask,
  getAllTasks,
  getCurrentTask,
  deleteTask,
  updatecurrentTask,
  getTaskFilter,
  completeTask,
  getPTaskTags,
  createTaskNote,
  getAllTaskNote,
  deleteTaskNote,
  updateNoteV2,
  updateBriefDescriptionV2
};

/***********************************************************************COMMON DATA******************************************************************************* */


/** create new project */
function createProject(params, callBack = () => { }) {
  return (dispatch, getState) => {
    dispatch(request(projectConstant.CREATE_PROJECT_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    const validation = validateUserPlan(params, getState(), 'project')
    if (validation.isValid) {
      projectService.createProject(params).then(data => {
        let message = data.message.split("_");
        dispatch(success(projectConstant.CREATE_PROJECT_S, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, message[0]));
        dispatch(request(loaderConstant.LOADING_FALSE));
        history.push(`/projects/detail/${message[1]}`);
        callBack(message[1]);
      }, error => {
        dispatch(failure(projectConstant.CREATE_PROJECT_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
      );
    } else {
      dispatch(failure(alertConstant.ALERT_ERROR, validation.message));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
  };
}

/** get project detail */
function updateDND(params) {
  return (dispatch) => {
    // dispatch(request(projectConstant.UPDATE_DND_R, params));
    projectService.updateDND(params.ids).then(data => {
     
      dispatch(success(authConstant.UPDATE_FLOWS_ORDER, params));
    }, error => {
      dispatch(failure(projectConstant.UPDATE_DND_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
    }
    );
  };
}

/** get tags */
function getAllTags(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(projectConstant.GET_TAGS_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    projectService.getAllTags(params).then(data => {
      dispatch(success(projectConstant.GET_TAGS_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(projectConstant.GET_TAGS_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

//completeProject
function completeProject(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(projectConstant.COMPLETE_PROJECT_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    projectService.completeProject(params).then(data => {
      dispatch(success(projectConstant.COMPLETE_PROJECT_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(projectConstant.COMPLETE_PROJECT_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

//archiveProject
function archiveProject(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(projectConstant.ARCHIVE_PROJECT_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    projectService.archiveProject(params).then(data => {
      dispatch(success(projectConstant.ARCHIVE_PROJECT_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(projectConstant.ARCHIVE_PROJECT_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}


/** get brief */
function getProjects(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(projectConstant.GET_PROJECTS_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    projectService.getProjects(params).then(data => {
      dispatch(success(projectConstant.GET_PROJECTS_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(projectConstant.GET_PROJECTS_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/** get project detail */
function getProjectDetail(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(projectConstant.GET_PROJECT_DETAIL_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    projectService.getProjectDetail(params).then(data => {
      if (data.message === "failed") {
        history.push("/projects")
        dispatch(failure(alertConstant.ALERT_ERROR, data.data));
        dispatch(request(loaderConstant.LOADING_FALSE));
      } else {
        dispatch(success(projectConstant.GET_PROJECT_DETAIL_S, data));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack(data.data);
      }
    }, error => {
      dispatch(failure(projectConstant.GET_PROJECT_DETAIL_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/** get project detail */
function getProjectsFilter(params) {
  return (dispatch) => {
    dispatch(request(projectConstant.GET_PROJECT_FILTER_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    projectService.getProjectsFilter(params).then(data => {
      dispatch(success(projectConstant.GET_PROJECT_FILTER_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }, error => {
      dispatch(failure(projectConstant.GET_PROJECT_FILTER_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/* deleteProject */
function deleteProject(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(projectConstant.REMOVE_PROJECT_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    projectService.deleteProject(params).then(data => {
      dispatch(success(projectConstant.REMOVE_PROJECT_S, data));
      dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(projectConstant.REMOVE_PROJECT_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/* remove team members */
function removeProjectUser(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(projectConstant.REMOVE_TEAM_USER_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    projectService.removeProjectUser(params).then(data => {
      dispatch(success(projectConstant.REMOVE_TEAM_USER_S, data));
      dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(projectConstant.REMOVE_TEAM_USER_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/* delete team members from agency */
function deleteUserFromAgency(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(projectConstant.REMOVE_USER_FROM_AGENCY_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    projectService.deleteUserFromAgency(params).then(data => {
      dispatch(success(projectConstant.REMOVE_USER_FROM_AGENCY_S, data));
      dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(projectConstant.REMOVE_USER_FROM_AGENCY_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/****************************************************************BRIEF DETAILS************************************************************************************** */


/** get brief */
function getBrief(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(projectConstant.GET_BRIEF_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    projectService.getBrief(params).then(data => {
      dispatch(success(projectConstant.GET_BRIEF_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack(data);
    }, error => {
      dispatch(failure(projectConstant.GET_BRIEF_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/** update brief */
function updateBrief(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(projectConstant.UPDATE_BRIEF_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    projectService.updateBrief(params).then(data => {
      dispatch(success(projectConstant.UPDATE_BRIEF_S, data));
      dispatch(success(alertConstant.ALERT_SUCCESS, data["message"]));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(projectConstant.UPDATE_BRIEF_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/** update brief description*/
function updateBriefDescription(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(projectConstant.UPDATE_BRIEF_R, params));
    // dispatch(request(loaderConstant.LOADING_TRUE));
    projectService.updateBrief(params).then(data => {

      dispatch(success(projectConstant.GET_BRIEF_S, data));
      dispatch(success(projectConstant.UPDATE_BRIEF_S, data))
      // dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
      // dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(projectConstant.UPDATE_BRIEF_F, error));
      // dispatch(failure(alertConstant.ALERT_ERROR, error));
      // dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/** update brief description*/
function updateBriefDescriptionV2(params) {
  return (dispatch) => {
    projectService.updateBrief(params).then(() => {
      // none to add
    }, error => {
      dispatch(failure(projectConstant.UPDATE_BRIEF_F, error));
    }
    );
  };
}

/** create duplicate flow */
function saveDuplicateProject(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(projectConstant.CREATE_CLONE_PROJECT_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    projectService.saveDuplicateProject(params).then(data => {
      dispatch(success(projectConstant.CREATE_CLONE_PROJECT_S, data));
      dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
      dispatch(request(loaderConstant.LOADING_FALSE));
      history.push("/projects")
      callBack()
    }, error => {
      dispatch(failure(projectConstant.CREATE_CLONE_PROJECT_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/****************************************************************NOTES DETAILS************************************************************************************** */

/** get all notes */
function getAllNotes(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(projectConstant.GET_ALL_NOTES_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    projectService.getAllNotes(params).then(data => {
      dispatch(success(projectConstant.GET_ALL_NOTES_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(projectConstant.GET_ALL_NOTES_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/** get one notes */
function getNote(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(projectConstant.GET_ONE_NOTES_R, params));
    if (!params["loading"]) {
      dispatch(request(loaderConstant.LOADING_TRUE));
    }
    projectService.getNote(params).then(data => {
      dispatch(success(projectConstant.GET_ONE_NOTES_S, data));
      if (!params["loading"]) {
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
      callBack()
    }, error => {
      dispatch(failure(projectConstant.GET_ONE_NOTES_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      if (!params["loading"]) {
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    }
    );
  };
}

/** add Note */
function addNote(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(projectConstant.ADD_NOTE_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    projectService.addNote(params).then(data => {
      dispatch(success(projectConstant.ADD_NOTE_S, data));
      dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(projectConstant.ADD_NOTE_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/** update Note */
function updateNote(params, callBack = () => { }) {
  return (dispatch, getState) => {
    const { newNote, note } = getState().project;
    let params = newNote && Object.keys(newNote).length > 0 && newNote || note;
    if (note.id) {
      params = { ...params, id: note.id };
    }
    const noteText = params['notes_text'] ? JSON.parse(params.notes_text) : null;
    if (params && (params["notes_title"] || (noteText && noteText.length > 0))) {
      dispatch(request(projectConstant.UPDATE_NOTE_R, params));
      // dispatch(request(loaderConstant.LOADING_TRUE));
      projectService.updateNote(params).then(data => {
        dispatch(success(projectConstant.UPDATE_NOTE_S, data));
        // dispatch(request(loaderConstant.LOADING_FALSE));
        callBack(data && data.data && data.data.id ? data.data.id : 0)
      }, error => {
        dispatch(failure(projectConstant.UPDATE_NOTE_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        // dispatch(request(loaderConstant.LOADING_FALSE));
      }
      );
    };
  }
}

/** Update Project Notes version 2 */
function updateNoteV2(params) {
  return (dispatch) => {
    if (params.newNote) {
      dispatch(request(loaderConstant.LOADING_TRUE))
    }
    projectService.updateNote(params).then(data => {
        if (data && data?.data?.id && params.newNote) {
          history.push(`/projects/detail/${[params.job_id]}/notes/${data?.data?.id}`);
        }
        dispatch(request(loaderConstant.LOADING_FALSE));
      }, error => {
        dispatch(failure(projectConstant.UPDATE_NOTE_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  }
}

/** update Note */
function shareNote(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(projectConstant.SHARE_NOTE_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    projectService.shareNote(params).then(data => {
      dispatch(success(projectConstant.SHARE_NOTE_S, data));
      dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack(data)
    }, error => {
      dispatch(failure(projectConstant.SHARE_NOTE_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/* delete Current Notes */
function deleteNote(params, callBack = () => { }) {
  return (dispatch, getState) => {
    // const { newNote } = getState().project;
    dispatch(request(projectConstant.DEL_CURRENT_NOTE_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    projectService.deleteNote(params).then(data => {
      dispatch(success(projectConstant.DEL_CURRENT_NOTE_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(projectConstant.DEL_CURRENT_NOTE_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/****************************************************************TEAMS DETAILS************************************************************************************** */

/** GET TEAM MEMBERS */
function getTeamMembers(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(projectConstant.GET_TEAM_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    projectService.getTeamMembers(params).then(data => {
      dispatch(success(projectConstant.GET_TEAM_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack(data)
    }, error => {
      dispatch(failure(projectConstant.GET_TEAM_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/** GET Active TEAM MEMBERS */
function getActiveTeamMembers(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(projectConstant.GET_ACTIVE_TEAM_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    projectService.getActiveTeamMembers(params).then(data => {
      dispatch(success(projectConstant.GET_ACTIVE_TEAM_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack(data)
    }, error => {
      dispatch(failure(projectConstant.GET_ACTIVE_TEAM_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/** NEW USER INVITATION ACCEPTING */
function newUserInvitationAccept(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(projectConstant.NEW_USER_ACCEPT_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    projectService.newUserInvitationAccept(params).then(data => {
      dispatch(success(projectConstant.NEW_USER_ACCEPT_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack(data)
    }, error => {
      dispatch(failure(projectConstant.NEW_USER_ACCEPT_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

//getProjectInviteData
function getProjectInviteData(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(projectConstant.G_NEW_USER_ACCEPT_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    projectService.getProjectInviteData(params).then(data => {
      dispatch(request(loaderConstant.LOADING_FALSE));
      if (data.data) {
        dispatch(success(projectConstant.G_NEW_USER_ACCEPT_S, data));
        callBack(data.data)
      } else {
        history.push('/')
        dispatch(failure(alertConstant.ALERT_ERROR, data.message));
      }
    }, error => {
      dispatch(failure(projectConstant.G_NEW_USER_ACCEPT_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/* Get Project Team Members */
function getProjectTeamUsers(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(projectConstant.GET_PROJECT_TEAM_R, params));
    // dispatch(request(loaderConstant.LOADING_TRUE));
    projectService.getProjectTeamUsers(params).then(data => {
      dispatch(success(projectConstant.GET_PROJECT_TEAM_S, data));
      // dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(projectConstant.GET_PROJECT_TEAM_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      // dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/* Invite new team members */
function inviteNewUsers(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(projectConstant.USER_NEW_INVITE_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    projectService.inviteNewUsers(params).then(data => {
      dispatch(success(projectConstant.USER_NEW_INVITE_S, data));
      dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(projectConstant.USER_NEW_INVITE_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/* Invite old team members */
function inviteExistingUsers(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(projectConstant.USER_EXISTING_INVITE_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    projectService.inviteExistingUsers(params).then(data => {
      dispatch(success(projectConstant.USER_EXISTING_INVITE_S, data));
      dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(projectConstant.USER_EXISTING_INVITE_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}


/****************************************************************TASK DETAILS************************************************************************************** */

/* create New Task */
function createNewTask(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(projectConstant.CREATE_NEW_TASK_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    projectService.createNewTask(params).then(data => {
      dispatch(success(projectConstant.CREATE_NEW_TASK_S, data));
      dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(projectConstant.CREATE_NEW_TASK_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/* get all Task */
function getAllTasks(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(projectConstant.GET_ALL_TASK_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    projectService.getAllTasks(params).then(data => {
      dispatch(success(projectConstant.GET_ALL_TASK_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(projectConstant.GET_ALL_TASK_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/* get getCurrentTask Task */
function getCurrentTask(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(projectConstant.GET_CURRENT_TASK_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    projectService.getCurrentTask(params).then(data => {
      dispatch(success(projectConstant.GET_CURRENT_TASK_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(projectConstant.GET_CURRENT_TASK_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/* delete Current Task */
function deleteTask(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(projectConstant.DEL_CURRENT_TASK_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    projectService.deleteTask(params).then(data => {
      dispatch(success(projectConstant.DEL_CURRENT_TASK_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(projectConstant.DEL_CURRENT_TASK_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/* delete Current Task */
function updatecurrentTask(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(projectConstant.UPDATE_CURRENT_TASK_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    projectService.updatecurrentTask(params).then(data => {
      dispatch(success(projectConstant.UPDATE_CURRENT_TASK_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(projectConstant.UPDATE_CURRENT_TASK_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

// /** get All Tasks */
function getTaskFilter(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(projectConstant.GET_TASKS_FILTER_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    projectService.getTaskFilter(params).then(data => {
      dispatch(success(projectConstant.GET_TASKS_FILTER_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(projectConstant.GET_TASKS_FILTER_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

// /** get All Tasks */
function completeTask(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(projectConstant.COMPLETE_TASK_R, params));
    // dispatch(request(loaderConstant.LOADING_TRUE));
    projectService.completeTask(params).then(data => {
      dispatch(success(projectConstant.COMPLETE_TASK_S, data));
      dispatch(success(taskConstant.UPDATE_COMPLETED_TASK_COUNT_S, data));
      dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
      // dispatch(request(loaderConstant.LOADING_FALSE));
      if (data.message && data.message.toLowerCase() === "updated successfully") {
        callBack()
      }
    }, error => {
      dispatch(failure(projectConstant.COMPLETE_TASK_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      // dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

function getPTaskTags(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(projectConstant.GET_P_TASK_TAGS_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    projectService.getPTaskTags(params).then(data => {
      dispatch(success(projectConstant.GET_P_TASK_TAGS_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(projectConstant.GET_P_TASK_TAGS_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    });
  };
}

/** create task note */
function createTaskNote(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(projectConstant.CREATE_TASK_NOTE_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    projectService.createTaskNote(params).then(data => {
      dispatch(success(projectConstant.CREATE_TASK_NOTE_S, data));
      dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(projectConstant.CREATE_TASK_NOTE_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/** get all note for a task*/
function getAllTaskNote(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(projectConstant.GET_TASK_NOTE_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    projectService.getAllTaskNote(params).then(data => {
      dispatch(success(projectConstant.GET_TASK_NOTE_S, data));
      // dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(projectConstant.GET_TASK_NOTE_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/** delete task note */
function deleteTaskNote(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(projectConstant.DELETE_TASK_NOTE_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    projectService.deleteTaskNote(params).then(data => {
      dispatch(success(projectConstant.DELETE_TASK_NOTE_S, data));
      dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(projectConstant.DELETE_TASK_NOTE_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}
import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  withStyles,
  IconButton,
  Popover,
  ListItemIcon,
  MenuItem,
  MenuList,
  Avatar,
} from "@material-ui/core";
import moment from "moment";
import { Typography, Skeleton, TitleAvatar } from "../../components";
import noMessage from "../../assets/images/nomessage1.png";
import noInvites from "../../assets/images/no-spam.png";
import HeaderStyles from "./HeaderStyles";
import { profileAction, chatv2Action } from "../../redux/actions";
import {
  TeamsIconWhite,
  NoTasks,
  ChatMenuIcon,
  CloseIcon,
} from "../../assets/svg";
import { userDisplayName } from "../../utils/appHelper";
import { joinRoom, subscribeToNewTasks } from "../../utils/socketv2";
import ChatNotifications from "./ChatNotifications";

function Notification(props) {
  //define dispatch
  const dispatch = useDispatch();

  //get props
  const { classes } = props;

  //reducer data
  const { ag_users, id: userId } = useSelector((s) => s.auth.loginUser);
  const { invites_n, tasks_n, avatar_filename, appConfig: { title, favicon }, invoice_n } = useSelector((s) => s.profile);
  const { newChat } = useSelector((s) => s.chatv2);

  //DropDown
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);

  useEffect(() => {
    const faviconUpdate = async () => {
      //grab favicon element by ID
      const favIcon = document.getElementById("ag_favicon");
      const appTitle = document.getElementById("ag_app_title");
      //check count value, if below 0 we change href property to our red circle image path
      if (newChat.length > 0) {
        // favIcon.href = favicon;
        appTitle.innerHTML = `(${newChat.length}) ${title}`;
      }
      //if less than 0, only app title
      else {
        favIcon.href = favicon;
        appTitle.innerHTML = `${title}`;
      }
    };

    //run our function here
    faviconUpdate();
  }, [newChat]);



  const handleClose = () => {
    setAnchorEl(null);
    readAllNotification("message");
  };

  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
    setAnchorEl(null);
    setAnchorEl2(null);

    // update task view status after 500ms
    setTimeout(() => {
      updateNotificationViewStatus("task");
    }, 500);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
    readAllNotification("task");
  };


  const handleClose2 = () => {
    setAnchorEl2(null);
    readAllNotification("invite");
  };

  const closeAll = () => {
    setAnchorEl(null);
    setAnchorEl1(null);
    setAnchorEl2(null);
  };

  useEffect(() => {
    dispatch(chatv2Action.getNewMessages());

    function getAlerts() {
      dispatch(profileAction.getNotifications({}));
      dispatch(profileAction.getInvites());
      dispatch(profileAction.getTasks());
      dispatch(profileAction.getInvoiceNotification())
    }
    getAlerts();
    joinRoom("newTask", () => {
      subscribeToNewTasks((err, res) => {
        dispatch(profileAction.getTasks());
      });
    });
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const open1 = Boolean(anchorEl1);
  const id1 = open1 ? "simple-popover" : undefined;

  const open2 = Boolean(anchorEl2);
  const id2 = open2 ? "simple-popover" : undefined;

  const updateNotification = (id, data, type) => {
    dispatch(
      profileAction.updateNotification({ id: id, data, type }, () => {
        if (type === "task") {
          if (data["invite_type"] === 1) {
            dispatch(profileAction.getTasks());
          } else {
            dispatch(profileAction.getTasks());
          }
        } else if (
          type === "invite" &&
          data?.notification_sub_type?.indexOf("wsm_") == -1
        ) {
          if (data["invite_type"] === 1) {
            dispatch(profileAction.getInvites());
          } else {
            dispatch(profileAction.getInvites());
          }
        } else if (data?.notification_sub_type?.indexOf("wsm_") >= 0) {
          dispatch(profileAction.getInvites());
        } else if( type === 'invoice'){
          dispatch(profileAction.getInvoiceNotification())
        }
        closeAll();
      })
    );
  };

  const clearNotifications = (type) => {
    dispatch(profileAction.clearNotifications(type));
  };

  const readAllNotification = (type) => {
    if (type == "invite" && invites_n.length > 0) {
      dispatch(
        profileAction.updateNotification(
          { ids: invites_n.map((e) => e.id), type },
          (type) => clearNotifications(type)
        )
      );
    }
    if (type == "task" && tasks_n.length > 0) {
      dispatch(
        profileAction.updateNotification(
          { ids: tasks_n.map((e) => e.id), type },
          (type) => clearNotifications(type)
        )
      );
    }
    if (type == "message" && newChat.length > 0) {
      dispatch(
        profileAction.updateNotification(
          { ids: newChat.map((e) => e.id), type },
          (type) => clearNotifications(type)
        )
      );
    }
    if (type === "invoice" && invoice_n.length > 0) {
      dispatch(
        profileAction.updateNotification(
          { ids: invoice_n.map((e) => e.id), type },
          (type) => clearNotifications(type)
        )
      );
    }
  };

  const updateNotificationViewStatus = (type) => {
    dispatch(profileAction.updateNotificationViewStatus({ type }, () => { }));
  };

  const getChatListItem = (item, indx) => {
    return (
      <MenuItem
        onClick={() => {
          updateNotification(item.id, item, "message");
        }}
        key={indx}
      >
        <ListItemIcon>
          {item.isGroup == 1 ? (
            <Avatar className={classes.groupIcon}>
              <TeamsIconWhite />
            </Avatar>
          ) : (
            <TitleAvatar
              alt={item.name}
              userId={userId}
              recipientId={item.id}
              type={item.avatar_type || null}
              value={
                item.invite_type == 3
                  ? avatar_filename || `/static/images/avatar/1.jpg`
                  : item.avatar
              }
            />
          )}
        </ListItemIcon>
        <Grid className="growOne">
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            wrap="nowrap"
          >
            <Grid className="growOne">
              <Typography variant="body1" className="fw-500">
                {item?.name || item?.users?.username || ""}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                className="oneLineEllip"
              >
                <span style={{ color: "#2563EB", fontWeight: "500" }}>
                  {item.users.id === userId
                    ? "You"
                    : `${item.users.first_name}`}
                </span>
                :
                {item.notification_message
                  ? `${item.notification_message.substring(0, 20)}${item.notification_message.length > 20 ? "..." : ""
                  }`
                  : ""}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="body2" color="textSecondary">
                {moment.utc(item.notification_time).local().fromNow()}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </MenuItem>
    );
  };

  const { loading } = useSelector((state) => state.UI);

  return (
    <>
      <Fragment>
        {/* <Badge className={classes.notifiBadge} badgeContent={newChat.length ? newChat.length : 0} color="primary">
                    <IconButton className="ml15" onClick={handleClick}><MessageIcon /></IconButton>
                </Badge>
                <Badge className={classes.notifiBadge} badgeContent={tasks_n ? tasks_n.length : 0} color="primary">
                    <IconButton className="ml15" onClick={handleClick1}><TaskIcon /></IconButton>
                </Badge>
                <Badge className={classes.notifiBadge} badgeContent={invites_n ? invites_n.length : 0} color="primary">
                    <IconButton className="ml15" onClick={handleClick2}><InviteIcon /></IconButton>
                </Badge> */}
        <ChatNotifications btnClasses={classes} />
      </Fragment>

      <Popover
        className={`${classes.notificationPopUp} ${!newChat.length < 1 && "notificationPopUp"
          }`}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {loading && (
          <React.Fragment>
            <Skeleton count={4} type={"avatarList"} />
          </React.Fragment>
        )}
        {!loading && (
          <MenuList>
            <Grid
              container
              justifyContent={newChat.length > 0 ? "space-between" : "center"}
              className={classes.titleWrapper}
            >
              <Grid item>
                {newChat.length < 1 ? (
                  <Grid
                    item
                    className={`${classes.noMessageContainer} noMessageContainer`}
                  >
                    <img src={noMessage} className="noMessageImg" />
                    <Typography className={classes.dropTitle} variant="h4">
                      No Messages
                    </Typography>
                  </Grid>
                ) : (
                  <Typography variant="h6" className={classes.titleContainer}>
                    <span className="mr-1 iconWrapper">
                      <ChatMenuIcon />
                    </span>
                    Messages <span className="nowtext">now</span>
                  </Typography>
                )}
              </Grid>
              {newChat.length > 0 ? (
                <IconButton className={classes.closeBtn} onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              ) : null}
            </Grid>
            {/* {notifications_n && notifications_n.map((itm, indx) => {
                            let userAvatar = ag_users && ag_users.length > 0 ? ag_users.filter((img) => itm.users.id === img.id) : [];
                            if (userAvatar.length > 1) {
                                userAvatar = userAvatar.filter((val) => val.avatar && val.avatar !== null)
                            }
                            return (
                                <MenuItem onClick={() => { updateNotification(itm.id, itm, "message") }} key={indx}>
                                    <ListItemIcon>
                                        <Avatar className={classes.menuDropAvatar} alt={userDisplayName(itm.users)}
                                            src={userAvatar.length > 0 && userAvatar[0] ? userAvatar[0].avatar : "/static/images/avatar/1.jpg"} />
                                    </ListItemIcon>
                                    <Grid className="growOne">
                                        <Grid container alignItems="center" justifyContent="space-between">
                                            <Grid className={classes.notifiMessage}>
                                                <Typography variant="subtitle1">{userDisplayName(itm.users)}</Typography>
                                                <Typography variant="body2" color="textSecondary" className="oneLineEllip">{itm.notification_message || ""}</Typography>
                                            </Grid>
                                            <Grid>
                                                <Typography variant="subtitle2" color="textSecondary" className={classes.time}>{moment(itm.notification_time).fromNow()}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </MenuItem>
                            )
                        })} */}

            {newChat
              ? newChat
                .slice(0)
                .reverse()
                .map((item, indx) => {
                  return getChatListItem(item, indx);
                })
              : null}
            {newChat.length > 10 && (
              <Typography
                variant="h6"
                className={`${"textGradient pl-2 pr-2 pt-1 pb-1"} ${classes.link
                  }`}
              >{`+ ${parseInt(newChat.length) - 10} more messages`}</Typography>
            )}
          </MenuList>
        )}
      </Popover>

      <Popover
        className={classes.notificationPopUp}
        id={id1}
        open={open1}
        anchorEl={anchorEl1}
        onClose={handleClose1}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuList>
          {tasks_n.length < 1 ? (
            <Grid
              item
              className={`${classes.noMessageContainer} noMessageContainer`}
            >
              {/* <img src={noTask} className="noMessageImg" /> */}
              <NoTasks />
              <Typography className={classes.dropTitle} variant="h4">
                No Tasks
              </Typography>
            </Grid>
          ) : (
            <Typography className={classes.dropTitle} variant="h4">
              Tasks
            </Typography>
          )}

          {tasks_n &&
            tasks_n
              .slice(0)
              .reverse()
              .map((itm, inx) => {
                let userAvatar =
                  ag_users && ag_users.length > 0
                    ? ag_users.filter((img) => itm.users.id === img.id)
                    : [];
                if (userAvatar.length > 1) {
                  userAvatar = userAvatar.filter(
                    (val) => val.avatar && val.avatar !== null
                  );
                }
                return (
                  <MenuItem
                    onClick={() => {
                      updateNotification(itm.id, itm, "task");
                    }}
                    key={inx}
                  >
                    <ListItemIcon>
                      <Avatar
                        className={classes.menuDropAvatar}
                        alt={
                          itm && itm.users && itm.users.id ? itm.users.id : ""
                        }
                        src={
                          userAvatar.length > 0 && userAvatar[0]
                            ? userAvatar[0].avatar
                            : "/static/images/avatar/1.jpg"
                        }
                      />
                    </ListItemIcon>
                    <Grid className={classes.notifiMessage2}>
                      <Typography variant="subtitle1">
                        {userDisplayName(itm.users)}
                        <span className={classes.TypeMessage}>
                          Assigned a task:{" "}
                        </span>
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        className="oneLineEllip"
                      >
                        {itm.notification_message || ""}
                      </Typography>
                      {/* <Typography variant="subtitle2" color="textSecondary" className={classes.time}>2 Months Ago</Typography> */}
                    </Grid>
                  </MenuItem>
                );
              })}
        </MenuList>
      </Popover>

      <Popover
        className={classes.notificationPopUp}
        id={id2}
        open={open2}
        anchorEl={anchorEl2}
        onClose={handleClose2}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuList>
          {invites_n.length < 1 ? (
            <Grid
              item
              className={`${classes.noMessageContainer} noMessageContainer`}
            >
              <img src={noInvites} className="noMessageImg" />
              <Typography className={classes.dropTitle} variant="h4">
                No Invites
              </Typography>
            </Grid>
          ) : (
            <Typography className={classes.dropTitle} variant="h4">
              Invites
            </Typography>
          )}
          {invites_n.length &&
            invites_n
              .slice(0)
              .reverse()
              .map((itm) => {
                let { agencyData } = itm;
                return (
                  <MenuItem
                    onClick={() => {
                      updateNotification(itm.id, itm, "invite");
                    }}
                  >
                    <ListItemIcon>
                      <Avatar
                        className={classes.menuDropAvatar}
                        alt={agencyData?.agency_name}
                        src={
                          agencyData && agencyData.agency_logo
                            ? agencyData?.agency_logo
                            : "/static/images/avatar/1.jpg"
                        }
                      />
                    </ListItemIcon>
                    <Grid className={classes.notifiMessage2}>
                      <Typography variant="subtitle1">
                        <b>{agencyData?.agency_name}</b>{" "}
                        {itm.notification_sub_type == "wsm_2"
                          ? "Accepted :"
                          : itm.notification_sub_type == "wsm_3"
                          ? "Rejected :"
                          : itm.notification_sub_type == "wsm_4"
                          ? "Stopped :"
                          : "Invite :"}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        className="oneLineEllip"
                      >
                        {itm.notification_message || ""}
                      </Typography>
                    </Grid>
                  </MenuItem>
                );
              })}
        </MenuList>
      </Popover>
    </>
  );
}

// default props
Notification.defaultProps = {
  classes: {},
  isPublic: true,
};

// prop types
Notification.propTypes = {
  classes: PropTypes.object,
  isPublic: PropTypes.bool,
};

export default withStyles(HeaderStyles)(Notification);

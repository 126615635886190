import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core'

//Import Component
import { Typography } from '../Typography'
import Placeholder from '../../assets/images/svg/link.svg'

const TinyUrlMessage = (props) => {
    const { classes, urlData, messageUrl } = props;
    return (
        <>
            <a target="_blank" href={messageUrl.url} rel="noopener noreferrer" className="decorationNone">
                <Grid className={classes.linkListDiv}>
                    <Grid className={classes.linkListImgDiv}>
                        <Grid className={classes.linkListImg}>
                        <img src={urlData ? (Array.isArray(urlData.image) && urlData.image.length > 0 ? urlData.image[0] : (urlData.image != null ? urlData.image : Placeholder)) : Placeholder}  alt="linkImg" />
                        </Grid>
                        <Grid className={classes.linkListDesc}>
                            <Typography variant="subtitle1" className={`oneLineEllip ${classes.linkTitle}`}>{urlData && urlData.title}</Typography>
                            <Typography variant="body2" className={`twoLineEllip ${classes.linkDesc}`}>{urlData.description || messageUrl.url}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </a>
        </>
    )
}


// default props
TinyUrlMessage.defaultProps = {
    classes: {}
};

// prop types
TinyUrlMessage.propTypes = {
    classes: PropTypes.object
};

export default TinyUrlMessage

import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Grid, SwipeableDrawer, Typography, useMediaQuery, withStyles } from '@material-ui/core';

// Imports Styles
import styles from "./styles";
import { CloseIcon } from '../../../assets/svg';

/**
 * Google Login Button Component
 * @param {*} props
 */



function MobileActionSheetComponent(props) {
    const { classes, title, actionItems, handleActionSelect, selectedAction, handleCloseAction, subtitle, className, open } = props;

    // const [closeSheet, handleCloseSheet] = useState(false)
    const isMobile = useMediaQuery('(max-width: 899px)');

    const handleClose = (e) => {
        e?.preventDefault();
        // handleCloseSheet(true);
        handleCloseAction(e);
        //     setTimeout(() => {
        //     handleCloseAction(e);
        // }, 200);
    }

    const ActionCard = () => (
        <Fragment>
            {(title || subtitle) && (
                <Grid container className={`${classes.actionSheetHeader} ${className}`}>
                    <Grid item xs={10}>
                        <Typography variant="h5" color="primary" className="fw-600">{title}</Typography>
                        <Typography variant="body1" color="textSecondary">{subtitle}</Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.ActionCloseContainer} onClick={handleCloseAction}>
                        <CloseIcon />
                    </Grid>
                </Grid>
            )}
            <Grid container className={classes.DividerContainer}>
                <Grid xs={12}>
                    <Divider className={classes.Divider} />
                </Grid>
            </Grid>
            {
                props?.children ? props.children :
                    actionItems.map(column => (
                        <Grid
                            container
                            className={`${selectedAction === column.value ? classes.actionSelected : classes.actionOption}`}
                            onClick={() => {
                                handleClose();
                                handleActionSelect(column.value)
                            }}
                        >
                            <Grid>
                                <Typography variant="body1" color="primary">
                                    {column.label}
                                </Typography>
                            </Grid>
                            {/* <Grid className={`${!sortColumn.isAcending ? classes.sortChevron : classes.rotateChevron}`}>
                            <CTSortChevron />
                        </Grid> */}
                        </Grid>
                    ))
            }
        </Fragment>
    )

    const ActionCardContainer = (props = {}) => (
        <Fragment>
            <Box {...(isMobile ? props : {})} display={"flex"} justifyContent={"center"} className='disable-user-select'>
                <Box p={0} className={classes.actionBarLine}></Box>
            </Box>
            <ActionCard />
        </Fragment>
    )

    return (
        <Fragment>
            <SwipeableDrawer
               disableSwipeToOpen={true}
                anchor={'bottom'}
                open={open}
                onClose={handleClose}
                className={classes.swipeableDrawer}
            >
                <ActionCardContainer />
            </SwipeableDrawer>
        </Fragment>
    );
}

// Default props
MobileActionSheetComponent.defaultProps = {
    classes: {},
    handleCloseAction: () => { },
    title: '',
    actionItems: [],
    handleActionSelect: () => { },
    selectedAction: '',
    open: true
};

// Prop types
MobileActionSheetComponent.propTypes = {
    classes: PropTypes.object,
    open: PropTypes.bool
};

/** Export Component */
export const MobileActionSheet = withStyles(styles)(MobileActionSheetComponent);

const styles = (theme) => ({
  cardRoot: {
    background: "#F2F5FA",
    boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.13)",
    // borderRadius: "0px 16px 16px 16px",
    padding: "30px 20px 20px",
    width: "100%",
    maxWidth: "290px",
    position: "relative",
    textAlign: "left",
    "& .department": {
      display: "flex",
      alignItems: "center",
      "&>span": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "17px",
        height: "17px",
        marginRight: '10px',
        "& span": {
          display: 'block',
          width: "5px",
          height: "5px",
          borderRadius: '1px'
        },
      },
    },
    "& .dateShow": {
      background: "#FFFFFF",
      borderRadius: "8px",
      height: "32px",
      fontSize: "12px",
      fontWeight: "500",
      color: "#64748B",
      display: "inline-flex",
      alignItems: "center",
      padding: "10px",
      marginBottom: "16px",
    },
    "& .statusChip": {
      height: "24px",
      borderRadius: "4px",
      marginBottom: "18px",
      fontSize: "12px",
      fontWeight: "700",
      display: "inline-flex",
      alignItems: "center",
      padding: "0px 12px",
    },
    "& .priorityChip": {
      height: "26px",
      borderRadius: "8px",
      marginBottom: "18px",
      fontSize: "12px",
      fontWeight: "700",
      display: "inline-flex",
      alignItems: "center",
      padding: "0px 12px",
      color: "#fff",
    },
    "& .clickHereBtn": {
      color: "#2563EB !important",
      background: "#fff !important",
      height: "30px",
      boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.13)",
      borderRadius: "12px",
    },
    '& .cardLogo': {
      "& .avatarComp, & .MuiAvatar-root": {
        height: "40px",
        width: "40px",
      },
    },
    '& .MuiLinearProgress-colorPrimary': {
      height: '8px',
      borderRadius: '50px',
      background: '#E4ECF3',
      marginBottom: '5px'
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#2563EB',
      borderRadius: '50px'
    }
  },
});

export default styles;

const overrides = (theme) => ({
  MuiOutlinedInput: {
    input: {
      fontSize: 14,
      padding: '9px 16px',
      borderRadius:"7px"
    },
    root: {
      borderRadius: "7px",
      borderColor: theme.palette.primary.border1,
      backgroundColor: theme.palette.containers.bg1,
      color: theme.palette.text.text5,
      
      "& input": {
        '&:-webkit-autofill': {
            "-webkit-box-shadow": `none`,
            "-webkit-text-fill-color": `#949599`
        }
    },

      height: 40,
      '&:hover $notchedOutline': {
        borderColor: 'rgb(95 95 95 / 87%)'
      },
      '& .MuiAutocomplete-input': {
        margin: '-9px !important',
        // paddingLeft: '14px !important'
      },
      '& legend': {
        lineHeight: '14px'
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.border1,
        top: '-8px'
      },
      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: `${theme.palette.text.blueText} !important`
        }
      }
    }
  },
  MuiRadio: {
    root: {
      '&.MuiRadio-colorPrimary.Mui-checked': {
        color: '#2563EB !important'
      }
    }
  },
  MuiBadge: {
    root: {
      '& .MuiBadge-colorPrimary': {
        background: theme.palette.primary.gradientBtn,
      }
    }
  },
  MuiAutocomplete: {
    root: {
      borderColor: theme.palette.primary.border1,
      backgroundColor: theme.palette.containers.bg1,
      color: theme.palette.text.text1,
    }
  },
  MuiPaper: {
    root: {
    }
  },
  MuiListItem: {
    root: {
      color: theme.palette.primary.main
    }
  },
  MuiFormHelperText: {
    root: {
      fontSize: '12px !important',
      marginTop: '2px !important',
      marginLeft: '0px !important'
    }
  },

  MuiButton: {
    root: {
      textTransform: "none",
      borderRadius: 0,
      // lineHeight: '0 !important',
      fontWeight: 400,
      // fontFamily: 'DINPro-Medium',
      '&.Mui-disabled': {
        color: '#6a6a6a !important',
        backgroundColor: `${theme.palette.disabled.disabled1.bg} !important`,
        background: '#EBECED !important',
      },
    },
    containedSecondary: {
      backgroundColor: `${theme.palette.primary.disabled}!important`,
      color: `${theme.palette.primary.light} !important`,
    },
    containedPrimary: {
      background: `linear-gradient(90deg, #5351FC 0%, #19A9FC 100%) !important`,
      // background: `${theme.palette.primary.gradientBtn} !important`,
      color: theme.palette.text.contrastText,
      borderRadius: '7px !important',
      '&:hover': {
        opacity: 0.8
      }
    },
    outlinedSecondary: {
      backgroundColor: `${theme.palette.containers.card} !important`,
      color: `${theme.palette.primary.main} !important`,
      borderRadius: '7px !important',
      '&:hover': {
      }
    },
    outlinedSizeSmall: {
      fontSize: 14,
      padding: '7px 15px',
      border: `1px solid ${theme.palette.primary.border1}`,
      fontFamily: 'Inter, sans-serif',
      fontWeight: 500,
      borderRadius: 7
    },
    outlinedSizeMedium: {
      fontSize: 14,
      padding: '7px 20px',
      border: '1px solid #101010',
      borderRadius: 0,
    },
    outlinedSizeLarge: {
      fontSize: 15,
      padding: '10px 20px',
      border: `1px solid ${theme.palette.primary.border1}`,
      borderRadius: 7,
    },
    containedSizeLarge: {
      fontSize: 15,
      padding: '10px 20px',
      fontWeight: 500,
      borderRadius: 7
    },
    containedSizeSmall: {
      borderRadius: '7px !important',
      fontSize: 14,
      padding: '7px 20px',
    },
  },
  MuiIconButton: {
    root: {
      padding: '6px',
    }
  },
  MuiAppBar: {
    colorPrimary: {
      backgroundColor: theme.palette.greyshades.main,
    },
    root: {
      boxShadow: 'none',
    },
  },
  MuiToolbar: {
    root: {
      minHeight: '50px!important',
    },
    gutters: {
      paddingLeft: '20px!important',
      paddingRight: '20px!important',
    },
  },
  MuiTableCell: {
    root: {
      fontSize: '14px',
      fontWeight: '500',
    },
  },
  MuiTabs: {
    root: {
    },
    scroller: {
      borderBottom: '1px solid #8080801c',
      '& .Mui-selected': {
        '& .MuiTypography-colorTextSecondary': {
          color: `${theme.palette.primary.main} !important`
        }
      }
    }
  },
  MuiTab: {
    root: {
      minHeight: '40px !important',
      textTransform: 'capitalize'
    }
  },
  MuiSelect: {
    root: {
      '&:focus': {
        borderRadius: 30
      }
    }
  },
  MuiTypography: {
    root: {
      color: theme.palette.primary.main,
      colorTextSecondary: theme.palette.primary.light,
      '&.MuiTypography-colorTextPrimary': {
        color: theme.palette.primary.main
      },
      '&.MuiTypography-colorTextSecondary': {
        color: theme.palette.primary.light
      },
      '&.MuiTypography-colorTextPrimary.colorWhite': {
        color: theme.palette.text.contrastText
      },
    }
  },
  MuiLink: {
    underlineHover: {
      '&:hover': {
        textDecoration: 'none',
        color: theme.palette.primary.dary
      }
    }
  },
  MuiInputAdornment: {
    positionStart: {
      color: '#AEAEAE',
    },
    positionEnd: {
      color: '#cccccc',
    },
  },
  MuiStepLabel: {
    root: {
      flexDirection: 'column'
    }
  },
  MuiStepButton: {
    root: {
      padding: '15px 16px'
    }
  },
  MuiStepper: {
    root: {
      '& .MuiStepLabel-label': {
        marginTop: 10
      },
      '& .MuiStepConnector-lineHorizontal': {
        borderTopWidth: 6,
        borderColor: theme.palette.greyshades.light2
      }
    }
  },
  MuiDivider: {
    root: {
      width: '100%'
    }
  },
  MuiAvatar: {
    colorDefault: {
      background: 'linear-gradient(180deg, #5154F8 0%, #1AA5FB 100%)'

    }
  },
  MuiSwitch: {
    root: {
      '& .Mui-checked': {
        '& .MuiSwitch-thumb': {
          background: '#2563EB !important'
        },
      },
      '& .MuiSwitch-thumb': {
        background: '#fff !important'
      }
    }
  },
  MuiCheckbox: {
    root: {
  color: theme.palette.primary.main
}
  },
  MuiFormControlLabel: {
    root: {
      color: theme.palette.primary.main
    }
  },
  MuiPaper: {
    root: {
      '&.MuiDialog-paper, &.MuiPopover-paper': {
        background: theme.palette.containers.card,
        '& .MuiPickersDay-daySelected': {
          background: `${theme.palette.containers.bg20} !important`,
          color: theme.palette.text.text5
        },
        '& .MuiPickersDay-day': {
          color: theme.palette.text.text5
        },
        '& .MuiPickersDay-dayDisabled': {
          color: theme.palette.text.text6
        },
        '& .MuiPickersCalendarHeader-transitionContainer': {
          '& .MuiTypography-root ': {
            color: theme.palette.primary.main
          }
        }
      }
    }
  }
});

export default overrides;
import moment from "moment";
import PropTypes from "prop-types";
import React, { Fragment, forwardRef, useEffect, useImperativeHandle, useState } from "react";

import {
    Button,
    Grid,
    Menu,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useMediaQuery,
    withStyles,
} from '@material-ui/core';

import { Pagination, Skeleton } from '@material-ui/lab';
import { MoreHorizontal2 } from '../../../assets/svg';
import Nodata from "../NoDataComponent/Nodata";
import { CustomTableHeader } from "./CustomTableHeader";
import Style from "./styles";


/**
* 
< ------------------------------------------ ColumnConfig ---------------------------------------------->
    
    It is an Array which is used to configure each columns by passing a configuration json.

    <---- Column Config Options ---->
    
    key: Object key from which the column gets the data
    
    lable: Column Name that is displayed for each column
    
    componentMap: array of key value pairs. Values will be mapped with the key and replacted by the value
        - eg data = {1, 2 ,3} componentMap = { '1': <Grid>Show 1</Grid>, '2': <Grid>Show 2</Grid>, '3': <Grid>Show 3</Grid> }
    
    customLabelComponent: Custom component for the lable which is showed for each column name.
    
    moreOptions: Array of label and handler. These labels will be showed in a button and the handlers will be assinged to onClick of each button.

    type: We have twoe type i.e more_options and custom_component. 
        - When a column's type is more options three dots will be showed and the options passed in moreOptions array will be renderd when hovered.
        - When a column's type is custom_component is will use the custom component passed in the column config.

    hideSort: Hides a column from the sort option.

    componentGenerator: Funcion that get tableProps and should return a html or component based on the tableProps.

    renderValidator : Function for displaying Option based on conditions 

*/

/**
 * Custom Table Component
 *
 * @class CustomTable
 * @extends {Component}
 */
const CSTable = forwardRef((props, ref) => {

    // Handle TextField Events
    const {
        tableData,
        columnConfig,
        enableHeader,
        tableName,
        rowsPerPage,
        currentPage,
        rowsPerPageOptions,
        totalRecords,
        handleSort,
        handleSearch,
        onPageChange,
        onRowsPerPageChange,
        handleToggleButton,
        siblingCount,
        boundaryCount,
        classes,
        headerBtnLabel,
        enableHeaderBtn,
        enableHeaderSearch,
        onHeaderBtnClick,
        enableFilter,
        loading,
        tableFooterComponent,
        TableResponsive,
        handleTabChange,
        tabValue,
        tabList,
        enableTab,
        filterData,
        handleFilterChange,
        onFilterSubmit,
        enableBackBtn,
        handleBack,
        searchKey,
        isPagination = true,
        isBooking,
        filterKeys,
        handleFilterData,
        setBlockUserspopup,
        emptyDataConfig,
        enableNodataFound,
        InputProps,
        filterCount,
        paginationStyle,
        dateWidth,
        enableDateFilter,
        enableBlockUserButton,
        enableNoSearchResultsFound,
        disableIcon,
        applyFilter,
        dateRange,
        setDaterange,
        openDatePicker,
        setopenDatePicker,
        clearFilter
    } = props;

    const [pageCount, setPageCount] = useState(0)
    const [rowLimit, setRowLimit] = useState(5)
    const [loaderDummy, setLoaderDummy] = useState([null, null, null, null, null, null, null])
    const [anchorEl, setAnchorEl] = useState(null);
    const [moreOpenRow, setMoreOpenRow] = useState(-1);
    const [moreOptions, setMoreOptions] = useState([]);
    const openMore = Boolean(anchorEl);
    const openMoreId = openMore ? 'more-options-popover' : undefined;
    const isMobile = useMediaQuery('(max-width:768px)');
    const isDesktop = useMediaQuery('(min-width:1025px)');

  const [openSearch, setOpenSearch] = useState(false);


    useImperativeHandle(ref, () => ({

        openMoreOption(e, data, row_index, config) {
            openMoreOptions(e, data, row_index, config);
        }

    }));


    const handleRowsPerPage = (value) => {
        setLoaderDummy(Array.apply(null, Array(parseInt(value))))
        setRowLimit(value);
        onRowsPerPageChange(value);
    }

    const handlePageChange = (event, value) => {
        onPageChange(event, value);
    }

    const mapValueToComponent = (cMap, value) => {
        console.log("🚀 ~ mapValueToComponent ~ cMap:", cMap)
        return cMap.hasOwnProperty(value) ? cMap[value] : cMap['default']
    }

    const openMoreOptions = (event, row, row_index, config) => {
        let moreOptions = config.moreOptions
        setMoreOptions(moreOptions)
        setMoreOpenRow(row_index);
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMore = () => {
        setMoreOptions([])
        setMoreOpenRow(-1);
        setAnchorEl(null)
    }

    const dataShortFormat = function (date) {

        // we can use moment's native fn  moment(new Date()).format("DD MMM YYYY"); -> 27 Mar 2024;

        const monthNames = ["Jan", "Feb", "Mar", "Apr",
            "May", "Jun", "Jul", "Aug",
            "Sep", "Oct", "Nov", "Dec"];

        const day = date.getDate();

        const monthIndex = date.getMonth();
        const monthName = monthNames[monthIndex];

        const year = date.getFullYear();

        return `${day} ${monthName} ${year}`;
    }

    const formatDataIfRequireied = (data, format) => {

        switch (format) {
            case 'date':
                return dataShortFormat(new Date(data));
            default:
                return data;
        }

    }


    const getCellContent = (data, row_index, config, col_index) => {
        const column_type = config.hasOwnProperty('type') ? config.type : 'default';


        switch (column_type) {
            case 'more_options':
                return (
                    <Fragment>
                        <Button
                            className={`${moreOpenRow === row_index ? '' : "showOnRowHover"} ${classes.CTMoreIcon}`}
                            size="small"
                            variant="contained"
                            color="secondary"
                            onClick={(e) => { openMoreOptions(e, data, row_index, config) }}
                        >
                            <MoreHorizontal2 color={userPrefTheme === "dark" && "#fff"} />
                        </Button>
                    </Fragment>
                )
            case 'custom_component':
                return (
                    <Fragment>
                        {config.componentGenerator({ data, row_index, config, col_index })}
                    </Fragment>
                )
            case 'date':
                return (
                    <Fragment>
                        {moment(data[config.key]).format(config?.option)}
                    </Fragment>
                )
            default:
                return (
                    <Fragment>
                        {
                            config.hasOwnProperty('componentMap') ?
                                mapValueToComponent(config.componentMap, data[config.key])
                                :
                                `${data.hasOwnProperty(config.prefixKey) && data[config.prefixKey] != null ? data[config.prefixKey] : config.prefix ? config.prefix : ''}${data.hasOwnProperty(config.key) && data[config.key] != null ? formatDataIfRequireied(data[config.key], config.format) : ""}`

                        }
                    </Fragment>
                )
        }
    }

    useEffect(() => {
        if (!loading) {
            if (totalRecords != 0) {
                let pgCount = totalRecords / rowLimit;
                pgCount = Math.ceil(pgCount);
                setPageCount(pgCount);
            } else {
                setPageCount(0);
            }
        }
    }, [totalRecords, rowLimit, loading]);

    useEffect(() => {
        if (rowsPerPage != rowLimit) {
            setLoaderDummy(Array.apply(null, Array(parseInt(rowsPerPage))))
            setRowLimit(rowsPerPage)
        }
    }, [rowsPerPage]);

    useEffect(() => {
        if (!rowsPerPageOptions.includes(rowLimit)) {
            setRowLimit(rowsPerPageOptions[0])
        }
    }, [rowsPerPageOptions]);

    let userPrefTheme = localStorage.getItem('themeType')
    return (
        <Grid container className={`relative h-100`} alignContent="flex-start">
            {
                enableHeader &&
                <Grid item xs={12} className={`${classes.CTHeaderWrapper} page-spacing`}>
                    <CustomTableHeader
                        handleSort={handleSort}
                        handleSearch={handleSearch}
                        tableName={tableName}
                        buttonLabel={headerBtnLabel}
                        handleToggleButton={handleToggleButton}
                        enableBtn={enableHeaderBtn}
                        enableSearch={enableHeaderSearch}
                        handleAdd={onHeaderBtnClick}
                        columnConfig={filterKeys ? filterKeys : columnConfig}
                        handleFilterData={handleFilterData}
                        enableFilter={enableFilter}
                        tabList={tabList}
                        handleTabChange={handleTabChange}
                        tabValue={tabValue}
                        enableTab={enableTab}
                        filterData={filterData}
                        handleFilterChange={handleFilterChange}
                        onFilterSubmit={onFilterSubmit}
                        enableBackBtn={enableBackBtn}
                        handleBack={handleBack}
                        search={searchKey}
                        tableFooterComponent={tableFooterComponent}
                        isBooking={isBooking}
                        setBlockUserspopup={setBlockUserspopup}
                        InputProps={InputProps}
                        dateWidth={dateWidth}
                        enableDateFilter={enableDateFilter}
                        enableBlockUserButton={enableBlockUserButton}
                        filterCount={filterCount}
                        applyFilter={applyFilter}
                        dateRange={dateRange}
                        setDaterange={setDaterange}
                        openDatePicker={openDatePicker}
                        setopenDatePicker={setopenDatePicker}
                        clearFilter={clearFilter}
                        openSearch = {openSearch}
                        setOpenSearch = {setOpenSearch}
                    ></CustomTableHeader>
                </Grid>
            }

            {((!enableNodataFound && !enableNoSearchResultsFound) || tableData.length > 0 || loading) && (
                <Grid item xs={12} className={`${isMobile ? 'p0' : ''} ${classes.CSTableContainer} CSTableContainer ${openSearch ? 'CSTableContainerWithSearch' : '' }`} >
                    <Grid container className="h-100" alignContent="flex-start">
                        <Grid item xs={12} className={`${classes.CSTableWrapper} CSTableWrapper customHeight`}>
                            <Grid className={classes.H_100}>
                                <Grid container className={classes.H_100}>
                                    <TableContainer className={`${classes.H_100} TableContainer`}>
                                        <Table stickyHeader className={TableResponsive ? classes.CSDesktop : ""}>

                                            <TableHead>
                                                <TableRow className={classes.CSRow} >
                                                    {
                                                        columnConfig.map((config, index) => (
                                                            !config.hideColumn && (
                                                                <TableCell key={index} className={`${classes.CSCell} ${classes.CSColumnHeader}`} >
                                                                    {
                                                                        config.hasOwnProperty('customLabelComponent') ? config.customLabelComponent : config.label
                                                                    }
                                                                </TableCell>
                                                            )
                                                        ))
                                                    }
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {
                                                    !loading ?
                                                        tableData.map((data, row_index) => (
                                                            <TableRow key={row_index} className={`${classes.CSRow} ${classes.CSRowHover}`}>
                                                                {
                                                                    columnConfig.map((config, col_index) => (
                                                                        !config.hideColumn && (
                                                                            <TableCell key={col_index} className={classes.CSCell}>
                                                                                {getCellContent(data, row_index, config, col_index)}
                                                                            </TableCell>
                                                                        )
                                                                    ))
                                                                }
                                                            </TableRow>
                                                        ))
                                                        :
                                                        loaderDummy.map((_row_dummy, row_index) => (
                                                            <TableRow key={row_index} className={`${classes.CSRow} ${classes.CSRowHover}`}>
                                                                {
                                                                    columnConfig.map((_column_dummy, col_index) => (
                                                                        !_column_dummy.hideColumn && (
                                                                            <TableCell key={col_index} className={classes.CSCell}>
                                                                                <Skeleton variant="text" animation='wave' sx={{ fontSize: '1rem' }} />
                                                                            </TableCell>
                                                                        )
                                                                    ))
                                                                }
                                                            </TableRow>
                                                        ))
                                                }
                                            </TableBody>

                                        </Table>
                                        {
                                            TableResponsive &&
                                            <Grid className={classes.CSMobileCard}>
                                                <TableResponsive {...props} />
                                            </Grid>
                                        }
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </Grid>
                        {
                            (isDesktop || isPagination) &&
                            <Grid item xs={12} className={classes.CSPaginationWrapper}>
                                <Grid container className={"alignCenter"} spacing={1}>
                                    <Grid item xs={12} md={8}>
                                        <Grid container justifyContent={isMobile ? "center" : "flex-start"} className={`${isMobile ? '' : 'nowrap'}`} alignItems="center">
                                            {
                                                isDesktop &&
                                                <Fragment>
                                                    <Grid item>
                                                        <Typography className={`${classes.summaryshowRow} whiteSpacNowrap`} color="primary">Show rows : </Typography>
                                                    </Grid>
                                                    <Grid item className={paginationStyle ? paginationStyle : classes.rows}>
                                                        <Select
                                                            disableUnderline
                                                            value={rowLimit}
                                                            onChange={(e) => handleRowsPerPage(e.target.value)}
                                                            className={`${classes.CSSelect} `}
                                                        >
                                                            {rowsPerPageOptions.map((item, index) => (
                                                                <MenuItem key={index} value={item}>
                                                                    {item} items
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </Grid>
                                                    <Grid item>
                                                        {
                                                            tableFooterComponent
                                                        }
                                                    </Grid>
                                                </Fragment>
                                            }
                                        </Grid>
                                    </Grid>
                                    {
                                        isPagination &&
                                        <Grid item xs={12} md={4} className={`${classes.CSShowRow} ${classes.CSPagination} ${isMobile ? 'justifyCenter' : 'justifyFlexEnd'}`}>
                                            <Pagination
                                                variant="outlined"
                                                shape="rounded"
                                                count={pageCount}
                                                page={currentPage}
                                                onChange={handlePageChange}
                                                siblingCount={!isMobile ? siblingCount : 0}
                                                boundaryCount={!isMobile ? boundaryCount : 0}
                                            />
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        }
                    </Grid >
                </Grid >
            )}


            {(enableNodataFound || enableNoSearchResultsFound) && tableData.length === 0 && !loading && (
                <Grid item xs={12} className={`${isMobile ? 'p0' : ''} ${classes.CSTableContainer} CSTableContainer`}>
                    <Nodata
                        title={emptyDataConfig?.emptyTitle}
                        description={emptyDataConfig?.emptyDescription}
                        buttonText={emptyDataConfig?.emptyButtonText}
                        BtnOnClick={onHeaderBtnClick}
                        enableButton={emptyDataConfig?.enableButton}
                        enableNoSearchResultsFound={enableNoSearchResultsFound}
                        disableIcon={disableIcon}
                    />
                </Grid>
            )}
            <Menu
                id={openMoreId}
                anchorEl={anchorEl}
                open={openMore}
                onClose={handleCloseMore}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                className={classes.CSTablePopover}
            >
                {
                    moreOptions.map(option => (
                        <MenuItem
                            className={option?.renderValidator && option.renderValidator(tableData[moreOpenRow]) ? classes.hide : classes.moreOptionButton}
                            onClick={(e) => {
                                handleCloseMore();
                                option.handler(e, tableData[moreOpenRow]);
                            }}
                        >
                            {option?.label}
                        </MenuItem>
                    ))
                }
            </Menu>
        </Grid >
    );
})

// default props
CSTable.defaultProps = {
    tableData: [],
    columnConfig: [],
    enableHeader: true,
    tableName: "Data Table",
    rowsPerPageOptions: [5, 10, 15],
    component: "div",
    totalRecords: 0,
    rowsPerPage: 0,
    currentPage: 1,
    siblingCount: 0,
    boundaryCount: 1,
    classes: {},
    headerBtnLabel: "New",
    enableHeaderBtn: true,
    enableHeaderSearch: true,
    onHeaderBtnClick: () => { },
    handleSort: () => { },
    handleSearch: () => { },
    changePage: () => { },
    onPageChange: () => { },
    onRowsPerPageChange: () => { },
    enableFilter: true,
    loading: true,
    tableFooterComponent: null,
    tabList: [],
    enableTab: false,
    handleTabChange: () => { },
    filterData: {},
    handleFilterChange: () => { },
    onFilterSubmit: () => { },
    enableBackBtn: false,
    handleBack: () => { },
    search: '',
    isPagination: true,
    enableNodataFound: false,
    enableNoSearchResultsFound: false,
};

CSTable.propTypes = {
    tableData: PropTypes.array,
    columnConfig: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            lable: PropTypes.string,
            componentMap: PropTypes.array,
            customLabelComponent: PropTypes.any,
            moreOptions: PropTypes.array,
            type: PropTypes.string,
            hideSort: PropTypes.bool,
            customComponent: PropTypes.any,
            format: PropTypes.string
        })
    ),
    enableHeader: PropTypes.bool,
    tableName: PropTypes.string,
    rowsPerPage: PropTypes.number,
    currentPage: PropTypes.number,
    rowsPerPageOptions: PropTypes.array,
    component: PropTypes.any,
    totalRecords: PropTypes.number,
    page: PropTypes.number,
    classes: PropTypes.object,

    handleSort: PropTypes.func,
    handleSearch: PropTypes.func,
    onPageChange: PropTypes.func,
    onRowsPerPageChange: PropTypes.func,

    headerBtnLabel: PropTypes.string,
    enableHeaderBtn: PropTypes.bool,
    enableHeaderSearch: PropTypes.bool,
    onHeaderBtnClick: PropTypes.func,
    enableFilter: PropTypes.bool,
    loading: PropTypes.bool,
    tableFooterComponent: PropTypes.any,
    tabList: PropTypes.array,
    enableTab: PropTypes.bool,
    handleTabChange: PropTypes.func,

    filterData: PropTypes.object,
    handleFilterChange: PropTypes.func,
    onFilterSubmit: PropTypes.func,

    enableBackBtn: PropTypes.bool,
    handleBack: PropTypes.func,

    search: PropTypes.string,
    isPagination: PropTypes.bool,
    enableNodataFound: PropTypes.bool.isRequired,

};

// Export Component
export const CustomTable = withStyles(Style)(CSTable);
const styles = (theme) => ({
    autocomplete: {
        '.MuiAutocomplete-root': {
            height: '56px'
        }
    },
    tagInputField: {
        "& input": {
            minHeight: "3rem",
            '@media only screen and (max-width: 599px)': {
                width:'100% !important'
            }
            
        }
    },
    renderTag:{
        '@media only screen and (max-width: 599px)':{
        '& .MuiPopover-paper':{
        padding:'10px !important',
        maxHeight:'100% !important',
        maxWidth:'100 !important',
        overflowY:'auto !important',
        }
    }
    },
});

export default styles;

const styles = (theme) => ({
    autoComplete: {
        '& .MuiOutlinedInput-root': {
            padding: '0 9px',
            height: 40,
            minHeight: 40,
            borderRadius:'7px'

        },
        '& .MuiAutocomplete-root': {
            borderRadius:'7px'
        }
    },
    option: {
        color: theme.palette.primary.main,
    },
    popoverPaper: {
        boxShadow: theme.palette.shadows.shadow7
    },
    input:{
        marginLeft:20
    }
})

export default styles
const styles = (theme) => ({
    actionSheetContainer: {
        position: "relative",
        height: "100%"
    },
    sheetBackdrop: {
        position: "fixed",
        background: "rgb(0 0 0 / 39%)",
        height: "100%",
        top: "0",
        width: "100%",
        left: "0",
        zIndex: "999",
        animation: `$slideEffect 0.5s ease-in-out`,
    },
    actionSheetCard: {
        zIndex: "1000",
        position: "fixed",
        bottom: "0px",
        padding: "10px 0",
        background: theme.palette.containers.card,
        left: "0px",
        borderRadius: "18px 18px 0px 0px",
        width: "100%",
        maxHeight: "90%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        textAlign: "left",
        animation: `$slide 0.5s ease-in-out`,
        paddingBottom: 'env(safe-area-inset-bottom)',
        '&.actionSheetCardClosed': {
            animation: `$slide-out 0.5s ease-in-out`
        }
    },
    actionBarLine: {
        height: "7px",
        borderRadius: "6px",
        background: "#D1D4DB",
        width: "25px",
        margin: "12px",
    },
    actionSheetHeader: {
        display: "flex",
        height: "50px",
        justifyContent: "space-between",
        color: "var(--greyscale-900, #0F172A)",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "140%" /* 19.6px */,
        letterSpacing: "0.2px",
        textTransform: "uppercase",
        alignItems: 'center',
        padding: "10px 20px"
    },
    ActionTitle: {
        fontSize: "14px",
        fontWeight: "700",
        lineHeight: "20px",
        display: "flex",
        alignItems: "center",
        color: theme.palette.primary.main,
    },
    ActionCloseContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: theme.palette.containers.bg7,
        borderRadius: "6px",
        height: "24px",
        width: "24px",
        cursor: "pointer",
        "& .CloseIcon": {
            height: "10px",
            width: "10px"
        }
    },
    DividerContainer: {
        justifyContent: "center",
    },
    actionSelected: {
        background: "#2563EB",
        padding: "12px 20px",
        color: "#FFFFFF",
        cursor: "pointer",
        '& .MuiTypography-colorPrimary': {
            color: "#FFFFFF",
        }
    },
    actionOption: {
        background: theme.palette.containers.card,
        padding: "10px 20px",
        cursor: "pointer",
    },
    "@keyframes slide": {
        "0%": {
            opacity: "1",
            bottom: "-100%",
        },
        "100%": {
            opacity: 1,
            bottom: "0",
        },
    },
    "@keyframes slide-out": {
        "0%": {
            opacity: "1",
            bottom: "0",
        },
        "100%": {
            opacity: 1,
            bottom: "-100%",
        },
    },
    "@keyframes slideEffect": {
        "0%": {
            opacity: "0",
            bottom: "-100px",
        },
        "100%": {
            opacity: 1,
            bottom: "0",
        },
    },
    swipeableDrawer: {
        zIndex: 9999,
        '& .MuiPaper-root': {
            maxHeight: '85%',
            overflowY: 'auto',
            paddingBottom: 'env(safe-area-inset-bottom)',
            zIndex: 999,
        }
    }
})


export default styles;

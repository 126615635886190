import { GROUP_OBJ, PRIORITY_OBJ, STATUS_OBJ, getNewVal } from "../../containers/Tasks/constant";
import { tasksConstant } from "../constants";
import { defaultTaskDate } from "../../utils"

const handleComments = (existingComments, newComment) => {
    let comments = existingComments || [];

    if(newComment.id){
        comments = comments.map(c => {
            const isEditedComment = c.id === Number(newComment.id);
            const isCreatedComment = c.uuid && newComment.uuid && c.uuid === newComment.uuid;

            if(isEditedComment || isCreatedComment){
                return { ...c, ...newComment, uuid: "" }
            }

            return c;
        })
    } else {
        comments = [ newComment, ...comments ]
    }

    return comments
}

const initialState = {
    taskGroupTypes: {},
    taskGroups: [],
    tasksList: {},
    taskListSort: {
        sortBy: 'desc',
        sortField: 'created_at'
    },
    filtersList: { flowsList: [], usersList: [], clientsList: [] },
    taskFilter: {},
    newTask: {
        status: "1",
        priority: "2",
        group_type: "0",
        assignedTo: [],
        approvedBy: [],
        ...defaultTaskDate()
    },
    taskClone: {},
    taskActivity: [],
    activityLoader:false,
    taskComments: {
        count: 0,
        comments: [],
        loading: false,
        editCommentId: null,
        editCommentData: {}
    },
    defaultList: {
        data: [],
        count: 0,
        skip: 0,
        limit: 25,
        sortBy: 'desc',
        sortField: 'created_at'
    },
    listLoader: false
}

export default function task(state = initialState, action) {
    switch (action.type) {
        case tasksConstant.GET_TASKS_GROUPS_R:
            return {
                ...state,
                taskGroups: initialState.taskGroups,
                tasksList: initialState.tasksList
            }
        case tasksConstant.GET_TASKS_GROUPS_S:
            return {
                ...state,
                taskGroups: action.data.data?.taskGroups,
                tasksList: action.data.data?.tasksList,
                taskListSort: action.data.data?.taskListSort || initialState.taskListSort,
            }
        case tasksConstant.GET_TASKS_GROUPS_F:
            return {
                ...state,
                tasks: initialState.taskGroups
            }

        // task prefencee update
        case tasksConstant.SAVE_TASK_PREFERENCE_R:
            return {
                ...state,
            }
        case tasksConstant.SAVE_TASK_PREFERENCE_S:
            return {
                ...state,
            }
        case tasksConstant.SAVE_TASK_PREFERENCE_F:
            return {
                ...state,
            }

        // task group types
        case tasksConstant.GET_TASK_GROUP_TYPE_R:
            return {
                ...state,
            }
        case tasksConstant.GET_TASK_GROUP_TYPE_S:
            return {
                ...state,
                taskGroupTypes: action.data.data
            }
        case tasksConstant.GET_TASK_GROUP_TYPE_F:
            return {
                ...state,
            }


        // task filters
        case tasksConstant.GET_TASK_FILTERS_R:
            return {
                ...state,
                filtersList: initialState.filtersList
            }
        case tasksConstant.GET_TASK_FILTERS_S:
            return {
                ...state,
                filtersList: action.data.data
            }
        case tasksConstant.GET_TASK_FILTERS_F:
            return {
                ...state,
                filtersList: initialState.filtersList
            }

        // update task filter reducer
        case tasksConstant.UPDATE_TASK_FILTER:
            return {
                ...state,
                taskFilter: action.data
            }

        // task filters
        case tasksConstant.GET_TASK_DETAILS_R:
            return {
                ...state,
                newTask: initialState.newTask,
                taskComments: initialState.taskComments,
                detailLoader:true
            }
        case tasksConstant.GET_TASK_DETAILS_S:
            return {
                ...state,
                newTask: action.data.data,
                taskClone: action.data.data,
                detailLoader:false
            }
        case tasksConstant.GET_TASK_DETAILS_F:
            return {
                ...state,
                newTask: initialState.newTask,
                detailLoader:false
            }
        // task comments count
        case tasksConstant.GET_TASK_COMMENTS_COUNT_R:
            return {
                ...state,
                taskComments: {
                    ...state.taskComments,
                    loading: true
                }
            }
        case tasksConstant.GET_TASK_COMMENTS_COUNT_S:
            return {
                ...state,
                taskComments: {
                    ...initialState.taskComments,
                    count: action.data.data,
                    loading: false
                }
            }
        case tasksConstant.GET_TASK_COMMENTS_COUNT_F:
            return {
                ...state,
                loading: false
            }

        case tasksConstant.GET_TASK_COMMENTS_LIST_R:
            return {
                ...state,
                taskComments: {
                    ...state.taskComments,
                    loading: true
                }
            }
        case tasksConstant.GET_TASK_COMMENTS_LIST_S:
            return {
                ...state,
                taskComments: {
                    ...state.taskComments,
                    comments: action.data,
                    loading: false,
                },
            }
        case tasksConstant.GET_TASK_COMMENTS_LIST_F:
            return {
                ...state,
                taskComments: {
                    ...state.taskComments,
                    loading: false
                }
            }

        case tasksConstant.COMPLETE_TASKS_R:
        case tasksConstant.COMPLETE_TASKS_S:
        case tasksConstant.COMPLETE_TASKS_F:
            return state

        case tasksConstant.COMPLETE_TASK_REDUCER:
            const { id, task_date_id, group, groupByPref, task: { parent_task_id }, taskType, newStatus } = action.data;
            let tasksList = { ...state.tasksList };
            const statusType = newStatus === 4 ? "Awaiting Approval" : "Completed"
            if (groupByPref === "status" && newStatus === 3) {
                // for swapping the group
                const curInx = tasksList[group].findIndex(f => (f.task_date_id === task_date_id));
                tasksList["Completed"] = tasksList["Completed"] || []
                tasksList["Completed"].push({ ...tasksList[group][curInx], task_status: 3, status: "Completed" })
                tasksList[group].splice(curInx, 1)
                let hasCompletedGroup = false;
                let taskGroups = state.taskGroups.map(m => {
                    if (m.groupings === 'Completed') {
                        hasCompletedGroup = true;
                    }
                    return {
                        ...m,
                        count: m.groupings === 'Completed' ? m.count + 1 : m.groupings === group ? m.count - 1 : m.count
                    }
                });
                if (!hasCompletedGroup) {
                    taskGroups.push({ groupings: "Completed", count: 1, groupFilter: 3 })
                }
                return {
                    ...state,
                    tasksList: tasksList,
                    taskGroups: taskGroups
                }
            } else if (groupByPref === "default") {
                tasksList = [...(state.defaultList.data || [])]
                if (taskType === "SUB") {
                    const taskIndex = tasksList.findIndex(f => (f.id === parent_task_id))
                    if (taskIndex > -1) {
                        const subTaskIndex = (tasksList[taskIndex].subTasks || []).findIndex(f => (f.id === id));
                        if (subTaskIndex > -1) {
                            tasksList[taskIndex].subTasks[subTaskIndex] = { ...tasksList[taskIndex].subTasks[subTaskIndex], task_status: newStatus, status: statusType }
                        }
                    }
                } else {
                    const taskIndex = tasksList.findIndex(f => (f.task_date_id === task_date_id))
                    if (taskIndex > -1) {
                        tasksList[taskIndex] = { ...tasksList[taskIndex], task_status: newStatus, status: statusType }
                    }
                }
                return {
                    ...state,
                    defaultList: {
                        ...state.defaultList,
                        data: tasksList
                    }
                }
            } else {
                if (tasksList[group]) {
                    const curIndex = tasksList[group].findIndex(f => (f.task_date_id === task_date_id));
                    tasksList[group][curIndex] = { ...tasksList[group][curIndex], task_status: newStatus, status: statusType }
                }
                return {
                    ...state,
                    tasksList: tasksList
                }
            }

        // task list
        case tasksConstant.GET_TASK_LIST_R:
            return {
                ...state,
                listLoader: true
            }
        case tasksConstant.GET_TASK_LIST_S:
            const grouping = action.data && action.data.params && action.data.params.groupingDisplay ? action.data.params.groupingDisplay : "null";
            if (grouping) {
                let tasksList = { ...state.tasksList };
                if (tasksList[grouping]) {
                    tasksList[grouping] = [...(tasksList[grouping] || []), ...(action.data.data || [])]
                } else {
                    tasksList[grouping] = [...(action.data.data || [])]
                }
                return {
                    ...state,
                    tasksList: tasksList,
                    listLoader: false
                }
            } else {
                return {
                    ...state,
                    listLoader: false
                }
            }
        case tasksConstant.GET_TASK_LIST_F:
            return {
                ...state,
                listLoader: false
            }

        // update current task
        case tasksConstant.UPDATE_TASK_REDUCER:
            return {
                ...state,
                newTask: action.data
            }

        case tasksConstant.DELETE_COMMENT_S:
            return {
                ...state,
                taskComments: {
                    ...state.taskComments,
                    comments: action.data
                }
            }

        // reset tasks
        case tasksConstant.RESET_TASK_REDUCER:
            return {
                ...state,
                newTask: action && action.data && action.data === "title" ? { ...state.newTask, title: "" } : { ...initialState.newTask, ...defaultTaskDate() }
            }

        // save tasks
        case tasksConstant.SAVE_TASK_R:
            const { params } = action;
            if (params && params.isQuickTask && params.groupings && params.groupBy) {
                const taskData = {
                    ...params,
                    assigned_to: params.assignedTo ? params.assignedTo.map(m => {
                        m.avatar = m.avatar_filename || null;
                        return m
                    }) : [],
                    group_type: params.group_type ? GROUP_OBJ[params.group_type] : "-",
                    group_id: params.group_id && params.group_id.value ? params.group_id.value : null,
                    priority: params.priority ? PRIORITY_OBJ[params.priority] : "-",
                    start_date: params.start_date ? params.start_date : '-',
                    due_date: params.due_date ? params.due_date : '-',
                    status: params.status ? STATUS_OBJ[params.status] : "1",
                    completed_by: null,
                    completed_on: null,
                    task_status: params.status ? Number(params.status) : 1,
                    group_type_name: params.group_id && params.group_id.value ? JSON.stringify({
                        ...params.group_id,
                        "title": params.group_id.label || "",
                    }) : "{}",
                }
                const taskList = { ...state.tasksList }
                if (params.groupBy === "default") {
                    if (params.parent_task_id) {
                        const tmpIndex = state.defaultList.data.findIndex(f => (f.id === params.parent_task_id));
                        if (tmpIndex > -1) {
                            state.defaultList.data[tmpIndex] = {
                                ...state.defaultList.data[tmpIndex],
                                showSubTasks: true,
                                subTasks: [
                                    ...(state.defaultList.data[tmpIndex]?.subTasks || []),
                                    taskData
                                ]
                            }
                        }
                    } else {
                        state.defaultList.count = (state.defaultList.count + 1)
                        if (params.addOnTop) {
                            state.defaultList.data = [
                                taskData,
                                ...(state.defaultList.data || [])
                            ]
                        } else {
                            state.defaultList.data.push(taskData)
                        }
                    }
                } else if (taskList[params.groupings]) {
                    taskList[params.groupings].push(taskData)
                    state.tasksList = taskList
                    const taskGroupsIndex = state.taskGroups.findIndex(f => (f.groupings === params.groupings));
                    state.taskGroups[taskGroupsIndex] = { ...state.taskGroups[taskGroupsIndex], count: (state.taskGroups[taskGroupsIndex].count) + 1 }
                }
                return { ...state }
            } else {
                return state
            }
        case tasksConstant.SAVE_TASK_S:
            const { params: param, data: { data } } = action.data;
            if (param && param.isQuickTask && param.groupings && param.groupBy) {
                const taskList = param.groupBy === "default" ? [...state.defaultList.data] : { ...state.tasksList }
                if (param.groupBy === "default") {
                    if (param.parent_task_id) {
                        const tmpIndex = state.defaultList.data.findIndex(f => (f.id === param.parent_task_id));
                        if (tmpIndex > -1) {
                            const tmpSubtasks = state.defaultList.data[tmpIndex].subTasks || []
                            const tmpIndex1 = tmpSubtasks.findIndex(f => (f.title == data.title));
                            if (tmpIndex1 > -1) {
                                tmpSubtasks[tmpIndex1] = { ...tmpSubtasks[tmpIndex1], ...data };
                                state.defaultList.data[tmpIndex].subTasks = tmpSubtasks
                            }
                        }
                    } else {
                        const tmpIndex = taskList.findIndex(f => (f.title === data.title));
                        if (tmpIndex > -1) {
                            taskList[tmpIndex] = { ...taskList[tmpIndex], ...data };
                            state.defaultList.data = taskList
                        }
                    }
                } else if (taskList[param.groupings]) {
                    const tmpIndex = taskList[param.groupings].findIndex(f => (f.title === data.title));
                    if (tmpIndex > -1) {
                        taskList[param.groupings][tmpIndex] = { ...taskList[param.groupings][tmpIndex], ...data };
                        state.tasksList = taskList
                    }
                }
                return { ...state }
            } else {
                return state
            }
        case tasksConstant.SAVE_TASK_F:
            return state

        // save comment
        case tasksConstant.SAVE_COMMENT_R:
            const isNewComment = action?.params?.uuid;
            const { count, comments } = state?.taskComments || {};

            return {
                ...state,
                taskComments: {
                    ...state.taskComments,
                    comments: handleComments(comments, action?.params),
                    count: isNewComment ? count + 1 : count
                }
            }
        case tasksConstant.SAVE_COMMENT_S:
            return {
                ...state,
                taskComments: {
                    ...state.taskComments,
                    comments: handleComments(state?.taskComments?.comments, action?.data)
                },
            }
        case tasksConstant.SAVE_COMMENT_F:
            return {
                ...state,
            };

        //default task count
        case tasksConstant.GET_DEFAULT_COUNT_R:
            return {
                ...state,
                defaultList: initialState.defaultList
            }
        case tasksConstant.GET_DEFAULT_COUNT_S:
            return {
                ...state,
                defaultList: {
                    ...initialState.defaultList,
                    count: action.data.data
                }
            }
        case tasksConstant.GET_DEFAULT_COUNT_F:
            return {
                ...state,
                defaultList: initialState.defaultList
            }

        //default task list
        case tasksConstant.GET_DEFAULT_LIST_R:
            return {
                ...state,
                listLoader: true
            }
        case tasksConstant.GET_DEFAULT_LIST_S:
            const skip = action.data.data && action.data.data.skip ? action.data.data.skip : 0;
            const isSubTask = action.data.data && action.data.data.isSubTask ? action.data.data.isSubTask : false;
            const taskIndex = action.data.data && action.data.data.index >= 0 ? action.data.data.index : null;
            if (isSubTask) {
                const taskData = state.defaultList.data || [];
                taskData[taskIndex] = { ...taskData[taskIndex], subTasks: (action.data?.data?.data || []) }
                return {
                    ...state,
                    defaultList: {
                        ...state.defaultList,
                        data: taskData
                    },
                    listLoader: false
                }
            } else {
                return {
                    ...state,
                    defaultList: {
                        ...state.defaultList,
                        ...(action.data.data || {}),
                        data: [
                            ...(skip === 0 ? [] : (state.defaultList.data || [])),
                            ...(action.data?.data?.data || [])]
                    },
                    listLoader: false
                }
            }
        case tasksConstant.GET_DEFAULT_LIST_F:
            return {
                ...state,
                listLoader: false
            }

        case tasksConstant.EDIT_COMMENT_R:
            return {
                ...state,
            }
        case tasksConstant.EDIT_COMMENT_S:
            return {
                ...state,
                taskComments: {
                    ...state.taskComments,
                    editCommentId: action.data?.id,
                    editCommentData: action.data
                }
            }
        case tasksConstant.EDIT_COMMENT_F:
            return {
                ...state
            }

        case tasksConstant.CLEAR_COMMENT_S:
            return {
                ...state,
                taskComments: {
                    ...initialState.taskComments,
                }

            }
        case tasksConstant.UPDATE_SHOW_HIDE_SUBTASK:
            const { index } = action.data;
            let defaultListData = [...(state?.defaultList?.data || [])]
            if (index >= 0) {
                defaultListData[index] = { ...defaultListData[index], showSubTasks: !(defaultListData[index]?.showSubTasks || false) }
                return {
                    ...state,
                    defaultList: {
                        ...state.defaultList,
                        data: defaultListData
                    }
                }
            } else {
                return state
            }

        case tasksConstant.UPDATE_INLINE_TASK_S:
            return {
                ...state,
            }
        case tasksConstant.UPDATE_INLINE_TASK_R:
            const { params: { viewType, newValue, data: inlineData, taskType: tasktype, index: currentIndex, group: inlineGroup } } = action.params;
            if (viewType === "DEFAULT") {
                const tmpTasksList = [...(state.defaultList.data || [])];
                if (tasktype === "SUB") {
                    const mainIndex = tmpTasksList.findIndex(f => (f.id === inlineData.parent_task_id));
                    if (mainIndex > -1 && currentIndex > -1) {
                        const tmpTasks = tmpTasksList[mainIndex]?.subTasks || []
                        tmpTasks[currentIndex] = {
                            ...tmpTasks[currentIndex],
                            ...getNewVal(newValue)
                        }
                        tmpTasksList[mainIndex] = {
                            ...tmpTasksList[mainIndex],
                            subTasks: tmpTasks
                        }
                    }
                } else if (currentIndex > -1) {
                    tmpTasksList[currentIndex] = {
                        ...tmpTasksList[currentIndex],
                        ...getNewVal(newValue)
                    }
                }
                return {
                    ...state,
                    defaultList: {
                        ...state.defaultList,
                        data: tmpTasksList
                    }
                }
            } else {
                const tmpTasksListGrp = { ...state.tasksList };
                if (tmpTasksListGrp[inlineGroup]) {
                    const curIndex = tmpTasksListGrp[inlineGroup].findIndex(f => (f.task_date_id === inlineData.task_date_id));
                    tmpTasksListGrp[inlineGroup][curIndex] = {
                        ...tmpTasksListGrp[inlineGroup][curIndex],
                        ...getNewVal(newValue)
                    }
                }
                return {
                    ...state,
                    tasksList: tmpTasksListGrp
                }
            }
        case tasksConstant.UPDATE_INLINE_TASK_F:
            return {
                ...state
            }

        case tasksConstant.GET_TASK_ACTIVITY_R:
            return {
                ...state,
                taskActivity: initialState.taskActivity,
                activityLoader: true
            }
        case tasksConstant.GET_TASK_ACTIVITY_F:
            return {
                ...state,
                taskActivity: initialState.taskActivity,
                activityLoader: false
            }
        case tasksConstant.GET_TASK_ACTIVITY_S:
            return {
                ...state,
                taskActivity: action.data.data,
                activityLoader: false
            }

        default:
            return state
    }
}
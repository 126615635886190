export const eventsConstant = {
    SAVE_EVENT_R: "SAVE_EVENT_R",
    SAVE_EVENT_S: "SAVE_EVENT_S",
    SAVE_EVENT_F: "SAVE_EVENT_F",

    GET_EVENT_R: "GET_EVENT_R",
    GET_EVENT_S: "GET_EVENT_S",
    GET_EVENT_F: "GET_EVENT_F",

    GET_AGENDA_EVENT_R: "GET_AGENDA_EVENT_R",
    GET_AGENDA_EVENT_S: "GET_AGENDA_EVENT_S",
    GET_AGENDA_EVENT_F: "GET_AGENDA_EVENT_F",

    GET_SELECTED_EVENT_R: "GET_SELECTED_EVENT_R",
    GET_SELECTED_EVENT_S: "GET_SELECTED_EVENT_S",
    GET_SELECTED_EVENT_F: "GET_SELECTED_EVENT_F",

    SET_EVENT_R: "SET_EVENT_R",
    SET_EVENT_S: "SET_EVENT_S",
    SET_EVENT_F: "SET_EVENT_F",

    DELETE_EVENT_R: "DELETE_EVENT_R",
    DELETE_EVENT_S: "DELETE_EVENT_S",
    DELETE_EVENT_F: "DELETE_EVENT_F",

    GET_GOOGLE_ACCOUNT_R: "GET_GOOGLE_ACCOUNT_R",
    GET_GOOGLE_ACCOUNT_S: "GET_GOOGLE_ACCOUNT_S",
    GET_GOOGLE_ACCOUNT_F: "GET_GOOGLE_ACCOUNT_F",

    GET_GOOGLE_TOKEN_R: "GET_GOOGLE_TOKEN_R",
    GET_GOOGLE_TOKEN_S: "GET_GOOGLE_TOKEN_S",
    GET_GOOGLE_TOKEN_F: "GET_GOOGLE_TOKEN_F",

    GET_GOOGLE_EMAIL_R: "GET_GOOGLE_EMAIL_R",
    GET_GOOGLE_EMAIL_S: "GET_GOOGLE_EMAIL_S",
    GET_GOOGLE_EMAIL_F: "GET_GOOGLE_EMAIL_F",

    UPDATE_EVENT_REDUCER: 'UPDATE_EVENT_REDUCER',
    UPDATE_EVENTVIEW_REDUCER:"UPDATE_EVENTVIEW_REDUCER",

    GET_EVENTVIEW_R: "GET_EVENTVIEW_R",
    GET_EVENTVIEW_S: "GET_EVENTVIEW_S",
    GET_EVENTVIEW_F: "GET_EVENTVIEW_F",

    SAVE_EVENTVIEW_R: "SAVE_EVENTVIEW_R",
    SAVE_EVENTVIEW_S: "SAVE_EVENTVIEW_S",
    SAVE_EVENTVIEW_F: "SAVE_EVENTVIEW_F",

    DISCONNECT_GOOGLE_ACCOUNT_R: "DISCONNECT_GOOGLE_ACCOUNT_R",
    DISCONNECT_GOOGLE_ACCOUNT_S: "DISCONNECT_GOOGLE_ACCOUNT_S",
    DISCONNECT_GOOGLE_ACCOUNT_F: "DISCONNECT_GOOGLE_ACCOUNT_F",

    GET_GOOGLE_AUTH_URL_R: "GET_GOOGLE_AUTH_URL_R",
    GET_GOOGLE_AUTH_URL_S: "GET_GOOGLE_AUTH_URL_S",
    GET_GOOGLE_AUTH_URL_F: "GET_GOOGLE_AUTH_URL_F",

    GET_MEET_LINK:'GET_MEET_LINK',

    SAVE_APP_AUTH_TOKENS_R: "SAVE_APP_AUTH_TOKENS_R",
    SAVE_APP_AUTH_TOKENS_S: "SAVE_APP_AUTH_TOKENS_S",
    SAVE_APP_AUTH_TOKENS_F: "SAVE_APP_AUTH_TOKENS_F",
}
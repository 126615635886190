const FooterStyles = (theme) => ({
    footerContainer: {
        // position: 'fixed',
        bottom: 0,
        // height: 50,
        background: '#101010',
        padding: '0 15px',
        '& .MuiButton-outlined': {
            // border: '1px solid #fff'
        },
        '& .MuiTypography-root, & .MuiButton-label': {
            color: '#fff'
        },
        '& svg': {
            fill: '#fff'
        }
    },
    footerContainerdiv: {
        maxWidth: 1200,
        margin: "auto",
        padding: 20,
        '& .MuiTypography-body2': {
            paddingBottom: 20
        }
    },
    MobileFooterContainer: {
        // zIndex: 99,
        background: theme.palette.containers.card,
        // background: '#101010',
        borderTop: `1px solid ${theme.palette.primary.border1}`,
        // padding: 16,
        flexGrow: 1,
        // '& .MuiTypography-root': {
        //     color: '#2563EB !important',
        //     alignItems: 'center',
        //     display: "flex",
        //     justifyContent: 'center',
        //     margin: 'auto'
        // },
        '& .icon': {
            fontSize: 18,
            padding: 16,
            color: '#fff !important',
            cursor: 'pointer',
            '&:hover': {
                background: '#272727'
            }
        },
        '& .icon.active': {
            background: '#272727'
        },
        '& .mobileMenu': {
            // flex: '1 1 auto',
            height: '50px',
            justifyContent: 'center',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            '&.footerMenu': {
                width: '40px',
            },
            '&.active_menu': {
                borderBottom: '2px solid #2563EB',
                '&.leftMenu': {
                    borderBottom: 'none !important'
                },
                '& svg': {
                    '& .strokeFill': {
                        stroke: '#2563EB'
                    },
                    '& .pathFill': {
                        fill: '#2563EB'
                    }
                },
            },
            '&.menu_active': {
                '& .mfText': {
                    display: 'block',
                    color: '#2563EB',
                    fontSize: '12px',
                    fontWeight: '700'
                },
                '& svg': {
                    '& .strokeFill': {
                        stroke: '#2563EB'
                    },
                    '& .pathFill': {
                        fill: '#2563EB'
                    }
                },
                '& .MuiTypography-root': {
                    border: '1px solid #2563EB',
                    borderRadius: '8px',
                    padding: '9px 8px',
                    background: theme.palette.containers.bg3,
                    height: '30px',
                    display: 'flex',
                    alignItems: 'center',
                    '& svg': {
                        width: '15px',
                        height: '13px'
                    }
                }
            }
        },
        '& .mfText': {
            display: 'none',
            color: theme.palette.primary.light,
            paddingLeft: '8px'
        }
        // '& .svgImg': {
        //     marginTop: '16px !important'
        // }

    },
    activeFooter: {
        background: '#272727',
        minWidth: 100,
        padding: '16px 40px',
        '& .MuiTypography-root': {
            color: '#fff !important',
            alignItems: 'center',
            display: "flex"
        },
        '& .icon': {
            fontSize: 18,
            paddingRight: 15,
            color: '#fff !important',
        },
        '& .mfText': {
            // display: 'block'
        }
    },
    subNav: {
        background: '#fff',
        '& .MuiGrid-root': {
            padding: 15,
            flexGrow: 1,
            textAlign: 'center',
            '&:hover': {
                background: '#f0f2f4'
            }
        }
    },
    MobileFooter: {
        position: 'relative',
    },
    subNavContainer: {
        position: 'absolute',
        top: 1,
        width: '100%',
        transition: 'all .6s .1s',
        boxShadow: '0 0 15px 0 rgb(0 0 0 / 10%)',
        zIndex: 1
    },
    subNavContainerActive: {
        position: 'absolute',
        top: -46,
        width: '100%',
        transition: 'all .6s .1s',
        boxShadow: '0 0 15px 0 rgb(0 0 0 / 10%)'
    },

    socialContainer: {
        '& span.icon': {
            border: '1.5px solid #fff',
            borderRadius: '50%',
            backgroundClip: 'padding-box',
            padding: '9px',
            marginLeft: '1px',
            color: "#fff",
            fontSize: 16
        },
        '& span.icon:hover': {
            color: '#101010',
            background: '#fff'
        }
    },
    menuSvg: {
        position: 'relative',
        cursor: 'pointer',
        '& .TeamIcon2, .InvoiceIcon, .ProjectIcon': {
            width: '20px !important',
            height: '20px !important',
            fill: theme.palette.primary.light
        },
        '& .TaskIcon path, .MessageIcon path, .TeamIcon2 path, .InvoiceIcon path, .ProjectIcon path': {
            fill: theme.palette.primary.light
        },
        '& .TaskIcon, .MessageIcon': {
            width: '18px !important',
            height: '18px !important',
            fill: theme.palette.primary.light
        },
        '& .menuSvgGrid': {
            // position: 'absolute',
            // height: '50px',
            // width: '53px',
            // display: 'flex',
            // alignItems: 'center',
            // justifyContent: 'center',
            // marginTop: '-12px',
            '&:hover': {
                background: '#272727 !important'
            }
        },
    },
    menuPage: {
        position: 'fixed',
        top: '0px',
        left: '0px',
        height: 'calc(100% - (env(safe-area-inset-top)))',
        width: '100%',
        background: theme.palette.containers.bg1,
        zIndex: 999,
        display: 'flex',
        flexDirection: 'column',
        '& .MuiAvatar-root': {
            height: '33px !important',
            width: '33px !important'
        },
        '& .MuiListItem-gutters': {
            paddingLeft: '24px',
            paddingRight: '24px',
            paddingTop: '12px',
            paddingBottom: '12px',
            marginBottom: '5px',
            '&.active_menu': {
                '& svg': {
                    '& .strokeFill': {
                        stroke: '#2563EB'
                    },
                    '& .fillPath': {
                        fill: '#2563EB'
                    }
                },
                '& .MuiTypography-body1': {
                    color: '#2563EB',
                    fontWeight: '700'
                }
            }
        },
        '& .MuiListItemText-root': {
            '& .MuiTypography-body1': {
                fontSize: '16px'
            }
        },
        '& .MuiListItemIcon-root': {
            minWidth: '45px',
            '& .BrainIcon': {
                width: '26px',
                height: '28px'
            }
        },
        '& ul.MuiList-root':{
            overflowY: 'auto',
            maxHeight: '100%',
        },
    },
    logo: {
        maxWidth: '100%',
        height: 34,
        // width: 34,
        objectFit: 'cover',
        cursor: 'pointer',
    },
    menuHeader: {
        padding: '15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& .CloseIcon2': {
            width: '15px',
            height: '15px'
        },
    }

})

export default FooterStyles
export const funnelConstants = {
  GET_FUNNEL_R: "GET_FUNNEL_R",
  GET_FUNNEL_S: "GET_FUNNEL_S",
  GET_FUNNEL_F: "GET_FUNNEL_F",

  CREATE_FUNNEL_R: "CREATE_FUNNEL_R",
  CREATE_FUNNEL_S: "CREATE_FUNNEL_S",
  CREATE_FUNNEL_F: "CREATE_FUNNEL_F",

  GET_FUNNEL_STEPS_R: "GET_FUNNEL_STEPS_R",
  GET_FUNNEL_STEPS_S: "GET_FUNNEL_STEPS_S",
  GET_FUNNEL_STEPS_F: "GET_FUNNEL_STEPS_F",

  GET_FUNNEL_LIST_R: "GET_FUNNEL_LIST_R",
  GET_FUNNEL_LIST_S: "GET_FUNNEL_LIST_S",
  GET_FUNNEL_LIST_F: "GET_FUNNEL_LIST_F",

  UPDATE_FUNNEL_LIST:"UPDATE_FUNNEL_LIST",

  REORDER_FUNNEL_STEPS_R: "REORDER_FUNNEL_STEPS_R",
  REORDER_FUNNEL_STEPS_S: "REORDER_FUNNEL_STEPS_S",
  REORDER_FUNNEL_STEPS_F: "REORDER_FUNNEL_STEPS_F",

  GET_PUBLIC_INVOICE_LIST_R: "GET_PUBLIC_INVOICE_LIST_R",
  GET_PUBLIC_INVOICE_LIST_S: "GET_PUBLIC_INVOICE_LIST_S",
  GET_PUBLIC_INVOICE_LIST_F: "GET_PUBLIC_INVOICE_LIST_F",

  RESET_PUBLIC_INVOICE_LIST: "RESET_PUBLIC_INVOICE_LIST",

  UPDATE_FUNNEL_STEP_TEMPLATE_R: "UPDATE_FUNNEL_STEP_TEMPLATE_R",
  UPDATE_FUNNEL_STEP_TEMPLATE_S: "UPDATE_FUNNEL_STEP_TEMPLATE_S",
  UPDATE_FUNNEL_STEP_TEMPLATE_F: "UPDATE_FUNNEL_STEP_TEMPLATE_F",




  CREATE_FUNNEL_SPACE_REQUEST: "CREATE_FUNNEL_SPACE_REQUEST",
  CREATE_FUNNEL_SPACE_SUCCESS: "CREATE_FUNNEL_SPACE_SUCCESS",
  CREATE_FUNNEL_SPACE_FAILURE: "CREATE_FUNNEL_SPACE_FAILURE",

  GET_FUNNEL_SPACE_REQUEST: "GET_FUNNEL_SPACE_REQUEST",
  GET_FUNNEL_SPACE_SUCCESS: "GET_FUNNEL_SPACE_SUCCESS",
  GET_FUNNEL_SPACE_FAILURE: "GET_FUNNEL_SPACE_FAILURE",

  UPDATE_FUNNEL_SPACE_REQUEST: "UPDATE_FUNNEL_SPACE_REQUEST",
  UPDATE_FUNNEL_SPACE_SUCCESS: "UPDATE_FUNNEL_SPACE_SUCCESS",
  UPDATE_FUNNEL_SPACE_FAILURE: "UPDATE_FUNNEL_SPACE_FAILURE",

  DELETE_FUNNEL_SPACE_REQUEST: "DELETE_FUNNEL_SPACE_REQUEST",
  DELETE_FUNNEL_SPACE_SUCCESS: "DELETE_FUNNEL_SPACE_SUCCESS",
  DELETE_FUNNEL_SPACE_FAILURE: "DELETE_FUNNEL_SPACE_FAILURE",

  GET_SIGNED_CONTRACTS_R:"GET_SIGNED_CONTRACTS_R",
  GET_SIGNED_CONTRACTS_S:"GET_SIGNED_CONTRACTS_S",
  GET_SIGNED_CONTRACTS_F:"GET_SIGNED_CONTRACTS_F",
  RESET_FUNNEL_LIST_SKIP:"RESET_FUNNEL_LIST_SKIP"
};

/* eslint-disable react/prop-types */
import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { isMobile, isIOS, isBrowser, mobileModel } from "react-device-detect";

import history from "../../../config/history";


const CustomAuthLayout = (props) => {
  const { isPublic } = props;
  let safeAreaTopHeight = getComputedStyle(document.documentElement).getPropertyValue("--sat");

  const { profile } = useSelector((s) => s.profile);
  const { loginUser: { user_role } } = useSelector((s) => s.auth);

  // Set Container Height
  // let height = getContainerHeight();
  if (isIOS && isMobile && !isBrowser && mobileModel === "iPhone") {
    // height = height - parseInt(safeAreaTopHeight.replace("px", ""));
  }
  /**
   * Handle Header Menu Action
   */

  const handleHomePage = () => {
    const page = isPublic
      ? "/"
      : user_role && user_role["dash"]
        ? "/dashboard"
        : "clients";
    history.push(page);
  };

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <Grid container className={`${isIOS && isMobile && !isBrowser && mobileModel === "iPhone" ? "notchContainer" : null} h-100`} direction="column" wrap="nowrap">
      {isIOS && isMobile && !isBrowser && mobileModel === "iPhone" ? (
        <Grid
          item
          xs={12}
          style={{ height: `${safeAreaTopHeight}` }}
          className="notchGrid flexBasis0 "
        />
      ) : null}

      <Grid item xs={12} className={`dflex alignSelfCenter alignCenter alignContentCenter textCenter`}>
        {props.children}
      </Grid>
    </Grid>
  );
};

// Define Props Types
CustomAuthLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
  user: PropTypes.object.isRequired,
  access: PropTypes.bool.isRequired,
};

// Default props
CustomAuthLayout.defaultProps = {
  classes: {},
  children: {},
  user: {},
  access: false,
};

export default CustomAuthLayout;

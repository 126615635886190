import { baseAction } from "./baseAction";
import { updateAction } from "../actions";
import { userAgencyConstant, authConstant, alertConstant, loaderConstant, chatv2Constant } from "../constants";
import { userAgencyService } from "../services";
import history from "../../config/history";

const { request, success, failure } = baseAction;

// All login based actions
export const userAgencyAction = {
  getUserAgencies,
  updateUserAgencies,
  updateAgencyData,
  updatePayment,
  removePaymentData,

  //bills
  getPlanDetails,
  getPaymentHistory,
  getInvoiceDetails,
  nextInvoiceDate,
  dowloadInvoice,
  getAutoPay,
  updateAutoPay,
  updatePlan,
  getCardDetails,
  getMybillsUserInfo,
  updateCardDetails,
  getAgencyNameAndLogo,

  getFeedbackForm,
  cancelAgencyWithFeedback,
  updateToSoloPlan,
  payUnpaidInvoices,
  getAgencyUserLimits,
  getAllPlans,
  upgradePlan,
  createSubscriptionOnError,
  getAllAvailablePlans,

  planList,
  getPlanMembers,
  getPlanAddOnsList,
  updatePlanNew,

  clearUserAgencies,
};

/** GET ALL USER AGENCIES */
function getUserAgencies(params) {
  return (dispatch, getState) => {
    // avoid multiple calls
    const { userAgencies } = getState().userAgency || {}
    if (userAgencies && userAgencies?.length > 0 && !params?.type) {
      return
    }
    dispatch(request(userAgencyConstant.GET_USER_AGENCY_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    userAgencyService.getUserAgencies(params).then(data => {
      dispatch(success(userAgencyConstant.GET_USER_AGENCY_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }, error => {
      dispatch(failure(userAgencyConstant.GET_USER_AGENCY_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

// /** UPDATE USER AGENCIES */
function updateUserAgencies(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(userAgencyConstant.UPDATE_USER_AGENCY_R, params));
    userAgencyService.updateUserAgencies(params).then(data => {
      dispatch(success(userAgencyConstant.UPDATE_USER_AGENCY_S, data));
      dispatch(updateAction.update(chatv2Constant.CLEAR_CHAT_STATE, {}))
      // dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack(data)
    }, error => {
      dispatch(failure(userAgencyConstant.UPDATE_USER_AGENCY_R, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}


/**
 * update signup user data
 * @param {*} params 
 */
function updateAgencyData(params, cb = () => { }) {
  return (dispatch, getState) => {
    let userId = getState().auth.loginUser.id;
    userId = userId ? userId : localStorage.getItem("newID")
    dispatch(request(userAgencyConstant.UPDATE_AG_DATA_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    userAgencyService.updateAgencyData({ data: params, userId }).then(data => {
      dispatch(success(userAgencyConstant.UPDATE_AG_DATA_S, data));
      dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
      dispatch(success(authConstant.UPDATE_STEP_2, 1));
      localStorage.setItem("newID1", data && data.data && data.data.agency_id ? data.data.agency_id : 0);
      dispatch(request(loaderConstant.LOADING_FALSE));
      history.push(`/AgencyAdd/payment`);
      cb()
    }, error => {
      dispatch(failure(userAgencyConstant.UPDATE_AG_DATA_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/**
 * update payment
 */
function updatePayment(params, cb = () => { }) {
  return (dispatch, getState) => {
    let userId = getState().auth.loginUser.id;
    userId = userId ? userId : localStorage.getItem("newID")
    let agency_id = localStorage.getItem("newID1")

    dispatch(request(userAgencyConstant.UPDATE_PAYMENT_AG_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    userAgencyService.updatePayment({ data: params, userId, agency_id }).then(data => {
      if (data.status === "success") {
        dispatch(success(userAgencyConstant.UPDATE_PAYMENT_AG_S, data));
        dispatch(success(authConstant.UPDATE_STEP_3, 1));
        dispatch(success(authConstant.SHOW_POPUP_LOGIN, { message: "" }));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(loaderConstant.LOADING_FALSE));
        localStorage.removeItem("newID1")
        cb(data)
      }
      dispatch(request(loaderConstant.LOADING_FALSE));
      dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
    }, error => {
      dispatch(failure(userAgencyConstant.UPDATE_PAYMENT_AG_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}


/****************************************************************MY BILLS************************************************************************************** */

// /** getPlanDetails */
function getPlanDetails(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(userAgencyConstant.GET_PLAN_DETAILS_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    userAgencyService.getPlanDetails(params).then(data => {
      dispatch(success(userAgencyConstant.GET_PLAN_DETAILS_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(userAgencyConstant.GET_PLAN_DETAILS_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

// /** getPlanDetails */
function getAllAvailablePlans(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(userAgencyConstant.GET_AVAILABLE_PLAN_DETAILS_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    userAgencyService.getAllAvailablePlans(params).then(data => {
      dispatch(success(userAgencyConstant.GET_AVAILABLE_PLAN_DETAILS_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(userAgencyConstant.GET_AVAILABLE_PLAN_DETAILS_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

// /** getPaymentHistory */
function getPaymentHistory(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(userAgencyConstant.GET_PAYMENT_HISTORY_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    userAgencyService.getPaymentHistory(params).then(data => {
      dispatch(success(userAgencyConstant.GET_PAYMENT_HISTORY_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(userAgencyConstant.GET_PAYMENT_HISTORY_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

// /** get All Tasks */
function getInvoiceDetails(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(userAgencyConstant.GET_INV_DETAILS_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    userAgencyService.getInvoiceDetails(params).then(data => {
      dispatch(success(userAgencyConstant.GET_INV_DETAILS_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(userAgencyConstant.GET_INV_DETAILS_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

// /** nextInvoiceDate */
function nextInvoiceDate(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(userAgencyConstant.NEXT_INVOICE_DATE_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    userAgencyService.nextInvoiceDate(params).then(data => {
      dispatch(success(userAgencyConstant.NEXT_INVOICE_DATE_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(userAgencyConstant.NEXT_INVOICE_DATE_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

// /** dowloadInvoice */
function dowloadInvoice(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(userAgencyConstant.DOWNLOAD_INVOICE_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    userAgencyService.dowloadInvoice(params).then(data => {
      dispatch(success(userAgencyConstant.DOWNLOAD_INVOICE_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(userAgencyConstant.DOWNLOAD_INVOICE_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

function getAutoPay(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(userAgencyConstant.GET_AUTO_PAY_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    userAgencyService.getAutoPay(params).then(data => {
      dispatch(success(userAgencyConstant.GET_AUTO_PAY_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(userAgencyConstant.GET_AUTO_PAY_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

function updateAutoPay(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(userAgencyConstant.UPDATE_AUTO_PAY_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    userAgencyService.updateAutoPay(params).then(data => {
      dispatch(success(userAgencyConstant.UPDATE_AUTO_PAY_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(userAgencyConstant.UPDATE_AUTO_PAY_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

function updatePlan(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(userAgencyConstant.UPDATE_PLAN_A_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    userAgencyService.updatePlan(params).then(data => {
      dispatch(success(userAgencyConstant.UPDATE_PLAN_A_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      dispatch(success(authConstant.SHOW_POPUP_LOGIN, { message: "" }));
      if(data.message === 'success'){
        callBack(true)
      } else{
        callBack(false)
      }
    }, error => {
      callBack(false)
      dispatch(failure(userAgencyConstant.UPDATE_PLAN_A_F, error));
      // dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

function getCardDetails(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(userAgencyConstant.GET_CARD_DETAILS_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    userAgencyService.getCardDetails(params).then(data => {
      dispatch(success(userAgencyConstant.GET_CARD_DETAILS_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(userAgencyConstant.GET_CARD_DETAILS_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

function getMybillsUserInfo(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(userAgencyConstant.GET_MYBILLS_USER_INFO_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    userAgencyService.getMybillsUserInfo(params).then(data => {
      dispatch(success(userAgencyConstant.GET_MYBILLS_USER_INFO_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(userAgencyConstant.GET_MYBILLS_USER_INFO_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

function updateCardDetails(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(userAgencyConstant.UPDATE_CARD_DETAILS_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    userAgencyService.updateCardDetails(params).then(data => {
      dispatch(success(userAgencyConstant.UPDATE_CARD_DETAILS_S, data));
      dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(userAgencyConstant.UPDATE_CARD_DETAILS_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

//removePaymentData
function removePaymentData(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(userAgencyConstant.REMOVE_PAYMENT_DATA_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    userAgencyService.removePaymentData(params).then(data => {
      dispatch(success(userAgencyConstant.REMOVE_PAYMENT_DATA_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(userAgencyConstant.REMOVE_PAYMENT_DATA_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

//getAgencyNameAndLogo
function getAgencyNameAndLogo(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(userAgencyConstant.GET_AG_NAME_LOGO_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    userAgencyService.getAgencyNameAndLogo(params).then(data => {
      dispatch(success(userAgencyConstant.GET_AG_NAME_LOGO_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      // dispatch(failure(userAgencyConstant.GET_AG_NAME_LOGO_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

//get feedback form
function getFeedbackForm() {
  return (dispatch) => {
    dispatch(request(userAgencyConstant.GET_FEEDBACK_FORM_R));
    dispatch(request(loaderConstant.LOADING_TRUE));
    userAgencyService.getFeedbackForm().then(data => {
      dispatch(success(userAgencyConstant.GET_FEEDBACK_FORM_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }, error => {
      dispatch(failure(userAgencyConstant.GET_FEEDBACK_FORM_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

// cancelAgencyWithFeedback
function cancelAgencyWithFeedback(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(userAgencyConstant.CANCEL_AGENCY_FEEDBACK_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    userAgencyService.cancelAgencyWithFeedback(params).then(data => {
      dispatch(success(userAgencyConstant.CANCEL_AGENCY_FEEDBACK_S, data));
      dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(userAgencyConstant.CANCEL_AGENCY_FEEDBACK_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

// updateToSoloPlan
function updateToSoloPlan(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(userAgencyConstant.UPDATE_TO_SOLO_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    userAgencyService.updateToSoloPlan(params).then(data => {
      dispatch(success(userAgencyConstant.UPDATE_TO_SOLO_S, data));

      dispatch(request(loaderConstant.LOADING_FALSE));
      if (data && data.message === "success") {
        // history.push('/mybills');
        window.location.reload();
      } else {
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
      }

    }, error => {
      dispatch(failure(userAgencyConstant.UPDATE_TO_SOLO_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

// payUnpaidInvoices
function payUnpaidInvoices(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(userAgencyConstant.PAY_UNPAID_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    userAgencyService.payUnpaidInvoices(params).then(data => {
      dispatch(success(userAgencyConstant.PAY_UNPAID_S, data));
      dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
      dispatch(request(loaderConstant.LOADING_FALSE));
      if (data.status === "success") {
        dispatch(success(authConstant.SHOW_POPUP_LOGIN, { message: "" }));
        history.push("/mybills")
      }
    }, error => {
      dispatch(failure(userAgencyConstant.PAY_UNPAID_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

// getAgencyUserLimits
function getAgencyUserLimits(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(userAgencyConstant.AGENCY_USER_LIMITS_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    userAgencyService.getAgencyUserLimits(params).then(data => {
      dispatch(success(userAgencyConstant.AGENCY_USER_LIMITS_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(userAgencyConstant.AGENCY_USER_LIMITS_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

// getAllPlans
function getAllPlans(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(userAgencyConstant.AGENCY_PLANS_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    userAgencyService.getAllPlans(params).then(data => {
      dispatch(success(userAgencyConstant.AGENCY_PLANS_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(userAgencyConstant.AGENCY_PLANS_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

// upgradePlan
function upgradePlan(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(userAgencyConstant.UPGRADE_PLAN_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    userAgencyService.upgradePlan(params).then(data => {
      dispatch(success(userAgencyConstant.UPGRADE_PLAN_S, data));
      dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
      dispatch(request(loaderConstant.LOADING_FALSE));
      if (data && data.message === "success") {
        history.push("/mybills")
        window.location.reload();
      }
    }, error => {
      dispatch(failure(userAgencyConstant.UPGRADE_PLAN_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

//create Subscription On Error
function createSubscriptionOnError(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(userAgencyConstant.CREATE_SUB_ON_ERR_R));
    dispatch(request(loaderConstant.LOADING_TRUE));
    userAgencyService.createSubscriptionOnError(params).then(data => {
      dispatch(success(userAgencyConstant.CREATE_SUB_ON_ERR_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack(data)
    }, error => {
      dispatch(failure(userAgencyConstant.CREATE_SUB_ON_ERR_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

// Plan List
function planList(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(userAgencyConstant.PLANS_LIST_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    userAgencyService.planList(params).then(data => {
      dispatch(success(userAgencyConstant.PLANS_LIST_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(userAgencyConstant.PLANS_LIST_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

// Plan members
function getPlanMembers(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(userAgencyConstant.PLANS_MEMBERS_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    userAgencyService.getPlanMembers(params).then(data => {
      dispatch(success(userAgencyConstant.PLANS_MEMBERS_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(userAgencyConstant.PLANS_MEMBERS_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

// Plan addons List
function getPlanAddOnsList(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(userAgencyConstant.PLANS_ADD_ON_LIST_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    userAgencyService.getPlanAddOnsList(params).then(data => {
      dispatch(success(userAgencyConstant.PLANS_ADD_ON_LIST_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(userAgencyConstant.PLANS_ADD_ON_LIST_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

// update user plan
function updatePlanNew(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(userAgencyConstant.UPGRADE_PLANS_NEW_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    userAgencyService.updatePlanNew(params).then(data => {
      dispatch(success(userAgencyConstant.UPGRADE_PLANS_NEW_S, data));
      dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
      dispatch(success(authConstant.SHOW_POPUP_LOGIN, { message: "" }));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(userAgencyConstant.UPGRADE_PLANS_NEW_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/** Clear USER AGENCIES detail while user logout */
function clearUserAgencies(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(userAgencyConstant.DELETE_USER_AGENCY_S, {}));
  };
}

import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  Grid,
  Popover,
  Card,
  Divider,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Chip,
  Avatar,
  TextField,
  InputAdornment,
  Button,
  Box,
  ClickAwayListener,
  useMediaQuery,
  Slider,
  Tooltip,
  Badge,
} from "@material-ui/core";
import {
  CloseIcon,
  SearchIcon,
  SummaryFilterIcon
} from "../../../assets/svg/index";

import Style from "./styles";
import { Typography } from "../../Typography";
import { Autocomplete } from "@material-ui/lab";
import { DatePickers } from "../../DatePicker";
import { MODE_OPTIONS, currencyList } from "../../../config/constants";
import { CheckBox as CheckBox2 } from "../../Checkbox";
import { JobAutoComplete } from "../../JobAutoComplete";
import { convertToKTerms } from "../../../utils/invoiceHelper";
import { MobileActionSheet } from "../../MobileComponents/MobileActionSheet";

/**
 * Custom Table Sort Component
 *
 * @class CSTableSort
 * @extends {Component}
 */
function CSTableFilter(props) {
  const {
    classes,
    columnConfig,
    filterData,
    handleFilterChange,
    onFilterSubmit,
    InputProps,
    filterCount
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState({ label: "US Dollar", symbol: "$", value: "USD" });
  const [sliderValue, setSliderValue] = useState([0, 1000000]);

  const handleCurrencyChange = (_, newValue, columnName, field) => {
    setSelectedCurrency(newValue);
    const combinedValues = [newValue?.value].concat(sliderValue)
    handleFilterChange(columnName, field, combinedValues);
  };

  const isMobile = useMediaQuery('(max-width:899px)');

  const isDesktop = useMediaQuery('(min-width:900px)')

  const handleSliderChange = (newValue, columnName, field) => {
    setSliderValue(newValue);

    const combinedValues = [selectedCurrency?.value].concat(newValue)
    handleFilterChange(columnName, field, combinedValues);
  };

  const open = Boolean(anchorEl);
  const id = open ? "cs-table-sort" : undefined;


  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    e?.preventDefault();
    setAnchorEl(null);
  };

  function valuetext(value) {
    return `${value}`;
  }

  function ValueLabelComponent(props) {
    const { children, open, value } = props;
    let valueInK = convertToKTerms(value)
    return (
      <Tooltip open={open} enterTouchDelay={0} placement="bottom" title={valueInK}>
        {children}
      </Tooltip>
    )

  }

  function handleClearStateData() {
    setSelectedCurrency({ label: "US Dollar", symbol: "$", value: "USD" })
    setSliderValue([0, 100000])
  }

  const searchField = (key, placeholder, suggestions) => {
    let options = suggestions.filter(e => e.label !== null)
    return (
        <JobAutoComplete
          id={key}
          name={key}
          margin="none"
          placeholder={placeholder}
          InputProps={InputProps}
          suggestions={options}
          value={filterData["web_conference"]?.filters}
          handleAutoComplete={(val, name) => { handleFilterChange("web_conference", "filters", val) }}
        />
    )
  }

  const filterComponents = (column) => {
    switch (column?.filterType) {
      case "search":
        return (
          <Fragment>
            <Grid item xs={6} className={classes.TableFilterAutoComplete}>
              <Autocomplete
                // style={{ width: '200px' }}
                multiple
                fullWidth
                size="small"
                disableCloseOnSelect
                value={filterData[column?.key]?.filters}
                getOptionLabel={(option) => option.label}
                options={column?.filterOptions || []}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="outlined"
                    placeholder={`Search ${column.label}`}
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon height="12px" width="12px" />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                onChange={(e, newValue) => {
                  handleFilterChange(column?.key, "filters", newValue);
                }}
                renderTags={(value, getTagProps) => (
                  <Chip
                    className="br-4"
                    variant="outlined"
                    label={`${value.length} Selected`}
                    size="small"
                  />
                )}
                classes={{
                  option: classes.option
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                {filterData[column?.key]?.filters.map((item, index) => (
                  <Grid item key={index}>
                    <Chip
                      className="br-4"
                      size="small"
                      avatar={
                        column?.showAvatar && (
                          <Avatar alt={item.label} src={item.avatar || ""} />
                        )
                      }
                      label={item.label}
                      onDelete={() => {
                        filterData[column?.key]?.filters.splice(index, 1);
                        handleFilterChange(
                          column?.key,
                          "filters",
                          filterData[column?.key]?.filters
                        );
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Fragment>
        );
      case "checkMenu":
        return (
          <Fragment>
            <Grid className={`ml-3 ${classes.checkmenuContainer}`}>
              <FormGroup>
                {column?.filterOptions?.map((item) => {
                  return (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={filterData[column?.key].filters.includes(
                            item.value
                          )}
                          onChange={() => {
                            handleFilterChange(
                              column?.key,
                              "filters",
                              item.value
                            );
                          }}
                        />
                      }
                      label={
                        <Typography variant="body1" className={classes.filterCheckmenu}>{item.label}</Typography>
                      }
                    />
                  );
                })}
              </FormGroup>
            </Grid>
          </Fragment>
        );
      case "rangeSlider":
        return (
          <Fragment>
            <Grid>
              <Grid container>
                <Autocomplete
                  className={classes.currencyList}
                  onChange={(e, value) => handleCurrencyChange(e, value, column?.key, 'filters')}
                  options={currencyList}
                  value={selectedCurrency}
                  getOptionLabel={(option) => option.value || ''}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                    />
                  )}
                  classes={{
                    option: classes.option
                  }}
                />
              </Grid>
              <Grid>
                <Typography variant='body1'>Amount Range</Typography>
              </Grid>
              <Grid>
                <Slider
                  className={classes.filterSlider}
                  valueLabelDisplay="auto"
                  value={sliderValue}
                  ValueLabelComponent={ValueLabelComponent}
                  aria-labelledby="range-slider"
                  getAriaValueText={valuetext}
                  min={0}
                  max={1000000}
                  onChange={(event, value) => {
                    handleSliderChange(value, column?.key, 'filters')
                  }}
                />
                <div className={`${classes.filterSliderValue}`}>
                  <Typography variant='body1' className="fs-12 fw-500 textLeft">
                    {convertToKTerms(sliderValue[0])}
                  </Typography>
                  <Typography variant='body1' className="fs-12 fw-500 textRight">
                    {convertToKTerms(sliderValue[1])}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Fragment>
        )
      case 'checkbox':
        return (
          <Grid container>
            {column?.filterOptions?.map((sts, stInx) => (
              <Grid item xs={12}>
                <Grid container key={`sd-s${stInx}`} className="smFlex pl-2">
                  <Grid item xs={6}>
                    <CheckBox2
                      id={sts.value}
                      name={sts.value}
                      // className={"ml-2"}
                      label={<Typography variant='body1' className='fs-12 fw-400'>{sts.label}</Typography>}
                      checked={filterData[`${column.key}`]?.filters?.find(e => e.value == sts.value) ? true : false}
                      handleChange={(e) => {

                        handleFilterChange(column?.key, 'filters', sts);
                      }}
                    />
                  </Grid>

                  {(sts.label === 'Web Conference' && filterData.appointment_type.filters.find(e => e.label == 'Web Conference')) && <Grid item xs={6}>{searchField(sts.key, "Search Mode", MODE_OPTIONS[0].options)}</Grid>}
                </Grid>
              </Grid>
            ))}
          </Grid>
        );
      default:
        return (
          <Fragment>
            <Grid item xs={6} className="pr5 pt5">
              <Typography variant="body1" color="textSecondary" className="pb5">
                From
              </Typography>
              <DatePickers
                id={"start_date"}
                name={"start_date"}
                fullWidth
                value={filterData[column?.key]?.filters[0] || ""}
                handleChange={(e) => {
                  let tmpFilter = filterData[column?.key]?.filters;
                  tmpFilter[0] = e.value;
                  handleFilterChange(column?.key, "filters", tmpFilter);
                }}
                inputVariant="outlined"
                margin="none"
                size="small"
              />
            </Grid>
            <Grid item xs={6} className="pl5 pt5">
              <Typography variant="body1" color="textSecondary" className="pb5">
                To
              </Typography>
              <DatePickers
                id={"end_date"}
                name={"end_date"}
                fullWidth
                value={filterData[column?.key]?.filters[1] || ""}
                handleChange={(e) => {
                  let tmpFilter = filterData[column?.key]?.filters;
                  tmpFilter[1] = e.value;
                  handleFilterChange(column?.key, "filters", tmpFilter);
                }}
                inputVariant="outlined"
                margin="none"
                size="small"
                minDate={
                  filterData[column?.key]?.filters[0]
                    ? new Date(filterData[column?.key]?.filters[0])
                    : new Date()
                }
              />
            </Grid>
          </Fragment>
        );
    }
  };

  const FilterCard = () => (
    <Grid container className={`${classes.filterCard}`} style={{ padding: isDesktop || !isMobile ? "20px" : "10px" }}>
      {!isMobile && (
        <Fragment>
          <Grid item xs={12} className="pl-2 pr-2 pb-1">
            <Grid container justifyContent="space-between" className="pl6">
              <Grid item>
                <Typography variant="h6" className="fw-700 pb-2">
                  FILTER BY
                </Typography>
              </Grid>
              <Grid
                item
                className={classes.SortCloseContainer}
                onClick={() => setAnchorEl(null)}
              >
                <CloseIcon />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider className={classes.Divider} />
          </Grid>
        </Fragment>
      )}
      <Grid item xs={12} className={isMobile ? `${classes.filterContainer}` : `${classes.filterTab}pl-1`}>
        <Grid container>
          <FormGroup row style={{ borderRadius: "3px",marginBottom:'10px'}}>
            {columnConfig
              .filter((e) => !(e.hasOwnProperty("hideFilter") && e.hideFilter))
              .map((column, index) => (
                <Grid item key={index} xs={12} className="pt-1">
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        className="pl6"
                      >
                        <Grid
                          item
                          {...(column?.filterType === "search"
                            ? { xs: "unset" }
                            : { xs: 12 })}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                checked={filterData[column?.key]?.checked}
                                onChange={(e) => {
                                  handleFilterChange(
                                    column?.key,
                                    "checked",
                                    e.target.checked
                                  );
                                }}
                              />
                            }
                            label={
                              <Typography variant="body1" className='fw-600'>
                                {column.label}
                              </Typography>
                            }
                          />
                        </Grid>
                        {filterData[column?.key]?.checked &&
                          filterComponents(column)}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
          </FormGroup>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.filterbutton}>
        <Grid container spacing={2} justifyContent="flex-end" className={window.innerWidth <= 599 ? `${classes.filterBtnMob}` : `${classes.filterBtnTab}`}>
          <Grid item xs={window.innerWidth <= 599 && 6}>
            <Button
              fullWidth={window.innerWidth <= 599}
              variant="outlined"
              color="secondary"
              onClick={(e) => {
                handleClose(e)
                onFilterSubmit("clear");
                handleClearStateData()
              }}
            >
              Clear Filter
            </Button>
          </Grid>
          <Grid item xs={window.innerWidth <= 599 && 6} >
            <Button
              fullWidth={window.innerWidth <= 599}
              variant="contained"
              color="primary"
              onClick={(e) => {
                handleClose(e)
                onFilterSubmit("submit");
              }}
            >
              Apply
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Fragment>
      <Grid container alignItems="center">
        <Grid item className="cursorPointer dflex" onClick={handleOpen}>
          <Badge badgeContent={filterCount} color="primary">
            <SummaryFilterIcon width={16} height={16} />
          </Badge>
        </Grid>
      </Grid>
      {
        !isDesktop ?
          <MobileActionSheet
            open={open}
            title="FILTER BY"
            handleCloseAction={handleClose}
          >
            <FilterCard />
          </MobileActionSheet>
          // <Box className={classes.sortCardContainer}>
          //   <div className={isMobile ? `${classes.sortBackdrop}` : ""}></div>
          //   <ClickAwayListener onClickAway={handleClose}>
          //     <div className={isMobile ? `${classes.sortCard}` : `${classes.sortTabCard}`}>
          //       <Box display={"flex"} justifyContent={"center"} className="dflex justifyCenter">
          //         <Box p={0} className={isMobile && classes.sortBarLine}></Box>
          //       </Box>
          //       <FilterCard />
          //     </div>
          //   </ClickAwayListener>
          // </Box >
          :
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            style={{ marginTop: 16 }}
            className={classes.CreateWFPopover}
          >
            <Card style={{ maxWidth: 420 }}>
              <FilterCard />
            </Card>
          </Popover>
      }
    </Fragment >
  );
}

// default props
CSTableFilter.defaultProps = {
  tableName: "Sample Table",
  classes: {},
  handleSort: () => { },
  columnConfig: [],
  onFilterSubmit: () => { },
  filterCount: 0
};

CSTableFilter.propTypes = {
  tableName: PropTypes.string,
  classes: PropTypes.object,
  handleSort: PropTypes.func,
  columnConfig: PropTypes.array,
  onFilterSubmit: PropTypes.func,
  filterCount: PropTypes.number
};

// Export Component
export const CustomTableFilter = withStyles(Style)(CSTableFilter);

const styles = (theme) => ({
    recording: {
        position: "absolute",
        top: "0",
        bottom: "0",
        left: "0",
        right: "0",
        background: "transparent",
        borderRadius: "50%",
        border: "2px dashed #000",
        animationName: '$rotate',
        animationDuration: '3s',
        animationIterationCount: 'infinite',
        animationTimingFunction: 'linear',
    },
    recordingGrid: {
        right: "1rem",
        bottom: "105%",
        position: "absolute",
        borderRadius: "2rem",
        background: theme.palette.containers.bg4,
        height: "auto",
        width: "auto",
        minWidth: '176px',
        zIndex: "99",
        boxShadow: theme.palette.shadows.shadow10,
        "& .recordingContainer": {
            display: "flex",
            padding: "0.5rem",
            alignItems: "center",
            borderRadius: "2rem",
            flexDirection: "column",
            justifyContent: "space-around",
            height: "100%",
        },
        "& .iconBtn": {
            color: theme.palette.text.text1,
            background: theme.palette.containers.card,
            boxShadow: "0px 3px 6px #0000001A",
        },
        "& .recordIcon": {
            color: "red",
        },
        "& .micIcon": {
            width: "10rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "6rem",
            '& svg': {
                fill: theme.palette.text.text1
            }
        },
        "& .timerSection": {
            color: theme.palette.text.text1
        },
        "& .playerControls": {
            width: "100%",
            justifyContent: "space-evenly",
            display: "flex",
            alignItems: "center"
        },
        "& audio": {
            // visibility: "hidden",
        },
    },
    audioContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        "& .btn-box": {
            display: "flex",
            justifyContent: "space-evenly",
            "& button": {
                borderRadius: "50%",
            }
        },
        "& audio": {
            display: "none"
        }
    },
    hideAudioContainer: {
        display: "none"
    }
});

export default styles;

import { scheduleConstant } from "../constants";

const initialState = {
    scheduleMessageList:{
        data: [],
        page: {}
     },
    scheduleDetail:{
        schedule_tab: 1,
        schedule_type: "1",
        end_type: 1,
        occuranceType: "1",
        messageList:[]
    },
    projectList:[],
    clientList:{},
    scheduleGroup: {
        selected_flow_id: [],
        selected_internal_client_id: [],
        selected_external_client_id: [],
        selected_team_id: [],
        selected_new_group_id: [],
        title: ""
    },
    storedScheduleGroupIds: {
        selected_flow_id: [],
        selected_internal_client_id: [],
        selected_external_client_id: [],
        selected_team_id: [],
        selected_new_group_id: [],
        title: ""
    },
    addedMessage:[],
    teamList:[],
    scheduleFilter: {
        title: [],
        message_title: [],
        status: [],
        schedule_tab: []
    },
    pageFilter: {}
}

export default function schedule( state={...initialState}, action) {
    switch(action.type){
        
        //Schedule Message List
        case scheduleConstant.GET_SCHEDULE_MESSAGE_LIST_R:
            return{
                ...state,
            }
        case scheduleConstant.GET_SCHEDULE_MESSAGE_LIST_S:
            return{
                ...state,
                scheduleMessageList: action.data.data,
            };
        case scheduleConstant.GET_SCHEDULE_MESSAGE_LIST_F:
            return{
                ...state,
                scheduleMessageList: initialState.scheduleMessageList
            };

        //Search Flow List
        case scheduleConstant.GET_SEARCH_FLOW_LIST_R:
            return {
                ...state
            };
        case scheduleConstant.GET_SEARCH_FLOW_LIST_S:
            return {
                ...state,
                projectList: action.data.data,
            };
        case scheduleConstant.GET_SEARCH_FLOW_LIST_F:
            return {
                ...state,
                projectList: initialState.projectsList
            };

        //Search Client List
        case scheduleConstant.GET_SEARCH_CLIENT_LIST_R:
            return {
                ...state
            };
        case scheduleConstant.GET_SEARCH_CLIENT_LIST_S:
            return {
                ...state,
                clientList: action.data.data,
            };
        case scheduleConstant.GET_SEARCH_CLIENT_LIST_F:
            return {
                ...state,
                clientList: initialState.clientList
            };
            
        //Search Teams List
        case scheduleConstant.GET_SEARCH_TEAM_LIST_R:
            return {
                ...state
            };
        case scheduleConstant.GET_SEARCH_TEAM_LIST_S:
            return {
                ...state,
                teamList: action.data.data,
            };
        case scheduleConstant.GET_SEARCH_TEAM_LIST_F:
            return {
                ...state,
                teamsList: initialState.teamsList
            };
        
        //Get Selected Schedule Group
        case scheduleConstant.GET_SELECTED_SCHEDULE_IDS_R:
            return {
                ...state,
            };
        case scheduleConstant.GET_SELECTED_SCHEDULE_IDS_S:
            return {
                ...state,
                scheduleGroup: action.data.data,
                storedScheduleGroupIds: action.data.data,
            };
        case scheduleConstant.GET_SELECTED_SCHEDULE_IDS_F:
            return {
                ...state,
                scheduleGroup: initialState.scheduleGroup,
                storedScheduleGroupIds: initialState.storedScheduleGroupIds
            };

        //Get Add Message
        case scheduleConstant.GET_ADDED_SCHEDULE_MESSAGE_R:
            return {
                ...state,
            };
        case scheduleConstant.GET_ADDED_SCHEDULE_MESSAGE_S:
            return {
                ...state,
                addedMessage: action.data.data,
            };
        case scheduleConstant.GET_ADDED_SCHEDULE_MESSAGE_F:
            return {
                ...state,
                addedMessage: initialState.addedMessage,
            };
        //Get Message List after added
        case scheduleConstant.SAVE_ADD_SCHEDULE_MESSAGE_INPUT_R:
            return {
                ...state,
            };
        case scheduleConstant.SAVE_ADD_SCHEDULE_MESSAGE_INPUT_S:
            return {
                ...state,
                scheduleDetail:{
                    ...state.scheduleDetail,
                    messageList: [...state.scheduleDetail.messageList, action.data.data],
                }
            };
        case scheduleConstant.SAVE_ADD_SCHEDULE_MESSAGE_INPUT_F:
            return {
                ...state,
                scheduleDetail: initialState.scheduleDetail,
            };

        //Get Uploaded Item List after added
        case scheduleConstant.UPLOAD_MESSAGE_FILES_R:
            return {
                ...state,
                scheduleDetail: {
                    ...state.scheduleDetail,
                    messageList: [
                        ...state.scheduleDetail.messageList,
                        ...(action.params?.preview_msg?.tempMessage ? [action.params.preview_msg.tempMessage] : [])
                    ]
                }
            };
        case scheduleConstant.UPLOAD_MESSAGE_FILES_S:
            const tmp_id = action?.data?.temp_id
            const updated_msglist = state.scheduleDetail.messageList.filter(m => m.id.toString() !== tmp_id.toString())
            return {
                ...state,
                scheduleDetail: {
                    ...state.scheduleDetail,
                    messageList: [...updated_msglist, action.data.data],
                }
            };
        case scheduleConstant.UPLOAD_MESSAGE_FILES_F:
            return {
                ...state,
                scheduleDetail: initialState.scheduleDetail,
            };
        //Get Message List After Deletion
        case scheduleConstant.DELETE_MESSAGE_ITEM_R:
            return {
                ...state,
            };
        case scheduleConstant.DELETE_MESSAGE_ITEM_S:
            let filteredMessage = state?.scheduleDetail?.messageList?.filter((ele) => ele.id !== action.data.messageItemId)
            return {
                ...state,
                scheduleDetail: {
                    ...state.scheduleDetail,
                    messageList: filteredMessage,
                }
            };
        case scheduleConstant.DELETE_MESSAGE_ITEM_F:
            return {
                ...state,
                scheduleDetail: initialState.scheduleDetail,
            };
        //Get Message Details
        case scheduleConstant.GET_MESSAGE_DETAIL_R:
            return {
                ...state,
            };
        case scheduleConstant.GET_MESSAGE_DETAIL_S:
            return {
                ...state,
                scheduleDetail: action.data.data,
            };
        case scheduleConstant.GET_MESSAGE_DETAIL_F:
            return {
                ...state,
                scheduleDetail: initialState.scheduleDetail,
            };


        //Get Schedule Filter
        case scheduleConstant.GET_FILTER_LIST_R:
            return {
                ...state,
            };
        case scheduleConstant.GET_FILTER_LIST_S:
            return {
                ...state,
                scheduleFilter: action?.data?.data,
            };
        case scheduleConstant.GET_FILTER_LIST_F:
            return {
                ...state,
                scheduleDetail: initialState.scheduleDetail,
            };
        //Update Message Schedule Details
        case scheduleConstant.UPDATE_SCHEDULE_REDUCER:
            return {
                ...state,
                scheduleDetail: action.data
            }
        
        //Update Schedule Group
        case scheduleConstant.UPDATE_SCHEDULE_GROUP_REDUCER:
            return {
                ...state,
                scheduleGroup: action.data
            }

        //Clear Schedule Reducer
        case scheduleConstant.CLEAR_SCHEDULE_REDUCER:
            return {...initialState}

        // Update selected ids
        case scheduleConstant.UPDATE_SELECTED_ID_REDUCER:
            return {
                ...state,
                scheduleGroup: action.data
            }
        
        // Update page filter reducer
        case scheduleConstant.UPDATE_PAGE_FILTER_REDUCER:
            return {
                ...state,
                pageFilter: action.data
            }

        // Update selected ids
        case scheduleConstant.UPDATE_SCHEDULE_MESSAGE_STATUS_S:
            const updated_message_list = state.addedMessage.map((m)=>{
                if(m.id == action.data.data.id) {
                    m["status"] = action.data.data.status
                }
                return m
            })
            return {
                ...state,
                addedMessage: updated_message_list
            }
            
        default:
            return state;
    }
}
const style = (theme) => ({
    popoverRoot: {
        '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '415px',
        }
    },
    popoverContainer: {
        padding: '24px',
        borderRadius: '12px',
        '& .header': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '15px',
            '& .closeBtn': {
                borderRadius: '4px',
                background: '#EDEDED',
                width: '24px',
                height: '24px',
            }
        },
        '& .searchContainer': {
            marginBottom: '20px',
            '& .MuiOutlinedInput-root': {
                height: '35px',
                '& svg path': {
                    stroke: '#A3B0B9'
                }
            },
        },
        '& .body': {
            maxHeight: '400px',
            overflowY: 'auto',
            '& .MuiAvatar-root, & .avatarDiv': {
                height: '36px',
                width: '36px'
            },
        },
        '& .footer': {
            display: 'flex',
            justifyContent: 'flex-end',
            paddingTop: '15px',
            '& .blueBtn': {
                fontWeight: '600',
                fontSize: '12px'
            },
        }
    },
    tableBorder_None: {
        padding: 20,
        '& .MuiTableCell-root': {
            borderBottom: 'none',
            padding: '9px 8px',
            paddingLeft: '0px !important'
        },
        '& .MuiAvatar-root': {
            height: '32px',
            width: '32px'
        }
    },
    listItem: {
        marginBottom: '15px',
        transition: 'all 0.5s',
        '& .nameContainer': {
            marginLeft: '2px'
        },
        '& .ltSection': {
            display: 'flex',
            alignItems: 'center'
        },
        '& .rtSection': {
            display: 'flex',
            '& .dropBtn': {
                borderRadius: '6px',
                background: '#F0F2F5',
                width: '24px',
                height: '24px',
                '& svg': {
                    width: '15px',
                    height: '15px'
                }
            },
            '& .deleteBtn': {
                borderRadius: '6px',
                background: '#F0F2F5',
                width: '24px',
                height: '24px',
                '& svg': {
                    width: '15px',
                    height: '15px'
                },
                '&.round': {
                    borderRadius: '30px',
                    background: '#DEE3EB !important'
                }
            },
            '& .sendBtn': {
                height: '28px',
                fontWeight: '500',
                fontSize: '12px'
            }
        },
        '& .MuiOutlinedInput-root': {
            minHeight: '30px',
            height: '30px'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
        }
    },
    createBtn: {
        fontWeight: '700',
        fontSize: '14px',
        '& svg path': {
            fill: '#fff'
        }
    },
    toggleContainer:{
        display: "flex",
        justifyContent: "flex-end"
    },
    trashCan: {
        borderRadius: '5px',
background: '#F0F2F5',
width: '24px',
height: '24px',
flexShrink: 0
    },
    userListSearch: {
        '& .searchContainer .MuiOutlinedInput-root':{
            height: 'auto !important',
            minHeight: '40px'
        }
    },
    sharePeopleContainer: {
        maxHeight: '400px',
        overflowY: 'auto'
    },
    deleteIconWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: '25px',
        width: '24px',
        borderRadius: '5px',
        background: '#F0F2F5',
        padding: '0px'
    },
    deleteConfirm: {
        display: "flex",
        alignItems: "center",
        flexShrink: 0,
        '& .alereText': {
            fontSize: '8px',
            fontWeight: '600',
            color: '#FF5656'
        }
    },
    selectBox: {
        '& .MuiSelect-root': {
            fontSize: '12px',
            color: '#778599 !important'
        },
        '& .MuiInput-underline:before': {
            border: 'none'
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            border: 'none'
        }
    }
})

export default style;

import { isAndroid, isIOS, isMobile } from "react-device-detect";

const Styles = () => ({
    menuIcons: {
        '& .active': {
            border: '1px solid blue'
        }
    },
    toolOptions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    ciTools: {
        display: 'flex',
        minHeight: '300px',
        margin: '8px',
        position: 'fixed',
        top: "70px",
        '& .ciToolsInner': {
            flexDirection: 'row !important',
            // marginTop : isMobile ? '':'60px',
            '& .MuiIconButton-root': {
                padding: '15px'
            },'& svg': {
                width: '20px',
                height: '20px'
            }
        },
        '&.ciTools': {
            minHeight: '48px !important',
        },
       
        '@media only screen and (max-width: 768px)': {
            top: "20px"
            // '& .ciToolsInner': {
            //     flexDirection: 'row !important'
            // },
            // '&.ciTools': {
            //     minHeight: '48px !important',
            // }
        }
    },
    colorBox: {
        width: '24px',
        height: '24px',
        backgroundColor: '#000',
        cursor: 'pointer',
        borderRadius: '50%'
    },
    colorHex: {
        paddingLeft: '8px'
    },
    optionsWrpr: {
        display: 'flex',
        margin: '8px',
        // width: '100px'
    },
    canvasWrapper: {
        position: 'relative',
        maxWidth: '100%',
        '& canvas': {
            margin: 'auto !important',
            top: '0 !important',
            bottom: '0 !important',
            left: '0 !important',
            right: '0 !important',
        },
        // '& .konvajs-content': {
        //     height: 'calc(100vh - 300px) !important',
        //     '& canvas': {
        //         height: 'fit-content !important',
        //         width: '100% !important',
        //     }
        // }
        '@media only screen and (max-width: 768px)': {
            '& .konvajs-content': {
                // height: 'calc(100vh - 365px) !important',
                '& canvas': {
                    height: '100% !important',
                    width: '100% !important',
                    maxWidth: '100% !important',
                }
            }
        }
    }
})

export default Styles
// Import API Helper
import { postRequest, getRequest, putRequest } from "../../utils/apiHelper";

export const profileService = {
    createProfile,
    saveWebBrowserToken,
    saveIosAppToken,

    getProfile,
    getProfileNames,
    saveProfileNames,

    getProjectUsers,
    getProjectClients,
    updateAgencyUserRole,
    deleteUser,
    deleteClientUser,

    getNotifications,
    getTasks,
    getInvites,
    updateNotification,
    updateNotificationViewStatus,
    deleteNotification,

    getGlobalData,

    inviteUserNew,
    newUserInvitationAccept,
    getNewUserInviteData,

    inviteClientUserNew,

    getInviteLink,
    getInviteFlowsAndClients,
    getAgencyDetailsCount,
    updateShowAppLogo,
    getEmailOtp,
    verifyOtp,
    resendOtp,

    updateFlowPermission,
    unSubscribeEmail,

    getAppConfig,
    getCustomDomainDetails,
    saveCustomDomain,
    checkUniqueCustomDomain,
    checkDnsStatus,
    checkPingStatus,
    checkSslStatus,
    generateSSL,
    updateAgencyProfile,
    verifyDomainRecords,

    publishCustomDomain,
    unPublishCustomDomain,

    publishPage,
    unPublishPage,

    saveAgencyEmailConfig,
    getAgencyEmailConfig,
    testAgencyEmailConfig,
    getAuthUrl,
    
    getApplicationApps,
    getUserConfiguredApps,

    getInvoiceNotification,
    updateSupportRestoreId
};

//create new profile
async function createProfile(params) {
    const response = await postRequest("profile/create", params);
    return response;
}

//update user browser web token
async function saveWebBrowserToken(params) {
    const response = await postRequest("profile/token/web", params);
    return response;
}

//update user ios app token
async function saveIosAppToken(params) {
    const response = await postRequest("profile/token/ios", params);
    return response;
}

//get profile
async function getProfile(params) {
    const response = await postRequest("profile/get");
    return response;
}

//get profile
async function getProfileNames(params) {
    const response = await postRequest("profile/getNames");
    return response;
}

async function saveProfileNames(params) {
    const response = await postRequest("profile/saveNames", params);
    return response;
}

// inviteUserNew
async function inviteUserNew(params) {
    const response = await postRequest("profile/inviteUserNew", params);
    return response;
}

//get Team Members
async function newUserInvitationAccept(params) {
    const response = await postRequest("profile/inviteAccept", params);
    return response;
}

//getClientInviteData
async function getNewUserInviteData(params) {
    const response = await postRequest("profile/newInviteData", params);
    return response;
}

// invite New Clients
async function inviteClientUserNew(params) {
    const response = await postRequest("profile/inviteClientUserNew", params);
    return response;
}

//**************************************************TEAMS************************************************************* */

//get projectUsers
async function getProjectUsers(params) {
    const response = await postRequest("profile/teams");
    return response;
}

//get Clients names
async function getProjectClients(params) {
    const response = await postRequest("profile/teams/clients");
    return response;
}

//get projectUsers
async function updateAgencyUserRole(params) {
    const response = await postRequest("profile/updateRole", params);
    return response;
}

//deleteUser
async function deleteUser(params) {
    const response = await postRequest("profile/deleteUser", params);
    return response;
}

//deleteClientUser
async function deleteClientUser(params) {
    const response = await postRequest("profile/deleteClientUser", params);
    return response;
}

//**************************************************NOTIFICATIONS************************************************************* */

//get Notifications
async function getNotifications(params) {
    const response = await postRequest("profile/getNotifications", params);
    return response;
}

//getTasks
async function getTasks(params) {
    const response = await postRequest("profile/getTasks", params);
    return response;
}

//getInvites
async function getInvites(params) {
    const response = await postRequest("profile/getInvites", params);
    return response;
}

//updateNotification
async function updateNotification(params) {
    const response = await putRequest("notification/update_read_status", params);
    return response;
}

//updateNotificationViewStatus
async function updateNotificationViewStatus(params) {
    const response = await postRequest("profile/updateNotificationViewStatus", params);
    return response;
}

// To delete the notification
async function deleteNotification(params) {
    const response = await putRequest("notification/delete_notification", params);
    return response;
}
//**************************************************COMMON************************************************************* */

//getGlobalData
async function getGlobalData(params) {
    const response = await postRequest("general/search", params);
    return response;
}

async function getInviteLink(params) {
    const response = await postRequest("profile/copyInviteLink", params);
    return response;
}

async function getInviteFlowsAndClients() {
    const response = await getRequest("profile/flowsAndClients");
    return response;
}

async function getAgencyDetailsCount() {
    const response = await getRequest("profile/agencyDetailsCount");
    return response;
}

//Update show agency logo
async function updateShowAppLogo(params) {
    const response = await postRequest("profile/updateShowAppLogo", params);
    return response;
}

//email verification 
async function getEmailOtp(params) {
    const response = await postRequest("profile/emailChangeAuth", params);
    return response;
}

async function verifyOtp(params) {
    const response = await postRequest("profile/emailotpVerification", params);
    return response;
}

async function resendOtp(params) {
    const response = await postRequest("profile/resendEmail", params)
    return response
}

async function updateFlowPermission(params) {
    const response = await postRequest("profile/updateFlowPermission", params)
    return response;
}

// email un-subscription using public link
async function unSubscribeEmail(params) {
    const response = await postRequest("profile/openEmailUnsubscription", params)
    return response;
}

// get app config data like title, logo, icon, etc.,
async function getAppConfig(params) {
    const response = await postRequest("profile/getAppConfig", params)
    return response;
}

async function saveCustomDomain(params) {
    const response = await postRequest("profile/custom_domain/save", params);
    return response;
}

async function getCustomDomainDetails(params) {
    const response = await postRequest("profile/custom_domain/get", params)
    return response;
}

// check unique domain
async function checkUniqueCustomDomain(params) {
    const response = await postRequest("profile/custom_domain/is_unique", params);
    return response;
}

// check domain status
async function checkDnsStatus(params) {
    const response = await postRequest("profile/checkDns", params);
    return response;
}

// check domain ping status
async function checkPingStatus(params) {
    const response = await postRequest("profile/checkPing", params);
    return response;
}

// check ssl status
async function checkSslStatus(params) {
    const response = await postRequest("profile/checkSSL", params);
    return response;
}

// generate ssl certificate for custom domain
async function generateSSL(params) {
    const response = await postRequest(
        `domain/host`,
        params,
        false,
        "json",
        params?.apiType || "pageBuilder"
    );
    return response;
}

// publish domain
async function publishPage(params) {
    const response = await postRequest(
        `domain/create`,
        params,
        false,
        "json",
        "pageBuilder"
    );
    return response;
}

// unpublish Domain
async function unPublishPage(params) {
    const response = await postRequest(
        `domain/update`,
        params,
        false,
        "json",
        params?.apiType || "pageBuilder"
    );
    return response;
}

// publish domain
async function publishCustomDomain(params) {
    const response = await postRequest(
        `domain/create`,
        params,
        false,
        "json",
        "customDomain"
    );
    return response;
}

// unpublish Domain
async function unPublishCustomDomain(params) {
    const response = await postRequest(
        `domain/update`,
        params,
        false,
        "json",
        "customDomain"
    );
    return response;
}


async function updateAgencyProfile(params) {
    const response = await postRequest("profile/updateAgencyDetails", params);
    return response;
}

async function saveAgencyEmailConfig(params) {
    const response = await postRequest("profile/saveAgencyEmailConfig", params);
    return response;
}

async function getAgencyEmailConfig() {
    const response = await getRequest("profile/getAgencyEmailConfig");
    return response;
}

async function testAgencyEmailConfig(params) {
    const response = await postRequest("profile/testAgencyEmailConfig", params);
    return response;
}

async function getAuthUrl(params, pageType) {
    const response = await postRequest(`events/getAuthUrl/${pageType}`, params)
    return response;
}

async function getApplicationApps(params) {
    const response = await getRequest("profile/getApplicationApps");
    return response;
}

async function getUserConfiguredApps(params) {
    const response = await getRequest("profile/getUserApps");
    return response;
}

async function getInvoiceNotification(params) {
    const response = await postRequest("profile/getInvoiceNotification", params);
    return response;
}

async function verifyDomainRecords(params) {
    const response = await postRequest("profile/verifyDomainRecords", params);
    return response;
}

async function updateSupportRestoreId(params) {
    const response = await putRequest("profile/support_restore_id", params);
    return response;
}
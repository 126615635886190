import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import {
    withStyles,
    Grid,
    Popover,
    Card,
    Divider,
    Box,
    ClickAwayListener,
    useMediaQuery
} from '@material-ui/core';
import {
    CTSortChevron,
    CloseIcon,
    CTSortIcon2
} from '../../../assets/svg/index'

import Style from "./styles";
import { Typography } from "../../Typography";
import { MobileActionSheet } from "../../MobileComponents/MobileActionSheet";

/**
 * Custom Table Sort Component
 *
 * @class CSTableSort
 * @extends {Component}
 */
function CSTableSort(props) {

    const {
        classes,
        columnConfig,
        handleSort
    } = props;

    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);
    const id = open ? 'cs-table-sort' : undefined;

    const [sortColumn, setSortColumn] = useState({});

    const isMobile = useMediaQuery('(max-width:767px)');

    const isDesktop = useMediaQuery('(min-width:1025px)')


    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = (e) => {
        e.preventDefault();
        setAnchorEl(null);
    }

    const handleSortSelect = (key) => {
        let selectedSort = {
            key,
            isAcending: true
        };

        if (sortColumn.key && (sortColumn.key === key)) {
            selectedSort = {
                key,
                isAcending: !sortColumn.isAcending
            }
        }

        setSortColumn(selectedSort);
        handleSort(selectedSort);
    }

    const SortCard = () => (
        <Fragment>
            {
                !isMobile &&
                <Fragment>
                    <Grid container className={classes.SortHeader}>
                        <Grid item className={classes.SortTitle}>
                            SORT BY
                        </Grid>
                        <Grid className={classes.SortCloseContainer} onClick={() => setAnchorEl(null)}>
                            <CloseIcon />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.DividerContainer}>
                        <Grid xs={12}>
                            <Divider className={classes.Divider} />
                        </Grid>
                    </Grid>
                </Fragment>
            }
            {
                columnConfig.filter((e => !(e.hasOwnProperty('hideSort') && e.hideSort))).map(column => (
                    <Grid
                        container
                        className={`${sortColumn.key === column.key ? classes.sortOptionSelected : classes.sortOption}`}
                        onClick={() => { handleSortSelect(column.key) }}
                    >
                        <Grid>
                            <Typography variant="body1" color="primary">
                                {column.label}
                            </Typography>
                        </Grid>
                        <Grid className={`${!sortColumn.isAcending ? classes.sortChevron : classes.rotateChevron}`}>
                            <CTSortChevron />
                        </Grid>
                    </Grid>
                ))
            }
        </Fragment>
    )

    return (
        <Fragment>
            <Grid
                onClick={handleOpen}
                aria-describedby={id}
                className={classes.sortIcon}
            >
                <CTSortIcon2 width={16} height={16} />
            </Grid>
            {
                !isDesktop ?
                    <MobileActionSheet
                        open={open}
                        title="SORT BY"
                        handleCloseAction={handleClose}
                    >
                        <SortCard />
                    </MobileActionSheet>
                    // <Box className={classes.sortCardContainer}>
                    //     <div className={isMobile ? `${classes.sortBackdrop}` : ""}></div>
                    //     <ClickAwayListener onClickAway={handleClose} >
                    //         <div className={isMobile ? `${classes.sortCard}` : `${classes.sortTabCard}`}>
                    //             <Box display={"flex"} justifyContent={"center"}>
                    //                 <Box p={0} className={isMobile && classes.sortBarLine}></Box>
                    //             </Box>
                    //             <SortCard />
                    //         </div>
                    //     </ClickAwayListener>
                    // </Box>
                    :
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        style={{ marginTop: 16 }}
                        className={classes.CreateWFPopover}
                    >
                        <Card className={classes.WFCard}>
                            <SortCard />
                        </Card>
                    </Popover>
            }
        </Fragment>
    );
}

// default props
CSTableSort.defaultProps = {
    tableName: "Sample Table",
    classes: {},
    handleSort: () => { },
    columnConfig: []
};

CSTableSort.propTypes = {
    tableName: PropTypes.string,
    classes: PropTypes.object,
    handleSort: PropTypes.func,
    columnConfig: PropTypes.array
};

// Export Component
export const CustomTableSort = withStyles(Style)(CSTableSort)

import React from 'react';
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import Play from "./Play";
import Pause from "./Pause";
import Bar from "./Bar";
import FakeBar from "./FakeBar";

import styles from './styles';

import useAudioPlayer from './useAudioPlayer';

function Audio(props) {
    const { classes, audioFile, message, msgComponentType, fromCall } = props;
    let elementId = `${fromCall || "nil"}_audio_${message.moduleType}_${message.id}`;
    let { curTime, duration, playing, setPlaying, setClickedTime, setPlaySpeed } = useAudioPlayer({ elementId });
    const media = message.message_file ? JSON.parse(message.message_file) : null;
    duration = duration && duration != "Infinity" ? Math.round(duration) : (media && media.duration ? Math.round(media.duration / 1000) : 0);

    const [speed, setSpeed] = React.useState(1);
    const handleChange = (event) => {
        let speed = event.target.value;
        setSpeed(speed);
        setPlaySpeed(speed);
    };

    return (
        <>
            <div className={classes[msgComponentType]}>
                <div className={`player`}>
                    <audio id={elementId} loop={false}>
                        <source src={audioFile} type="audio/mp3" />
                        Your browser does not support the <code>audio</code> element.
                    </audio>

                    <div className="controls">
                        {playing ?
                            <Pause handleClick={() => setPlaying(false)} /> :
                            <Play handleClick={() => setPlaying(true)} />
                        }
                        {duration && curTime >= 0 ? <Bar curTime={curTime} duration={duration} onTimeUpdate={(time) => setClickedTime(time)} {...props} /> : <FakeBar {...props} />}
                    </div>

                    <>
                        <FormControl variant="filled" className={`${classes.playerSpeed} playerSpeed`}>
                            <Select
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                                value={speed}
                                onChange={handleChange}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem value={1}>1x</MenuItem>
                                <MenuItem value={1.5}>1.5x</MenuItem>
                                <MenuItem value={2}>2x</MenuItem>
                            </Select>
                        </FormControl>
                    </>

                </div>
            </div>
        </>
    );
}

// default props
Audio.defaultProps = {
    variant: "outlined",
    audioFile: null,
};

// prop types
Audio.propTypes = {
    variant: PropTypes.string,
    audioFile: PropTypes.string,
};

// export component
export const AudioPlayer = withStyles(styles)(Audio);

// Alert reducer types
export const invoiceConstant = {
  //INVOICE STRIPE ACTIVATION
  INVOICE_STRIPE_ACTIVATION_R: "INVOICE_STRIPE_ACTIVATION_R",
  INVOICE_STRIPE_ACTIVATION_S: "INVOICE_STRIPE_ACTIVATION_S",
  INVOICE_STRIPE_ACTIVATION_F: "INVOICE_STRIPE_ACTIVATION_F",

  //INVOICE CONNECT STATUS
  GET_INVOICE_CONNECT_STATUS_R: "GET_INVOICE_CONNECT_STATUS_R",
  GET_INVOICE_CONNECT_STATUS_S: "GET_INVOICE_CONNECT_STATUS_S",
  GET_INVOICE_CONNECT_STATUS_F: "GET_INVOICE_CONNECT_STATUS_F",

  //UPDATE_INVOICE_ITEMS
  UPDATE_INVOICE_DATA_S: "UPDATE_INVOICE_DATA_S",

  //GET_INVOICE_INFO
  GET_INVOICE_INFO_R: "GET_INVOICE_INFO_R",
  GET_INVOICE_INFO_S: "GET_INVOICE_INFO_S",
  GET_INVOICE_INFO_F: "GET_INVOICE_INFO_F",

  //GET INVOICE SETTINGS
  GET_INVOICE_SETTING_R: "GET_INVOICE_SETTING_R",
  GET_INVOICE_SETTING_S: "GET_INVOICE_SETTING_S",
  GET_INVOICE_SETTING_F: "GET_INVOICE_SETTING_F",

  //GET_INVOICE_CLIENTS
  GET_INVOICE_CLIENTS_R: "GET_INVOICE_CLIENTS_R",
  GET_INVOICE_CLIENTS_S: "GET_INVOICE_CLIENTS_S",
  GET_INVOICE_CLIENTS_F: "GET_INVOICE_CLIENTS_F",

  GET_IN_SETTING_R: "GET_IN_SETTING_R",
  GET_IN_SETTING_S: "GET_IN_SETTING_S",
  GET_IN_SETTING_F: "GET_IN_SETTING_F",

  UPDATE_IN_SETTING_R: "UPDATE_IN_SETTING_R",
  UPDATE_IN_SETTING_S: "UPDATE_IN_SETTING_S",
  UPDATE_IN_SETTING_F: "UPDATE_IN_SETTING_F",

  UPDATE_IN_SETTING_REDUCER: "UPDATE_IN_SETTING_REDUCER",

  //SAVE_INVOICE
  SAVE_INVOICE_R: "SAVE_INVOICE_R",
  SAVE_INVOICE_S: "SAVE_INVOICE_S",
  SAVE_INVOICE_F: "SAVE_INVOICE_F",
  //SAVE_INVOICE
  GET_INVOICE_R: "GET_INVOICE_R",
  GET_INVOICE_S: "GET_INVOICE_S",
  GET_INVOICE_F: "GET_INVOICE_F",

  //GET_INVOICE_INFO
  GET_INVOICE_SUMMARY_R: "GET_INVOICE_SUMMARY_R",
  GET_INVOICE_SUMMARY_S: "GET_INVOICE_SUMMARY_S",
  GET_INVOICE_SUMMARY_F: "GET_INVOICE_SUMMARY_F",

  // GET_PAYMENT_CHART
  GET_PAYMENT_CHART_R: "GET_PAYMENT_CHART_R",
  GET_PAYMENT_CHART_S: "GET_PAYMENT_CHART_S",
  GET_PAYMENT_CHART_F: "GET_PAYMENT_CHART_F",

  // GET_SUBSCRIPTION
  GET_SUBSCRIPTION_R: "GET_SUBSCRIPTION_R",
  GET_SUBSCRIPTION_S: "GET_SUBSCRIPTION_S",
  GET_SUBSCRIPTION_F: "GET_SUBSCRIPTION_F",

  // INVOICE_REMINDER
  INVOICE_REMINDER_MAIL_R: "INVOICE_REMINDER_MAIL_R",
  INVOICE_REMINDER_MAIL_S: "INVOICE_REMINDER_MAIL_S",
  INVOICE_REMINDER_MAIL_F: "INVOICE_REMINDER_MAIL_F",

  //Invoice Stripe Disconnect
  INVOICE_STRIPE_DISCONNECT_R: "INVOICE_STRIPE_DISCONNECT_R",
  INVOICE_STRIPE_DISCONNECT_S: "INVOICE_STRIPE_DISCONNECT_S",
  INVOICE_STRIPE_DISCONNECT_F: "INVOICE_STRIPE_DISCONNECT_F",

  DELETE_INVOICE_R: "DELETE_INVOICE_R",
  DELETE_INVOICE_S: "DELETE_INVOICE_S",
  DELETE_INVOICE_F: "DELETE_INVOICE_F",

  DISABLE_EDIT: "DISABLE_EDIT",

  GET_INVOICE_ERRORS_R: "GET_INVOICE_ERRORS_R",
  GET_INVOICE_ERRORS_S: "GET_INVOICE_ERRORS_S",
  GET_INVOICE_ERRORS_F: "GET_INVOICE_ERRORS_F",

  CLOSE_INVOICE_ERRORS_R: "CLOSE_INVOICE_ERRORS_R",
  CLOSE_INVOICE_ERRORS_S: "CLOSE_INVOICE_ERRORS_S",
  CLOSE_INVOICE_ERRORS_F: "CLOSE_INVOICE_ERRORS_F",

  SUBSCRIPTION_LOGS_R: "SUBSCRIPTION_LOGS_R",
  SUBSCRIPTION_LOGS_F: "SUBSCRIPTION_LOGS_F",
  SUBSCRIPTION_LOGS_S: "SUBSCRIPTION_LOGS_S",

  GET_ARCHIVE_INVOICE_SUMMARY_R: "GET_ARCHIVE_INVOICE_SUMMARY_R",
  GET_ARCHIVE_INVOICE_SUMMARY_S: "GET_ARCHIVE_INVOICE_SUMMARY_S",
  GET_ARCHIVE_INVOICE_SUMMARY_F: "GET_ARCHIVE_INVOICE_SUMMARY_F",

  UPDATE_INVOICE_STATUS_R: "UPDATE_INVOICE_STATUS_R",
  UPDATE_INVOICE_STATUS_S: "UPDATE_INVOICE_STATUS_S",
  UPDATE_INVOICE_STATUS_F: "UPDATE_INVOICE_STATUS_F",

  CLEAR_INVOICE_DATA: "CLEAR_INVOICE_DATA",
  CREATE_INVOICE_FROM_PUBLIC_INVOICE_R: "CREATE_INVOICE_FROM_PUBLIC_INVOICE_R",
  CREATE_INVOICE_FROM_PUBLIC_INVOICE_S: " CREATE_INVOICE_FROM_PUBLIC_INVOICE_S",
  CREATE_INVOICE_FROM_PUBLIC_INVOICE_F: " CREATE_INVOICE_FROM_PUBLIC_INVOICE_F",


  GET_INVOICE_REPORTS_R: "GET_INVOICE_REPORTS_R",
  GET_INVOICE_REPORTS_S: "GET_INVOICE_REPORTS_S",
  GET_INVOICE_REPORTS_F: "GET_INVOICE_REPORTS_F",

  GET_INVOICE_ALL_REPORTS_R: " GET_INVOICE_ALL_REPORTS_R",
  GET_INVOICE_ALL_REPORTS_S: " GET_INVOICE_ALL_REPORTS_S",
  GET_INVOICE_ALL_REPORTS_F: " GET_INVOICE_ALL_REPORTS_F",

  GET_PUBLIC_INVOICE_SUMMARY_R: "GET_PUBLIC_INVOICE_SUMMARY_R",
  GET_PUBLIC_INVOICE_SUMMARY_S: "GET_PUBLIC_INVOICE_SUMMARY_S",
  GET_PUBLIC_INVOICE_SUMMARY_F: "GET_PUBLIC_INVOICE_SUMMARY_F",

  GET_PUBLIC_INVOICE_R: "GET_PUBLIC_INVOICE_R",
  GET_PUBLIC_INVOICE_S: "GET_PUBLIC_INVOICE_S",
  GET_PUBLIC_INVOICE_F: "GET_PUBLIC_INVOICE_F",

  UPDATE_INVOICE_SUMMARY: "UPDATE_INVOICE_SUMMARY",
  
  UPDATE_PUBLIC_INVOICE_SUMMARY: "UPDATE_PUBLIC_INVOICE_SUMMARY",

  GET_INVOICE_TIMELINE_R : "GET_INVOICE_TIMELINE_R",
  GET_INVOICE_TIMELINE_S : "GET_INVOICE_TIMELINE_S",
  GET_INVOICE_TIMELINE_F : "GET_INVOICE_TIMELINE_F",

  UPDATE_PAYMENT_STATUS_S : "UPDATE_PAYMENT_STATUS_S",


  ADD_NEW_CLIENT_R : "ADD_NEW_CLIENT_R",
  ADD_NEW_CLIENT_S : "ADD_NEW_CLIENT_S",
  ADD_NEW_CLIENT_F: "ADD_NEW_CLIENT_F",

  UPDATE_CLIENT_CUSTOMER_R : "UPDATE_CLIENT_CUSTOMER_R",
  UPDATE_CLIENT_CUSTOMER_S : "UPDATE_CLIENT_CUSTOMER_S",
  UPDATE_CLIENT_CUSTOMER_F: "UPDATE_CLIENT_CUSTOMER_F",

  DELETE_CLIENT_CUSTOMER_R : "DELETE_CLIENT_CUSTOMER_R",
  DELETE_CLIENT_CUSTOMER_S : "DELETE_CLIENT_CUSTOMER_S",
  DELETE_CLIENT_CUSTOMER_F: "DELETE_CLIENT_CUSTOMER_F",

  GET_PAYMENT_REPORTS_R: "GET_PAYMENT_REPORTS_R",
  GET_PAYMENT_REPORTS_S: "GET_PAYMENT_REPORTS_S",
  GET_PAYMENT_REPORTS_F: "GET_PAYMENT_REPORTS_F",

  GET_NOTIFICATION_R : "GET_NOTIFICATION_R",
  GET_NOTIFICATION_S : "GET_NOTIFICATION_S",
  GET_NOTIFICATION_F : "GET_NOTIFICATION_F",

  REMOVE_NOTIFICATIONS_S : "REMOVE_NOTIFICATIONS_S",

  INVOICE_REFUND_R : "INVOICE_REFUND_R",
  INVOICE_REFUND_S : "INVOICE_REFUND_S",
  INVOICE_REFUND_F : "INVOICE_REFUND_F",

  UPDATE_INVOICE_SUBSCRIPTION: "UPDATE_INVOICE_SUBSCRIPTION",
};

let APP_CREDENTIALS = {
    google: {
        "client_id": "932824503983-8q3cmokhnaamoo28g26vbdv0kff7mbjs.apps.googleusercontent.com",
        "google_api_key": "AIzaSyDMyTak2q2OaKt4om5W_-Id-oEPz5XvIrc"
    },
    firebase: {
        apiKey: "AIzaSyACy89FHB9xy1I1pn61XyAKhS_Tw_Cu980",
        authDomain: "dev-agenciflow.firebaseapp.com",
        projectId: "dev-agenciflow",
        storageBucket: "dev-agenciflow.appspot.com",
        messagingSenderId: "932824503983",
        appId: "1:932824503983:web:36a27aaca23baff8c0a65a",
        measurementId: "G-ZK8V8MVDSQ",
    },
    fcm_vapidKey: "BLik1PjjF4jlASeMUP-DASAGlCPs6pBU0jhYzwyUG9fpJWF1vsEbbs7BpkOBnhGNOwrTDXKjpPvRNTrUF7vPkIk",
    facebook: {
        "appId": "263016778523041",
        "facebook_graph_version": "v2.5"
    },
    stripe: {
        "stripe_clientId": "ca_JWvRZytm9tee2HoPVVQAQS7wkQlkl3hx",
        "stripe_publickey": "pk_test_51ItZCfCyDKnAYNYpe0FaPuzdKsAhW3EXRux2B7JvRdIPrVpn3fZQBF8ft3jqIACPhQNDswEJW0imrnCFCpeM8yZl004sn3ot6y"
    },
    apple: {
        "client_id": "com.agenciflow.loginservice",
        "redirect_url": "https://qa-server.agenciflow.com/api/v1/auth/appleLogin"
    },
    tabFiliate: {
        "account_id": "41933-605f41",
    },
    customDomain: {
        keyValue: process.env.REACT_APP_HOST_APP_DNS,
        pages:  process.env.REACT_APP_PAGES_APP_DNS
    }
};

const SERVER_POINT = process.env.REACT_APP_BUILD_TO || "LOCAL"; // QA, UAT, PROD, LOCAL

switch (SERVER_POINT) {
    case "PROD":
        APP_CREDENTIALS = {
            google: {
                "client_id": "361169640779-ssuf2oqs3jm6u0g5tve3pup8fgudgbps.apps.googleusercontent.com",
                "google_api_key": "AIzaSyDkCDEM4-m22T_uM9RHjb-JBQN3OY3XvBw",
            },
            firebase: {
                apiKey: "AIzaSyANYCVz_ixZpKEeRE7fIecvCDnsmE4UfnU",
                authDomain: "prod-agenciflow.firebaseapp.com",
                projectId: "prod-agenciflow",
                storageBucket: "prod-agenciflow.appspot.com",
                messagingSenderId: "361169640779",
                appId: "1:361169640779:web:4d5580873ea67592ee1cce",
                measurementId: "G-RQRP3EFBF8",
            },
            fcm_vapidKey: "BO1yCyAGa7dCi4HDDG0b1eycYAHb4slmQ60TGZv8FByN_cNlC5-YAbtC5XmEs6aRCEfxR7gpGZpbMAo17sQNoro",
            facebook: {
                "appId": "193115722666318",
                "facebook_graph_version": "v2.5"
            },
            stripe: {
                "stripe_clientId": "ca_JWvRZfbnjrtcBr0lVh8TiYXyGXgfZHLK",
                "stripe_publickey": "pk_live_51ItZCfCyDKnAYNYpdymsUHYlPHroYz7OISpqAEoCHVzXg1EElmuA4bk4kEqOlDbXyj5q49xr2u4SJThz8TEGl6k300hfFcP2Ah"
            },
            apple: {
                "client_id": "com.agenciflow.loginservice",
                "redirect_url": "https://server.agenciflow.com/api/v1/auth/appleLogin"
            },
            tabFiliate: {
                "account_id": "41933-605f41",
            },
            customDomain: {
                keyValue: process.env.REACT_APP_HOST_APP_DNS,
                pages:  process.env.REACT_APP_PAGES_APP_DNS
            },
            VideoCall: {
                "APP_ID" : "712fbbf9aef84d16affd2e0e8c107260",
                "APP_CERTIFICATE" : "5147d68a77434e9ab62cc561b56bfabb"
            }
        };
        break;
    case "UAT":
        APP_CREDENTIALS = {
            google: {
                "client_id": "729266441493-ps3futmsuru330kq35i7rdckrnljem4u.apps.googleusercontent.com",
                "google_api_key": "AIzaSyA-0d0NtAqxMl14e1uzsNd8ab-3ivtVQMk",
            },
            firebase: {
                apiKey: "AIzaSyAF9uiYM6VB4GbVVT7P8pL3fUgiBE8WfG8",
                authDomain: "uat-agenciflow.firebaseapp.com",
                projectId: "uat-agenciflow",
                storageBucket: "uat-agenciflow.appspot.com",
                messagingSenderId: "729266441493",
                appId: "1:729266441493:web:5a3a8355d5a9a1d47bad1c",
                measurementId: "G-9YHF41MBPN"
            },
            fcm_vapidKey: "BLik1PjjF4jlASeMUP-DASAGlCPs6pBU0jhYzwyUG9fpJWF1vsEbbs7BpkOBnhGNOwrTDXKjpPvRNTrUF7vPkIk",
            facebook: {
                "appId": "263016778523041",
                "facebook_graph_version": "v2.5"
            },
            stripe: {
                "stripe_clientId": "ca_JWvMHPJo7n5jLw0lYOKEeNABZsHfTYfj",
                "stripe_publickey": "pk_live_51HlbIjImEFoZPSnyCfRPxNxOLij47vgeF7QUi8gFMrMCEMgfMVzexUoKT9OmB7Ry1aXZ70nkpNxcDvpd7WJ4RuGv00qSoTvgWJ"
            },
            apple: {
                "client_id": "com.agenciflow.loginservice",
                "redirect_url": "https://uat-server.agenciflow.com/api/v1/auth/appleLogin"
            },
            tabFiliate: {
                "account_id": "41933-605f41",
            },
            customDomain: {
                keyValue: process.env.REACT_APP_HOST_APP_DNS,
                pages:  process.env.REACT_APP_PAGES_APP_DNS
            },
            VideoCall: {
                "APP_ID" : "a1615484d82c4b07966d33fd129a8267",
                "APP_CERTIFICATE" : "061ae7b1bce0497ca09c97ba47527200"
            }
        };
        break;
    case "STAGE":
        APP_CREDENTIALS = {
            google: {
                "client_id": "680988439343-a9tuo94e7gl0vmo52e6cjmpll4hn3cl8.apps.googleusercontent.com",
                "google_api_key": "AIzaSyDXP28dUnFoRPpZKQlYqbRv95n5jcBJgic",
            },
            firebase: {
                apiKey: "AIzaSyA0nRqaLsKGrGgCpqKxrrNpEY2hq8xzg1g",
                authDomain: "qa-agenciflow.firebaseapp.com",
                projectId: "qa-agenciflow",
                storageBucket: "qa-agenciflow.appspot.com",
                messagingSenderId: "680988439343",
                appId: "1:680988439343:web:dbf73483d727fbcb2aa7da",
                measurementId: "G-80DRN0DWH8",
            },
            fcm_vapidKey: "BH6R-rKPepCuIXaRTL8PO1JwleY5UecgfF-Q5x4U2Z-pdVfr6ZdKLRpUusV-SXsviji_ksSSiHOxnVakDboxvFc",
            facebook: {
                "appId": "882364659379470",
                "facebook_graph_version": "v2.5"
            },
            stripe: {
                "stripe_clientId": "ca_JWvRZytm9tee2HoPVVQAQS7wkQlkl3hx",
                "stripe_publickey": "pk_test_51ItZCfCyDKnAYNYpe0FaPuzdKsAhW3EXRux2B7JvRdIPrVpn3fZQBF8ft3jqIACPhQNDswEJW0imrnCFCpeM8yZl004sn3ot6y"
            },
            apple: {
                "client_id": "com.agenciflow.loginservice",
                "redirect_url": "https://stage2-server.agenciflow.com/api/v1/auth/appleLogin"
            },
            tabFiliate: {
                "account_id": "41933-605f41",
            },
            customDomain: {
                keyValue: process.env.REACT_APP_HOST_APP_DNS,
                pages:  process.env.REACT_APP_PAGES_APP_DNS
            },
            VideoCall: {
                "APP_ID" : "a1615484d82c4b07966d33fd129a8267",
                "APP_CERTIFICATE" : "99ce79086d0849dca192b462aed8a013"
            }
        };
        break;
    case "DEMO":
        APP_CREDENTIALS = {
            google: {
                "client_id": "231456997848-1a5ucm0um04kburttrls2vcobdqh47sr.apps.googleusercontent.com",
                "google_api_key": "AIzaSyDoFi_4G8jdzCwYj7sdwGYAMX552u4dXFQ",
            },
            firebase: {
                apiKey: "AIzaSyAUkdFMLB865-8eE30TKLyfKVFh0ZSHpv8",
                authDomain: "fair-gradient-416411.firebaseapp.com",
                projectId: "fair-gradient-416411",
                storageBucket: "fair-gradient-416411.appspot.com",
                messagingSenderId: "231456997848",
                appId: "1:231456997848:web:a8c72b6448ae456517f2ad",
                measurementId: "G-8YX7E3H5WL"
            },
            fcm_vapidKey: "BH6R-rKPepCuIXaRTL8PO1JwleY5UecgfF-Q5x4U2Z-pdVfr6ZdKLRpUusV-SXsviji_ksSSiHOxnVakDboxvFc",
            facebook: {
                "appId": "882364659379470",
                "facebook_graph_version": "v2.5"
            },
            stripe: {
                "stripe_clientId": "ca_JWvRZytm9tee2HoPVVQAQS7wkQlkl3hx",
                "stripe_publickey": "pk_test_51ItZCfCyDKnAYNYpe0FaPuzdKsAhW3EXRux2B7JvRdIPrVpn3fZQBF8ft3jqIACPhQNDswEJW0imrnCFCpeM8yZl004sn3ot6y"
            },
            apple: {
                "client_id": "com.agenciflow.loginservice",
                "redirect_url": "https://stage1-server.agenciflow.com/api/v1/auth/appleLogin"
            },
            tabFiliate: {
                "account_id": "41933-605f41",
            },
            customDomain: {
                keyValue: process.env.REACT_APP_HOST_APP_DNS,
                pages:  process.env.REACT_APP_PAGES_APP_DNS
            }
        };
        break;
    case "QA":
        APP_CREDENTIALS = {
            google: {
                "client_id": "680988439343-a9tuo94e7gl0vmo52e6cjmpll4hn3cl8.apps.googleusercontent.com",
                "google_api_key": "AIzaSyDXP28dUnFoRPpZKQlYqbRv95n5jcBJgic",
            },
            firebase: {
                apiKey: "AIzaSyA0nRqaLsKGrGgCpqKxrrNpEY2hq8xzg1g",
                authDomain: "qa-agenciflow.firebaseapp.com",
                projectId: "qa-agenciflow",
                storageBucket: "qa-agenciflow.appspot.com",
                messagingSenderId: "680988439343",
                appId: "1:680988439343:web:dbf73483d727fbcb2aa7da",
                measurementId: "G-80DRN0DWH8",
            },
            fcm_vapidKey: "BH6R-rKPepCuIXaRTL8PO1JwleY5UecgfF-Q5x4U2Z-pdVfr6ZdKLRpUusV-SXsviji_ksSSiHOxnVakDboxvFc",
            facebook: {
                "appId": "882364659379470",
                "facebook_graph_version": "v2.5"
            },
            stripe: {
                "stripe_clientId": "ca_JWvRZytm9tee2HoPVVQAQS7wkQlkl3hx",
                "stripe_publickey": "pk_test_51ItZCfCyDKnAYNYpe0FaPuzdKsAhW3EXRux2B7JvRdIPrVpn3fZQBF8ft3jqIACPhQNDswEJW0imrnCFCpeM8yZl004sn3ot6y"
            },
            apple: {
                "client_id": "com.agenciflow.loginservice",
                "redirect_url": "https://stage2-server.agenciflow.com/api/v1/auth/appleLogin"
            },
            tabFiliate: {
                "account_id": "41933-605f41",
            },
            customDomain: {
                keyValue: process.env.REACT_APP_HOST_APP_DNS,
                pages:  process.env.REACT_APP_PAGES_APP_DNS
            },
            VideoCall: {
                "APP_ID" : "a1615484d82c4b07966d33fd129a8267",
                "APP_CERTIFICATE" : "99ce79086d0849dca192b462aed8a013"
            }
        }
        break;

    case "DEV":
        APP_CREDENTIALS = {
            google: {
                "client_id": "932824503983-8q3cmokhnaamoo28g26vbdv0kff7mbjs.apps.googleusercontent.com",
                "google_api_key": "AIzaSyDMyTak2q2OaKt4om5W_-Id-oEPz5XvIrc"
            },
            firebase: {
                apiKey: "AIzaSyACy89FHB9xy1I1pn61XyAKhS_Tw_Cu980",
                authDomain: "dev-agenciflow.firebaseapp.com",
                projectId: "dev-agenciflow",
                storageBucket: "dev-agenciflow.appspot.com",
                messagingSenderId: "932824503983",
                appId: "1:932824503983:web:36a27aaca23baff8c0a65a",
                measurementId: "G-ZK8V8MVDSQ"
            },
            fcm_vapidKey: "BLik1PjjF4jlASeMUP-DASAGlCPs6pBU0jhYzwyUG9fpJWF1vsEbbs7BpkOBnhGNOwrTDXKjpPvRNTrUF7vPkIk",
            facebook: {
                "appId": "263016778523041",
                "facebook_graph_version": "v2.5"
            },
            stripe: {
                "stripe_clientId": "ca_JWvRZytm9tee2HoPVVQAQS7wkQlkl3hx",
                "stripe_publickey": "pk_test_51ItZCfCyDKnAYNYpe0FaPuzdKsAhW3EXRux2B7JvRdIPrVpn3fZQBF8ft3jqIACPhQNDswEJW0imrnCFCpeM8yZl004sn3ot6y"
            },
            apple: {
                "client_id": "com.agenciflow.loginservice",
                "redirect_url": "https://qa-server.agenciflow.com/api/v1/auth/appleLogin"
            },
            tabFiliate: {
                "account_id": "41933-605f41",
            },
            customDomain: {
                keyValue: process.env.REACT_APP_HOST_APP_DNS,
                pages:  process.env.REACT_APP_PAGES_APP_DNS
            },
            VideoCall: {
                "APP_ID" : "a1615484d82c4b07966d33fd129a8267",
                "APP_CERTIFICATE" : "99ce79086d0849dca192b462aed8a013"
            }
        };
        break;
    // mobile app environments
    case "APP_PROD":
        APP_CREDENTIALS = {
            google: {
                "client_id": "361169640779-ssuf2oqs3jm6u0g5tve3pup8fgudgbps.apps.googleusercontent.com",
                "google_api_key": "AIzaSyDkCDEM4-m22T_uM9RHjb-JBQN3OY3XvBw",
            },
            firebase: {
                apiKey: "AIzaSyANYCVz_ixZpKEeRE7fIecvCDnsmE4UfnU",
                authDomain: "prod-agenciflow.firebaseapp.com",
                projectId: "prod-agenciflow",
                storageBucket: "prod-agenciflow.appspot.com",
                messagingSenderId: "361169640779",
                appId: "1:361169640779:web:4d5580873ea67592ee1cce",
                measurementId: "G-RQRP3EFBF8",
            },
            fcm_vapidKey: "BO1yCyAGa7dCi4HDDG0b1eycYAHb4slmQ60TGZv8FByN_cNlC5-YAbtC5XmEs6aRCEfxR7gpGZpbMAo17sQNoro",
            facebook: {
                "appId": "193115722666318",
                "facebook_graph_version": "v2.5"
            },
            stripe: {
                "stripe_clientId": "ca_JWvRZfbnjrtcBr0lVh8TiYXyGXgfZHLK",
                "stripe_publickey": "pk_live_51ItZCfCyDKnAYNYpdymsUHYlPHroYz7OISpqAEoCHVzXg1EElmuA4bk4kEqOlDbXyj5q49xr2u4SJThz8TEGl6k300hfFcP2Ah"
            },
            apple: {
                "client_id": "com.agenciflow.loginservice",
                "redirect_url": "https://server.agenciflow.com/api/v1/auth/appleLogin"
            },
            tabFiliate: {
                "account_id": "41933-605f41",
            },
            customDomain: {
                keyValue: process.env.REACT_APP_HOST_APP_DNS,
                pages:  process.env.REACT_APP_PAGES_APP_DNS
            },
            VideoCall: {
                "APP_ID" : "a1615484d82c4b07966d33fd129a8267",
                "APP_CERTIFICATE" : "99ce79086d0849dca192b462aed8a013"
            }
        };
        break;
    case "APP_STAGE":
        APP_CREDENTIALS = {
            google: {
                "client_id": "680988439343-a9tuo94e7gl0vmo52e6cjmpll4hn3cl8.apps.googleusercontent.com",
                "google_api_key": "AIzaSyDXP28dUnFoRPpZKQlYqbRv95n5jcBJgic",
            },
            firebase: {
                apiKey: "AIzaSyA0nRqaLsKGrGgCpqKxrrNpEY2hq8xzg1g",
                authDomain: "qa-agenciflow.firebaseapp.com",
                projectId: "qa-agenciflow",
                storageBucket: "qa-agenciflow.appspot.com",
                messagingSenderId: "680988439343",
                appId: "1:680988439343:web:dbf73483d727fbcb2aa7da",
                measurementId: "G-80DRN0DWH8",
            },
            fcm_vapidKey: "BH6R-rKPepCuIXaRTL8PO1JwleY5UecgfF-Q5x4U2Z-pdVfr6ZdKLRpUusV-SXsviji_ksSSiHOxnVakDboxvFc",
            facebook: {
                "appId": "882364659379470",
                "facebook_graph_version": "v2.5"
            },
            stripe: {
                "stripe_clientId": "ca_JWvRZytm9tee2HoPVVQAQS7wkQlkl3hx",
                "stripe_publickey": "pk_test_51ItZCfCyDKnAYNYpe0FaPuzdKsAhW3EXRux2B7JvRdIPrVpn3fZQBF8ft3jqIACPhQNDswEJW0imrnCFCpeM8yZl004sn3ot6y"
            },
            apple: {
                "client_id": "com.agenciflow.loginservice",
                "redirect_url": "https://stage1-server.agenciflow.com/api/v1/auth/appleLogin"
            },
            tabFiliate: {
                "account_id": "41933-605f41",
            },
            customDomain: {
                keyValue: process.env.REACT_APP_HOST_APP_DNS,
                pages:  process.env.REACT_APP_PAGES_APP_DNS
            },
            VideoCall: {
                "APP_ID" : "a1615484d82c4b07966d33fd129a8267",
                "APP_CERTIFICATE" : "99ce79086d0849dca192b462aed8a013"
            }
        };
        break;

    default:
        APP_CREDENTIALS = {
            google: {
                "client_id": "932824503983-8q3cmokhnaamoo28g26vbdv0kff7mbjs.apps.googleusercontent.com",
                "google_api_key": "AIzaSyDMyTak2q2OaKt4om5W_-Id-oEPz5XvIrc"
            },
            firebase: {
                apiKey: "AIzaSyACy89FHB9xy1I1pn61XyAKhS_Tw_Cu980",
                authDomain: "dev-agenciflow.firebaseapp.com",
                projectId: "dev-agenciflow",
                storageBucket: "dev-agenciflow.appspot.com",
                messagingSenderId: "932824503983",
                appId: "1:932824503983:web:36a27aaca23baff8c0a65a",
                measurementId: "G-ZK8V8MVDSQ"
            },
            fcm_vapidKey: "BLik1PjjF4jlASeMUP-DASAGlCPs6pBU0jhYzwyUG9fpJWF1vsEbbs7BpkOBnhGNOwrTDXKjpPvRNTrUF7vPkIk",
            facebook: {
                "appId": "263016778523041",
                "facebook_graph_version": "v2.5"
            },
            stripe: {
                "stripe_clientId": "ca_JWvRZytm9tee2HoPVVQAQS7wkQlkl3hx",
                "stripe_publickey": "pk_test_51ItZCfCyDKnAYNYpe0FaPuzdKsAhW3EXRux2B7JvRdIPrVpn3fZQBF8ft3jqIACPhQNDswEJW0imrnCFCpeM8yZl004sn3ot6y"
            },
            apple: {
                "client_id": "com.agenciflow.loginservice",
                "redirect_url": "https://qa-server.agenciflow.com/api/v1/auth/appleLogin"
            },
            tabFiliate: {
                "account_id": "41933-605f41",
            },
            VideoCall : {
                "APP_ID" : "a1615484d82c4b07966d33fd129a8267",
                "APP_CERTIFICATE" : "99ce79086d0849dca192b462aed8a013"
            },
            customDomain: {
                keyValue: process.env.REACT_APP_HOST_APP_DNS,
                pages:  process.env.REACT_APP_PAGES_APP_DNS,
                default: process.env.REACT_APP_HOST_APP_DNS,
            }
        };
        break;
}

export const app_credentials = APP_CREDENTIALS;

// Import API Helper
import { deleteRequest, getRequest, postRequest, putRequest } from "../../utils/apiHelper";

const getEvent = async (params) => {
    const response = await postRequest("events/list", params);
    return response;
}

const getAgendaEvents = async (params) => {
    const response = await postRequest("events/agenda", params);
    return response;
}

const getSelectedEvent = async (params) => {
    const response = await postRequest("events/detail", params);
    return response;
}

//create new event
async function saveEvent(params) {
    const response = await postRequest("events/save", params);
    return response;
}

//edit event
async function editEvent(params) {
    const response = await postRequest("events/edit", params);
    return response;
}

//delete event
async function deleteEvent(params, occurence, dateRange) {
    const response = await postRequest(`events/delete/${params}/${occurence}`, dateRange)
    return response
}

async function getGoogleEmail(params) {
    const response = await postRequest("events/googleEmail", params)
    return response
}

async function getAuthorized(params) {
    const response = await postRequest("events/getAuthorized", params)
    return response
}

async function getAuthUrl(params, pageType) {
    const response = await postRequest(`events/getAuthUrl/${pageType}`, params)
    return response
}

async function getAppEvents(params) {
    const response = await postRequest("events/getAppEvents", params)
    return response
}

// get calendar view
const getEventView = async () => {
    const response = await postRequest("events/listview");
    return response;
}

//save calendar view
async function setCalendarView(params) {
    const response = await postRequest("events/view", params);
    return response;
}

async function getGoogleMeetLink() {
    const response = await getRequest("events/getgoogleMeetLink");
    return response;
}

async function saveAppAuthTokens(params) {
    const response = await postRequest("events/saveAppToken", params);
    return response;
}

async function getZoomLink() {
    const response = await getRequest("events/getzoomMeetLink");
    return response;
}

async function disconnectZoom() {
    const response = await postRequest("events/disconnectZoom");
    return response;
}

async function deleteBooking(id) {
    const response = await deleteRequest(`events/deleteBooking/${id}`,);
    return response;
}

// export all services
export const eventsService = {
    saveEvent,
    getEvent,
    getAgendaEvents,
    getSelectedEvent,
    editEvent,
    deleteEvent,
    getGoogleEmail,
    getAppEvents,
    getEventView,
    setCalendarView,
    getAuthorized,
    getAuthUrl,
    getGoogleMeetLink,
    saveAppAuthTokens,
    getZoomLink,
    disconnectZoom,
    deleteBooking
};
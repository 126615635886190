import React, { useState } from 'react';
import {
  Chip,
  Popover,
  withStyles,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  IconButton,
  Tooltip,
  useMediaQuery
} from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Autocomplete } from '@material-ui/lab';
import { ChromePicker } from "react-color";
import { TextField } from '../TextField';
import { CircleIcon, CircleIcon2, CloseIcon } from '../../assets/svg';
import styles from "./styles";
import theme from '../../assets/theme';

const ColorPicker = (props) => {
  const { anchorEl, open,
    onClose, color, onChange
  } = props
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <ChromePicker
        color={color}
        onChangeComplete={onChange}
        disableAlpha={true}
      />
    </Popover>
  )
}

const RoundButton = (props) => {
  const { onClick, color, style, ...rest } = props
  return (
    <button
      style={{
        borderStyle: 'solid',
        borderColor: color,
        borderWidth: '2px',
        borderRadius: '50%',
        width: '24px',
        height: '24px',
        ...style
      }}
      onClick={onClick}
      {...rest}
    />
  )
}

export const TagsComponent = (props) => {
  const { label, value, options, onChange, placeholder, classes, style = {}, borderRadius, tagProp, IconProps = () => { }, ...rest } = props
  const [tagColor, setTagColor] = useState(null)

  const onTagColorChange = (color) => {
    const updated_values = (value || []).map(m => {
      return {
        ...m,
        color: m.value === tagColor?.option ? color.hex : m.color
      }
    })
    setTagColor({
      ...tagColor,
      color: color
    })
    onChange(updated_values)
  }

  const handleChange = (e, v) => {
    const updated_values = (v || []).map(m => {
      return {
        ...getColor(m)
      }
    })
    onChange(updated_values)
  }

  const getColor = (val) => {
    const d = value.find(f => f.label === val) || options.find(f => f.label === val)
    return d || {
      value: val,
      label: val,
      color: theme.palette.primary.main
    }
  }

  return (
    <div className='heightAutoText tagsInputDiv' style={{ minHeight: '40px' }}>
      <Typography variant="overline" color="textSecondary" className="fLabel">{label}</Typography>
      <form onSubmit={e => { e.preventDefault() }}>
        <Autocomplete
          multiple
          id="tags-filled"
          options={(options || []).map((option) => option.label)}
          value={(value || []).map(m => m.label)}
          freeSolo
          renderTags={(values, getTagProps) => {
            return (values || []).map((option, index) => {
              let tagProps = tagProp == "hide" ? null : { ...getTagProps({ index }) }
              return (
                <span
                  className='tagsChipItem'
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    border: '1px solid #94A3B8',
                    borderRadius: borderRadius ? borderRadius : "24px",
                    paddingLeft: '10px',
                    marginRight: '5px',
                    marginBottom: '5px',
                    ...style,
                  }}>
                  <RoundButton
                    className="tagRound"
                    style={{ height: '20px', width: '20px' }}
                    color={getColor(option)?.color}
                    onClick={(e) => {
                      setTagColor({
                        ele: e.target,
                        option
                      })
                    }}
                  />
                  <Chip
                    variant="outlined"
                    style={{ border: 'none', height: '24px' }}
                    label={<span style={{ marginLeft: '-8px' }}>{option}</span>}
                    {...tagProps}
                  />
                  {IconProps(option)}

                </span>
              )
            })
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              className={classes.tagInputField}
              placeholder={placeholder}
            />
          )}
          renderOption={(option, props) => {
            return (
              <li {...props} style={{ display: 'flex' }}>
                <RoundButton
                  color={getColor(option)?.color}
                  style={{ marginRight: '8px', pointerEvents: 'none' }}
                  disabled
                />
                <Typography variant='body1' color="primary">
                  {option}
                </Typography>
              </li>
            )
          }}
          onChange={handleChange}
          onKeyUp={(e) => {
            if (e.key === 'Enter' || e.keyCode === 13) {
              // Prevent the default behavior of the Enter key on android
              e.preventDefault();
            }
          }}
          {...rest}
        />
      </form>
      <ColorPicker
        anchorEl={tagColor?.ele}
        open={tagColor !== null}
        onClose={() => setTagColor(null)}
        color={tagColor?.color}
        onChange={onTagColorChange}
      />
    </div>
  );
}
 const RenderTagsComponent = (props) => {
  const { classes } = props
  const { tag, tagColor } = props;
  const job_tags = tag?.split(',') || [];
  const job_colors = tagColor?.split(',') || [];
  const maxVisibleTags = 1;
  const isMobile = useMediaQuery('(max-width: 899px)');
  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'tags-popover' : undefined;

  return (
    <Grid container className='dflex flexWrap tagsContainer'>
      <Grid item>
        {job_tags.filter(f => f).slice(0, maxVisibleTags).map((tag, tagIndex) => (
          <Grid container key={tagIndex} className='dflex alignCenter'>
            <Grid item className="dflex alignCenter">
              <CircleIcon style={{ fill: job_colors[tagIndex] }} />
            </Grid>
            <Typography color='primary' variant="body1" className="fourLineEllip breakWord tagText">
              {tag}
              {tagIndex < job_tags.length - 1 && tagIndex < maxVisibleTags - 1 ? ',' : ''}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Grid item className='pl-1'>
        {job_tags.length > maxVisibleTags && (
          <Grid item>
            {isMobile ? (
              <>
                <Chip
                  className='br-8 fontSize10'
                  label={`+ ${job_tags.length - maxVisibleTags} more`}
                  variant="outlined"
                  color='secondary'
                  size='small'
                  onClick={handlePopoverOpen}
                />
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handlePopoverClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  className={classes.renderTag}
                >
                  <Typography sx={{ p: 2 }}>{job_tags.slice(maxVisibleTags).join(', ')}</Typography>
                </Popover>
              </>
            ) : (
              <Tooltip
                title={job_tags.slice(maxVisibleTags).join(', ')}
                arrow
              >
                <Chip
                  className='br-8 fontSize10'
                  label={`+ ${job_tags.length - maxVisibleTags} more`}
                  variant="outlined"
                  color='secondary'
                  size='small'
                />
              </Tooltip>
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};


export const RenderOneTag = (props) => {
  const { tag, tagColor } = props
  const job_tags = tag?.split(',').splice(0, 1) || []
  const job_colors = tagColor?.split(',') || []
  return (
    <Grid className='dflex flexWrap tagsContainer'>
      {
        job_tags.filter(f => f).map((tag, tagIndex) => (
          <>
            {/* <Grid className='dflex alignCenter'>
              <Grid item className="dflex alignCenter">
                <CircleIcon style={{ fill: job_colors[tagIndex] }} />
              </Grid>
              <Typography color='primary' variant="body1" className="fourLineEllip breakWord tagText">
                {tag}
                {tagIndex < job_tags.length - 1 ? ',' : ''}
              </Typography>
            </Grid> */}
            <Grid className='dflex alignCenter'>
              <Grid className='dflex alignCenter justifyCenter' style={{ width: '16px', height: '16px', borderRadius: '2px', marginRight: '8px', background: `${job_colors[0]}20` }}>
                <Grid item style={{ width: '5px', height: '5px', borderRadius: '1px', background: job_colors[0] }}>
                </Grid>
              </Grid>
              <Typography color='primary' variant="body2" className='whiteSpacNowrap fw-600' style={{ color: job_colors[0] }}>
                {job_tags[0]}
              </Typography>
            </Grid>
          </>
        ))
      }
    </Grid>
  )
}

export const StackedBarTags = (props) => {
  const { tagColor } = props
  let job_colors = tagColor?.split(',') || []
  const barHeight = 100 / job_colors.length
  return (
    <Grid
      className='dflex flexWrap flexColumn'
      style={{
        height: '50px', width: '6px', marginRight: '12px'
      }}
    >
      {
        job_colors.map((color) => (
          <>
            <Grid className='dflex alignCenter chipItem' style={{
              width: '6px',
              height: `${barHeight}%`,
              backgroundColor: color || theme.palette.primary.main
            }} />
          </>
        ))
      }
    </Grid>
  )
}

export const Tags = withStyles(styles)(TagsComponent);
export const RenderTags = withStyles(styles)(RenderTagsComponent);

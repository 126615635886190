const style = (theme) => ({
    attendee: {
        width: "100%",
        height: "100%",
        borderRadius: "8px"
    },
    cover:{
        objectFit: "cover"
    },
    contain:{
        // Added important as agora adds style tag throught js
        objectFit: "contain !important",
    },
    RemoteVideoStyle: {
        position: 'relative',
        overflow: 'hidden',
        padding: "4px"
    },
    ScreenshareStyle: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        height: '100%'
    },
    RemoteScreenShareVideo: {
        width: '90vw',
        height: '90vh',
        position: 'fixed',
        objectFit: "contain",
        right: "3%"
    },
    UnpinUserMuteContainer: {
        width: "119.97px",
        height: "106.256px",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        /* border: 1px solid black; */
        background: '#574141'
    },
    UnPinUserMuteAvatar: {
        width: '46px',
        height: '46px',

    },
    AudioMuteIcon: {
        position: "absolute",
        top: "10px",
        right: '10px'
    },
    remoteUserName: {

        top: '89%',
        /* width: auto; */
        /* height: 50px; */
        position: 'absolute',
        backgroundColor: ' #0000004A',
        borderRadius: '12px',
        padding: '4px',
        right: '10px'
    },

    MuteVideoContainer: {
        position: "absolute",
        left: "0",
        top: "0",
        height: "100%",
        width: "100%",
        padding: "2px",
    },
    avatarContainer: {
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "8px",
        background: "#ffffff20"
    }
})

export default style
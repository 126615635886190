import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Grid, withStyles, Typography } from '@material-ui/core'

//import history
import history from "../../config/history";

//import Styles
import FooterStyles from './FooterStyles'
import { MobileDashboard, MobileChat, MobileFlows, MobileClients, MobileTasks, CalendarIconNew } from '../../assets/svg';
import MenuPage from './MenuPage';
import { getDefaultChatRoomPreference } from '../../utils';

function MobileFooter(props) {
    const { is_client } = useSelector(s => s.auth.loginUser)

    const { classes } = props;

    // const [settings, OpenSettings] = useState(false);

    //get reducer data
    const { loginUser: { user_role,is_client:isClient } } = useSelector(s => (s.auth));

    const [page, setPage] = useState("");

    const locate = history.location.pathname;

    useEffect(() => {
        if (locate && locate.indexOf("dashboard") > -1) {
            setPage("Dashboard")
        } else if (locate && locate.indexOf("chat") > -1) {
            setPage("Chat")
        } else if (locate && locate.indexOf("projects") > -1) {
            setPage("Projects")
        } else if (locate && locate.indexOf("invoice") === -1 && locate.indexOf("clients") > -1) {
            setPage("Clients")
        } else if (locate && locate.indexOf("tasks") > -1) {
            setPage("Tasks")
        } else if (locate && locate.indexOf("invoice") > -1) {
            setPage("Invoices")
        } else if (locate && locate.indexOf("boards") > -1) {
            setPage("Boards")
        } else if (locate && locate.indexOf("brain") > -1) {
            setPage("Brain")
        } else if (locate && locate.indexOf("calendar") > -1) {
            setPage("Calendar")
        } else if (locate && locate.indexOf("booking") > -1) {
            setPage("Booking")
        } else if (locate && locate.indexOf("page-builder") > -1) {
            setPage("Pages")
        } else if (locate && locate.indexOf("funnel") > -1) {
            setPage("Funnel")
        } else if (locate && locate.indexOf("menu") > -1) {
            setPage("Menu")
        } else {
            if (!user_role?.dash) {
                setPage("")
            } else {
                setPage(user_role?.dash ? "Dashboard" : 'Clients')
            }
        }
    }, [history, history.location, locate, user_role, user_role?.dash])

    const getDefaultChatRoom = () => {
        const { type } = getDefaultChatRoomPreference(is_client)
        return type
    }

    const getMainMenu = (user_role) => {
        const { type, cli, pjt, dash, cHub } = user_role || {};

        if (type === "admin" || type === "emp_admin") {
            return (
                <>
                    <Grid item className={`${page === "Dashboard" ? "active_menu" : ""} mobileMenu footerMenu`} onClick={() => { history.push("/dashboard") }}><Typography variant="body2" className={classes.menuSvg}><MobileDashboard /><span className="mfText">Dashboard</span></Typography></Grid>
                    <Grid item className={`${page === "Chat" ? "active_menu" : ""} mobileMenu footerMenu`} onClick={() => { history.push(`/chat/${getDefaultChatRoom()}`) }}><Typography variant="body2" className={classes.menuSvg}><MobileChat /><span className="mfText">Chat</span></Typography></Grid>
                    <Grid item className={`${page === "Projects" ? "active_menu" : ""} mobileMenu footerMenu`} onClick={() => { history.push("/projects") }}><Typography variant="body2" className={classes.menuSvg}><MobileFlows /><span className="mfText">Flows</span></Typography></Grid>
                    <Grid item className={`${page === "Clients" ? "active_menu" : ""} mobileMenu footerMenu`} onClick={() => { history.push("/clients") }}><Typography variant="body2" className={classes.menuSvg}><MobileClients /><span className="mfText">Clients</span></Typography></Grid>
                    <Grid item className={`${page === "Tasks" ? "active_menu" : ""} mobileMenu footerMenu`} onClick={() => { history.push("/tasks") }}><Typography variant="body2" className={classes.menuSvg}><MobileTasks /> <span className="mfText">Tasks</span></Typography></Grid>
                    <MenuPage />
                </>
            )
        } else if (type === "emp") {
            return (
                <>
                    {dash && <Grid item className={`${page === "Dashboard" ? "active_menu" : ""} mobileMenu footerMenu`} onClick={() => { history.push("/dashboard") }}><Typography variant="body2" className={classes.menuSvg}><MobileDashboard /><span className="mfText">Dashboard</span></Typography></Grid>}
                    {cHub && <Grid item className={`${page === "Chat" ? "active_menu" : ""} mobileMenu footerMenu`} onClick={() => { history.push(`/chat/${getDefaultChatRoom()}`) }}><Typography variant="body2" className={classes.menuSvg}><MobileChat /><span className="mfText">Chat</span></Typography></Grid>}
                    {pjt && <Grid item className={`${page === "Projects" ? "active_menu" : ""} mobileMenu footerMenu`} onClick={() => { history.push("/projects") }}><Typography variant="body2" className={classes.menuSvg}><MobileFlows /><span className="mfText">Flows</span></Typography></Grid>}
                    {cli && <Grid item className={`${page === "Clients" ? "active_menu" : ""} mobileMenu footerMenu`} onClick={() => { history.push("/clients") }}><Typography variant="body2" className={classes.menuSvg}><MobileClients /><span className="mfText">Clients</span></Typography></Grid>}
                    <Grid item className={`${page === "Tasks" ? "active_menu" : ""} mobileMenu footerMenu`} onClick={() => { history.push("/tasks") }}><Typography variant="body2" className={classes.menuSvg}><MobileTasks /> <span className="mfText">Tasks</span></Typography></Grid>
                    <MenuPage />
                </>
            )
        } else {
            return (
                <>
                    {dash && <Grid item className={`${page === "Dashboard" ? "active_menu" : ""} mobileMenu footerMenu`} onClick={() => { history.push("/dashboard") }}><Typography variant="body2" className={classes.menuSvg}><MobileDashboard /><span className="mfText">Dashboard</span></Typography></Grid>}
                    {cHub && <Grid item className={`${page === "Chat" ? "active_menu" : ""} mobileMenu footerMenu`} onClick={() => { history.push(`/chat/${getDefaultChatRoom()}`) }}><Typography variant="body2" className={classes.menuSvg}><MobileChat /><span className="mfText">Chat</span></Typography></Grid>}
                    {cli && <Grid item className={`${page === "Clients" ? "active_menu" : ""} mobileMenu footerMenu`} onClick={() => { history.push("/clients") }}><Typography variant="body2" className={classes.menuSvg}><MobileClients /><span className="mfText">Clients</span></Typography></Grid>}
                    <Grid item className={`${page === "Calendar" ? "active_menu" : ""} mobileMenu footerMenu`} onClick={() => { history.push("/calendar") }}><Typography variant="body2" className={classes.menuSvg}><CalendarIconNew height={24} width={23} /> <span className="mfText">Calendar</span></Typography></Grid>
                    <MenuPage />
                </>
            )
        }
    }

    return (
        <React.Fragment>
            <Grid container alignItems="baseline" className={`${"MobileFooter"} ${classes.MobileFooter}`}>
                <Grid item className={classes.MobileFooterContainer}>
                    <Grid item container justifyContent="space-around" alignItems="center">
                        {
                            getMainMenu(user_role)
                        }
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

// default props
MobileFooter.defaultProps = {
    classes: {}
};

// prop types
MobileFooter.propTypes = {
    classes: PropTypes.object
};

export default withStyles(FooterStyles)(MobileFooter)

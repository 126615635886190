import { baseAction } from "./baseAction";
import { tasksConstant, loaderConstant, alertConstant, authConstant } from "../constants";
import { tasksService } from "../services";
import { sendTaskData } from "../../utils/socketv2";

const { request, success, failure } = baseAction;

// get all the groups in the task
const getTasksGroups = (params) => {
    return (dispatch) => {
        dispatch(request(tasksConstant.GET_TASKS_GROUPS_R, params));
        dispatch(request(loaderConstant.LOADING_TRUE));
        tasksService.getTasksGroups(params).then(data => {
            dispatch(success(tasksConstant.GET_TASKS_GROUPS_S, data));
            dispatch(request(loaderConstant.LOADING_FALSE));
        }, error => {
            dispatch(failure(tasksConstant.GET_TASKS_GROUPS_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        }
        );
    };
}

// save task preference in db
const saveTaskPreference = (params, cb = () => { }) => {
    return (dispatch) => {
        dispatch(request(tasksConstant.SAVE_TASK_PREFERENCE_R, params));
        dispatch(request(loaderConstant.LOADING_TRUE));
        tasksService.saveTaskPreference(params).then(data => {
            dispatch(success(tasksConstant.SAVE_TASK_PREFERENCE_S, data));
            dispatch(request(loaderConstant.LOADING_FALSE));
            dispatch(success(authConstant.SAVE_TASK_PREFERENCE, params));
            cb()
        }, error => {
            dispatch(failure(tasksConstant.SAVE_TASK_PREFERENCE_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        }
        );
    };
}

// save task preference in db
const getTaskGroupTypes = (params, cb = () => { }) => {
    return (dispatch) => {
        dispatch(request(tasksConstant.GET_TASK_GROUP_TYPE_R, params));
        dispatch(request(loaderConstant.LOADING_TRUE));
        tasksService.getTaskGroupTypes(params).then(data => {
            dispatch(success(tasksConstant.GET_TASK_GROUP_TYPE_S, data));
            dispatch(request(loaderConstant.LOADING_FALSE));
            cb()
        }, error => {
            dispatch(failure(tasksConstant.GET_TASK_GROUP_TYPE_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        }
        );
    };
}

// get task filters
const getTaskFilters = (params, cb = () => { }) => {
    return (dispatch) => {
        dispatch(request(tasksConstant.GET_TASK_FILTERS_R, params));
        dispatch(request(loaderConstant.LOADING_TRUE));
        tasksService.getTaskFilters(params).then(data => {
            dispatch(success(tasksConstant.GET_TASK_FILTERS_S, data));
            dispatch(request(loaderConstant.LOADING_FALSE));
            cb()
        }, error => {
            dispatch(failure(tasksConstant.GET_TASK_FILTERS_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        }
        );
    };
}

// get task details
const getTaskDetails = (params, cb = () => { }) => {
    return (dispatch) => {
        dispatch(request(tasksConstant.GET_TASK_DETAILS_R, params));
        dispatch(request(loaderConstant.LOADING_TRUE));
        tasksService.getTaskDetails(params).then(data => {
            dispatch(success(tasksConstant.GET_TASK_DETAILS_S, data));
            dispatch(request(loaderConstant.LOADING_FALSE));
            cb(data)
        }, error => {
            dispatch(failure(tasksConstant.GET_TASK_DETAILS_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        }
        );
    };
}

//get task comments list 
const getTaskComments = (params, cb = () => { }) => {
    return (dispatch, getState) => {
        dispatch(request(tasksConstant.GET_TASK_COMMENTS_LIST_R, params));
        tasksService.getTaskComments(params).then(data => {
            let { comments: commentData } = data.data;
            if (commentData && commentData.length > 0) {
                let { comments } = getState().task.taskComments;
                let newCommentsList = [];
                if (comments.length > 0) {
                    newCommentsList = [...comments, ...commentData];
                } else {
                    newCommentsList = [...commentData];
                }
                const newDataObj = newCommentsList;
                dispatch(success(tasksConstant.GET_TASK_COMMENTS_LIST_S, newDataObj));
                cb()
            }
        }, error => {
            dispatch(failure(tasksConstant.GET_TASK_COMMENTS_LIST_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        }
        );
    };
}

// get task counts
const getTaskCommentsCounts = (params, cb = () => { }) => {
    return (dispatch) => {
        dispatch(request(tasksConstant.GET_TASK_COMMENTS_COUNT_R, params));
        tasksService.getTaskCommentsCounts(params).then(data => {
            dispatch(success(tasksConstant.GET_TASK_COMMENTS_COUNT_S, data));
            cb(data)
        }, error => {
            dispatch(failure(tasksConstant.GET_TASK_COMMENTS_COUNT_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    };
}

// complete task
function completeTask(params, callBack = () => { }) {
    return (dispatch) => {
        dispatch(request(tasksConstant.COMPLETE_TASKS_R, params));
        tasksService.completeTask(params).then(data => {
            dispatch(success(tasksConstant.COMPLETE_TASKS_S, data));
            dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
            callBack()
        }, error => {
            dispatch(failure(tasksConstant.COMPLETE_TASKS_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        }
        );
    };
}

// /** get Tasks listing */
function getTaskList(params, callBack = () => { }) {
    return (dispatch) => {
        if (params && params.skip === 0) {
            dispatch(request(loaderConstant.LOADING_TRUE));
        }
        dispatch(request(tasksConstant.GET_TASK_LIST_R, params));
        tasksService.getTaskList(params).then(data => {
            dispatch(success(tasksConstant.GET_TASK_LIST_S, { params, ...data }));
            if (params && params.skip === 0) {
                dispatch(request(loaderConstant.LOADING_FALSE));
            }
            callBack()
        }, error => {
            dispatch(failure(tasksConstant.GET_TASK_LIST_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        });
    };
}

function saveTask(params, callBack = () => { }) {
    return (dispatch) => {
        const { isQuickTask = false } = params;
        dispatch(request(tasksConstant.SAVE_TASK_R, params));
        if (!isQuickTask) {
            dispatch(request(loaderConstant.LOADING_TRUE));
        }
        tasksService.saveTask(params).then(data => {
            dispatch(success(tasksConstant.SAVE_TASK_S, { params, data }));
            if (!isQuickTask) {
                if (params instanceof FormData) {
                    let users = params.get("assignedTo") || [];
                    if (users && users.length) {
                        sendTaskData({ users, emitType: 'tasks:notify' })
                    }
                }
                dispatch(request(loaderConstant.LOADING_FALSE));
            }
            callBack()
        }, error => {
            dispatch(failure(tasksConstant.SAVE_TASK_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        });
    };
}


function deleteTask(params, callBack = () => { }) {
    return (dispatch) => {
        dispatch(request(loaderConstant.LOADING_TRUE));
        dispatch(request(tasksConstant.DELETE_TASKS_R, params));
        tasksService.deleteTask(params).then(data => {
            dispatch(success(tasksConstant.DELETE_TASKS_S, params));
            dispatch(request(loaderConstant.LOADING_FALSE));
            callBack()
        }, error => {
            dispatch(failure(tasksConstant.DELETE_TASKS_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        });
    };
}

function saveComment(params, callBack = () => { }, comment) {
    return (dispatch, getState) => {
        dispatch(request(tasksConstant.SAVE_COMMENT_R, comment));

        tasksService.saveComment(params).then(data => {
            dispatch(success(tasksConstant.SAVE_COMMENT_S, data?.data));
            callBack(data)
        }, error => {
            dispatch(failure(tasksConstant.SAVE_COMMENT_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    }
}

function editTaskComment(data) {
    return (dispatch) => {
        dispatch(success(tasksConstant.EDIT_COMMENT_S, data));
    }
}

function clearTaskComment(data) {
    return (dispatch) => {
        dispatch(success(tasksConstant.CLEAR_COMMENT_S, data));
    }
}

function deleteComment(params, callBack = () => { }) {
    return (dispatch, getState) => {
        dispatch(request(tasksConstant.DELETE_COMMENT_R, params));
        tasksService.deleteComment(params).then(data => {
            let { comments } = getState().task.taskComments;
            let newCommentsList = comments.filter((item) => item.id !== params.id);
            dispatch(success(tasksConstant.DELETE_COMMENT_S, newCommentsList));
            callBack()
        }, error => {
            dispatch(failure(tasksConstant.DELETE_COMMENT_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        }
        );
    }
}

// /** get default Tasks listing */
function getDefaultList(params, callBack = () => { }) {
    return (dispatch) => {
        if (params && params.skip === 0) {
            dispatch(request(loaderConstant.LOADING_TRUE));
        }
        dispatch(request(tasksConstant.GET_DEFAULT_LIST_R, params));
        tasksService.getDefaultList(params).then(data => {
            dispatch(success(tasksConstant.GET_DEFAULT_LIST_S, data));
            if (params && params.skip === 0) {
                dispatch(request(loaderConstant.LOADING_FALSE));
            }
            callBack()
        }, error => {
            dispatch(failure(tasksConstant.GET_DEFAULT_LIST_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        });
    };
}

// /** get default Tasks count */
function getDefaultCount(params, callBack = () => { }) {
    return (dispatch) => {
        dispatch(request(tasksConstant.GET_DEFAULT_COUNT_R, params));
        dispatch(request(loaderConstant.LOADING_TRUE));
        tasksService.getDefaultCount(params).then(data => {
            dispatch(success(tasksConstant.GET_DEFAULT_COUNT_S, data));
            dispatch(request(loaderConstant.LOADING_FALSE));
            callBack(data)
        }, error => {
            dispatch(failure(tasksConstant.GET_DEFAULT_COUNT_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        });
    };
}

/**update inline task */
function updateInlineTask(params, callBack = () => { }) {
    return (dispatch) => {
        dispatch(request(tasksConstant.UPDATE_INLINE_TASK_R, { params }));
        // dispatch(request(loaderConstant.LOADING_TRUE));
        tasksService.updateInlineTask(params).then(data => {
            dispatch(success(tasksConstant.UPDATE_INLINE_TASK_S, { params, data }));
            // dispatch(request(loaderConstant.LOADING_FALSE));
            callBack(data)
        }, error => {
            dispatch(failure(tasksConstant.UPDATE_INLINE_TASK_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            // dispatch(request(loaderConstant.LOADING_FALSE));
        });
    };
}

// /** get task activity */
function getTaskActivity(params, callBack = () => { }) {
    return (dispatch) => {
        dispatch(request(tasksConstant.GET_TASK_ACTIVITY_R, params));
        tasksService.getTaskActivity(params).then(data => {
            dispatch(success(tasksConstant.GET_TASK_ACTIVITY_S, data));
            callBack(data)
        }, error => {
            dispatch(failure(tasksConstant.GET_TASK_ACTIVITY_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    };
}


// export all task actions
export const tasksAction = {
    getTasksGroups,
    saveTaskPreference,
    getTaskGroupTypes,
    getTaskFilters,
    getTaskDetails,
    getTaskComments,
    getTaskCommentsCounts,
    completeTask,
    getTaskList,
    saveTask,
    deleteTask,
    saveComment,
    editTaskComment,
    clearTaskComment,
    deleteComment,
    getDefaultCount,
    getDefaultList,
    updateInlineTask,
    getTaskActivity
};

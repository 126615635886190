import React, {useState} from 'react';
import PropTypes from "prop-types";
import { Grid, IconButton, withStyles, CardMedia } from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';


//Import Images
import { DownloadCircleIcon } from '../../assets/svg'
//Import Styles
import styles from "./styles";
import { saveAs } from 'file-saver';

const DocsMessage = (props) => {
    const { classes, message, handleUndo, undo, fromCall } = props;
    const media = message.message_file ? JSON.parse(message.message_file) : null;

    let fileName = media?.name;
    let fileExtension = fileName?.split('.').pop();
    const [imageSrc, setImageSrc] = useState(`./icons/${fileExtension}.svg`);
    const handleImageError = () => {
        setImageSrc(`./icons/doc.svg`);
      };

    return (
        <>
            {!undo && message?.message_file ?
                <Grid container className={`${classes.ChatdocumentList} ChatdocumentList`} alignItems="center" wrap="nowrap">
                    {/* <img className={classes.chatBoxFileIcon} src={`./icons/${fileExtension}.svg`} onerror="this.onerror=null;this.src='./icons/apk.svg';" alt={"FT"} /> */}
                    <CardMedia
                    className={classes.chatBoxFileIcon}
                    component="img"
                    alt="FT"
                    src={imageSrc}
                    onError={handleImageError}
                    />
                    <Grid className={`${"oneLineEllip documenTitle"} ${classes.documenTitle}`}>
                        {
                            media && media.url ?
                                media.name :
                                <Skeleton variant="rect" width={210} height={118} />
                        }
                    </Grid>
                    {fromCall!=='star_messages'&&<IconButton onClick={()=>{saveAs(media.url)}}> <DownloadCircleIcon /> </IconButton>}
                    {/* <a href={media.url} rel="noopener noreferrer" title="download" target="_blank" download><DownloadCircleIcon /></a> */}
                </Grid>
                :
                <span>This message has been deleted
                    <a style={{ paddingLeft: "10px", color: "blue", fontWeight: "bold" }} className={"cursorPointer"} onClick={() => handleUndo(message.id)}>undo</a>
                </span>
            }
        </>
    )
}

// Default props
DocsMessage.defaultProps = {
    classes: {},
    message: null,
    handleHashTagClick: () => { }
};

// Prop types
DocsMessage.propTypes = {
    classes: PropTypes.object,
    message: PropTypes.object,
    handleHashTagClick: PropTypes.func,
};

export default withStyles(styles)(DocsMessage)
/* eslint-disable import/no-anonymous-default-export */
import { slideShowConstant } from "../constants/slideShowConstant";

// Set Initial State
const initialState = {
  loading: false,
  open: false,
  files: [],
  mediaFiles: [],
  activeFileIndex: 0,
  star:0,
  previewData: [],
  isFromMedia: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case slideShowConstant.DISPLAY_STATUS_TRUE:
      return {
        ...state,
        open: true
      };
    case slideShowConstant.DISPLAY_STATUS_FALSE:
      return {
        // ...initialState,
        open: false
      };
    case slideShowConstant.SLIDE_SHOW_FILE_UPDATE:
      return {
        ...state,
        open: true,
        files: [...action.data.mediaArray],
        activeFileIndex: action.data && action.data.index ? action.data.index : initialState.activeFileIndex,
        star: action.data.star,
        previewData : action.data.previewData,
        data:action.data.data,
        isFromMedia: action.data.isFromMedia ? action.data.isFromMedia : false,
        mediaFiles: action.data.allFiles ? action.data.allFiles : [],
        directDownload: action.data?.directDownload || false
      };
    case slideShowConstant.SLIDE_SHOW_EMOJI_UPDATE:
    return {
      ...state,
      previewData : state.previewData.map((slide)=> {
        if(slide?.message_id === action.data.message_id) {
            let data = {
              user_id: action.data.userId,
              avatar_filename: action.data.avatar_filename,
              name: action.data.name,
              username: action.data.username,
              emoji: action.data.emoji
          }
          slide["reactions"][action.data.emoji] = slide?.["reactions"]?.[action?.data?.emoji] ?? [];
          if(action.data.actionType === "add") {
            const exist = (slide["reactions"]?.[action?.data?.emoji] || []).find(r => r.user_id == data.user_id)
            if(!exist) {
              slide["reactions"]?.[action?.data?.emoji].push(data)
            }
          } else {
            slide["reactions"][action.data.emoji] = slide["reactions"][action.data.emoji]?.filter(item => item?.user_id !== data.user_id) || [];
            if (slide["reactions"][action.data.emoji]?.length === 0) {
              const emojiList = slide["reactions"][action.data.emoji] || [];
              const userIndex = emojiList.findIndex(elem => elem.user_id === data.user_id);
              if (userIndex > -1) {
                slide["reactions"][action.data.emoji].splice(userIndex, 1);
              }
              if (slide["reactions"][action.data.emoji].length === 0) {
                  delete slide["reactions"][action.data.emoji];
              }
          }
          }
        }
        return slide
      })
      };
    case slideShowConstant.SLIDE_SHOW_DATA_UPDATE:
      return {
        ...state,
        data: action?.data?.data || state.data,
        activeFileIndex: action?.data?.index || state.activeFileIndex
      }
    case slideShowConstant.SLIDE_SHOW_STAR_UPDATE:
      let updatedFiles = state.mediaFiles
      if(action?.data?.data?.id && updatedFiles.length) {
        updatedFiles = updatedFiles.map((f)=>{
          if(f.id === action?.data?.data?.id) {
            return action?.data?.data
          } else {
            return f
          }
        })
      }
      return {
        ...state,
        mediaFiles: updatedFiles
      }


    default:
      return state;
  }
}

import { lightPalette as palette } from "../../assets/theme/palette";
import TaskDetailArrow from "../../assets/images/svg/taskCardArrow.svg";

const style = (theme) => ({
  filterItems: {
    borderRadius: '7px',
    height: '24px',
    fontSize: 12,
    paddingRight: '10px',
    margin: 2,
    '& .MuiChip-label': {
      paddingRight: 5
    },
    '& .CloseCircleIcon': {
      width: 17,
      fill: '#A6A6A6'
    }
  },
  recurring: {
    border: 'none',
    '@media only screen and (min-width: 900px)': {
      display: 'flex'
    }
  },
  groupingButton: {
    '@media only screen and (max-width: 599px)': {
      width: '100%'
    }
  },
  MobileComments: {
    '@media only screen and (max-width: 599px)': {
      minWidth: '100px !important'
    }
  },
  MobilebuttonView: {
    '@media only screen and (max-width: 599px)': {
      position: 'sticky',
      bottom: '0px'
    },
  },
  GroupingContainer: {
    '@media only screen and (max-width: 599px)': {
      overflowY: 'auto',
      height: '100%'
    },
    padding: '0px'
  },
  taskHeaderSection: {
    '& .FilterIcon2': {
      width: 20,
      height: 20
    },
    '& .FilterIcon3': {
      width: 17,
      height: 17
    },
    '& .task-calander-new-icon > svg': {
      width: 20,
      height: 20
    }
  },
  taskHeaderMob: {
    '@media only screen and (max-width: 768px)': {
      flexFlow: "nowrap"
    },
  },
  TaskIndiMenuBody_null: {
    height: 'calc(100vh - 196px)',
    borderRadius: 7,
    overflowY: 'auto',
    paddingRight: '5px !important',
    '& .taskListBody': {
      '&.MuiAccordion-root': {
        background: `transparent !important`,
        borderRadius: '12px',
        '&.Mui-expanded': {
          margin: '0px !important'
        }
      },
      '& .MuiTable-root': {
        borderSpacing: '0px 0px !important',
        borderCollapse: 'separate !important'
      },
      '& .MuiTableCell-root': {
        borderBottom: 'none !important',
        padding: '8px 16px',

      },
      '& .tagsCell': {
        '& svg': {
          flexShrink: 0
        }
      },
      '&:nth-child(2)': {
        '& .mt-1': {
          marginTop: '0px !important',
        },
        '& .MuiAccordionSummary-expandIcon': {
          marginTop: '-12px'
        }
      }
    },
    '& .addTaskBtn': {
      borderRadius: '12px',
      border: '1px dashed #C6C6C6',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '6px',
      cursor: 'pointer'
    },
    // '& .MuiAccordion-root': {

    // background: `transparent !important`
    // },

    '& .MuiAvatar-img, & .MuiAvatar-root': {
      height: '32px',
      width: '32px',
      fontSize: '14px'
    },
    '& .headerCheckIcon, & .unCheckIcon': {
      fill: theme.palette.text.secondary2
    },

    '& .checkedIcon': {
      fill: theme.palette.text.blueText
    },
    '& .taskTableAction': {
      '& .MuiIconButton-root': {
        height: '28px'
      }
    },
  },
  tableAccordionHeader: {
    justifyContent: 'flex-start',
    '& .MuiAccordionSummary-content': {
      display: 'inline-flex',
      flex: 'initial'
    }
  },
  taskTitle: {
    textAlign: "left",
    paddingLeft: '0px',
    '@media screen and (max-width: 899px)': {
      paddingLeft: "15px !important"
    },
  },
  taskListRow: {
    '&:hover': {
      background: theme.palette.containers.bg48,
      '& td.MuiTableCell-root.MuiTableCell-body': {
        background: theme.palette.containers.bg48,
      }
    },
    '& .MuiFormControlLabel-root': {
      marginRight: 0
    },
    '& .MuiSvgIcon-root': {
      width: '28px',
      height: '28px'
    },
    '& td.MuiTableCell-root.MuiTableCell-body': {
      background: theme.palette.containers.card,
      '&:first-child': {
        paddingLeft: '18px !important'
      },
      '&:nth-child(2)': {
        paddingLeft: '25px !important'
      },
      '&:last-child': {
        paddingRIght: '26px !important'
      }
    },
    '&:first-child': {
      '& td.MuiTableCell-root.MuiTableCell-body': {
        background: theme.palette.containers.card,
        '&:first-child': {
          borderTopLeftRadius: '12px',
        },
        '&:last-child': {
          borderTopRightRadius: '12px',
        }
      },
    },
    '&:last-child': {
      '& td.MuiTableCell-root.MuiTableCell-body': {
        background: theme.palette.containers.card,
        '&:first-child': {
          borderBottomLeftRadius: '12px',
        },
        '&:last-child': {
          borderBottomRightRadius: '12px',
        }
      },
    },
    '& .taskTableAction': {},
    '& .MuiAvatar-root': {
      height: '32px',
      width: '32px',
      fontSize: '14px',
      border: '2px solid #fafafa'
    },
    '& .taskName': {
      '& .MuiInputBase-root': {
        border: `0.5px solid #2563EB`,
        background: `#2563EB20`,
        padding: '0px 4px',
        marginRight: '0px',
        color: theme.palette.text.text5,
      },
      '& .MuiInput-underline': {
        '&::before': {
          border: 'none !important'
        },
        '&::after': {
          border: 'none !important'
        }
      }
    },
    '& .noItemIcon': {
      color: theme.palette.secondary.main
    },
    '& .moreTags': {
      width: '24px',
      height: '24px',
      borderRadius: '24px',
      background: theme.palette.containers.bg3,
      fontSize: '12px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer'
    },
  },
  chipStyle: {
    minWidth: "60px",
    maxWidth: "65px",
    padding: "2px 6px",
    borderRadius: "7px",
    textAlign: "center",
    marginRight: 15,
    '& .MuiTypography-root': {
      color: palette.primary.contrastText,
      textAlign: 'center'
    }
  },
  statusStyle: {
    minWidth: "75px",
    padding: "2px 6px",
    borderRadius: "7px",
    textAlign: "center",
    marginRight: 15,
    display: 'inline-block',
    whiteSpace: 'nowrap',
    '&.todoTag': {
      background: `${theme.palette.greyshades.light6} !important`
    },
    '& .MuiTypography-root': {
      color: palette.primary.contrastText,
      textAlign: 'center'
    }
  },
  sortContainer: {
    borderRadius: "10px",
    border: "1px solid #6f6f6f33",
    background: theme.palette.containers.card,
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.05)",
    margin: "2px",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  createTaskForm: {
    marginTop: '178px',
    marginLeft: '42px',
    marginRight: 64,
    borderRadius: '12px',
    border: `1px solid ${theme.palette.primary.border1}`,
    backgroundColor: theme.palette.containers.card,
    boxShadow: '0px 4px 15px 0px rgba(37, 99, 235, 0.05)',
    '& .MuiOutlinedInput-root': {
      border: 'none !important'
    },
    '& .MuiTableHead-root': {
      display: 'none'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none !important'
    },
    '& .MuiTableCell-root': {
      padding: '2px 10px !important',
    },
    '& .saveBtn': {
      backgroundColor: '#406FFC',
      borderRadius: '8px',
      color: '#FFF',
      border: 'none',
      boxSHadow: 'none'
    },
    '& .closeBtn': {
      height: '24px',
      width: '24px',
      background: '#F8FAFC',
      borderRadius: '8px',
      padding: '7px'
    },
    '& .MuiAccordion-root': {
      background: theme.palette.containers.card
    },
    '& .MuiSelect-outlined': {
      paddingRight: '32px !important'
    },
    '& .MuiTableCell-root': {
      borderBottom: 'none !important',
      padding: '2px 10px !important',
    },
    '& .SubTaskArrow': {
      display: 'none'
    }
  },
  createTaskName: {
    color: '#94A3B8',
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: '125%'
  },
  priority: {
    display: 'inline-flex',
    height: ' 26px',
    justifyContent: "center",
    alignItems: "center",
    gap: "4px",
    flexShrink: "0"
  },
  addtaskButton: {
    width: '1334px',
    height: '35px',
    flexShrink: '0',
    borderRadius: '12px',
    border: '1px dashed #C6C6C6'
  },
  customDialogContainer: {
    '& .closeDialog': {
      height: '24px',
      width: '24px',
      background: theme.palette.containers.bg7,
      borderRadius: '8px',
      padding: '7px'
    },
    '& .MuiDialog-paper': {
      borderRadius: '10px',
      boxShadow: '0px 4px 55px 55px rgba(0, 0, 0, 0.06)'
    },
    '& .cDialogHeader': {
      borderBottom: `1px solid ${theme.palette.primary.border5}`,
      paddingBottom: '12px',
      marginBottom: '20px'
    },
    '& .cDialogFooter': {
      borderTop: `1px solid ${theme.palette.primary.border5}`,
      marginTop: '20px'
    },
    '& .cFilterOptions': {
      '& .MuiFormControl-root ': {
        height: '28px'
      },
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
        padding: '5px',
      },
      '& .filterChips': {
        borderRadius: '5px',
        background: theme.palette.containers.bg50,
        padding: '3px 10px',
        alignItems: 'center',
        justifyContent: "space-between",
        '& .MuiTypography-body1': {
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis'
        },
        '& svg': {
          width: '10px',
          height: '10px'
        }
      }
    },
    '@media only screen and (max-width: 599px)': {
      overflowY: 'auto !important',
      height: '100% !important',
      overflowX: 'hidden !important'
    }
  },
  newFormDialog: {
    '@media only screen and (max-width: 599px)': {
      '& .MuiDialog-container': {
        background: theme.palette.containers.card,
        boxShadow: 'unset'
      }
    },
    width: "100%",
    '& .taskTitle': {
      maxWidth: "100%"
    },
    "& .MuiDialog-paper": {
      borderRadius: "16px",

    },
    "& .formDialogHeader": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "25px 20px 24px",
      borderBottom: `1px solid ${theme.palette.primary.border5}`,
      background: theme.palette.containers.card,
      position: 'sticky',
      top: '0',
      zIndex: 1000,
      "& .detailbreadcrumb": {
        padding: "10.5px 12px",
        background: theme.palette.containers.bg7,
        borderRadius: "8px",
      },
      "& .MuiIconButton-root": {
        height: "24px",
        width: "24px",
        "& svg": {
          flexShrink: "0",
          width: "16px",
          "& .strokePath": {
            stroke: "#64748B",
          },
          "& .fillPath": {
            fill: "#64748B",
          },
        },
        '& .TrashIcon2': {
          height: '18px',
          width: '18px'
        }
      },
    },
    "& .closeDialog": {
      background: theme.palette.containers.bg7,
      borderRadius: "8px",
    },
    "& .newFormBody": {
      padding: "30px 30px 24px",
      maxHeight: 'calc(100% - 75px)',
      '& .slectDropWhite': {
        '& .MuiSelect-iconOutlined': {
          fill: '#fff'
        },
      },
      '& .selectBgField': {
        '& .todoTag': {
          '& .MuiOutlinedInput-root': {
            background: `${theme.palette.greyshades.light6} !important`,
          }
        },
        '& .MuiOutlinedInput-root': {
          '@media only screen and (max-width: 599px)': {
            maxWidth: "none !important"
          }
        },
        '& .inprogressTag': {
          '& .MuiSelect-icon': {
            fill: '#2563eb !important'
          }
        },
        '& .completedTag': {
          '& .MuiSelect-icon': {
            fill: '#0e8e2a !important'
          }
        },
      },
      "& .MuiFormControl-root": {
        minWidth: "40px",
        color: "#fff !important",
      },
      "& .MuiSelect-select ": {
        paddingRight: "10px",
      },
      '& .selectpl0': {
        "& .MuiSelect-select ": {
        }
      },
      "& fieldset": {
        border: "none",
      },
      "& .customFields": {
        "& fieldset": {
          border: "1px solid #D7DBEC !important",
        },
        "& .MuiChip-root": {
          background: "#EDF0F4",
          borderRadius: "5px",
          paddingRight: "14px",
          marginRight: "5px",
          "& .MuiChip-label": {
            color: "#64748B",
            fontSize: "11px",
          },
          "& .CloseIcon": {
            width: "8px",
            height: "8px",
          },
        },
      },
      "& .MuiOutlinedInput-root": {
        height: "32px",
        minHeight: "32px",
      },
      "& .taskDilogAction": {
        "& .MuiButtonBase-root": {
          borderRadius: "12px !important",
          height: "48px !important",
        },
        "& .MuiButton-containedPrimary": {
          background: "#2563EB !important",
        },
      },
      "& .descriptionField": {
        '& .MuiOutlinedInput-root': {
          paddingLeft: '0px',
          paddingRight: '0px',
          overflowY: 'auto',
          height: 'auto',
          padding: '0px'
        },
        '& textarea:focus-visible': {
          border: 'none'
        },
        "& input": {
          paddingLeft: "0px !important",
        },
      },
      '& .tagsInputDiv': {
        width: '100%',
      },
      '& .tagsContainer': {
        display: 'flex',
        alignItems: 'center',
        '& .MuiOutlinedInput-root': {
          border: '1px solid #6F6F6F33',
          paddingLeft: '15px',
          gap: "5px !important"
        },
        '& .tagsChipItem': {
          borderRadius: '5px !important',
          fontSize: '11px',
          fontWeight: '500',
          height: '30px',
          marginLeft: '5px',
          '& .MuiChip-label': {
            paddingLeft: '8px'
          },
          '& button': {
            width: '18px !important',
            height: '18px !important',
            padding: '0px'
          }
        },
        '@media only screen and (min-width: 768px)': {
          '&.tagsHalfWidth': {
            maxWidth: '65%'
          },
          '&.tagsFullWidth': {
            maxWidth: '100%',
            '& .MuiTextField-root': {
              boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.05)'
            }
          }
        },
        '@media only screen and (max-width: 768px)': {
          '&.tagsHalfWidth, &.tagsFullWidth': {
            maxWidth: '100%',
            flexWrap: 'wrap'
          }
        }
      },
      "& .blueText": {
        color: "#2563EB",
        fontSize: "14px",
        fontWeight: "600",
        minWidth: 'auto',
        padding: '0px',
        marginRight: '15px',
        '& .MuiButton-label': {
          fontWeight: "600",
          minWidth: 'auto',
          padding: '0px'
        }
      },
    },
    "& .selectBgField": {
      "& .MuiSelect-root": {
        fontSize: "12px",
        fontWeight: "700",
      },
    },
    "& .repeatBtn .RepeatIconImg": {
      marginBottom: "-4px",
    },
    "& .nonSticky": {
      zIndex: "0 !important",
      position: "revert !important"
    },
    "& .titleContainer": {
      display: "flex",
      justifyContent: "space-between",
      background: 'transparent',
      background: theme.palette.containers.card,
      position: 'relative',
      position: 'sticky',
      top: 64,
      '@media only screen and (max-width: 599px)': {
        top: 45
      },
      zIndex: 999,
      borderBottom: `1px solid ${theme.palette.primary.border5}`,
      marginBottom: '5px',
      '&.createTitleDiv': {
        '& .MuiSelect-iconOutlined': {
          fill: theme.palette.secondary.main
        }
      },
      "& .taskName input": {
        fontSize: "16px",
        fontWeight: "700",
        paddingLeft: "0px",
        width: "100%",
      },

      "& .taskStatus": {},
      '@media only screen and (max-width: 576px)': {
        '& .selectBgField': {
          right: '20px',
          bottom: '10px'
        }
      }
    },
    '&.CreateContainer': {
      '& .MuiPaper-root': {
        maxWidth: '868px',
      }
    },
    '&.ViewContainer': {
      '& .MuiPaper-root': {
        maxWidth: '900px',
      }
    }
  },
  taskGroupSelector: {
    "& .MuiSelect-root, .MuiAutocomplete-input": {
      "&:focus": {
        backgroundColor: "transparent",
      },
    },
    "& .priorityChip": {
      height: "26px",
      borderRadius: "8px",
      display: "flex",
      alignItems: "center",
      color: "#fff",
      padding: "0px 12px",
      fontSize: "12px",
      fontWeight: "700",
      maxWidth: '100px'
    },
    "& .taskType svg": {
      width: "10px",
      height: "10px",
      marginRight: "8px",
    },
    '& .nameField': {
      '& .MuiOutlinedInput-root': {
        paddingTop: '6px'
      }
    },
    '@media only screen and (max-width: 576px)': {
      '& .fw-mob': {
        width: '100%'
      }
    },
    '& .selectpl0': {
      '& .MuiSelect-outlined, & .MuiAutocomplete-input': {
        paddingLeft: '0px !important'
      },
      '& .menuText': {
        '& .mr-1': {
          marginRight: '0px !important'
        }
      }
    }
  },
  taskdetailAccordion: {
    "& .MuiAccordion-root": {
      border: `1px solid ${theme.palette.primary.border5}`,
      borderRadius: "12px",
      background: theme.palette.containers.card
    },
    "& .MuiAccordionSummary-root": {
      minHeight: "40px",
      padding: "10px 8px 10px 16px !important",
    },
    "& .MuiCollapse-wrapper": {
      padding: "24px 16px",
      borderTop: `1px solid ${theme.palette.primary.border5}`,
    },
    "& .MuiAvatar-root": {
      width: "34px",
      height: "34px",
      fontSize: "14px",
      fontWeight: "500",
      border: '2px solid #fff'
    },
    "& .valueContainer": {
      marginTop: "12px",
      "& .MuiAvatar-root": {
        width: "34px",
        height: "34px",
        fontSize: "14px",
        fontWeight: "500",
      },
      "& .dot": {
        height: "6px",
        width: "6px",
        borderRadius: "6px",
      },
      "&.dateContainer": {
        background: "#EDF6FF",
        borderRadius: "8px",
        padding: "10px",
        display: "flex",
        "& .TaskCalendarIcon .strokePath": {
          stroke: "#0F172A",
        },
      },
    },
    "& .noItems": {
      display: "flex",
      alignItems: "center",
      marginTop: "12px",
      "& .noItemIcon": {
        height: "32px",
        border: "1px dashed #64748B",
        width: "32px",
        borderRadius: "30px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: "12px",
        flexShrink: '0'
      },
      "& .MuiList-root": {
        overflow: "scroll",
        maxHeight: "300px"
      },
      '& .fillStroke': {
        stroke: theme.palette.secondary.main
      }
    },
    '& .timeSelect': {
      '& .ClockIcon2, & .TimeZoneIcon': {
        position: 'absolute'
      },
      '& .MuiSelect-select': {
        paddingLeft: '25px !important'
      },
      '& .MuiOutlinedInput-root': {
        background: 'transparent'
      }
    },
    '@media only screen and (max-width: 500px)': {
      '& .fw-mob': {
        flexBasis: '100%',
        '& .MuiFormControl-root': {
          width: 'auto'
        }
      }
    },
    '& .timeSelect, & .tzCont': {
      height: '40px',
      border: '1px solid #6F6F6F33',
      borderRadius: '7px',
      boxShadow: '0px 4px 16px 0px #0000000D',
      padding: '10px',
    },
    '& .timeZoneContainer': {
      '& .tzCont': {
        maxWidth: 'fit-content'
      },
      '@media only screen and (max-width:599px)': {
        '&.timeZoneContainer': {
          '& .tzCont': {
            height: "100px"
          },
        }
      }
    },
    '& .MuiCollapse-root': {
      backgroundColor: theme.palette.containers.card,
      borderRadius: 7
    }
  },
  taskBg: {
    backgroundColor: theme.palette.containers.card,
    borderRadius: 7
  },
  subTaskCollapse: {
    padding: "0px 12px",
  },
  subTaskContainer: {
    border: "1px solid #2563EB",
    boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.06)",
    borderRadius: "16px",
    padding: "16px 20px",
    position: 'relative',
    background: theme.palette.containers.card,
    '&::before': {
      backgroundImage: `url(${TaskDetailArrow})`,
      content: '" "',
      position: 'absolute',
      backgroundSize: '100%',
      width: '60px',
      height: '11px',
      zIndex: 9,
      backgroundRepeat: 'no-repeat',
      top: '-11px',
      left: '113px'
    },
    "& .subTaskAction": {
      "& .btnC": {
        backgroundColor: "transparent",
        border: "none"
      },
      "& .btnS": {
        background: "#2563EB !important",
      },
    },
  },
  subTaskList: {
    padding: "18px 24px",
    background: theme.palette.containers.card,
    border: "1px solid #D7D7D7",
    borderRadius: "10px",
    width: "100%",
    overflow: "hidden",
    marginBottom: '5px',
    "& .taskStatus, .priorityChip": {
      height: "22px",
      padding: "0px 11px",
      display: "flex",
      alignItems: "center",
      borderRadius: "4px",
      fontSize: "11px",
      fontWeight: "600",
    },
    "& .priorityChip": {
      color: theme.palette.containers.card,
    },
    "& .MuiAvatar-root": {
      width: "32px",
      height: "32px",
      fontSize: "14px",
      fontWeight: "500",
    },
    '& .actionBtns': {
      '& svg': {
        width: '14px',
        height: '14px'
      },
      '& svg.MoreHorizontal2': {

      }
    }
  },
  calenderCont: {
    borderRadius: "8px",
    height: "40px",
    '& .MuiInputAdornment-positionEnd': {
      order: -1,
      marginLeft: '-6px'
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: '0px'
    },
    '& .MuiInputAdornment-positionStart': {
      marginRight: 0
    },
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: '0px'
    },

    '& .MuiOutlinedInput-input': {
      paddingLeft: '5px'
    },

  },
  recurring: {
    border: `1px solid ${theme.palette.primary.border1}`,
    padding: 10,
    borderRadius: 8,
    width: "100%"
  },
  recurringCont: {
    borderRadius: 10,
    marginTop: 10,
    padding: 12,
    '& .MuiOutlinedInput-root': {
      minHeight: '40px !important'
    },
  },
  repeatCont: {
    padding: "25px 35px",
    "& .MuiOutlinedInput-root": {
      marginTop: "10px",
      strokeWidth: "1px",
      filter: "drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.05))",
      border: "1px solid #6f6f6f33"
    },
    "&.inputFieldrecurring": {
      width: '115px',
      height: '40px',
      fill: '#FFF',
      strokeWidth: '1px',
      stroke: 'rgba(111, 111, 111, 0.20)',
      filter: 'drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.05))'
    },
    "& .radioButton": {
      color: 'blue'
    },
    "& repeatTab": {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    '& .repeatField': {
      "& .MuiOutlinedInput-root": {
        paddingLeft: '15px !important',
      }
    },
    '& .startDate': {
      '& .MuiOutlinedInput-input': {
        paddingLeft: '10px'
      }
    }
  },
  repeatDayCont: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 6
  },
  repeatDays: {
    background: theme.palette.containers.card,
    border: `1px solid #6F6F6F33`,
    borderRadius: 9,
    border: "1px solid #6f6f6f33",
    padding: 10,
    width: 54,
    height: 40,
    marginRight: '10px',
    '& .MuiList-root': {
      display: 'flex'
    },
    '& .MuiCheckbox-root': {
      padding: '0px !important',
      margin: '0px !important'
    },
    '& .MuiFormControlLabel-root': {
      margin: '0px !important',
      marginLeft: '-23px !important'
    },
    '& .MuiListItemIcon-root': {
      minWidth: 0,
      visibility: 'hidden'
    },
    '& .MuiList-padding': {
      padding: '0px !important'
    },
    '& .MuiListItemText-root': {
      marginLeft: '-20px'
    },
  },
  selectedDay: {
    border: `1px solid ${theme.palette.colors.blue} !important`,
    color: theme.palette.colors.blue,
    background: theme.palette.containers.bg27,
    '& .MuiButtonBase-root': {
      fontWeight: '600',
    }
  },
  repeatCont: {

    "& .MuiOutlinedInput-root": {
      marginTop: "10px",
      strokeWidth: "1px",
      filter: "drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.05))",
      border: "1px solid #6f6f6f33"
    },
    "&.inputFieldrecurring": {
      width: '115px',
      height: '40px',
      fill: '#FFF',
      strokeWidth: '1px',
      stroke: 'rgba(111, 111, 111, 0.20)',
      filter: 'drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.05))'
    },
    "& .radioButton": {
      color: 'blue'
    },
    "& repeatTab": {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    '& .repeatField': {
      "& .MuiOutlinedInput-root": {
        paddingLeft: '15px !important',
      }
    },
    '& .startDate': {
      '& .MuiOutlinedInput-input': {
        paddingLeft: '10px'
      }
    }
  },
  repeatDayCont: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 6
  },
  repeatDays: {
    background: theme.palette.containers.card,
    border: `1px solid #6F6F6F33`,
    borderRadius: 9,
    border: "1px solid #6f6f6f33",
    padding: 10,
    width: 54,
    height: 40,
    marginRight: '10px',
    '& .MuiList-root': {
      display: 'flex'
    },
    '& .MuiCheckbox-root': {
      padding: '0px !important',
      margin: '0px !important'
    },
    '& .MuiFormControlLabel-root': {
      margin: '0px !important',
      marginLeft: '-23px !important'
    },
    '& .MuiListItemIcon-root': {
      minWidth: 0,
      visibility: 'hidden'
    },
    '& .MuiList-padding': {
      padding: '0px !important'
    },
    '& .MuiListItemText-root': {
      marginLeft: '-20px'
    },
  },
  selectedDay: {
    border: `1px solid ${theme.palette.colors.blue} !important`,
    color: theme.palette.colors.blue,
    background: theme.palette.containers.bg27,
    '& .MuiButtonBase-root': {
      fontWeight: '600',
    }
  },
  repeatMonth: {
    width: "fit-content !important"
  },
  toggleCont: {
    height: "40px",
    padding: "6px",
    borderRadius: '12px',
    background: theme.palette.containers.bg4,
    '& .MuiToggleButtonGroup-root': {
      background: theme.palette.containers.bg4
    },
    '& .MuiToggleButton-root': {
      background: theme.palette.containers.bg4,
      border: 'none',
      textTransform: 'capitalize',
      '& .MuiTypography-root': {
        color: theme.palette.secondary.main,
        fontSize: '12px'
      },
      '&.Mui-selected': {
        background: '#fff',
        borderRadius: '5px',
        '& .MuiTypography-root': {
          color: theme.palette.colors.blue,
          fontWeight: '700'
        }
      }
    },
  },
  attachments: {
    position: 'relative',
    cursor: 'pointer',
    "& img": {
      width: '200px',
      height: '100px',
      maxHeight: "100px",
      maxWidth: "200px",
      borderRadius: '11px',
      objectFit: 'cover'
    },
    "& .react-thumbnail-generator": {
      width: '200px',
      height: '100px',
      position: 'relative',
      "& img": {
        width: '200px',
        height: '100px',
        overFlow: "hidden",
        borderRadius: "10px",
        objectFit: "cover"
      },
      '& .snapshot-generator': {
        position: 'absolute',
        background: "#000",
        width: '200px',
        height: '100px',
        overFlow: "hidden",
        borderRadius: "10px",
        objectFit: "cover"
      }
    },
    '& .deleteIcon': {
      margin: '5px',
      position: 'absolute',
      top: '0px',
      left:"155px",
      background: '#808185',
      border: '1px solid transparent',
      borderRadius: 50,
      zIndex: 998
    },
  },
  playButton: {
    position: 'absolute',
    top: '0',
    left:"70px",
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%'
  },
  Documents: {
    position: 'relative',
    top: '0',
    left: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'end',
    border: "1px dashed #64748B",
  },
  taskLineDiv: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
  },
  taskLine: {
    width: '537px',
    height: '1px',
    background: '#DBDBDB'
  },
  userModelPopper: {
    '& .MuiPaper-root': {
      width: '100%',
      maxWidth: '360px',
      paddingBottom: '15px',
      overflow: 'hidden'
    },
    '& .addUsersHeader': {
      padding: '20px 20px 0px',
      '& .closeBtn': {
        borderRadius: '4px',
        width: '26px',
        height: '26px',
        background: theme.palette.containers.bg7,
        padding: '7px'
      }
    },
    '& .selectedUserDel': {
      background: '#E7E7E7',
      borderRadius: '30px',
      zIndex: 999,
      position: 'absolute',
      height: '18px',
      width: '18px',
      right: '-8px',
      top: '-3px'
    },
    '& .userImgClose': {
      width: '40px',
      height: '40px',
      position: 'relative',
      margin: "auto"
    },
    '& .selectedUsers': {
      display: 'flex',
      overflow: 'auto',
      maxWidth: 'calc(100% - 40px)',
      margin: 'auto',
      paddingBottom: '8px',
      justifyContent: 'flex-start',
      gap: "10px",
      marginTop: "15px",
      '& .MuiAvatar-root': {
        color: '#fff'
      },
      '&::-webkit-scrollbar': {
        visibility: 'hidden'
      },
      '&:hover::-webkit-scrollbar': {
        visibility: 'visible'
      }
    },
    '& .usersList': {
      overflowY: 'auto',
      maxHeight: '400px',
      // marginTop:'25px'
    }
  },
  addtaskTopBtn: {
    marginLeft: '-20px'
  },
  taskHeaderLabels: {
    maxHeight: "500px",
    '& .MuiTableSortLabel-root': {
      '&:hover': {
        '& .sortIcon': {
          opacity: '1',
        }
      }
    },
    '& .sortIcon': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      transition: 'all 0.5s',
      opacity: '0',
      marginLeft: '10px',
      '& svg': {
        width: '12px',
        height: '10px',
        flexShrink: 0
      },
      '&.asc': {
        '& svg': {
          '&:first-child': {
            '& path': {
              stroke: theme.palette.secondary.main
            }
          },
          '&:last-child': {
            '& path': {
              stroke: '#2563EB'
            }
          }
        }
      },
      '&.dsc': {
        '& svg': {
          '&:first-child': {
            '& path': {
              stroke: '#2563EB'
            }
          },
          '&:first-child': {
            '& path': {
              stroke: theme.palette.secondary.main
            }
          }
        }
      }
    },
    '@media only screen and (max-width: 899px)': {
      '& .sortIcon': {
        display: 'flex !important',
        flexDirection: 'column',
        gap: '5px',
      }
    },
    '& .MuiTableCell-head:first-child': {
      paddingLeft: '0px !important'
    },
    '& .MuiTableCell-head:nth-child(2)': {
      '&  .MuiTypography-root': {
        paddingLeft: '10px'
      }
    },
    '& .MuiTableCell-root': {
      borderBottom: 'none',
      padding: '6px 16px !important',
      whiteSpace: 'nowrap'
    },
  },
  headerMobile: {
    '@media only screen and (min-width: 1000px)': {
      '& .taskpageTableBody': {
        display: 'table',
        '& .RoundTickIcon': {
          flexShrink: 0
        }
      }
    },
    '@media only screen and (max-width: 1000px)': {
      '& .taskpageTableBody': {
        display: 'none'

      }
    }
  },
  accordionTaskTable: {
    background: theme.palette.containers.card,
    borderRadius: '12px',
    paddingBottom: '8px !important'
  },
  emptyContainer: {
    '@media only screen and (min-width: 768px)': {
      '&.emptyContainer': {
        height: 'calc(100vh - 177px)'
      }
    },
    '@media only screen and (max-width: 768px)': {
      '&.emptyContainer': {
        height: 'calc(100vh - 230px)'
      }
    }
  },
  recurringWeekly: {
    fill: '#FFF',
    strokeWidth: '1px',
    stroke: 'rgba(111, 111, 111, 0.20)',
    filter: 'drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.05))'
  },
  recurringTabs: {
    width: '220px',
    height: ' 36px',
    borderRadius: '12px',
    background: theme.palette.containers.bg44,
    display: 'flex',
    flexDirection: 'row',
    marginTop: 10,
    "& .never": {
      "& .text": {
        marginLeft: '5px',
        marginTop: '2px'
      },
      "& .unselecttext": {
        marginLeft: '19px',
        marginTop: '-2px'
      }
    },
    "& .on": {
      width: 40,
      "& .text": {
        marginLeft: '6px',
        marginTop: '1px',

      },
      "& .unselecttext": {
        marginTop: -2,
        marginLeft: '-2px',
        position: 'absolute'
      }
    },
    "& .after": {
      left: 10,
      "& .text": {
        marginLeft: '1px',
        marginTop: '2px',
        position: 'absolute'
      },
      "& .unselecttext": {
        marginTop: -1,
        marginLeft: '16px',
      }
    }
  },
  selectedItem: {
    borderRadius: '8px',
    background: theme.palette.containers.card,
    boxShadow: '0px 0px 12px 0px rgba(107, 114, 128, 0.04), 0px 0px 1px 0px rgba(75, 85, 99, 0.04)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    "& p": {
      color: "#2563EB !important",
      fontWeight: 600,
    }
  },
  tabItem: {
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: "0.3rem 1.5rem",
    cursor: "pointer",
    "& p": {
      color: 'var(--greyscale-500, #64748B)',
    }
  },
  recurringTabInputs: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: "1rem"
  },
  inputFieldrecurring: {
    width: '115px',
    height: '40px',
    fill: '#FFF',
    strokeWidth: '1px',
    stroke: 'rgba(111, 111, 111, 0.20)',
    filter: 'drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.05))'
  },
  inputFieldText: {
    color: '#778599',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal'
  },
  month: {
    width: '198px',
    height: '40px',
    fill: '#FFF',
    strokeWidth: '1px',
    stroke: 'rgba(111, 111, 111, 0.20)',
    filter: 'drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.05))',
  },
  afterInput: {
    width: '65px',
    height: ' 36px',
    flexShrink: '0',
    strokeWidth: '1px',
    stroke: '#2563EB',
    filter: 'drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.05))'
  },
  enableSubTask: {
    color: '#2563EB',

    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '160%', /* 22.4px */
    letterSpacing: '0.2px'
  },
  enableSubTasks: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'static',
  },
  enableSubTasksText: {
    marginLeft: 300
  },
  taskCalendar: {
    width: '16px',
    height: ' 15.568px',
    padding: '1.946px 2.667px',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '4px'
  },
  calendar: {
    fill: '#FFF',
    strokeWidth: '1px',
    stroke: 'rgba(111, 111, 111, 0.20)',
    filter: 'drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.05))',
    height: '32px',
    border: '1px solid #ccc',
    borderRadius: '8px',
    width: "200px"
  },
  calendarFont: {
    color: '#64748B',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '160%'
  },
  recurringHeaders: {
    color: theme.palette.primary.main,

    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal'
  },
  enableSubTaskContainer: {
    display: 'flex',
    position: 'absolute',
    left: '400px',
  },
  editRecurringContainer: {
    width: ' 100%',
    padding: "18px 24px",
    flexShrink: '0',
    borderRadius: '10px',
    marginTop: '20px',
    border: `1px solid ${theme.palette.primary.border1}`,
    background: theme.palette.containers.card,
    position: 'relative',
    "& .ediText": {
      color: theme.palette.primary.main,
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '500',
      letterSpacing: '0.2px',
    },

  },
  date: {
    color: 'var(--greyscale-500, #64748B)',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '140%',
    display: 'flex',
    paddingLeft: '80px'
  },
  circle: {
    marginLeft: '10px',
    marginRight: '10px',
    display: 'flex',
    alignItems: 'center'
  },
  recurringText: {
    color: '#2563EB',
    fontFamily: 'Inter',
    width: 72,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '600',
    letterSpacing: '0.2px',
    height: 20,
    borderRadius: 4,
    textAlign: "center",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  subTaskenableEdit: {
    color: '#64748B',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: '0.2px',
    display: 'flex',
    alignItems: 'center'
  },
  moreIcon: {
    position: 'absolute',
    right: '20px',
    top: '18px'
  },
  actionPaper: {
    '& .MuiPaper-root': {
      width: '100%',
      maxWidth: '500px',
    },
    '@media screen and (max-width: 599px)': {
      maxWidth: '0px !important'
    },
  },
  editPopup: {
    width: '100%',
    padding: '24px',
    flexShrink: '0',
    '& .closeBtn': {
      borderRadius: '4px',
      width: '26px',
      height: '26px',
      background: theme.palette.containers.bg7,
      padding: '7px'
    }
  },
  repeat: {
    height: 40,
    paddingTop: "12px",
  },
  start: {
    height: 41,
    height: '40px',
    alignItems: 'center',
    border: '1px solid #6F6F6F33',
    justifyContent: 'center',
    borderRadius: '7px',
    boxShadow: '0px 4px 16px 0px #0000000D',
    padding: '10px',
  },
  timeZone: {
    fill: '#FFF',
    strokeWidth: '1px',
    stroke: 'rgba(111, 111, 111, 0.20)',
    filter: 'drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.05))',
    width: '151 px',
    paddingTop: "11px",
    height: 40,
  },
  month: {
    width: '198px',
    height: '40px',
    fill: '#FFF',
    strokeWidth: '1px',
    stroke: 'rgba(111, 111, 111, 0.20)',
    filter: 'drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.05))',
  },
  inputFieldText: {
    color: '#778599',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal'
  },
  endOptions: {
    width: "78%",
    padding: "4px",
    background: theme.palette.containers.bg4,
    borderRadius: "15px",
    justifyContent: "space-around"
  },
  selectedTab: {
    width: '75px',
    height: '28px',
    borderRadius: '8px',
    background: theme.palette.containers.card,
    boxShadow: '0px 0px 12px 0px rgba(107, 114, 128, 0.04), 0px 0px 1px 0px rgba(75, 85, 99, 0.04)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: "-1px"
  },
  normalTab: {
    color: 'var(--greyscale-500, #64748B)',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '160%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  afterInput: {
    width: '65px',
    flexShrink: '0',
    strokeWidth: '1px',
    stroke: '#2563EB',
    filter: 'drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.05))'
  },
  tagItemPad: {
    padding: "4px 3px 0 0"
  },
  uModelList: {
    display: "flex",
    flexDirection: "row",
    marginLeft: 20,
    alignItems: "center",
    paddingBottom: "10px",
    '& .MuiAvatar-root': {
      color: '#fff'
    }
  },
  selusersList: {
    display: "flex",
    flexDirection: "column",
    marginRight: 20,
    alignItems: "center",
    justifyContent: "flex-start",
  },
  taskDialog: {
    padding: '15px',
    '&.taskMenu': {
      padding: '8px',
      minWidth: '140px',
      '& .MuiListItem-root': {
        borderRadius: '8px',
        fontSize: '13px',
        color: theme.palette.secondary.main
      }
    },
    '& .closeDialog': {
      height: '24px',
      width: '24px',
      background: theme.palette.containers.bg7,
      borderRadius: '8px',
      padding: '7px'
    }
  },
  timezoneelement: {
    display: 'block',
    position: 'relative'
  },
  timezonetime: {
    height: 1,
    width: 220,
    backgroundColor: 'grey'
  },
  timeZonePopup: {
    '& .MuiPaper-root': {
      maxWidth: '385px'
    },
    '@media only screen and (max-width: 599px)': {
      left: '3px !important'
    }
  },
  endColor: {
    color: theme.palette.text.blueText,
    fontWeight: "bold"
  },
  tblHover: {
    '& .openHover': {
      alignSelf: "flex-end",
      alignItems: 'center',
      marginLeft: "auto",
      display: "flex",
      justifyContent: "space-between",
      fontSize: '10px',
      color: theme.palette.secondary.main,
      height: '40px',
      paddingRight: '8px'
    },
    '& .openHover .openHoverItem': {
      display: "none",
      border: " 0.1px solid #64748B",
      background: theme.palette.containers.card,
      boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.12)",
      borderRadius: "42px",
      padding: "2px 6px",
      cursor: "pointer",
      height: '19px'
    },
    '& .plus': {
      height: '19px',
      width: '19px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& svg': {
        width: '10px',
        height: '10px',
        flexShrink: '0',
        '& path': {
          fill: theme.palette.secondary.main
        }
      }
    },
    '&:hover': {
      '& .openHover .openHoverItem': {
        display: "flex"
      },
    },
  },
  rowContainer: {
    padding: '0px 25px 0px 3px',
  },
  newInlineForm: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    '& .inlineActions': {
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      right: '10px'
    },
    '& .MuiOutlinedInput-root': {
      paddingRight: '170px'
    }
  },
  repeatBar: {
    '& .repeatBarInner': {
      border: `1px solid ${theme.palette.primary.border1}`,
      borderRadius: '10px',
      padding: '14px 24px',
      '@media only screen and (max-width: 599px)': {
        backgroundColor: theme.palette.containers.card,
        borderRadius: 7
      }
    }
  },
  createRoundBtn: {
    borderRadius: '32px !important',
    height: '32px',
    width: '32px',
    minWidth: '32px',
    justifyContent: 'center',
    display: 'flex',
    padding: '0px !important',
    '&.MuiButton-containedPrimary': {
      background: 'linear-gradient(251deg, #8361FD 28.05%, #2F63ED 68.98%) !important'
    }

  },
  recurringIcn: {
    paddingLeft: "12px",
    alignItems: 'center'
  },

  // /
  defaultTable: {
    '& td.MuiTableCell-root.MuiTableCell-body': {
      '&:nth-child(2)': {
        paddingLeft: '0px !important'
      },
    },
    '& .MuiTableSortLabel-active': {
      color: theme.palette.primary.main
    },
    '& .MuiTableSortLabel-root': {
      '&:hover': {
        '& .sortIcon': {
          opacity: '1',
        }
      }
    },
    '& .sortIcon': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      transition: 'all 0.5s',
      opacity: '0',
      marginLeft: '10px',
      '& svg': {
        width: '12px',
        height: '10px',
        flexShrink: 0
      },
      '&.asc': {
        '& svg': {
          '&:first-child': {
            '& path': {
              stroke: theme.palette.secondary.main
            }
          },
          '&:last-child': {
            '& path': {
              stroke: '#2563EB'
            }
          }
        }
      },
      '&.dsc': {
        '& svg': {
          '&:first-child': {
            '& path': {
              stroke: '#2563EB'
            }
          },
          '&:first-child': {
            '& path': {
              stroke: theme.palette.secondary.main
            }
          }
        }
      }
    },
    '@media only screen and (min-width: 1201px)': {
      '& .addBtnCell': {
        marginLeft: '-15px',
        marginRight: '15px'
      }
    },
    '@media only screen and (max-width: 1200px)': {
      '& .addBtnCell': {
        marginLeft: '-60px',
        marginRight: '60px'
      }
    },
    height: 'calc(100vh - 142px)',
    "& .MuiTableCell-head": {
      color: "#94A3B8",
      fontSize: "12px",
      textTransform: "uppercase",
      background: theme.palette.containers.bg4,
      padding: "0px 10px !important",
      whiteSpace: 'nowrap'
    },
    "& .MuiTableCell-root": {
      borderBottom: "none",
      padding: "10px"
    },
    "& .defaultBodyCls": {
      borderRadius: "12px",
      background: theme.palette.containers.card
    },
    "& tr:first-child": {
      '& td:first-child': {
        borderTopLeftRadius: '12px',
      },
      '& td:last-child': {
        borderTopRightRadius: '12px',
      }
    },
    "& tr:last-child": {
      '& td:first-child': {
        borderBottomLeftRadius: '12px',
      },
      '& td:last-child': {
        borderBottomRightRadius: '12px',
      }
    },

    '@media only screen and (min-width: 350px) ': {
      height: 'calc(100vh - 240px)',
    },
    '@media only screen and (min-width: 600px) ': {
      height: 'calc(100vh - 225px)',
    },
    '@media only screen and (min-width: 900px) ': {
      height: 'calc(100vh - 150px)',
    },
    '@media only screen and (min-width: 1200px) ': {
      height: 'calc(100vh - 120px)',
    },
  },
  defaultTableHeight: {
    '@media only screen and (max-width: 599px)': {
      height: 'calc(100vh - 154px)'
    },
    '@media only screen and (min-width: 600px) and (max-width: 899px)': {
      height: 'calc(100vh - 166px)'
    }
  },
  subTaskCell: {
    textAlign: '-webkit-right',
    '& .MuiAccordion-root.createTaskForm': {
      maxWidth: 'calc(100% - 50px)',
      '& .SubTaskArrow': {
        display: 'block',
        position: 'absolute',
        left: '-19px'
      }
    }
  },
  formCheck: {
    marginLeft: '0px',
    cursor: "pointer"
  },
  datePickrCls: {
    // important to overrite the package css
    "& .rdrMonthPicker select": {
      height: "auto !important"
    },
    "& .rdrYearPicker select": {
      height: "auto !important"
    },
    '& .rdrDateDisplayWrapper': {
      background: theme.palette.containers.bg2
    },
    '& .rdrDateDisplayItem, & .rdrCalendarWrapper, & .rdrStaticRange, & .rdrDefinedRangesWrapper, & .rdrInputRangeInput': {
      background: theme.palette.containers.card
    },
    '& .rdrDayNumber span, & .rdrStaticRangeLabel, & .rdrInputRange': {
      color: theme.palette.primary.main
    },
    '& .rdrInputRangeInput, & .rdrStaticRange, & .rdrDefinedRangesWrapper': {
      borderColor: theme.palette.primary.border1
    },
    '& .rdrNextPrevButton': {
      background: theme.palette.containers.bg3
    },
    '& .rdrStaticRange': {
      '&:hover .rdrStaticRangeLabel': {
        background: `${theme.palette.containers.body} !important`
      }
    }
  },
  popverClass: {
    borderRadius: '10px',
    overflow: "unset",
  },
  ellipsisWidth: {
    maxWidth: "150px"
  },
  tasklistInnerContainer: {
    height: 'calc(100% - (env(safe-area-inset-bottom) + 28px))',
    '& .emptyContainer': {
      height: '100% !important'
    },
    '& > div': {
      height: '100% !important'
    },
    '@media screen and (min-width: 900px)': {
      height: 'calc(100% - (env(safe-area-inset-bottom) + 102px))',
    },
    '@media screen and (max-width: 899px)': {
      paddingLeft: "12px !important",
      paddingRight: "12px !important",
    },
  },
  avatarSkeleton: {
    width: "40px",
    height: "40px"
  },
  Automated: {
    background: theme.palette.containers.card,
    width: '100%',
    marginBottom: '30px !important',
    padding: '10px'
  },
  mobileFirstContainer: {
    color: theme.palette.primary.main,
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '20px',
    textAlign: 'left',
    padding: '10px'
  },
  clients: {
    marginLeft: '100px',
    fontWeight: 400,
    fontSize: '14px',
    fontFamily: 'Inter',
    lineHeight: '22.4px'
  },
  AssignCont1: {
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '19.2px',
    letterSpacing: '1px',
    textAlign: 'left',

  },
  dropdwonMobile: {
    padding: '8px 12px 8px 12px',
    gap: '8px',
    marginLeft: '190px',
    bottom: '19px'
  },
  tasktag: {
    justifyContent: 'space-between',
    width: '100%',
  },
  bodycontainer: {
    background: theme.palette.containers.body
  },
  AutomatedText: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '22.4px',
    letterSpacing: '0.2px',
    paddingLeft: '10px'
  },
  Assignies: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '19.2px',
    letterSpacing: '1px',
    color: '#94A3B8'
  },
  details: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '22.4px',
    letterSpacing: '0.2px',
  },
  button1: {
    background: theme.palette.containers.bg1,
    color: theme.palette.text.text1
  },
  button2: {
    background: theme.palette.containers.bg45,
    color: theme.palette.editor.textColor
  },
  buttonback: {
    padding: '8px solid #ffff !important',
    background: theme.palette.containers.card,
    width: '100%',
    position: 'fixed',
    bottom: '0px',
    left: '0px'
  },
  sortIcon: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.5s',
    opacity: '0',
    marginLeft: '10px',
    '& svg': {
      width: '12px',
      height: '10px',
      flexShrink: 0
    },
    '&.asc': {
      '& svg': {
        '&:first-child': {
          '& path': {
            stroke: theme.palette.secondary.main
          }
        },
        '&:last-child': {
          '& path': {
            stroke: '#2563EB'
          }
        }
      }
    },
    '&.dsc': {
      '& svg': {
        '&:first-child': {
          '& path': {
            stroke: '#2563EB'
          }
        },
        '&:first-child': {
          '& path': {
            stroke: theme.palette.secondary.main
          }
        }
      }
    },
    '@media only screen and (max-width: 899px)': {
      display: 'none'
    }
  },
  ColumnSettingFooter: {
    '@media only screen and (max-width: 899px)': {
      position: 'sticky',
      bottom: 0,
      background: theme.palette.containers.card
    }
  },
  emptyTaskCotainer: {
    " & .empty-list-content": {
      marginTop: "-1.5rem"
    }
  },

  fullPageMobileView: {
    '& .MuiPaper-root': {
      width: '100%',
      height: '100% ',
      maxWidth: 'none',
      maxHeight: '100%',
      margin: '0 ',
      borderRadius: '0px !important',
      backgroundColor: theme.palette.containers.body
    }
  },
  updateButton: {
    position: "sticky",
    bottom: 0,
    paddingBottom: "env(safe-area-inset-bottom) 0",
    background: theme.palette.containers.card,
    zIndex: 99
  },
  bgColor: {
    background: theme.palette.containers.card,
    '& .MuiCollapse-root': {
      background: theme.palette.containers.card,
    }
  },
  bgColor: {
    background: theme.palette.containers.card,
    '& .MuiCollapse-root': {
      background: theme.palette.containers.card,
    }
  },
  scrollToTop: {
    bottom: "30px",
    right: "25px",
    position: "absolute",
    zIndex: 500,
    cursor: "pointer"
  },
  recurringEnd: {
    display: 'flex',
    padding: '10px 10px !important',
    justifyContent: 'space-evenly',
    background: '#F5F6F9',
    width: 800,
    borderRadius: '12px',
    maxHeight: "50px",
    '& .elements': {
      borderRadius: '8px',
      padding: '8px 20px'
    }
  },
  customClass:{  
    padding:"5px 20px"
  },
  searchFieldCls: {
    "& .MuiAutocomplete-inputRoot": {
      paddingLeft: '10px !important'
    }
  },
  fileAttachment:{
    border: '1px solid #EAE9E9',
    margin: '5px',
    borderRadius:"6px",
    "& .MuiListItemText-root":{
      paddingLeft:"10px"
    }
  }
})

export default style;
const styles = (theme) => ({
    chipInput: {
        '& .MuiInputBase-root': {
            paddingTop: '0px !important'
        },
        '& .MuiInputBase-input': {
            paddingBottom: '4px !important'
        },
        '& .MuiChip-root': {
            margin: 2
        }
    }
});

export default styles;

/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Popover } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';
import { ChromePicker } from "react-color";

import { AutoCompleteChip } from "../AutocompleteChip"

// Imports Styles
import styles from "./styles";
/**
 * TextField Component
 * @param {*} props
 */
function TextFieldComponent(props) {

  const { colorValue, suggestions, handleTagColor, name, adornmentStyle, classes } = props;

  // const cover = {
  //   position: "fixed",
  //   top: "0px",
  //   right: "0px",
  //   bottom: "0px",
  //   left: "0px",
  // };

  //set state
  const [isOpen, setIsOpen] = useState(false)
  const [color, setColor] = useState(colorValue ? colorValue : "#000000")
  const [tagColor, setTagColor] = useState(colorValue ? colorValue : "#000000")

  const [anchorEl, setAnchorEl] = React.useState(null);


  const handleColorPicker = (event) => {
    setIsOpen(!isOpen);
    setAnchorEl(event.currentTarget);
  }

  useEffect(() => {
    setColor(colorValue)
    setTagColor(colorValue)
  }, [colorValue, props.value])

  // const handleClose = () => {
  //   setIsOpen(false)
  // }

  const handleChange = (color, evt) => {
    setColor(color.rgb);
    setTagColor(color.hex);
    handleTagColor(color.hex);
  }

  const handleAutoComplete = (newValue, name) => {

    props.onTagChange(newValue["label"], newValue["color"])
  }

  const onInputChange = (s, value, reason) => {
    props.onChange({ target: { value: value, name: name } })
  }


  //

  const handleColorClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Grid className="colorPickerDiv" style={{ display: "flex", position: "relative" }}>
      <Grid style={{ width: "100%" }}>
        <AutoCompleteChip
          suggestions={suggestions}
          onInputChange={onInputChange}
          handleAutoComplete={handleAutoComplete}
          value={{ label: props.value, value: props.value }}
        />
      </Grid>
      <Grid style={{ position: "absolute", right: "40px", top: "20px" }}>
        {/* <Core
          id={id}
          name={name}
          className={classes.underline}
          autoComplete={autoComplete}
          margin={margin}
          // variant={variant}
          fullWidth={fullWidth}
          InputProps={{
            endAdornment:  */}
        <InputAdornment position="end" style={adornmentStyle}>
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleColorPicker}
            edge="end"
          >
            <FiberManualRecordOutlinedIcon style={{ fill: tagColor }} />
          </IconButton>
        </InputAdornment>
        {/* }}
        // {...rest}
        /> */}
        {/* {isOpen ? (
          <div style={popover}>
            <div style={cover} onClick={handleClose} />
            <ChromePicker
              color={color}
              onChangeComplete={handleChange}
              disableAlpha={true}
            />
          </div>
        ) : null} */}
      </Grid>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleColorClose}
        className={classes.chromePicker}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <ChromePicker
          color={color}
          onChangeComplete={handleChange}
          disableAlpha={true}
        />
      </Popover>
    </Grid>
  );
}

// default props
TextFieldComponent.defaultProps = {
  id: "",
  name: "",
  type: "text",
  autoComplete: "off",
  min: "0",
  margin: "dense",
  variant: "outlined",
  fullWidth: true,
  withImage: false,
  passShow: false,
  colorValue: "#000000",
  adornmentStyle: {}
};

// prop types
TextFieldComponent.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  min: PropTypes.string,
  autoComplete: PropTypes.string,
  margin: PropTypes.string,
  variant: PropTypes.string,
  fullWidth: PropTypes.bool,
  withImage: PropTypes.bool,
  passShow: PropTypes.bool,
  colorValue: PropTypes.string,
  className: PropTypes.string
};

// export component
export const ColorPickerTextField1 = withStyles(styles)(TextFieldComponent);

import React, { useRef } from "react";
import PropTypes from 'prop-types';
import { Dialog, Grid, IconButton, Typography, withStyles } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import { EditChatInput } from './EditChatInput'
import { DoubleTickIcon2,SingleTickIcon} from '../../assets/svg';



// Imports Styles
import styles from "./styles";
import { getOnlyTime } from "../../utils/appHelper";

const ChatEdit = (props) => {
    const { id, classes, handleClose, messageObj, open, chatViewStyle, type, handleChatEditMessage, chatUserList, ...chatprops } = props;
    const media = messageObj?.message_file ? messageObj?.message_file : (messageObj?.uploadPreviews?.files_data || null)
    const thumbUrl = media ? JSON.parse(media) : null
    const editor = useRef(null);
    const renderTickStatus = () => {
        return (
            messageObj?.read_status || messageObj?.delivered_status) ?
            // <DoubleTickIcon2 color={read_status ? 'yellow' : '#FFF'} /> :
            <DoubleTickIcon2 color={messageObj?.read_status ? '#FFF':'yellow'} /> :
            ((messageObj?.delivered_status || messageObj?.delivered) ?
                <SingleTickIcon /> :
                null
            )
    }
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Grid item className={classes.dialogContainer}>
                <Grid item className={classes.header}>
                    <Typography variant='h6' className='pl-1 fw-700'>
                        Edit Message
                    </Typography>
                    <IconButton className='closeIcon' onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
                <Grid item className={classes.bodyContainer}>
                    <Grid className={`${classes.chatSingle} chatSingle left bubblewView disable-user-select`} align={'left'}>
                        <Grid className={`${classes.chatTile} chatTile chatTileStarDiv ${`chatTileSend istxtsend`}`}>

                            {thumbUrl?.type?.includes("video") && (
                                <video controls width={335} height={200}>
                                    <source src={thumbUrl?.url} type={thumbUrl?.type} />
                                </video>
                            )}
                            {thumbUrl?.type?.includes("image") && (
                                <img src={thumbUrl?.url} width={335} height={200}/>
                            )}
                            {messageObj?.message_text}
                            <Grid className={classes.messagestatus}>
                            {messageObj?.is_edited ?
                                <span className="disable-user-select mr5 fw-500">
                                    Edited
                                </span> :
                                null
                            } 
                            { renderTickStatus()}
                          
                                <span className='bubbleViewTime disable-user-select'>
                                    {getOnlyTime(messageObj.created_on)}
                                </span> 
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" direction="row" className={`${classes.ChatTypingArea} ChatTypingArea nowrap relative`} style={{ padding: 0 }}>
                        <Grid item xs={12} className="dflex">
                            <EditChatInput
                                ref={editor}
                                id={id}
                                type={chatprops?.type}
                                editChatData={messageObj}
                                userId={messageObj?.user_id}
                                className={`${classes.chatTypeField} ${"borderNone"}`}
                                handleChatEditMessage={handleChatEditMessage}
                                handleAttachment={chatprops?.handleAttachment}
                                handleChatGif={chatprops?.handleChatGif}
                                userSuggestions={chatUserList.length > 0 ? chatUserList : []}
                                handleTyping={chatprops?.setTyping}
                                handleVoiceNoteMessage={chatprops?.handleVoiceNoteMessage}
                                infinityModeHandler={chatprops?.infinityModeHandler}
                                inputfocus={chatprops?.inputFocus}
                                typingUsers={chatprops?.typingUsers}
                                unsendMessages={chatprops?.unsendMessages}
                                updateDraftMessages={chatprops?.updateDraftMessages}
                                placeHolder={chatprops?.placeHolder}
                                actionIcons={['emoji', 'edit']}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    );
};

// Default props
ChatEdit.defaultProps = {
    classes: "",
    handleClose: () => { },
    messageObj: { id: "" },
    open: false
};

// Prop types
ChatEdit.propTypes = {
    classes: PropTypes.object,
    onClose: PropTypes.func,
    messageObj: PropTypes.any,
    open: PropTypes.bool
};

/** Export Component */
export const ChatEditComponent = withStyles(styles)(ChatEdit);

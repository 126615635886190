import { projectConstant, docsConstant } from "../constants";

const initialState = {
    loading: false,

    newProject: {},
    tags: [],

    projectFilter: { completed: 0, inProgress: 0, deleted: 0, tags: [], isLoading: false },
    projectList: {
        projects: [],
        users: [],
        page: {
            sort: "",
            skip: 0,
            limit: 20,
            search: "",
            tag: [],
            status: ""
        }
    },
    projectDetail: {
        project: null,
        project_users: [],
        user_detail: null,
        chat_room: {
            chatRoom: "",
            userId: "",
            name: '',
            userName: ""
        },
        board_detail: null
    },

    //clone page
    projectClone: {
        title: "",
        description: "",
        short_description: "",
        team: [],
        task: "all",
        notes: "all",
        boards: "all"
    },

    brief: {},
    briefEdit: {},


    notes: [],
    note: {
        content_status: null
    },
    newNote: {},


    teamMembers: [],
    activeTeamMembers: [],
    invitation: false,
    projectTeam: [],
    projectShared: [],
    inviteUser: {
        existing: [],
        new: []
    },

    chatData: {
        projectId: '',
        chatMessages: [],
        newMessage: {}
    },

    newTask: {},
    tasks: {
        data: [],
        page: {}
    },
    currentTask: {},
    currentTaskNotes: [],
    taskTags: [],
    filters: { completed: 0, inProgress: 0, dueDate: [], assigned: [], total: 0, tags: [] },
    viewMany: false
}

export default function project(state = initialState, action) {
    switch (action.type) {

        case projectConstant.CREATE_PROJECT_R:
            return {
                ...state,
            }
        case projectConstant.CREATE_PROJECT_S:
            return {
                ...state,
                newProject: initialState.newProject
            }
        case projectConstant.CREATE_PROJECT_F:
            return {
                ...state,
            }

        //GET_PROJECTS
        case projectConstant.GET_PROJECTS_R:
            return {
                ...state,
            }
        case projectConstant.GET_PROJECTS_S:
            return {
                ...state,
                projectList: action.data.data,
            }
        case projectConstant.GET_PROJECTS_F:
            return {
                ...state,
                projectList: initialState.projectList
            }

        //GET_PROJECTS tags
        case projectConstant.GET_TAGS_R:
            return {
                ...state,
            }
        case projectConstant.GET_TAGS_S:
            return {
                ...state,
                tags: action.data.data,
            }
        case projectConstant.GET_TAGS_F:
            return {
                ...state,
            }

        //update project order
        case projectConstant.UPDATE_DND_R:
            return {
                ...state,
                projectList: { ...state.projectList, projects: action.params }
            }
        case projectConstant.UPDATE_DND_S:
            return {
                ...state,
                projectList: { ...state.projectList, page: { ...state.projectList.page, sort: "" } }
            }
        case projectConstant.UPDATE_DND_F:
            return {
                ...state,
            }

        //GET_PROJECT_DETAIL
        case projectConstant.GET_PROJECT_DETAIL_R:
            return {
                ...state,
            }
        case projectConstant.GET_PROJECT_DETAIL_S:
            return {
                ...state,
                projectDetail: { ...action.data.data },
            }
        case projectConstant.GET_PROJECT_DETAIL_F:
            return {
                ...state,
                projectDetail: initialState.projectDetail
            }


        //update create project data
        case projectConstant.UPDATE_CREATE_PROJECT:
            return {
                ...state,
                newProject: action.data
            }


        //GET_PROJECT_DETAIL
        case projectConstant.GET_PROJECT_FILTER_R:
            return {
                ...state,
                isLoading: true,
                projectFilter: { ...initialState.projectFilter, isLoading: true }
            }
        case projectConstant.GET_PROJECT_FILTER_S:
            return {
                ...state,
                projectFilter: { ...action.data.data, isLoading: false, },
            }
        case projectConstant.GET_PROJECT_FILTER_F:
            return {
                ...state,
                isLoading: false,
                projectFilter: { ...initialState.projectFilter, isLoading: false }
            }

        case projectConstant.UPATE_FILTER_DATA:
            return {
                ...state,
                projectList: { ...state.projectList, page: action.data }
            }
        case projectConstant.UPATE_CARD_VIEW:
            return {
                ...state,
                viewMany: action.data.val
            }
        /********************************BRIEF DETAILS**************************************************************** */


        //GET_BRIEF
        case projectConstant.GET_BRIEF_R:
            return {
                ...state,
                brief: {
                    content_status: null
                }
            }
        case projectConstant.GET_BRIEF_S:
            let { job_description, job_title, short_description } = action.data.data;
            return {
                ...state,
                brief: {
                    ...action.data.data,
                    content_status: 'loaded'
                },
                briefEdit: action.data.data,
                projectDetail: {
                    ...state.projectDetail,
                    project: action.data.data
                },
                projectClone: {
                    ...state.projectClone,
                    title: `${job_title ? job_title : ""} Copy`,
                    description: job_description ? job_description : "",
                    short_description: short_description ? short_description : "",
                }
            }
        case projectConstant.GET_BRIEF_F:
            return {
                ...state,
                brief: initialState.brief,
                briefEdit: initialState.briefEdit,
                projectDetail: initialState.projectDetail,
            }

        //GET_BRIEF
        case projectConstant.COMPLETE_PROJECT_R:
            return {
                ...state,
            }
        case projectConstant.COMPLETE_PROJECT_S:
            return {
                ...state,
                brief: { ...state.brief, job_ongoing: !(state.brief.job_ongoing), status: !(state.brief.status) },
                briefEdit: { ...state.brief, job_ongoing: !(state.brief.job_ongoing), status: !(state.brief.status) },
            }
        case projectConstant.COMPLETE_PROJECT_F:
            return {
                ...state,
                brief: initialState.brief,
                briefEdit: initialState.briefEdit,
            }

        //UPDATE_BRIEF
        case projectConstant.UPDATE_BRIEF_R:
            return {
                ...state,
            }
        case projectConstant.UPDATE_BRIEF_S:
            return {
                ...state,
                brief: { ...state.briefEdit }
            }
        case projectConstant.UPDATE_BRIEF_F:
            return {
                ...state,
            }

        //UPDATE_BRIEF REDUCER
        case projectConstant.UPDATE_BRIEF_REDUCER:
            return {
                ...state,
                briefEdit: action.data,
            }

        //UPDATE Team Members in Project Clone Data   
        case projectConstant.UPDATE_PROJECT_CLONE_ACTIVE_TEAM_S:
            return {
                ...state,
                projectClone: {
                    ...state.projectClone,
                    team: action.data
                },
            }

        //UPDATE Project Clone Data
        case projectConstant.UPDATE_PROJECT_CLONE_REDUCER:
            return {
                ...state,
                projectClone: action.data,
            }


        /********************************NOTES DETAILS**************************************************************** */


        case projectConstant.UPDATE_NOTE_REDUCER_TITLE:
            return {
                ...state,
                newNote: { ...state.newNote, notes_title: action.data },
            }

        //UPDATE_NOTE
        case projectConstant.UPDATE_NOTE_R:
            return {
                ...state,
            }
        case projectConstant.UPDATE_NOTE_S:
            return {
                ...state,
                newNote: initialState.newNote
            }
        case projectConstant.UPDATE_NOTE_F:
            return {
                ...state,
            }

        //UPDATE_NOTE
        case projectConstant.ADD_NOTE_R:
            return {
                ...state,
            }
        case projectConstant.ADD_NOTE_S:
            return {
                ...state,
                note: {}
            }
        case projectConstant.ADD_NOTE_F:
            return {
                ...state,
            }

        //UPDATE_NOTE REDUCER
        case projectConstant.UPDATE_NOTE_REDUCER:
            return {
                ...state,
                newNote: action.data,
            }

        //UPDATE TASk REDUCER
        case projectConstant.UPDATE_P_CREATE_TASK:
            return {
                ...state,
                newTask: action.data,
            }

        //UPDATE_NOTE REDUCER
        case projectConstant.CLEAR_SELECTED_NOTE:
            return {
                ...state,
                note: initialState.note,
            }

        //GET_ALL NOTES
        case projectConstant.GET_ALL_NOTES_R:
            return {
                ...state,
                note: {
                    content_status: null
                }
            }
        case projectConstant.GET_ALL_NOTES_S:
            return {
                ...state,
                notes: action.data.data,
            }
        case projectConstant.GET_ALL_NOTES_F:
            return {
                ...state,
                notes: initialState.notes,
            }

        //GET_ONE NOTE
        case projectConstant.GET_ONE_NOTES_R:
            return {
                ...state,
                note: {
                    content_status: null
                }
            }
        case projectConstant.GET_ONE_NOTES_S:
            return {
                ...state,
                note: {
                    content_status: 'loaded',
                    ...action.data.data
                },
            }
        case projectConstant.GET_ONE_NOTES_F:
            return {
                ...state,
                note: initialState.note,
            }

        /************************************TEAMS DETAILS*********************************************************** */


        //GET PROJECT TEAM USERS
        case projectConstant.GET_PROJECT_TEAM_R:
            return {
                ...state,
            }
        case projectConstant.GET_PROJECT_TEAM_S:
            return {
                ...state,
                projectTeam: action.data.data,
            }
        case projectConstant.GET_PROJECT_TEAM_F:
            return {
                ...state,
                projectTeam: initialState.projectTeam,
            }

        //UPDATE INVITE USR
        case projectConstant.UPDATE_P_TEAM_USERS:
            return {
                ...state,
                inviteUser: action.data,
            }

        //Update team members
        case projectConstant.GET_TEAM_R:
            return {
                ...state,
            }
        case projectConstant.GET_TEAM_S:
            return {
                ...state,
                teamMembers: action.data.data,
            }
        case projectConstant.GET_TEAM_F:
            return {
                ...state,
                teamMembers: initialState.teamMembers
            }

        //update active team members
        case projectConstant.GET_ACTIVE_TEAM_R:
            return {
                ...state,
            }
        case projectConstant.GET_ACTIVE_TEAM_S:
            return {
                ...state,
                activeTeamMembers: action.data.data,
            }
        case projectConstant.GET_ACTIVE_TEAM_F:
            return {
                ...state,
                activeTeamMembers: initialState.teamMembers
            }

        //USER INVITATION ACCEPT
        case projectConstant.NEW_USER_ACCEPT_R:
            return {
                ...state,
            }
        case projectConstant.NEW_USER_ACCEPT_S:
            return {
                ...state,
                invitation: action.data.data,
            }
        case projectConstant.NEW_USER_ACCEPT_F:
            return {
                ...state,
                invitation: initialState.invitation
            }

        //USER INVITATION ACCEPT
        case projectConstant.G_NEW_USER_ACCEPT_R:
            return {
                ...state,
            }
        case projectConstant.G_NEW_USER_ACCEPT_S:
            return {
                ...state,
            }
        case projectConstant.G_NEW_USER_ACCEPT_F:
            return {
                ...state,
            }

        case projectConstant.USER_EXISTING_INVITE_R:
        case projectConstant.USER_EXISTING_INVITE_S:
        case projectConstant.USER_EXISTING_INVITE_F:
            return state

        case projectConstant.USER_NEW_INVITE_R:
        case projectConstant.USER_NEW_INVITE_S:
        case projectConstant.USER_NEW_INVITE_F:
            return state

        case projectConstant.REMOVE_TEAM_USER_R:
        case projectConstant.REMOVE_TEAM_USER_S:
        case projectConstant.REMOVE_TEAM_USER_F:
            return state

        case projectConstant.REMOVE_USER_FROM_AGENCY_R:
        case projectConstant.REMOVE_USER_FROM_AGENCY_S:
        case projectConstant.REMOVE_USER_FROM_AGENCY_F:
            return state

        /***************************************TASK DETAILS****************************************************** */


        //create new task
        case projectConstant.CREATE_NEW_TASK_R:
            return state
        case projectConstant.CREATE_NEW_TASK_S:
            return {
                ...state,
                newTask: {}
            }
        case projectConstant.CREATE_NEW_TASK_F:
            return state

        //get all tasks
        case projectConstant.GET_ALL_TASK_R:
            return {
                ...state,
            }
        case projectConstant.GET_ALL_TASK_S:
            return {
                ...state,
                tasks: action.data.data
            }
        case projectConstant.GET_ALL_TASK_F:
            return {
                ...state,
                tasks: initialState.tasks
            }

        //get current tasks
        case projectConstant.GET_CURRENT_TASK_R:
            return {
                ...state,
            }
        case projectConstant.GET_CURRENT_TASK_S:
            return {
                ...state,
                currentTask: action.data.data
            }
        case projectConstant.GET_CURRENT_TASK_F:
            return {
                ...state,
                currentTask: initialState.currentTask
            }

        case projectConstant.DEL_CURRENT_TASK_R:
        case projectConstant.DEL_CURRENT_TASK_S:
        case projectConstant.DEL_CURRENT_TASK_F:
            return state

        case projectConstant.UPDATE_CURRENT_TASK_R:
        case projectConstant.UPDATE_CURRENT_TASK_S:
        case projectConstant.UPDATE_CURRENT_TASK_F:
            return state


        case projectConstant.UPDATE_CURRENT_TASK:
            return {
                ...state,
                currentTask: action.data,
            }

        case projectConstant.GET_TASKS_FILTER_R:
            return {
                ...state,
            }
        case projectConstant.GET_TASKS_FILTER_S:
            return {
                ...state,
                filters: action.data.data
            }
        case projectConstant.GET_TASKS_FILTER_F:
            return {
                ...state,
                filters: initialState.filters
            }

        case projectConstant.COMPLETE_TASK_R:
        case projectConstant.COMPLETE_TASK_S:
        case projectConstant.COMPLETE_TASK_F:
            return state

        case projectConstant.GET_P_TASK_TAGS_R:
            return {
                ...state,
                taskTags: []
            }
        case projectConstant.GET_P_TASK_TAGS_S:
            return {
                ...state,
                taskTags: action.data.data
            }
        case projectConstant.GET_P_TASK_TAGS_F:
            return {
                ...state,
                taskTags: []
            }


        case projectConstant.CREATE_TASK_NOTE_R:
            return {
                ...state
            }
        case projectConstant.CREATE_TASK_NOTE_S:
            return {
                ...state,
                currentTaskNotes: action.data.data
            }
        case projectConstant.CREATE_TASK_NOTE_F:
            return {
                ...state
            }
        case projectConstant.GET_TASK_NOTE_R:
            return {
                ...state,
                currentTaskNotes: []
            }
        case projectConstant.GET_TASK_NOTE_S:
            return {
                ...state,
                currentTaskNotes: action.data.data
            }
        case projectConstant.GET_TASK_NOTE_F:
            return {
                ...state,
                currentTaskNotes: []
            }

        case projectConstant.DELETE_TASK_NOTE_R:
            return {
                ...state
            }
        case projectConstant.DELETE_TASK_NOTE_S:
            return {
                ...state,
                // currentTaskNotes: action.data.data
            }
        case projectConstant.DELETE_TASK_NOTE_F:
            return {
                ...state
            }

        default:
            return state
    }
}

export const CameraAttachments = () => (
    <svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.39015 4.98963C4.39015 4.6603 4.65712 4.39332 4.98646 4.39332C5.31579 4.39332 5.58276 4.6603 5.58276 4.98963C5.58276 5.31896 5.31579 5.58594 4.98646 5.58594C4.65712 5.58594 4.39015 5.31896 4.39015 4.98963Z" stroke="#7F83A0" stroke-width="5" />
        <path d="M8.5397 3.79307C8.5397 4.07314 8.31265 4.30019 8.03257 4.30019C7.75249 4.30019 7.52544 4.07314 7.52544 3.79307C7.52544 3.51299 7.75249 3.28594 8.03257 3.28594C8.31265 3.28594 8.5397 3.51299 8.5397 3.79307Z" stroke="#7F83A0" stroke-width="0.4" />
        <path d="M6.6 5C6.6 5.88366 5.88366 6.6 5 6.6C4.11634 6.6 3.4 5.88366 3.4 5C3.4 4.11634 4.11634 3.4 5 3.4C5.88366 3.4 6.6 4.11634 6.6 5Z" stroke="#7F83A0" stroke-width="0.8" />
        <path d="M3.23837 1.60063L3.23838 1.6006L3.77375 0.663565L3.77375 0.663563C3.86687 0.50056 4.04018 0.4 4.22789 0.4H5.92556C6.11327 0.4 6.28657 0.50056 6.3797 0.663563L6.72701 0.465135L6.3797 0.663566L6.91506 1.60061L6.91531 1.60105C7.06185 1.85676 7.33401 2.01539 7.62978 2.01539H9.23036C9.51934 2.01539 9.75344 2.24952 9.75344 2.53825V8.07652C9.75344 8.36537 9.51922 8.5996 9.23036 8.5996H0.923079C0.634226 8.5996 0.4 8.36537 0.4 8.07652V2.53847C0.4 2.24961 0.634227 2.01539 0.923079 2.01539H2.52366C2.81912 2.01539 3.09187 1.857 3.23837 1.60063Z" stroke="#7F83A0" stroke-width="0.8" />
    </svg>
);

export const GifAttachments = () => (
    <svg width="22" height="9" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="22" height="12" rx="2" fill="#64748B" />
        <path d="M8.36364 5.0625C8.32386 4.92424 8.26799 4.80208 8.19602 4.69602C8.12405 4.58807 8.03599 4.49716 7.93182 4.4233C7.82955 4.34754 7.71212 4.28977 7.57955 4.25C7.44886 4.21023 7.30398 4.19034 7.14489 4.19034C6.84754 4.19034 6.58617 4.2642 6.3608 4.41193C6.13731 4.55966 5.96307 4.77462 5.83807 5.05682C5.71307 5.33712 5.65057 5.67992 5.65057 6.08523C5.65057 6.49053 5.71212 6.83523 5.83523 7.11932C5.95833 7.40341 6.13258 7.62027 6.35795 7.76989C6.58333 7.91761 6.84943 7.99148 7.15625 7.99148C7.43466 7.99148 7.67235 7.94223 7.86932 7.84375C8.06818 7.74337 8.2197 7.60227 8.32386 7.42045C8.42992 7.23864 8.48295 7.02367 8.48295 6.77557L8.73295 6.8125H7.23295V5.88636H9.66761V6.61932C9.66761 7.13068 9.55966 7.57008 9.34375 7.9375C9.12784 8.30303 8.83049 8.58523 8.4517 8.78409C8.07292 8.98106 7.6392 9.07955 7.15057 9.07955C6.60511 9.07955 6.12595 8.95928 5.71307 8.71875C5.30019 8.47633 4.97822 8.13258 4.74716 7.6875C4.51799 7.24053 4.40341 6.71023 4.40341 6.09659C4.40341 5.625 4.47159 5.20455 4.60795 4.83523C4.74621 4.46402 4.93939 4.14962 5.1875 3.89205C5.43561 3.63447 5.72443 3.43845 6.05398 3.30398C6.38352 3.16951 6.74053 3.10227 7.125 3.10227C7.45455 3.10227 7.76136 3.15057 8.04545 3.24716C8.32955 3.34186 8.58144 3.47633 8.80114 3.65057C9.02273 3.82481 9.2036 4.0322 9.34375 4.27273C9.4839 4.51136 9.57386 4.77462 9.61364 5.0625H8.36364ZM11.8217 3.18182V9H10.5916V3.18182H11.8217ZM12.8338 9V3.18182H16.6861V4.19602H14.0639V5.58239H16.4304V6.59659H14.0639V9H12.8338Z" fill="white" />
    </svg>
)

export const VideoAttachments = () => (
    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.309 9.35896H3.62234C1.779 9.35896 1.1665 8.39646 1.1665 7.42937V3.57021C1.1665 2.12187 1.779 1.64062 3.62234 1.64062H7.309C9.15234 1.64062 9.76484 2.12187 9.76484 3.57021V7.42937C9.76484 8.87771 9.1465 9.35896 7.309 9.35896Z" stroke="#7F83A0" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M11.3868 7.83805L9.76514 6.9443V4.05222L11.3868 3.15847C12.1801 2.72305 12.8335 2.98888 12.8335 3.7543V7.2468C12.8335 8.01221 12.1801 8.27805 11.3868 7.83805Z" stroke="#7F83A0" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)

export const AudioAttachments = () => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 11.0827C8.93083 11.0827 10.5 9.51352 10.5 7.58268V4.66602C10.5 2.73518 8.93083 1.16602 7 1.16602C5.06917 1.16602 3.5 2.73518 3.5 4.66602V7.58268C3.5 9.51352 5.06917 11.0827 7 11.0827Z" stroke="#7F83A0" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M1.74951 6.41797V7.58464C1.74951 10.4838 4.10035 12.8346 6.99951 12.8346C9.89868 12.8346 12.2495 10.4838 12.2495 7.58464V6.41797" stroke="#7F83A0" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M5.31396 4.36445C6.3523 3.98529 7.48397 3.98529 8.5223 4.36445" stroke="#7F83A0" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M5.85107 6.11508C6.55107 5.92258 7.29191 5.92258 7.99191 6.11508" stroke="#7F83A0" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)

export const ResetIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.8901 5.08039C14.0201 4.82039 13.0601 4.65039 12.0001 4.65039C7.21008 4.65039 3.33008 8.53039 3.33008 13.3204C3.33008 18.1204 7.21008 22.0004 12.0001 22.0004C16.7901 22.0004 20.6701 18.1204 20.6701 13.3304C20.6701 11.5504 20.1301 9.89039 19.2101 8.51039" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M16.1302 5.32L13.2402 2" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M16.1298 5.32031L12.7598 7.78031" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)

export const Trash = () => (
    <svg width="24" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21 5.98047C17.67 5.65047 14.32 5.48047 10.98 5.48047C9 5.48047 7.02 5.58047 5.04 5.78047L3 5.98047" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M18.8499 9.14062L18.1999 19.2106C18.0899 20.7806 17.9999 22.0006 15.2099 22.0006H8.7899C5.9999 22.0006 5.9099 20.7806 5.7999 19.2106L5.1499 9.14062" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M10.3301 16.5H13.6601" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M9.5 12.5H14.5" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

)

export const AddNewUser = () => (
    <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.6465 7.77771H8.29107C6.99117 7.77771 5.93756 8.86886 5.93756 10.215V12.9696C11.7454 17.538 17 12.9696 17 12.9696V10.215C17 8.86864 15.9462 7.77771 14.6465 7.77771ZM11.4688 6.19512C12.9718 6.19512 14.1904 4.69915 14.1904 2.85361C14.1904 1.00851 12.9718 0 11.4688 0C9.96572 0 8.74713 1.00851 8.74713 2.85361C8.74713 4.69915 9.96572 6.19512 11.4688 6.19512ZM6.75813 4.51752H4.21938V1.88885H2.53854V4.51752H0V6.25788H2.53875V8.88654H4.21959V6.25788H6.75834L6.75813 4.51752Z" fill="white"/>
    </svg>
)
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { Grid, withStyles, List, ListItem, ListItemIcon, ListItemText, IconButton, Avatar, Typography, Slide } from '@material-ui/core';
import FooterStyles from './FooterStyles';

import history from "../../config/history";
import Logo from '../../assets/images/Icon_LightBG.png';
import { useSelector } from 'react-redux';
import { BookingIcon, BrainIcon, CalendarIconNew, CloseIcon2, FunnelIcon, MenuICon2, MobileBoards, MobileChat, MobileClients, MobileDashboard, MobileFlows, MobileInvoices, MobileTasks, PagebuilderIcon } from '../../assets/svg';
import { getDefaultChatRoomPreference } from '../../utils';
import { _envParams } from "../../config/env_params";

function MenuPage(props) {

    const { classes, isPublic } = props;

    const { loginUser: { user_role } } = useSelector(s => (s.auth));
    const { profile } = useSelector(s => (s.profile));

    const [page, setPage] = useState("");
    const [menu, setMenu] = useState(false);

    const handleMenu = () => {
        setMenu(!menu);
        setPage("");
    }

    const locate = history.location.pathname;

    useEffect(() => {
        if (locate && locate.indexOf("dashboard") > -1) {
            setPage("Dashboard")
        } else if (locate && locate.indexOf("chat") > -1) {
            setPage("Chat")
        } else if (locate && locate.indexOf("projects") > -1) {
            setPage("Projects")
        } else if (locate && locate.indexOf("invoice") === -1 && locate.indexOf("clients") > -1) {
            setPage("Clients")
        } else if (locate && locate.indexOf("tasks") > -1) {
            setPage("Tasks")
        } else if (locate && locate.indexOf("invoice") > -1) {
            setPage("Invoices")
        } else if (locate && locate.indexOf("boards") > -1) {
            setPage("Boards")
        } else if (locate && locate.indexOf("brain") > -1) {
            setPage("Brain")
        } else if (locate && locate.indexOf("calendar") > -1) {
            setPage("Calendar")
        } else if (locate && locate.indexOf("booking") > -1) {
            setPage("Booking")
        } else if (locate && locate.indexOf("page-builder") > -1) {
            setPage("Pages")
        } else if (locate && locate.indexOf("funnel") > -1) {
            setPage("Funnel")
        } else if (locate && locate.indexOf("menu") > -1) {
            setPage("Menu")
        } else {
            setPage("")
        }
    }, [locate, menu])

    const handleHomePage = () => {
        const page = isPublic ? "/" : user_role && user_role["dash"] ? "/dashboard" : 'clients';
        history.push(page);
    }

    const getDefaultChatRoom = () => {
        const { type } = getDefaultChatRoomPreference()
        return type
    }

    const notForMobile = _envParams.REACT_APP_BUILD_FOR === "MOBILE" ? false : true;

    const getMenuList = (user_role) => {
        const { type, cli, pjt, invoice, boards } = user_role || {};

        if (type === "admin" || type === "emp_admin") {
            return (
                <>
                    <ListItem button className={`${page === "Dashboard" ? "active_menu" : ""} mobileMenu leftMenu`} onClick={() => { history.push("/dashboard"); handleMenu(); }}> <ListItemIcon> <MobileDashboard /> </ListItemIcon> <ListItemText primary="Dashboard" /> </ListItem>
                    <ListItem button className={`${page === "Chat" ? "active_menu" : ""} mobileMenu leftMenu`} onClick={() => { history.push(`/chat/${getDefaultChatRoom()}`); handleMenu(); }}> <ListItemIcon> <MobileChat /> </ListItemIcon> <ListItemText primary="Chat" /> </ListItem>
                    <ListItem button className={`${page === "Projects" ? "active_menu" : ""} mobileMenu leftMenu`} onClick={() => { history.push("/projects"); handleMenu(); }}> <ListItemIcon> <MobileFlows /> </ListItemIcon> <ListItemText primary="Flows" /> </ListItem>
                    <ListItem button className={`${page === "Clients" ? "active_menu" : ""} mobileMenu leftMenu`} onClick={() => { history.push("/clients"); handleMenu(); }}> <ListItemIcon> <MobileClients /> </ListItemIcon> <ListItemText primary="Clients" /> </ListItem>
                    <ListItem button className={`${page === "Tasks" ? "active_menu" : ""} mobileMenu leftMenu`} onClick={() => { history.push("/tasks"); handleMenu(); }}> <ListItemIcon> <MobileTasks /> </ListItemIcon> <ListItemText primary="Tasks" /> </ListItem>
                    <ListItem button className={`${page === "Boards" ? "active_menu" : ""} mobileMenu leftMenu`} onClick={() => { history.push("/boards"); handleMenu(); }}> <ListItemIcon> <MobileBoards /> </ListItemIcon> <ListItemText primary="Boards" /> </ListItem>
                    <ListItem button className={`${page === "Brain" ? "active_menu" : ""} mobileMenu leftMenu`} onClick={() => { history.push("/brain"); handleMenu(); }}> <ListItemIcon> <BrainIcon /> </ListItemIcon> <ListItemText primary="Brain" /> </ListItem>
                    {notForMobile && <ListItem button className={`${page === "Pages" ? "active_menu" : ""} mobileMenu leftMenu`} onClick={() => { history.push("/page-builder"); handleMenu(); }}> <ListItemIcon>  <PagebuilderIcon height={23} width={23} /> </ListItemIcon> <ListItemText primary="Pages" /> </ListItem>}
                    <ListItem button className={`${page === "Invoices" ? "active_menu" : ""} mobileMenu leftMenu`} onClick={() => { history.push("/invoices"); handleMenu(); }}> <ListItemIcon> <MobileInvoices /> </ListItemIcon> <ListItemText primary="Invoices" /> </ListItem>
                    <ListItem button className={`${page === "Calendar" ? "active_menu" : ""} mobileMenu leftMenu`} onClick={() => { history.push("/calendar"); handleMenu(); }}> <ListItemIcon> <CalendarIconNew height={24} width={23} /> </ListItemIcon> <ListItemText primary="Calendar" /> </ListItem>
                    <ListItem button className={`${page === "Booking" ? "active_menu" : ""} mobileMenu leftMenu`} onClick={() => { history.push("/booking"); handleMenu(); }}> <ListItemIcon> <BookingIcon height={23} width={23} /> </ListItemIcon> <ListItemText primary="Booking" /> </ListItem>
                    <ListItem button className={`${page === "Funnel" ? "active_menu" : ""} mobileMenu leftMenu`} onClick={() => { history.push("/funnel"); handleMenu(); }}> <ListItemIcon> <FunnelIcon height={21} width={23} /> </ListItemIcon> <ListItemText primary="Funnel" /> </ListItem>
                </>
            )
        } else if (type === "emp") {
            return (
                <>
                    {<ListItem button className={`${page === "Dashboard" ? "active_menu" : ""} mobileMenu leftMenu`} onClick={() => { history.push("/dashboard"); handleMenu(); }}> <ListItemIcon> <MobileDashboard /> </ListItemIcon> <ListItemText primary="Dashboard" /> </ListItem>}
                    <ListItem button className={`${page === "Chat" ? "active_menu" : ""} mobileMenu leftMenu`} onClick={() => { history.push(`/chat/${getDefaultChatRoom()}`); handleMenu(); }}> <ListItemIcon> <MobileChat /> </ListItemIcon> <ListItemText primary="Chat" /> </ListItem>
                    {pjt && <ListItem button className={`${page === "Projects" ? "active_menu" : ""} mobileMenu leftMenu`} onClick={() => { history.push("/projects"); handleMenu(); }}> <ListItemIcon> <MobileFlows /> </ListItemIcon> <ListItemText primary="Flows" /> </ListItem>}
                    {cli && <ListItem button className={`${page === "Clients" ? "active_menu" : ""} mobileMenu leftMenu`} onClick={() => { history.push("/clients"); handleMenu(); }}> <ListItemIcon> <MobileClients /> </ListItemIcon> <ListItemText primary="Clients" /> </ListItem>}
                    <ListItem button className={`${page === "Tasks" ? "active_menu" : ""} mobileMenu leftMenu`} onClick={() => { history.push("/tasks"); handleMenu(); }}> <ListItemIcon> <MobileTasks /> </ListItemIcon> <ListItemText primary="Tasks" /> </ListItem>
                    {boards && <ListItem button className={`${page === "Boards" ? "active_menu" : ""} mobileMenu leftMenu`} onClick={() => { history.push("/boards"); handleMenu(); }}> <ListItemIcon> <MobileBoards /> </ListItemIcon> <ListItemText primary="Boards" /> </ListItem>}
                    <ListItem button className={`${page === "Brain" ? "active_menu" : ""} mobileMenu leftMenu`} onClick={() => { history.push("/brain"); handleMenu(); }}> <ListItemIcon> <BrainIcon /> </ListItemIcon> <ListItemText primary="Brain" /> </ListItem>
                    {invoice && <ListItem button className={`${page === "Invoices" ? "active_menu" : ""} mobileMenu leftMenu`} onClick={() => { history.push("/invoices"); handleMenu(); }}> <ListItemIcon> <MobileInvoices /> </ListItemIcon> <ListItemText primary="Invoices" /> </ListItem>}
                    <ListItem button className={`${page === "Calendar" ? "active_menu" : ""} mobileMenu leftMenu`} onClick={() => { history.push("/calendar"); handleMenu(); }}> <ListItemIcon> <CalendarIconNew height={24} width={23} /> </ListItemIcon> <ListItemText primary="Calendar" /> </ListItem>
                </>
            )
        } else {
            return (
                <>
                    <ListItem button className={`${page === "Dashboard" ? "active_menu" : ""} mobileMenu leftMenu`} onClick={() => { history.push("/dashboard"); handleMenu(); }}> <ListItemIcon> <MobileDashboard /> </ListItemIcon> <ListItemText primary="Dashboard" /> </ListItem>
                    <ListItem button className={`${page === "Chat" ? "active_menu" : ""} mobileMenu leftMenu`} onClick={() => { history.push(`/chat/${getDefaultChatRoom()}`); handleMenu(); }}> <ListItemIcon> <MobileChat /> </ListItemIcon> <ListItemText primary="Chat" /> </ListItem>
                    <ListItem button className={`${page === "Clients" ? "active_menu" : ""} mobileMenu leftMenu`} onClick={() => { history.push("/clients"); handleMenu(); }}> <ListItemIcon> <MobileClients /> </ListItemIcon> <ListItemText primary="Clients" /> </ListItem>
                    {/* <ListItem button className={`${page === "Boards" ? "active_menu" : ""} mobileMenu leftMenu`} onClick={() => { history.push("/boards"); handleMenu(); }}> <ListItemIcon> <MobileBoards /> </ListItemIcon> <ListItemText primary="Boards" /> </ListItem>
                    <ListItem button className={`${page === "Brain" ? "active_menu" : ""} mobileMenu leftMenu`} onClick={() => { history.push("/brain"); handleMenu(); }}> <ListItemIcon> <BrainIcon /> </ListItemIcon> <ListItemText primary="Brain" /> </ListItem> */}
                    <ListItem button className={`${page === "Calendar" ? "active_menu" : ""} mobileMenu leftMenu`} onClick={() => { history.push("/calendar"); handleMenu(); }}> <ListItemIcon> <CalendarIconNew height={24} width={23} /> </ListItemIcon> <ListItemText primary="Calendar" /> </ListItem>
                </>
            )
        }
    }

    return (
        <Grid className={classes.menuPageRoot}>
            <Grid item className={`${menu ? "menu_active" : ""} mobileMenu svgImgc`} onClick={handleMenu}><Typography variant="body2" className={classes.menuSvg}><MenuICon2 /><span className="mfText">Menu</span></Typography></Grid>
            {
                <Slide in={menu} direction='left'>
                    <Grid className={classes.menuPage}>
                        <Grid className={classes.menuHeader}>
                            <Grid className={classes.Logo}>
                                <Grid container alignItems="center" >
                                    {profile.custom_app_logo === 1 ?
                                        <Avatar className={classes.logo} alt="Logo" src={profile.agency_logo} onClick={() => handleHomePage()} /> :
                                        <img className={classes.logo} src={Logo} alt="Logo" onClick={() => handleHomePage()} />}
                                    {/* <Typography variant="h5" className="pl-1 fw-500 logoText">AgenciFlow</Typography> */}
                                </Grid>
                            </Grid>
                            <IconButton onClick={handleMenu}><CloseIcon2 /></IconButton>
                        </Grid>
                        <List className={classes.root}>
                            {
                                getMenuList(user_role)
                            }
                            {/* {user_role && user_role["dash"] && <ListItem button className={`${page === "Dashboard" ? "active_menu" : ""} mobileMenu leftMenu`} onClick={() => { history.push("/dashboard"); handleMenu(); }}> <ListItemIcon> <MobileDashboard /> </ListItemIcon> <ListItemText primary="Dashboard" /> </ListItem>}
                            {user_role && user_role["cHub"] && <ListItem button className={`${page === "Chat" ? "active_menu" : ""} mobileMenu leftMenu`} onClick={() => { history.push(`/chatv2/${getDefaultChatRoom()}`); handleMenu(); }}> <ListItemIcon> <MobileChat /> </ListItemIcon> <ListItemText primary="Chat" /> </ListItem>}
                            {user_role && user_role["pjt"] && <ListItem button className={`${page === "Projects" ? "active_menu" : ""} mobileMenu leftMenu`} onClick={() => { history.push("/projects"); handleMenu(); }}> <ListItemIcon> <MobileFlows /> </ListItemIcon> <ListItemText primary="Flows" /> </ListItem>}
                            {user_role && user_role["cli"] && <ListItem button className={`${page === "Clients" ? "active_menu" : ""} mobileMenu leftMenu`} onClick={() => { history.push("/clients"); handleMenu(); }}> <ListItemIcon> <MobileClients /> </ListItemIcon> <ListItemText primary="Clients" /> </ListItem>}
                            <ListItem button className={`${page === "Tasks" ? "active_menu" : ""} mobileMenu leftMenu`} onClick={() => { history.push("/tasks"); handleMenu(); }}> <ListItemIcon> <MobileTasks /> </ListItemIcon> <ListItemText primary="Tasks" /> </ListItem>
                            {user_role && user_role["cli"] && <ListItem button className={`${page === "Boards" ? "active_menu" : ""} mobileMenu leftMenu`} onClick={() => { history.push("/boards"); handleMenu(); }}> <ListItemIcon> <MobileBoards /> </ListItemIcon> <ListItemText primary="Boards" /> </ListItem>}
                            {user_role && user_role["cli"] && <ListItem button className={`${page === "Brain" ? "active_menu" : ""} mobileMenu leftMenu`} onClick={() => { history.push("/brain"); handleMenu(); }}> <ListItemIcon> <BrainIcon /> </ListItemIcon> <ListItemText primary="Brain" /> </ListItem>}
                            {user_role && user_role["invoice"] && <ListItem button className={`${page === "Pages" ? "active_menu" : ""} mobileMenu leftMenu`} onClick={() => { history.push("/page-builder"); handleMenu(); }}> <ListItemIcon>  <PagebuilderIcon height={23} width={23} /> </ListItemIcon> <ListItemText primary="Pages" /> </ListItem>}
                            {user_role && user_role["invoice"] && <ListItem button className={`${page === "Invoices" ? "active_menu" : ""} mobileMenu leftMenu`} onClick={() => { history.push("/invoices"); handleMenu(); }}> <ListItemIcon> <MobileInvoices /> </ListItemIcon> <ListItemText primary="Invoices" /> </ListItem>}

                            <ListItem button className={`${page === "Invoices" ? "active_menu" : ""} mobileMenu leftMenu`} onClick={() => { history.push("/calendar"); handleMenu(); }}> <ListItemIcon> <CalendarIconNew height={24} width={23} /> </ListItemIcon> <ListItemText primary="Calendar" /> </ListItem>
                            {user_role && user_role["invoice"] && <ListItem button className={`${page === "Booking" ? "active_menu" : ""} mobileMenu leftMenu`} onClick={() => { history.push("/booking"); handleMenu(); }}> <ListItemIcon> <BookingIcon height={23} width={23} /> </ListItemIcon> <ListItemText primary="Booking" /> </ListItem>}
                            {user_role && user_role["invoice"] && <ListItem button className={`${page === "Funnel" ? "active_menu" : ""} mobileMenu leftMenu`} onClick={() => { history.push("/funnel"); handleMenu(); }}> <ListItemIcon> <FunnelIcon height={21} width={23} /> </ListItemIcon> <ListItemText primary="Funnel" /> </ListItem>} */}
                        </List>
                    </Grid>
                </Slide>
            }
        </Grid>
    )
}

// default props
MenuPage.defaultProps = {
    classes: {}
};

// prop types
MenuPage.propTypes = {
    classes: PropTypes.object
};

export default withStyles(FooterStyles)(MenuPage)
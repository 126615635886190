import { combineReducers } from 'redux'
import userReducer from "./userReducer"
import authReducer from './authReducer'
import alertReducer from './alertReducer'
import uiReducer from "./uiReducer"
import projectReducer from "./projectReducer"
import profileReducer from "./profileReducer"
import userAgencyReducer from "./userAgencyReducer"
import slideShowReducer from "./slideShowReducer"
import mediaReducer from "./mediaReducer"
import chatReducer from "./chatReducer"
import clientReducer from "./clientReducer"
import clientMediaReducer from "./clientMediaReducer"
import taskReducer from "./taskReducer"
import chatHubReducer from './chatHubReducer'
import invoiceReducer from "./invoiceReducer"
import paymentReducer from "./paymentReducer"
import dashboardReducer from "./dashboardReducer"
import agencyLoginReducer from './agencyLoginReducer'
import boardsReducer from './boardsReducer'
import docsReducer from './docsReducer'
import featureFlagsReducer from './featureFlagsReducer'
import chatv2Reducer from './chatv2Reducer'
import wsmReducer from './wsmReducer'
import referralsReducer from './referralsReducer'
import fileHubReducer from './fileHubReducer'
import tasksReducer from './tasksReducer'
import workflowReducer from './workflowReducer'
import eventReducer from './eventReducer';
import bookingReducer from './bookingReducer'
import pageBuilderReducer from './pageBuilderReducer'
import funnelReducer from './funnelReducer'
import scheduleReducer from "./scheduleReducer"
import videocallReducer from './videoCallReducer'


const rootReducer = combineReducers({
    alert: alertReducer,
    users: userReducer,
    auth: authReducer,
    UI: uiReducer,
    project: projectReducer,
    profile: profileReducer,
    media: mediaReducer,
    userAgency: userAgencyReducer,
    slideShow: slideShowReducer,
    chat: chatReducer,
    clients: clientReducer,
    clientMedia: clientMediaReducer,
    tasks: taskReducer,
    chatHub: chatHubReducer,
    invoice: invoiceReducer,
    payment: paymentReducer,
    dashboard: dashboardReducer,
    agencyLogin: agencyLoginReducer,
    boards: boardsReducer,
    docs: docsReducer,
    featureFlags: featureFlagsReducer,
    chatv2: chatv2Reducer,
    wsm: wsmReducer,
    referrals: referralsReducer,
    fileHub: fileHubReducer,
    task: tasksReducer,
    workflows: workflowReducer,
    event:eventReducer,
    videocall : videocallReducer,
    booking: bookingReducer,
    pageBuilder: pageBuilderReducer,
    funnel: funnelReducer,
    schedule: scheduleReducer
})

export default rootReducer;
import { Button, Grid, IconButton, withStyles } from "@material-ui/core";
import AgoraRTC from "agora-rtc-sdk-ng";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AudioPlayIcon,
  RequestAudioMuteIcon,
  RequestAudioUnmuteIcon,
  RequestVideoCallEndIcon,
  RequestVideoMuteIcon,
  RequestVideoUnmuteIcon,
} from "../../assets/svg";
import { Typography } from "../../components";
import DialogModal from "../../components/DialogModal/DialogModal";
import history from "../../config/history";
import { chatAction, chatHubAction, userAgencyAction } from "../../redux/actions";
import { rejectCallReq } from "../../utils/socketv2";
import VideoPlayer from "./VideoPlayer";
import style from "./style";

function CallRequest(props) {
  const { classes } = props;
  const user_id = useSelector((s) => s.auth.loginUser.id);
  const dispatch = useDispatch();

  const agoraClient = AgoraRTC.createClient({
    mode: "rtc",
    codec: "vp8",
  });

  const [localTracks, setLocalTracks] = useState([]);
  const [localVideoTrack, setLocalVideoTrack] = useState(null);
  const [isVideoMuted, setIsVideoMuted] = useState(false);
  const [audioMuted, setAudioMuted] = useState(false);
  const [joined, setJoined] = useState(false);
  const [requestVideo, setRequestVideo] = useState(true);
  const [audioPlayTransform, setAudioPlayTransform] = useState(0);
  const [showNotification, setShowNotification] = useState(true);
  const [showPopup, setShowPopup] = useState(false)
  const [mutedUsers, setMutedUsers] = useState([]);

  const rejectCall = useSelector((s) => s.videocall.rejectData);
  const { planDetails } = useSelector(s => (s.userAgency))

  const { allUsers } = useSelector((s) => s.chatv2);

  const { videoCallRequest } = useSelector((state) => state.videocall);
  const { profile, appConfig: { isCustomDomain } } = useSelector((s) => s.profile);


  const localVideoPlay = async () => {
    try {
      const [audioTrack, videoTrack] =
        await AgoraRTC.createMicrophoneAndCameraTracks();


      setLocalTracks([videoTrack, audioTrack]);

      setLocalVideoTrack(videoTrack, audioTrack);

      // Analyze audio frequency and update transform
      const audioContext = new (window.AudioContext || window.AudioContext)();
      const analyser = audioContext.createAnalyser();
      const source = audioContext.createMediaStreamSource(
        audioTrack.getMediaStream()
      );
      source.connect(analyser);
      analyser.fftSize = 32;

      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      const updateAudioPlayTransform = () => {
        analyser.getByteFrequencyData(dataArray);
        const average =
          dataArray.reduce((acc, val) => acc + val, 0) / bufferLength;
        setAudioPlayTransform(average);
        requestAnimationFrame(updateAudioPlayTransform);
      };

      updateAudioPlayTransform();
    } catch (error) { }
  };

  useEffect(() => {
    localVideoPlay();
    dispatch(userAgencyAction.getPlanDetails())

    // const handleTimeout = async () => {
    //   setShowNotification(false);

    //   if (!joined) {
    //     notifyUsers({
    //       sender_id: videoCallRequest.sender_id,
    //       receiver_id: videoCallRequest.receiver_id,
    //       id: videoCallRequest.id
    //     });

    //   }

    //   setJoined(false);
    // };

    // const timeoutId = setTimeout(handleTimeout, 30000);


    const onVideoCall = async () => {
      try {
        let apiAction;
        let apiParams = {};

        switch (videoCallRequest.type) {
          case "clients":
            apiAction = chatAction.getClientChat;
            apiParams = { client_id: videoCallRequest.id };
            break;
          case "teams":
            apiAction = chatHubAction.getTeamChat;
            apiParams = { group_id: videoCallRequest.id };
            break;
          case "projects":
            apiAction = chatHubAction.getJobChat;
            apiParams = { job_id: videoCallRequest.id };
            break;
          default:
            break;
        }
        if (apiAction) {
          await dispatch(apiAction(apiParams));
        }
      } catch (error) {
      }
    };
    onVideoCall()
    return () => {
      // clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    if (planDetails.aId !== videoCallRequest.agency_id) {
      setShowPopup(true);
    }
  }, [planDetails]);

  console.log(localTracks, "localtrackt");
  console.log(mutedUsers, "muteduser");

  const handleVideoToggle = async () => {
    try {
      if (localTracks && localTracks.length > 0) {
        const videoTrack = localTracks.find(
          (track) => track.trackMediaType === "video"
        );

        if (videoTrack) {
          const newVideoMutedState = !isVideoMuted;

          if (joined) {
            videoTrack.setEnabled(!newVideoMutedState).then(() => {
              setIsVideoMuted(newVideoMutedState);
              const updatedMutedUsers = newVideoMutedState
                ? [...mutedUsers, user_id]
                : mutedUsers.filter((user) => user !== user_id);

              setMutedUsers(updatedMutedUsers);
            });
          } else {
            videoTrack.setEnabled(!newVideoMutedState).then(() => {
              setIsVideoMuted(newVideoMutedState);
              const updatedMutedUsers = newVideoMutedState
                ? [...mutedUsers, user_id]
                : mutedUsers.filter((user) => user !== user_id);

              setMutedUsers(updatedMutedUsers);
            });
          }
        }
      }
    } catch (error) {
    }
  };



  const handleAudioMute = (uid) => {
    if (localTracks && localTracks.length > 0) {
      const audioTrack = localTracks.find(
        (track) => track.trackMediaType === "audio"
      );

      if (audioTrack) {
        const newAudioMutedState = !audioMuted;

        if (joined) {
          audioTrack.setEnabled(!newAudioMutedState).then(() => {
            setAudioMuted(newAudioMutedState);

            const updatedMutedUsers = newAudioMutedState
              ? [...mutedUsers, uid]
              : mutedUsers.filter((userId) => userId !== uid);

            setMutedUsers(updatedMutedUsers);
          });
        } else {
          setAudioMuted(newAudioMutedState);
        }
      }
    }
  };


  const handleLeave = async () => {
    try {
      setJoined(false);
      setRequestVideo(false);
      if (localVideoTrack) {
        localVideoTrack.stop();
        localVideoTrack.close();
      }

      rejectCallReq({
        sender_id: user_id,
        receiver_id: videoCallRequest.sender_id,
        channel_name: videoCallRequest.channel_name,
        id: videoCallRequest.id,
      });

      await agoraClient.unpublish();
      await agoraClient.leave();
    } catch (error) { }
  };

  const handleJoin = async () => {
    try {
      setJoined(true);
      history.push(`/chatv2/call/${videoCallRequest.type}/${videoCallRequest.id}`);
      setRequestVideo(false);

    } catch (error) { }
  };


  const switchAgency = async () => {
    dispatch(userAgencyAction.updateUserAgencies({ agency_id: videoCallRequest.agency_id }, (res) => {
      if (res && res.message === "Updated Successfully") {
        history.push(`/chatv2/call/${videoCallRequest.type}/${videoCallRequest.id}`)
        setRequestVideo(false);
        setShowPopup(false)
      }
    }))
  }

  const handleClosePopup = () => {
    setRequestVideo(false);
    setShowPopup(false)
  }

  return (
    <>
      {showNotification && requestVideo
        ? videoCallRequest && (
          <Grid container className={classes.CallRequestContainer}>
            <Grid item className={classes.requestVideo}>
              {localVideoTrack && !joined && (
                <VideoPlayer
                  key={`local-${user_id}`}
                  user={{
                    uid: user_id,
                    videoTrack: localVideoTrack,
                  }}
                  classes={classes}
                  isVideoMuted={isVideoMuted}
                  isFullWidth={false}
                  localVideoTrack={localVideoTrack}
                  profile={profile}
                  allUsers={allUsers}
                />
              )}
            </Grid>
            <Grid className={classes.RequestHeader}>
              <Typography style={{ color: "white", fontWeight: 500 }}>
                Incoming video call
              </Typography>
              <Typography
                style={{ color: "white", fontWeight: 700, fontSize: "30px" }}
              >
                {videoCallRequest.name}
              </Typography>
            </Grid>
            <Grid className={classes.AudioPlayIcon}>
              <Grid
                className={classes.AudioPlayIconSvg}
                style={{
                  transform: `scale(${1 + audioPlayTransform * 0.01})`,
                }}
              >
                <AudioPlayIcon />
              </Grid>
            </Grid>
            <Grid item className={classes.RequstOptionsContainer}>
              <IconButton onClick={() => handleVideoToggle()}>
                {isVideoMuted ? (
                  <RequestVideoMuteIcon />
                ) : (
                  <RequestVideoUnmuteIcon />
                )}
              </IconButton>
              <IconButton onClick={handleAudioMute}>
                {audioMuted ? (
                  <RequestAudioMuteIcon />
                ) : (
                  <RequestAudioUnmuteIcon />
                )}
              </IconButton>
              <IconButton className={classes.answerBtn}>
                <Button onClick={handleJoin}>Answer</Button>
              </IconButton>
              <IconButton onClick={handleLeave} style={{ marginBottom: "18px" }}>
                <RequestVideoCallEndIcon />
              </IconButton>
            </Grid>
          </Grid>
        )
        : null}
      {planDetails.aId !== videoCallRequest.agency_id && showPopup &&
        <DialogModal
          open={showPopup}
          title={'Do you want to switch the agency?'}
          onConfirm={switchAgency}
          onCancel={handleClosePopup}
          confirmText={'Yes'}
          cancelText={'No'}
        />
      }
    </>
  );
}
export default withStyles(style)(CallRequest)
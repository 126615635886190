const styles = (theme) => ({
  roots: {
  },
  completeMsgContainer: {
    background: "transparent",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  completeBox: {
    background: "#fff",
    padding: "1rem",
    borderRadius: "0.5rem",
    zIndex: "999",
    boxShadow: "0 2px 5px 0 rgb(0 0 0 / 10%), 0 3px 20px 0 rgb(0 0 0 / 10%)",
    fontWeight: 900,
    display: "flex",
    alignItems: "center",
    "& img": {
      width: "2rem",
      margin: "0 0.5rem"
    }
  },
});


export default styles
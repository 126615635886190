export const boardsConstant = {

    // create board
    CREATE_BOARD_R: "CREATE_BOARD_R",
    CREATE_BOARD_S: "CREATE_BOARD_S",
    CREATE_BOARD_F: "CREATE_BOARD_F",

    // create board list
    CREATE_BOARD_LIST_R: "CREATE_BOARD_LIST_R",
    CREATE_BOARD_LIST_S: "CREATE_BOARD_LIST_S",
    CREATE_BOARD_LIST_F: "CREATE_BOARD_LIST_F",

    // Edit board list
    EDIT_BOARD_LIST_R: "EDIT_BOARD_LIST_R",
    EDIT_BOARD_LIST_S: "EDIT_BOARD_LIST_S",
    EDIT_BOARD_LIST_F: "EDIT_BOARD_LIST_F",


    // Edit board list
    DELETE_BOARD_LIST_R: "DELETE_BOARD_LIST_R",
    DELETE_BOARD_LIST_S: "DELETE_BOARD_LIST_S",
    DELETE_BOARD_LIST_F: "DELETE_BOARD_LIST_F",

    // get board names
    GET_BOARDS_R: "GET_BOARDS_R",
    GET_BOARDS_S: "GET_BOARDS_S",
    GET_BOARDS_F: "GET_BOARDS_F",

    // change board
    SET_BOARD_ID: "SET_BOARD_ID",
    SET_PUBLIC_BOARD_ID: 'SET_PUBLIC_BOARD_ID',

    // get board details
    GET_BOARD_DETAIL_R: "GET_BOARD_DETAIL_R",
    GET_BOARD_DETAIL_S: "GET_BOARD_DETAIL_S",
    GET_BOARD_DETAIL_F: "GET_BOARD_DETAIL_F",

    // change list order
    UPDATE_BOARD_LIST_ORDER_R: "UPDATE_BOARD_LIST_ORDER_R",
    UPDATE_BOARD_LIST_ORDER_S: "UPDATE_BOARD_LIST_ORDER_S",
    UPDATE_BOARD_LIST_ORDER_F: "UPDATE_BOARD_LIST_ORDER_F",

    // change list's task order
    UPDATE_BOARD_TASK_ORDER_R: "UPDATE_BOARD_TASK_ORDER_R",
    UPDATE_BOARD_TASK_ORDER_S: "UPDATE_BOARD_TASK_ORDER_S",
    UPDATE_BOARD_TASK_ORDER_F: "UPDATE_BOARD_TASK_ORDER_F",

    // create board list's task
    CREATE_BOARD_TASK_R: "CREATE_BOARD_TASK_R",
    CREATE_BOARD_TASK_S: "CREATE_BOARD_TASK_S",
    CREATE_BOARD_TASK_F: "CREATE_BOARD_TASK_F",

    // get task list
    GET_BOARD_TASK_R: "GET_BOARD_TASK_R",
    GET_BOARD_TASK_S: "GET_BOARD_TASK_S",
    GET_BOARD_TASK_F: "GET_BOARD_TASK_F",

    // load more
    GET_BOARD_TASK_LOAD_MORE_R: "GET_BOARD_TASK_LOAD_MORE_R",
    GET_BOARD_TASK_LOAD_MORE_S: "GET_BOARD_TASK_LOAD_MORE_S",
    GET_BOARD_TASK_LOAD_MORE_F: "GET_BOARD_TASK_LOAD_MORE_F",

    // get task detail
    GET_BOARD_TASK_DETAIL_R: "GET_BOARD_TASK_DETAIL_R",
    GET_BOARD_TASK_DETAIL_S: "GET_BOARD_TASK_DETAIL_S",
    GET_BOARD_TASK_DETAIL_F: "GET_BOARD_TASK_DETAIL_F",

    // update task detail
    UPDATE_BOARD_TASK_DETAIL_R: "UPDATE_BOARD_TASK_DETAIL_R",
    UPDATE_BOARD_TASK_DETAIL_S: "UPDATE_BOARD_TASK_DETAIL_S",
    UPDATE_BOARD_TASK_DETAIL_F: "UPDATE_BOARD_TASK_DETAIL_F",

    // Update filter on change
    FILTER_ON_CHANGE: 'FILTER_ON_CHANGE',

    // get users list for name avatar display in card
    GET_ASSIGNED_USERS_TASK_R: "GET_ASSIGNED_USERS_TASK_R",
    GET_ASSIGNED_USERS_TASK_S: "GET_ASSIGNED_USERS_TASK_S",
    GET_ASSIGNED_USERS_TASK_F: "GET_ASSIGNED_USERS_TASK_F",

    // update board task to reducer after edit task save
    RELOAD_BOARD_TASK_CARD: "RELOAD_BOARD_TASK_CARD",

    DELETE_BOARD_TASK_R: "DELETE_BOARD_TASK_R",
    DELETE_BOARD_TASK_S: "DELETE_BOARD_TASK_S",
    DELETE_BOARD_TASK_F: "DELETE_BOARD_TASK_F",

    // Boards Task Tags
    BOARDS_TASK_TAGS_R: "BOARDS_TASK_TAGS_R",
    BOARDS_TASK_TAGS_S: "BOARDS_TASK_TAGS_S",
    BOARDS_TASK_TAGS_F: "BOARDS_TASK_TAGS_F",

    // User access
    GET_USER_ACCESS_R: "GET_USER_ACCESS_R",
    GET_USER_ACCESS_S: "GET_USER_ACCESS_S",
    GET_USER_ACCESS_F: "GET_USER_ACCESS_F",

    // Get Board Members
    GET_BOARD_MEMBERS_R: "GET_BOARD_MEMBERS_R",
    GET_BOARD_MEMBERS_S: "GET_BOARD_MEMBERS_S",
    GET_BOARD_MEMBERS_F: "GET_BOARD_MEMBERS_F",

    // Add Board Members
    ADD_BOARD_MEMBERS_R: "ADD_BOARD_MEMBERS_R",
    ADD_BOARD_MEMBERS_S: "ADD_BOARD_MEMBERS_S",
    ADD_BOARD_MEMBERS_F: "ADD_BOARD_MEMBERS_F",

    // Update Board members
    MODIFY_BOARD_MEMBERS_R: "MODIFY_BOARD_MEMBERS_R",
    MODIFY_BOARD_MEMBERS_S: "MODIFY_BOARD_MEMBERS_S",
    MODIFY_BOARD_MEMBERS_F: "MODIFY_BOARD_MEMBERS_F",

    // Delete board members
    DELETE_BOARD_MEMBERS_R: "DELETE_BOARD_MEMBERS_R",
    DELETE_BOARD_MEMBERS_S: "DELETE_BOARD_MEMBERS_S",
    DELETE_BOARD_MEMBERS_F: "DELETE_BOARD_MEMBERS_F",

    // get activity history
    GET_BOARD_TASK_HISTORY_R: "GET_BOARD_TASK_HISTORY_R",
    GET_BOARD_TASK_HISTORY_S: "GET_BOARD_TASK_HISTORY_S",
    GET_BOARD_TASK_HISTORY_F: "GET_BOARD_TASK_HISTORY_F",

    // get board task comments
    GET_BOARD_TASK_COMMENTS_R: "GET_BOARD_TASK_COMMENTS_R",
    GET_BOARD_TASK_COMMENTS_S: "GET_BOARD_TASK_COMMENTS_S",
    GET_BOARD_TASK_COMMENTS_F: "GET_BOARD_TASK_COMMENTS_F",

    // add board task comments
    ADD_BOARD_TASK_COMMENTS_R: "ADD_BOARD_TASK_COMMENTS_R",
    ADD_BOARD_TASK_COMMENTS_S: "ADD_BOARD_TASK_COMMENTS_S",
    ADD_BOARD_TASK_COMMENTS_F: "ADD_BOARD_TASK_COMMENTS_F",

    // update board task comments
    UPDATE_BOARD_TASK_COMMENTS_R: "UPDATE_BOARD_TASK_COMMENTS_R",
    UPDATE_BOARD_TASK_COMMENTS_S: "UPDATE_BOARD_TASK_COMMENTS_S",
    UPDATE_BOARD_TASK_COMMENTS_F: "UPDATE_BOARD_TASK_COMMENTS_F",

    // delete board task comments
    DELETE_BOARD_TASK_COMMENTS_R: "DELETE_BOARD_TASK_COMMENTS_R",
    DELETE_BOARD_TASK_COMMENTS_S: "DELETE_BOARD_TASK_COMMENTS_S",
    DELETE_BOARD_TASK_COMMENTS_F: "DELETE_BOARD_TASK_COMMENTS_F",

    // save filter template
    SAVE_BOARD_FILTER: "SAVE_BOARD_FILTER",
    UPDATE_BOARD_FILTER: "UPDATE_BOARD_FILTER",
    SET_BOARD_FILTER: "SET_BOARD_FILTER",

    // reset board
    RESET_BOARD: 'RESET_BOARD',

    // Update Task Card
    // this will update task card by task id and list id and property to update
    UPDATE_TASK_CARD: 'UPDATE_TASK_CARD',

    // update board settings
    UPDATE_BOARD_SETTINGS_R: 'UPDATE_BOARD_SETTINGS_R',
    UPDATE_BOARD_SETTINGS_S: 'UPDATE_BOARD_SETTINGS_S',
    UPDATE_BOARD_SETTINGS_F: 'UPDATE_BOARD_SETTINGS_F',

    // Clone Board Task
    CLONE_BOARD_TASK_R: "CLONE_BOARD_TASK_R",
    CLONE_BOARD_TASK_S: "CLONE_BOARD_TASK_S",
    CLONE_BOARD_TASK_F: "CLONE_BOARD_TASK_F",

    // Clone Board Task
    DELETE_BOARD_R: "DELETE_BOARD_R",
    DELETE_BOARD_S: "DELETE_BOARD_S",
    DELETE_BOARD_F: "DELETE_BOARD_F",

    // Public Link
    PUBLIC_LINK: "PUBLIC_LINK",
    PUBLIC_BOARD_ID:"PUBLIC_BOARD_ID",
    SET_PUBLIC: "SET_PUBLIC",
    EXPORT_BOARD_R: "EXPORT_BOARD_R",
    EXPORT_BOARD_S: "EXPORT_BOARD_S",
    EXPORT_BOARD_F: "EXPORT_BOARD_F",

    // Read sheet data
    UPLOAD_SHEET_DATA_R: "UPLOAD_SHEET_DATA_R",
    UPLOAD_SHEET_DATA_S: "UPLOAD_SHEET_DATA_S",
    UPLOAD_SHEET_DATA_F: "UPLOAD_SHEET_DATA_F",

    PUBLIC_BOARD_R: "PUBLIC_BOARD_R",
    PUBLIC_BOARD_S: "PUBLIC_BOARD_S",
    PUBLIC_BOARD_F: "PUBLIC_BOARD_F",

};

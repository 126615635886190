import React, { useState, useEffect, useMemo } from "react";
import {
  IconButton,
  Badge,
  Popover,
  Typography,
  withStyles,
  Tooltip,
  Grid,
  useMediaQuery
} from "@material-ui/core";
import { NotificationIcon, CloseIcon } from "../../assets/svg";
import ChatStyles from "./ChatStyles";
import { useDispatch, useSelector } from "react-redux";
import { profileAction } from "../../redux/actions";
import NotificationList from "../Notification/NotificationList";
import { MobileActionSheet } from "../../components/MobileComponents/MobileActionSheet";

const ChatNotifications = (props) => {
  const isMobile = useMediaQuery('(max-width: 899px)')

  const { classes, btnClasses } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const { tasks_n, invites_n, notifications_count, invoice_n } = useSelector(
    (s) => s.profile
  );
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const id = open ? "chat-nt-popover" : undefined;
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    dispatch(profileAction.getNotifications({}));
    dispatch(profileAction.getInvites());
    dispatch(profileAction.getTasks());
  }, [dispatch]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenDialog(false);
  };
  const NotificationPopup = () => (
    <Grid className={classes.ChatNotificationsBox}>
      <Grid alignItems="center" justifyContent="center">
        <NotificationList
          classes={classes}
          setAnchorEl={setAnchorEl}
          hideHeaderElements={true}
          openDialog={openDialog}
          handleCloseAction={handleClose}
          maxNotifications={5}
          handleClose={handleClose}
          isPopover
        />
      </Grid>
    </Grid>
  )

  const memoNotificationPopup = useMemo(() => (
    <MobileActionSheet
      open={open}
      title="Notification"
      handleCloseAction={handleClose}>
      <Grid className={classes.ChatNotificationsBox}>
        <Grid alignItems="center" justifyContent="center">
          <NotificationList
            classes={classes}
            setAnchorEl={setAnchorEl}
            hideHeaderElements={true}
            openDialog={openDialog}
            handleCloseAction={handleClose}
            maxNotifications={5}
            handleClose={handleClose}
            isPopover
          />
        </Grid>
      </Grid>
    </MobileActionSheet >
  ), [open])
  return (
    <>
      <Badge
        className={btnClasses?.notifiBadge}
        badgeContent={
          notifications_count?.allNewMsgCount +
          tasks_n.length +
          invites_n.length +
          invoice_n.length
        }
        color="primary"
      >
        <Tooltip title={"Notification"}>
          <IconButton className="ml15" id={id} onClick={handleClick}>
            <NotificationIcon />
          </IconButton>
        </Tooltip>
      </Badge>
      {isMobile ?
        memoNotificationPopup
        :

        <Popover
          className={classes.ChatNotificationsBoxPoper}
          style={{ marginTop: "18px" }}
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Grid className={classes.ChatNotificationsBox}>
            <Grid container className="boxHeader" alignItems="center">
              <Typography className={classes.popup_head} variant="h6">
                Notification
              </Typography>
              <IconButton className={classes.onCloseicon} onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid alignItems="center" justifyContent="center">
              <NotificationList
                classes={classes}
                setAnchorEl={setAnchorEl}
                hideHeaderElements={true}
                openDialog={openDialog}
                handleCloseAction={handleClose}
                maxNotifications={5}
                handleClose={handleClose}
                isPopover
              />
            </Grid>
            {/* <NotificationPopup /> */}
          </Grid>

        </Popover>
      }
    </>
  );
};

export default withStyles(ChatStyles)(ChatNotifications);

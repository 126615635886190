// Import API Helper
import { deleteRequest, getRequest, postRequest } from "../../utils/apiHelper";

const getTasksGroups = async (params) => {
    const response = await postRequest("task/groups", params);
    return response;
}

// save task preference
const saveTaskPreference = async (params) => {
    const response = await postRequest("task/savePreference", params);
    return response;
}

// get all task groups
const getTaskGroupTypes = async (params) => {
    const response = await postRequest("task/getGroupTypes", params);
    return response;
}

// get all task groups
const getTaskFilters = async (params) => {
    const response = await getRequest("task/filters");
    return response;
}

// get task comments list
const getTaskComments = async (params) => {
    let queryStr = "";
    for (const key in params) {
        if (Object.hasOwnProperty.call(params, key)) {
            if (key !== "id") {
                const value = params[key];
                let str = params[key] ? `${key}=${value}` : "";
                queryStr = queryStr ? queryStr + "&" + str : str;
            }
        }
    }
    const response = await getRequest(`task/getTaskComment/${params.id}?${queryStr}`)
    return response;
}

// get task count
const getTaskCommentsCounts = async (params) => {
    const response = await getRequest(`task/count/${params.id}`);
    return response
}

//create new task
async function getTaskDetails(params) {
    const response = await postRequest("task/details", params);
    return response;
}

//complete task
async function completeTask(params) {
    const response = await postRequest("task/complete", params);
    return response;
}

//create new profile
async function getTaskList(params) {
    const response = await postRequest("task/list", params);
    return response;
}

//create new task
async function saveTask(params) {
    const response = await postRequest("task/save", params, params instanceof FormData ? true : false);
    return response;
}

//delete new task
async function deleteTask(params) {
    console.log(params)
    const response = await deleteRequest(`task/delete/${params.id}/?actionOption=${params.actionOption || 'CURRENT'}`);
    return response;
}

// save task comments
async function saveComment(params) {
    const response = await postRequest('task/saveTaskComment', params);
    return response;
}

// delete task comment
async function deleteComment(params) {
    const response = await deleteRequest(`task/deleteTaskComment/${params.id}`);
    return response;
}

//create new profile
async function getDefaultCount(params) {
    const response = await postRequest("task/default/count", params);
    return response;
}

//create new profile
async function getDefaultList(params) {
    const response = await postRequest("task/default/list", params);
    return response;
}

//update inline task
async function updateInlineTask(params) {
    const response = await postRequest("task/inlineEdit", params);
    return response;
}

// get task activity
const getTaskActivity = async (params) => {
    const response = await getRequest(`task/activity/${params.task_date_id}`);
    return response
}

// export all services
export const tasksService = {
    getTasksGroups,
    saveTaskPreference,
    getTaskGroupTypes,
    getTaskFilters,
    getTaskDetails,
    completeTask,
    getTaskList,
    saveTask,
    deleteTask,
    saveComment,
    getTaskCommentsCounts,
    getTaskComments,
    deleteComment,
    getDefaultCount,
    getDefaultList,
    updateInlineTask,
    getTaskActivity
};
// Import API Helper
import { postRequest, getRequest, deleteRequest } from "../../utils/apiHelper";

export const clientService = {
    getClientsFilter,
    getClients,
    getClientsCount,
    updateDND,
    getClientDetail,
    createClient,
    getAllTags,
    completeClient,
    deleteClient,
    archiveClient,

    getBrief,
    updateBrief,
    saveDuplicateClient,

    getAllNotes,
    getNote,
    addNote,
    updateNote,
    shareNote,
    deleteNote,

    getClientTeamUsers,
    inviteNewUsers,
    inviteExistingUsers,
    removeClientUser,
    deleteClientUserFromAgency,
    getTeamMembers,
    getActiveTeamMembers,
    newUserInvitationAccept,
    getClientInviteData,

    createNewTask,
    getAllTasks,
    getCurrentTask,
    deleteTask,
    updatecurrentTask,
    getTaskFilter,
    completeTask,
    getCTaskTags,
    createTaskNote,
    getAllTaskNote,
    deleteTaskNote,

    generateOpenLink,
    deleteOpenInvite,
    clientOnboardingInviteUniqueName,
    clientOnboardingInviteLinks,
    getClientOnboardingInvite,
    clientOnboardingLogsAdd,
    clientOnboardingUsersAdd,
    getAgencyNameAndLogo,
    createNewOnboardingUser,
    getOnboardingFilters
};

//create new Client
async function createClient(params) {
    const response = await postRequest("clients/create", params);
    return response;
}

//complete client
async function completeClient(params) {
    const response = await postRequest("clients/completeClient", params);
    return response;
}

//get clients
async function getClients(params) {
    const response = await postRequest("clients", params);
    return response;
}

//get clients count
async function getClientsCount(params) {
    const response = await postRequest("clients/count", params);
    return response;
}

async function getAllTags(params) {
    const response = await postRequest("clients/getAllTags", params);
    return response;
}

//get clients
async function getClientsFilter(params) {
    const response = await postRequest("clients/filter", params);
    return response;
}

//update client list order
async function updateDND(params) {
    const response = await postRequest("clients/DND", params);
    return response;
}

//get client detail
async function getClientDetail(params) {
    const response = await getRequest(`clients/${params.id}`);
    return response;
}

//delete client
async function deleteClient(params) {
    const response = await postRequest("clients/remove", params);
    return response;
}
//archive client
async function archiveClient(params) {
    const response = await postRequest("clients/archive", params);
    return response;
}

/****************************************************************BRIEF DETAILS************************************************************************************** */

//get brief
async function getBrief(params) {
    const response = await postRequest("clients/brief/get", params);
    return response;
}

//update brief
async function updateBrief(params) {
    const response = await postRequest("clients/brief/update", params);
    return response;
}

//create duplicate client
async function saveDuplicateClient(params) {
    const response = await postRequest("clients/create_clone", params);
    return response;
}

/****************************************************************NOTES DETAILS************************************************************************************** */


//get all notes
async function getAllNotes(params) {
    const response = await postRequest("clients/note/getAll", params);
    return response;
}

//get one note
async function getNote(params) {
    const response = await postRequest("clients/note/getOne", params);
    return response;
}

//add note
async function addNote(params) {
    const response = await postRequest("clients/note/add", params);
    return response;
}

//update note
async function updateNote(params) {
    const response = await postRequest("clients/note/update", params);
    return response;
}

//share note
async function shareNote(params) {
    const response = await postRequest("clients/note/share", params);
    return response;
}

//Delete current Notes
async function deleteNote(params) {
    const response = await postRequest("clients/note/del", params);
    return response;
}


/*************************************************************TEAMS DETAILS************************************************************************************** */

//get client team users
async function getClientTeamUsers(params) {
    const response = await postRequest(`clients/team`, params);
    return response;
}


//add existing user
async function inviteExistingUsers(params) {
    const response = await postRequest("clients/existingInvite", params);
    return response;
}

//add new user
async function inviteNewUsers(params) {
    const response = await postRequest("clients/newInvite", params);
    return response;
}

//remove existing user
async function removeClientUser(params) {
    const response = await postRequest("clients/removeClientUser", params);
    return response;
}

// delete client user from agency
async function deleteClientUserFromAgency(params) {
    const response = await postRequest("profile/deleteClientUser", params);
    return response;
}

//get Team Members
async function getTeamMembers(params) {
    const response = await postRequest("clients/getTeamMembers", params);
    return response;
}

//get Team Members
async function getActiveTeamMembers(params) {
    const response = await postRequest("clients/getActiveTeamMembers", params);
    return response;
}

//get Team Members
async function newUserInvitationAccept(params) {
    const response = await postRequest("clients/inviteAccept", params);
    return response;
}

//getClientInviteData
async function getClientInviteData(params) {
    const response = await postRequest("clients/clientInviteData", params);
    return response;
}
/*************************************************************TASk DETAILS************************************************************************************** */

//create new task
async function createNewTask(params) {
    const response = await postRequest("clients/task/createNew", params);
    return response;
}

//get all task
async function getAllTasks(params) {
    const response = await postRequest("clients/task/getAll", params);
    return response;
}

//get current task
async function getCurrentTask(params) {
    const response = await postRequest("clients/task/getOne", params);
    return response;
}

//get current task
async function deleteTask(params) {
    const response = await postRequest("clients/task/del", params);
    return response;
}

//get current task
async function updatecurrentTask(params) {
    const response = await postRequest("clients/task/update", params);
    return response;
}

//create new profile
async function getTaskFilter(params) {
    const response = await postRequest("clients/task/filter", params);
    return response;
}

//complete task
async function completeTask(params) {
    const response = await postRequest("clients/task/complete", params);
    return response;
}

//task tags get
async function getCTaskTags(params) {
    const response = await postRequest("clients/task/tags", params);
    return response;
}

//create task note
async function createTaskNote(params) {
    const response = await postRequest("task/notes/create", params);
    return response;
}

//get project task note
async function getAllTaskNote(params) {
    const response = await postRequest("task/notes", params);
    return response;
}

//delete task note
async function deleteTaskNote(params) {
    const response = await deleteRequest("task/notes", params);
    return response;
}

//Create Client Onboarding Open link
async function generateOpenLink(params) {
    const response = await postRequest("clients/onboarding/generate-open-invite", params);
    return response;
}

//Delete Client Onboarding Open link
async function deleteOpenInvite(params) {
    const response = await deleteRequest(`clients/onboarding/remove-open-invite/${params}`);
    return response;
}
//Check unique name for Client Onboarding Open link
async function clientOnboardingInviteUniqueName(name) {
    const response = await getRequest(`clients/onboarding/invite-unique-name/${name}`);
    return response;
}

//GET Client Onboarding Invite list
async function clientOnboardingInviteLinks(params) {
    const response = await postRequest(`clients/onboarding/invite-list`, params);
    return response;
}
//GET Client Onboarding Invite
async function getClientOnboardingInvite(params) {
    const response = await getRequest(`clients/onboarding/open-invite/${params}`);
    return response;
}

//Add Client Onboarding Link Clicks
async function clientOnboardingLogsAdd(params) {
    const response = await postRequest(`clients/onboarding/log`, params);
    return response;
}

//Add Client Onboarding Users
async function clientOnboardingUsersAdd(params) {
    const response = await postRequest(`clients/onboarding/user`, params);
    return response;
}
//Add Client Onboarding Users Logs
async function getAgencyNameAndLogo(params) {
    const response = await getRequest(`clients/onboarding/agency-details/${params}`);
    return response;
}
//Create new Client Onboarding Users
async function createNewOnboardingUser(params) {
    const response = await postRequest(`clients/onboarding/create-user`, params);
    return response;
}
// Client Onboarding Filters
async function getOnboardingFilters(params) {
    const response = await getRequest(`clients/onboarding/filters`);
    return response;
}
import React from "react";
import PropTypes from 'prop-types';
import { Grid, IconButton, withStyles,Avatar } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import { CameraAttachments, GifAttachments, VideoAttachments, AudioAttachments } from "../../assets/svg/chatIcons";
import { useSelector } from 'react-redux';
//Import Component
import { Typography } from '..'

// Imports Styles
import styles from "./styles";

const InputReplyComponent = (props) => {
    const { classes, onClose, messageObj } = props;
    const { id: userId } = useSelector((s) => s.auth.loginUser)
    let fileName = ''
    let fileType = null
    let fileUrl = null; let fileDoc = null; let fileIcon = null;
    if (messageObj && messageObj.message_file) {
        let fileObj = JSON.parse(messageObj.message_file);
        if (messageObj.message_category === 1) {         
            fileUrl = fileObj && fileObj.thumb ? fileObj.thumb : fileObj.url;
            fileName = fileObj.name
            if(fileObj.type.includes("video")) {
                fileType = "video"
                fileUrl = fileObj.thumb ? fileObj.thumb : fileObj
                fileIcon = <VideoAttachments />
            } else if(fileObj.type.includes("gif")) {
                fileType = "GIF"
                fileIcon = <GifAttachments />
            } else {
                fileType = "Image"
                fileIcon = <CameraAttachments />
            }
        }
        else if (messageObj.message_category === 2) {
            fileDoc = fileObj && fileObj.name ? fileObj.name : null;
            fileIcon = fileObj && fileObj.type ? fileObj.type.split("/")[1] : "docs";
            fileName = fileObj.name
        } else if (messageObj.message_category === 3) {
            fileUrl = fileObj && fileObj[0].url ? fileObj[0].url : null;
        }
        else if (messageObj.message_category === 4) {
            fileDoc = fileObj && fileObj.name ? fileObj.name : null;
            fileName = fileObj && fileObj.name ? "voice note" : "";
            fileIcon = fileObj && fileObj.name ? "voice note" : "";
        }
    }

    return (
        <>
            {messageObj ? <Grid className={classes.replyTile} container item alignItems="center">
                <Grid className={`${classes.replyTileLeft} ${messageObj.message_file ? classes.replyTileFile : ''} disable-user-select`}>
                    {messageObj.message_file ?
                        <>
                            <Grid className="dflex flexColumn justifyCenter">
                                <Typography variant="subtitle2" className="taskstitle fs-12 fw-500 disable-user-select">{messageObj?.user_id == userId? "You" : messageObj.name ? messageObj.name : `${messageObj.first_name ? messageObj.first_name : ''} ${messageObj.last_name ? messageObj.last_name : ''}`}</Typography>
                                {messageObj.message_text? <Typography variant="body2" className="tasksText threeLineEllip fs-12">{messageObj?.message_text?messageObj?.message_text:""}</Typography>:null}
                                <span className="dflex alignCenter">{fileIcon && <Typography variant="body2" color="textSecondary" className="tasksText oneLineEllip fontSize10 dflex alignCenter pr5"> {fileIcon}</Typography>}<Typography variant="body2" color="textSecondary" className="tasksText oneLineEllip fontSize10 dflex alignCenter">{fileType ? fileType : ""}</Typography></span>
                                {/* {messageObj.message_file ? <Typography variant="body2" className="tasksText threeLineEllip fs-12">{fileName ? fileName : ""}</Typography> : null} */}
                            </Grid>
                            {fileUrl ? <img alt={"imageone"} src={fileUrl} className={classes.replyThumb} /> : null}
                            {/* {fileDoc ? <Typography variant="body2" className="tasksText threeLineEllip">{fileIcon}</Typography> : null} */}
                        </>
                        :
                        <>
                            <Typography variant="subtitle2" className="taskstitle fs-12 fw-500 disable-user-select">{messageObj.name ? messageObj.name : ""}</Typography>
                            {messageObj.message_text ? <Typography variant="body2" className="tasksText threeLineEllip fs-12">{messageObj.message_text ? messageObj.message_text : ""}</Typography> : null}
                        </>
                    }
                </Grid>
                <Grid className={classes.replyTileRight}>
                    <IconButton onClick={() => onClose()} className={`p-0 ${classes.replyCloseBtn}`}><CloseIcon className={classes.closeIcon} /></IconButton>
                </Grid>
            </Grid> : null}
        </>
    );
};

// Default props
InputReplyComponent.defaultProps = {
    classes: "",
    onClose: () => { },
    messageObj: { id: "" }
};

// Prop types
InputReplyComponent.propTypes = {
    classes: PropTypes.object,
    onClose: PropTypes.func,
    messageObj: PropTypes.any,
};

/** Export Component */
export const ChatInputReplyComponent = withStyles(styles)(InputReplyComponent);

import {
    Avatar,
    Card, CardActionArea,
    CardContent,
    Grid,
    IconButton,
    withStyles,
    useMediaQuery
} from '@material-ui/core';
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChatMenuIcon, CloseIcon, InvoicesMenuIcon } from '../../../assets/svg';
import invoiceRingtone from '../../../assets/tones/invoice_tone.wav';
import ringToneFile from '../../../assets/tones/message_tone.mp3';
import { NotificationSound, Typography } from '../../../components';
import history from "../../../config/history";
import useVisibilty from "../../../hooks/useVisibilty";
import { chatv2Action, invoiceAction, profileAction, userAgencyAction } from "../../../redux/actions";
import { PARAM_KEY, PARAM_MODULE_URL } from "../Chatv2Constants";
import style from './style';

/**
 * NotificationTile Component
 * @param {*} props
 */
function NotificationTileComponent(props) {
    const { classes } = props;
    const dispatch = useDispatch();
    const isMobile = useMediaQuery("(max-width: 899px)")

    const { notifications, selected_channel,
        mutedRooms, channel } = useSelector((state) => state.chatv2);
    const { invoiceNotification } = useSelector((s) => s.invoice)
    const { id: userId } = useSelector(state => state.auth.loginUser)
    const newMessage = (notifications || []).find(f => f.created_by != userId) || {}
    const newInvoiceMessage = (invoiceNotification || []).find(f => f.notification_from === userId) || {}
    const CUR_MODULE_TYPE_NAME = PARAM_MODULE_URL[newMessage['moduleType']]
    const room_key = PARAM_KEY[newMessage['moduleType']]
    // const isActiveJob = newMessage['moduleType'] == !newMessage.hasOwnProperty("moduleType") || !newMessage.hasOwnProperty(room_key) || (PARAM_MODULE_KEY[selected_channel?.type] && newMessage[room_key] == selected_channel?.id)
    const [ringtone, setRingtone] = useState(false)
    // if muted no need ringtone
    const isMutedChat = mutedRooms[PARAM_MODULE_URL[newMessage['moduleType']]]
        ?.find(f => f == newMessage[room_key])
    const activeHubData = (channel[CUR_MODULE_TYPE_NAME] || []).find(f => f.id == newMessage[room_key])
    const { loginUser: { agency_pref } } = useSelector(s => (s.auth))
    const isChatv2Page = selected_channel?.id != null
    const isActivePage = activeHubData?.id == selected_channel?.id
    const isInvoiceSummary = window.location.hash?.includes('invoices/summary')
    const [visible] = useVisibilty()

    useEffect(() => {
        if (newMessage?.id && !newMessage?.shown) {
            const params = {
                status: "delivered",
                type: newMessage['moduleType'],
                job_id: newMessage[room_key],
                message_id: newMessage?.id,
                users: [{
                    id: newMessage?.message_from
                }],
                cur_type: CUR_MODULE_TYPE_NAME,
                room_id: newMessage[room_key]
            }
            // set delivery status for not active page and focus out / background app
            if (!isActivePage || visible !== 'visible') {
                dispatch(chatv2Action.setChatDeliveryStatus(params))
            }
            if (!isActivePage) {
                if (!isMutedChat) {
                    setRingtone(true)
                }
                dispatch(profileAction.getNotifications())
                dispatch(chatv2Action.getNewMessages())
            }
        }

    }, [newMessage?.id])

    useEffect(() => {
        if (newInvoiceMessage?.id && !isInvoiceSummary) {
            setRingtone(true)
            dispatch(invoiceAction.getInvoiceNewNotification())
            dispatch(profileAction.updateNotificationViewStatus({ type: 'invoice' }, () => { }));
            dispatch(profileAction.getInvoiceNotification())
        }
    }, [newInvoiceMessage?.id])

    useEffect(() => {
        if (ringtone) {
            const timer = setTimeout(() => {
                setRingtone(false);
            }, 500);
            return () => clearTimeout(timer);
        }
    }, [ringtone]);

    useEffect(() => {
        setTimeout(() => {
            removeNotification('chat')
        }, 3000)
    }, [newMessage?.id])

    useEffect(() => {
        setTimeout(() => {
            removeNotification('invoice')
        }, 3000)
    }, [newInvoiceMessage?.id])

    const switchAndOpenMessage = (type) => {
        if (type === 'chat') {
            if (newMessage && newMessage.agency_id && agency_pref === parseInt(newMessage.agency_id)) {
                const type = PARAM_MODULE_URL[newMessage['moduleType']]
                history.push(`/chat/${type}?id=${newMessage[room_key]}`)
            }
            else {
                // switch to another workspace
                dispatch(userAgencyAction.updateUserAgencies({ agency_id: newMessage.agency_id }, (res) => {
                    if (res && res.message === "Updated Successfully") {
                        const type = PARAM_MODULE_URL[newMessage['moduleType']]
                        history.push(`/chat/${type}?id=${newMessage[room_key]}`)
                        window.location.reload()
                    }
                }))
            }
            removeNotification('chat')
        } else if (type === 'invoice') {
            if (newInvoiceMessage && newInvoiceMessage.agency_id && agency_pref === parseInt(newInvoiceMessage.agency_id)) {
                history.push(`/invoices/summary`)
            } else {
                // switch to another workspace
                dispatch(userAgencyAction.updateUserAgencies({ agency_id: newInvoiceMessage.agency_id }, (res) => {
                    if (res && res.message === "Updated Successfully") {
                        history.push(`/invoices/summary`)
                        window.location.reload()
                    }
                }))
            }
            removeNotification('invoice')
        }
    }

    const closeMessage = (type) => {
        removeNotification(type)
    }

    const removeNotification = (type) => {
        if (type === 'invoice') {
            dispatch(invoiceAction.removeNotification({ type: 'invoice', id: newInvoiceMessage?.id }))
        } else if (type === 'chat') {
            dispatch(chatv2Action.removeNotification(newMessage?.id))
        }
    }

    if (invoiceNotification.length > 0 && !isInvoiceSummary) {
        return (
            <>
                <Card className={classes.notificationCard}>
                    <Grid container className={classes.cardHeader}>
                        <Grid className="growOne dflex alignflexEnd">
                            <Typography variant="h6" className={classes.titleContainer}><span className="mr-1 iconWrapper" ><InvoicesMenuIcon /></span>Invoice Paid</Typography>
                        </Grid>
                        <IconButton onClick={() => { closeMessage('invoice') }} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </Grid>

                    <CardActionArea onClick={() => { switchAndOpenMessage('invoice') }}>
                        <CardContent className="dflex alignCenter">
                            <Grid className="growOne">
                                <Typography gutterBottom variant="body1" className="fw-500">
                                    {newInvoiceMessage && newInvoiceMessage['notification_message']}
                                </Typography>
                            </Grid>
                        </CardContent>
                    </CardActionArea>
                </Card>
                {ringtone ? <NotificationSound key={newInvoiceMessage?.id} audioUrl={invoiceRingtone} /> : ""}
            </>
        )
    } else {
        if (newMessage && newMessage["id"] && !isActivePage && !isMutedChat && !isChatv2Page) {
            let fileUrl = null; let fileDoc = null; let fileIcon = null;
            if (newMessage.message_file) {
                let fileObj = JSON.parse(newMessage.message_file);
                if (newMessage.message_category === 1) {
                    fileUrl = fileObj && fileObj.url ? fileObj.url : null;
                }
                else if (newMessage.message_category === 2) {
                    fileDoc = fileObj && fileObj.name ? fileObj.name : null;
                    fileIcon = fileObj && fileObj.type ? fileObj.type.split("/")[1] : "docs";
                } else if (newMessage.message_category === 3) {
                    fileUrl = fileObj && fileObj[0].url ? fileObj[0].url : null;
                }
                else if (newMessage.message_category === 4) {
                    fileDoc = fileObj && fileObj.name ? fileObj.name : null;
                    fileIcon = fileObj && fileObj.name ? "voice note" : "";
                }
            }
            return (
                <>
                    {notifications.slice(0, 3)
                        .map((message, index) => (
                            < Card key={index} className={classes.notificationCard}>
                                {!isMobile &&
                                    <Grid container className={classes.cardHeader}>
                                        <Grid className="growOne dflex alignflexEnd">
                                            <Typography variant="h6" className={classes.titleContainer}><span className="mr-1 iconWrapper" ><ChatMenuIcon /></span>Notification</Typography>
                                        </Grid>
                                        <IconButton onClick={() => { closeMessage('chat') }} aria-label="close">
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                }
                                <CardActionArea onClick={() => { switchAndOpenMessage('chat') }}>
                                    <CardContent className="dflex alignCenter">
                                        {isMobile &&
                                            <Grid className={classes.closeIconMobile}>
                                                <IconButton onClick={() => { closeMessage('chat') }} aria-label="close">
                                                    <CloseIcon />
                                                </IconButton>
                                            </Grid>}
                                        <Grid className="mr-1">
                                            <Avatar aria-label="recipe" size="small" className={classes.avatar}>
                                                {newMessage && newMessage.avatar_filename 
                                                ? <img src={newMessage.avatar_filename} alt="user avatar" /> 
                                                : message && message.first_name && message.first_name[0] 
                                                ? message.first_name[0] 
                                                : ""}
                                            </Avatar>
                                        </Grid>
                                        <Grid className="growOne">
                                            <Typography gutterBottom variant="body1" className="fw-500">
                                                <div>
                                                    <b>
                                                        {newMessage ? 
                                                        `${newMessage["first_name"] ? newMessage["first_name"] : ""} 
                                                        ${newMessage["last_name"] ? newMessage["last_name"] : ""}` 
                                                        : ""}
                                                    </b> 
                                                    {newMessage && newMessage.group_name && newMessage.group_name !== "" 
                                                        ? <> sent a message in <b>{newMessage.group_name}</b></> 
                                                        : " sent a message"}
                                                </div>
                                            </Typography>

                                            {message?.message_text ? <Typography variant="body1" color="textSecondary" className="tasksText fs-13 twoLineEllip">{message?.message_text}</Typography> : null}
                                            {message?.message_file ? <Typography variant="body1" color="textSecondary" className="tasksText fs-13 twoLineEllip">{message?.message_text}</Typography> : null}
                                            {fileUrl ? <img alt={"imagethumb"} src={fileUrl} className={classes.replyThumb} /> : null}
                                            {fileDoc ? <Typography variant="body2" className="tasksText twoLineEllip">{fileIcon} {fileDoc}</Typography> : null}
                                        </Grid>
                                    </CardContent>
                                </CardActionArea>
                            </Card >
                        ))
                    }

                    {ringtone ? <NotificationSound key={newMessage?.id} audioUrl={ringToneFile} /> : ""}
                </>
            );
        } else {
            return ringtone ? <NotificationSound key={newMessage?.id} audioUrl={ringToneFile} /> : null
        }
    }

}

// default props
NotificationTileComponent.defaultProps = {
    classes: {},
    children: "",
    className: "",
    onClick: () => { },
    viewMessageClick: () => { }
};

// prop types
NotificationTileComponent.propTypes = {
    children: PropTypes.any,
    color: PropTypes.string,
    size: PropTypes.string,
    variant: PropTypes.string,
    component: PropTypes.string,
    href: PropTypes.string,
    onClick: PropTypes.func,
    classes: PropTypes.object,
    className: PropTypes.string,
    viewMessageClick: PropTypes.func
};

/** Export Component */
export const NotificationTilev2 = withStyles(style)(NotificationTileComponent);

import React, { useEffect, useState } from 'react'
import { Avatar, Grid, IconButton, InputAdornment } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import SendIcon from '@material-ui/icons/Send';
import { CloseIcon, EditIcon, DownloadIcon } from '../../assets/svg';
import DescriptionIcon from '@material-ui/icons/Description';

import { Typography, TextField } from '../';

import './style.css';

import { formatAttachments, convertToFormData } from '../../utils'
import { iconSetArray } from '../../config/constants';

const AttachmentDisplay = ({ img, index, handleRemoveAttachment, fileType }) => {
    let supportMediaType = ["jpeg", "jpg", "png", "gif"];

    return (
        <Grid className='taskNotesLinks CAttachBox' item xs={12} sm={4} key={index} spacing={8}>
            <div className='CAttachmentInner'>
                {
                    supportMediaType.includes(fileType) ?
                        <img
                            alt={"preview"}
                            src={img.previewFile}
                            className={"CAttachImg"}
                        /> :
                        iconSetArray.includes(fileType) ?
                            <a href={img.previewFile}>
                                <img className='docImage' src={`./icons/${fileType}.svg`} alt={fileType} />
                            </a> :
                            <a href={img.previewFile}>
                                <DescriptionIcon className='docImage' />
                            </a>
                }

                {handleRemoveAttachment && <IconButton
                    className="CCloseIcon"
                    onClick={() => handleRemoveAttachment()}
                >
                    <CloseIcon />
                </IconButton>}
                {<IconButton
                    className="CDownloadIcon"
                    onClick={() => {
                        window.open(img.previewFile)
                    }}
                >
                    <DownloadIcon />
                </IconButton>}
            </div>
        </Grid>
    )
}

export function CommentBox(props) {
    const { classes, label, comment, attachmentExt, onSend, onError,
        comment_id, data, noaccess } = props
    const [noteMsg, setNoteMsg] = useState("");
    const [newAttachments, setNewAttachments] = useState([])
    const [edit, setEdit] = useState(comment_id ? false : true)

    const [fileType, setFileType] = useState();

    const attachments = formatAttachments(data.attachments, 2) || []
    const hasAttachments = newAttachments.length > 0 || attachments.length > 0
    const canCommentSent = hasAttachments || noteMsg != ""

    useEffect(() => {
        setNoteMsg(comment)
    }, [comment])

    const onChange = (e) => {
        e.preventDefault();
        const files = e.target.files;
        let invalidCount, maxSizeCount = 0;
        let acceptedFiles = [];

        if (files && files.length > 0) {
            for (var i = 0; i < files.length; i++) {
                let file = files[i];
                console.log(file,"file")
                if (!file.type.includes('video') && !file.type.includes('image') && !file.type.includes('application')) { invalidCount++ }
                else {
                    file["previewFile"] = URL.createObjectURL(file);
                    acceptedFiles.push(file);
                }

                //Doctype
                let fileExt = file.name.split('.').pop();
                setFileType(fileExt);
            }

            if (acceptedFiles.length > 0) {
                setNewAttachments([...newAttachments, ...acceptedFiles])
                handleSubmit(acceptedFiles)
            }
        }
    }

    const handleRemoveAttachment = (index) => {
        const newFiles = newAttachments.filter((file, ind) => ind !== index);
        setNewAttachments(newFiles);
    }

    const handleSubmit = (newFiles = null) => {
        const formData = convertToFormData({
            comment: noteMsg,
            attachments: attachments,
            attachmentsNew: newFiles ? newFiles : newAttachments,
        })
        onSend(formData, comment_id)
        if (!comment_id) {
            setNoteMsg("")
            
        } else {
            setEdit(false)
        }

        setNewAttachments([])
    }

    return (
        <Grid className='mt-1'>
            {
                label && (
                    <Typography variant="h6" className={"pb5"}>
                        {label}
                    </Typography>
                )
            }
            <Grid container>
                <Grid item xs={12} className={"commentsInput"} style={{ display: 'flex' }}>
                    <Avatar
                        alt={data.user_name}
                        src={data && data.hasOwnProperty("avatar_filename") && data.avatar_filename ? data.avatar_filename : "/broken-image.jpg"}
                    />
                    <TextField
                        id={`job_status`}
                        name={`job_status`}
                        margin="none"
                        className="pl-2 w-100 test"
                        rowsMin={4}
                        multiline
                        placeholder={'Type comments here....'}
                        value={noteMsg}
                        disabled={noaccess || !edit}
                        onChange={(e) => setNoteMsg(e.target.value)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                {
                                    edit && (
                                        <IconButton variant="contained" component="label">
                                            <AttachFileIcon style={{ fill: '#7e84a3' }} />
                                            <input
                                                type="file"
                                                accept={attachmentExt}
                                                id="notesAttachment"
                                                name="notesAttachments[]"
                                                onChange={onChange}
                                                style={{ display: 'none' }}
                                                multiple
                                            />
                                        </IconButton>
                                    )
                                }
                                {
                                    edit && (
                                        <IconButton
                                            onClick={handleSubmit}
                                            disable={true}
                                            disabled={!canCommentSent}
                                        >
                                            <SendIcon style={noteMsg === "" ? { fill: '#d3d3d3' } : { fill: '#7e84a3' }} />
                                        </IconButton>
                                    )
                                }
                                {
                                    !edit && noaccess === false && (
                                        <IconButton
                                            onClick={() => {
                                                setEdit(true)
                                            }}
                                            disable={true}
                                        >
                                            <EditIcon style={{ fill: '#7e84a3' }} />
                                        </IconButton>
                                    )
                                }
                            </InputAdornment>
                        }}
                    />
                </Grid>
                <Grid item xs={12} className="mt-2" style={{ paddingLeft: '60px' }}>
                    <Grid container spacing={3}>
                        {
                            newAttachments.map((img, index) => {
                                return (
                                    <AttachmentDisplay
                                        fileType={img?.name ? img?.name.split('.').pop() : ""}
                                        key={index}
                                        classes={classes}
                                        img={img}
                                        index={index}
                                        handleRemoveAttachment={() => {
                                            handleRemoveAttachment(index)
                                        }}
                                    />
                                )
                            })
                        }
                        {
                            attachments.map((img, index) => {
                                return (
                                    <AttachmentDisplay
                                        fileType={img.url.split('.').pop()}
                                        key={index}
                                        classes={classes}
                                        img={{
                                            previewFile: img.url
                                        }}
                                        index={index}
                                    />
                                )
                            })
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

CommentBox.defaultProps = {
    comment: "",
    attachmentExt: "image/*,video/*,.pdf,.csv,.doc,.docx,.xls,.xlsx",
    comments: [],
    onSend: () => { },
    onError: () => { }
}
const styles = (theme) => ({
    searchBoxGrid: {
      width: '100%',
      "& .searchBox": {
        transition: "0.5s",
        width: '100%',
        '& .MuiOutlinedInput-adornedEnd': {
          paddingRight: '8px'
        }
      },
      "& .MuiOutlinedInput-root": {
        height: "40px",
        minHeight: "32px",
      },
    },
    padding6: {
      padding: 6
    }
  });
  export default styles;
  
// Import API Helper
import { postRequest, getRequest ,putRequest,deleteRequest} from "../../utils/apiHelper";

export const invoiceService = {
  getUserConnectStatus,
  stripeInvoiceActivation,
  getInvoiceInfo,
  getInvoiceData,
  getInvoiceClients,
  getInvoiceSettings,
  getSettings,
  updateSettings,
  saveInvoice,
  getInvoiceSummary,
  getArchiveInvoiceSummary,
  getPaymentChart,
  sendInvoiceReminder,
  getSubscriptions,
  handleStripeDisconnect,
  deleteInvoice,
  getInvoiceErrors,
  closeInvoiceErrors,
  getSubscriptionLogs,
  updateInvoiceStatus,
  createInvoiceFromPublic,
  getPublicInvoiceSummary,
  getPublicInvoiceData,
  getFilters,
  getInvoiceTimeline,
  createNewClient,
  updateClientCustomer,
  deleteClientCustomer,
  getInvoiceAllReports,
  getInvoiceReports,
  getPaymentsReports,
  getInvoiceNewNotification,
  refundSingleInvoice,
  getPaymentsReports
};

//get user stripe connect status
async function getUserConnectStatus() {
  const response = await getRequest("invoice/getConnectStatus");
  return response;
}

//stripe connect account success process
async function stripeInvoiceActivation(params) {
  const response = await postRequest("invoice/stripeAccountActivation", params);
  return response;
}

//stripe  disconnect account
async function handleStripeDisconnect() {
  const response = await postRequest("invoice/stripeDisconnect");
  return response;
}

//get invoice clients list
async function getInvoiceClients(params) {
  const response = await postRequest("invoice/getInvoiceClients", params);
  return response;
}

//get invoice settings
async function getInvoiceSettings(params) {
  const response = await getRequest("invoice/settings", params);
  return response;
}

//get invoice info
async function getInvoiceInfo(params) {
  const response = await getRequest("invoice/getInfo");
  return response;
}

//get invoice data
async function getInvoiceData(params) {
  const response = await postRequest("invoice/getInvoice", params);
  return response;
}

//get invoice settings
async function getSettings(params) {
  const response = await postRequest("invoice/settingsGet", params);
  return response;
}

//get invoice settings
async function updateSettings(params) {
  const response = await postRequest("invoice/settingsUpdate", params);
  return response;
}

//get invoice settings
async function saveInvoice(params) {
  const response = await postRequest("invoice/create", params);
  return response;
}

//get invoice settings
async function sendInvoiceReminder(params) {
  const response = await postRequest("invoice/reminder", params);
  return response;
}

//get invoice settings
async function getInvoiceSummary(params) {
  const response = await postRequest("invoice/summary", params);
  return response;
}

// getArchiveInvoiceSummary
async function getArchiveInvoiceSummary(params) {
  const response = await postRequest("invoice/archive", params);
  return response;
}

//get invoice settings
async function getPaymentChart(params) {
  const response = await postRequest("invoice/paymentChart", params);
  return response;
}

//get all subscriptions
async function getSubscriptions(params) {
  const response = await postRequest("invoice/subscriptions", params);
  return response;
}

//deleteInvoice
async function deleteInvoice(params) {
  const response = await postRequest("invoice/del", params);
  return response;
}

async function getInvoiceErrors(params) {
  const response = await postRequest("invoice/getErrors", params);
  return response;
}

async function closeInvoiceErrors(params) {
  const response = await postRequest("invoice/closeErrors", params);
  return response;
}

async function getSubscriptionLogs(params) {
  const response = await getRequest(`invoice/subscriptionLogs/${params.id}`);
  return response;
}

async function updateInvoiceStatus(params) {
  const response = await postRequest(`invoice/updateInvoiceStatus`, params);
  return response;
}


async function getInvoiceReports(params) {

    const response = await postRequest("invoice/reports", params);
    return response;
}
async function getInvoiceAllReports(params) {
 
    const response = await postRequest("invoice/allReports", params);
    return response;
}
// create invoice from public

async function createInvoiceFromPublic(params) {
  const response = await postRequest(`invoice/createInvoiceFromPublic`, params);
  return response;
}

// get public invoice summary
async function getPublicInvoiceSummary(params) {
  const response = await postRequest(`invoice/publicSummary`, params);
  return response;
}

// get public invoice data
async function getPublicInvoiceData(params) {
  const response = await postRequest(`invoice/getPublicInvoice`, params);
  return response;
}

//get filters
async function getFilters() {
  const response = await getRequest(`invoice/summary/filter`);
  return response;
}

// get invoice timeline
async function getInvoiceTimeline(params) {
  const response = await getRequest(
    `invoice/getInvoiceTimeline/${params.invoice_id}`
  );
  return response;
}

// create new client 

async function createNewClient(params){
  const response = await postRequest("invoice/createClient", params); 
  return response
}

// updating the client customer in both stripe(name) and db(name and others)
async function updateClientCustomer(params){
  const response = await putRequest("invoice/updateClient",params)
  return response
}

// deleting the client customer in both stripe and db

async function deleteClientCustomer(params){
  const response = await deleteRequest("invoice/deleteClient",params)
  return response
}

async function getPaymentsReports(params) {
  const response = await postRequest("invoice/paymentReports", params);
  return response;
}

// invoice notification 

async function getInvoiceNewNotification(params){
  const response = await postRequest("invoice/getNewInvoiceNotifications", params)
  return response
}

// refund singleInvoice

async function refundSingleInvoice(params){
  const response = await postRequest("invoice/refundInvoice",params)
  return response
}

import io from 'socket.io-client';

// Import Environment Config
import { _api } from "../../config/environment";
import { getToken } from "../../utils/appHelper";
// Flag to track connection status to fix user status issue fix
let isConnected = false;

let socketv2;
const { socketUrl } = _api;

export const initiateSocket = (userData, onEvent) => {
    if (!isConnected) {
        socketv2 = io.connect(socketUrl, {
            transports: ['websocket'],
            auth: {
                token: `Bearer ${getToken()}`,
            },
            reconnection: true,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 5000,
            reconnectionAttempts: Infinity,
        });

        socketv2.on("connect", () => {
            isConnected = true;
            if (socketv2 && userData) {
                socketv2.emit('join', userData);
                getUserOnlineStatus({
                    isUserOnline: userData?.user_id,
                    userId: userData?.user_id
                })
            }
            console.log("connected")
        });

        socketv2.on('chat:notify', (msgData) => {
            onEvent(msgData?.emitType || 'chat:notify', msgData)
        })

        socketv2.on('call:notify', (msgData) => {
            onEvent(msgData?.emitType || 'call:notify', msgData)
        })

        socketv2.on('call:screen_share', (msgData) => {
            onEvent(msgData?.emitType || 'call:screen_share', msgData)
        })

        socketv2.on('call:stop_share', (msgData) => {
            onEvent(msgData?.emitType || 'call:stop_share', msgData)
        })


        socketv2.on('call:user_reject', (msgData) => {
            onEvent(msgData?.emitType || 'call:user_reject', msgData)
        })

        socketv2.on('call:notify_user', (msgData) => {
            onEvent(msgData?.emitType || 'call:notify_user', msgData)
        })

        socketv2.on('call:screen_share', (msgData) => {
            onEvent(msgData?.emitType || 'call:screen_share', msgData)
        })

        socketv2.on('call:reject_call', (msgData) => {
            onEvent(msgData?.emitType || 'call:reject_call', msgData)
        })

        socketv2.on('call:callEnded', (msgData) => {
            onEvent(msgData?.emitType || 'call:callEnded', msgData)
        })


        socketv2.on("disconnect", (reason) => {
            isConnected = false;
            console.log('disconnect', reason)
        });

        socketv2.on('error', function (err) {
            isConnected = false;
            console.error('socket:err', err)
        });
        socketv2.on('events:notify', (msgData) => {
            onEvent(msgData?.emitType || 'events:notify', msgData)
        })
        socketv2.on('tasks:notify', (msgData) => {
            onEvent(msgData?.emitType || 'tasks:notify', msgData)
        })

        socketv2.on("invoice:statusUpdate", (data) => {
            onEvent("invoice:statusUpdate", data)
        })

        socketv2.on("meeting:update_channel", (data) => {
            onEvent("meeting:update_channel", data)
        })

        socketv2.on('invoice:notify', (msgData) => {
            onEvent(msgData?.emitType || 'invoice:notify', msgData)
        })
    }
}

export const initiatePublicSocket = (onPublicEvent) => {
    socketv2 = io.connect(socketUrl, {
        transports: ['websocket'],
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: Infinity,
    });

    socketv2.on('call:notify_user', (msgData) => {
        onPublicEvent(msgData?.emitType || 'call:notify_user', msgData)
    })

    socketv2.on('call:screen_share', (msgData) => {
        onPublicEvent(msgData?.emitType || 'call:screen_share', msgData)
    })

    socketv2.on('call:reject_call', (msgData) => {
        onPublicEvent(msgData?.emitType || 'call:reject_call', msgData)
    })

    socketv2.on('call:callEnded', (msgData) => {
        onPublicEvent(msgData?.emitType || 'call:callEnded', msgData)
    })

    socketv2.on("meeting:update_channel", (data) => {
        onPublicEvent("meeting:update_channel", data)
    })

    socketv2.on("disconnect", (reason) => {
        console.log('disconnect', reason)
    });

    socketv2.on('error', function (err) {
        console.error('socket:err', err)
    });

}

const heartbeatInterval = 60000
setInterval(() => {
    if (socketv2) {
    socketv2.emit('heartbeat', { timestamp: Date.now() });
    }
}, heartbeatInterval);

export const sendMessage = (data) => {
    if (socketv2) {
        socketv2.emit('chat:message', data)
    }
}

export const getUserOnlineStatus = (data) => {
    if (socketv2) {
        socketv2.emit('chat:isUserOnline', data)
    }
}

export const updateVisibilityStatus = (data) => {
    if (socketv2) {
        socketv2.emit('chat:isUserVisible', data)
    }
}

export const setUserTypingStatus = (data) => {
    if (socketv2) {
        socketv2.emit('chat:user_typing', data)
    }
}

// tasks
export const joinRoom = (roomName, cb) => {
    if (socketv2) {
        socketv2.emit('task:joinRoom', { roomName }, cb)
    }
}



export const sendNewTaskNotification = (userData, cb) => {
    if (socketv2) {
        socketv2.emit('newTask', { ...userData }, msg => { return cb(msg); });
    }
}

export const subscribeToNewTasks = (cb) => {
    if (!socketv2) return (true);

    socketv2.on('newTask', data => {
        return cb(null, data);
    });
}

export const disconnectSocketv2 = () => {
    if (socketv2) {
        socketv2.disconnect()
    }
}

export const changeAgency = (userData) => {
    if (socketv2) {
        socketv2.emit('chat:change_profile', { ...userData });
    }
}
export const joinVideoCall = (data) => {
    if (socketv2) {
        socketv2.emit("video:user_join", data);
    }
};

export const shareScreen = (data) => {
    if (socketv2) {
        socketv2.emit("video:screen_share", data)
    }
}

export const stopScreenShare = (data) => {
    if (socketv2) {
        socketv2.emit("video:stop_share", data)
    }
}

export const rejectVideoCall = (data) => {
    if (socketv2) {
        socketv2.emit("video:user_reject", data);
    }
}


export const notifyUsers = (data) => {
    if (socketv2) {
        socketv2.emit("video:notify_user", data)
    }
}

export const rejectCallReq = (data) => {
    if (socketv2) {
        socketv2.emit("video:reject_call", data)
    }
}
export const callEnded = (data) => {
    if (socketv2) {
        socketv2.emit("video:callEnded", data)
    }
}


export const sendEventData = (data) => {
    if (socketv2) {
        socketv2.emit('event:create', data)
    }
}
export const sendTaskData = (data) => {
    if (socketv2) {
        socketv2.emit('tasks:create', data)
    }
}

import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core'
import { useDispatch, useSelector } from "react-redux";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Menu, MenuItem } from '@material-ui/core';

//import components
import { Button } from '../../../components'

//import history
import history from "../../../config/history"

//import action
import { alertAction, userAgencyAction, authAction } from '../../../redux/actions';


function UserErrorPopUp(props) {
    //define dispatch
    const dispatch = useDispatch();

    const { userAgencies } = useSelector(s => (s.userAgency));
    const { agency_pref } = useSelector(s => (s.auth.loginUser));

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    //update agencies
    const updateUserAgency = (id) => {
        if (agency_pref !== id) {
            dispatch(userAgencyAction.updateUserAgencies({ agency_id: id }, (res) => {
                setAnchorEl(null);
                if (res && res.message === "Updated Successfully") {
                    history.push("/dashboard")
                    window.location.reload();
                }
            }))
        } else {
            dispatch(alertAction.error("This agency is currently selected "));
            setAnchorEl(null);
        }
    }

    //logout application
    const logout = () => {
        localStorage.removeItem("staySignInAG");
        dispatch(authAction.logout());
    }


    return (
        <>
            <Grid style={{ padding: '35px' }}>
                <Typography variant='h3' align="center" className='fw-600 pb15'>
                    {"Unable to access the workspace"}
                </Typography>
                <DialogContentText id="alert-dialog-description" align="center" style={{ fontSize: '17px', lineHeight: "40px"}}>
                <Typography variant='body'>
                    Hey! Unfortunately, we were unable to access your workspace since it has been paused. Please contact your Agency for further details.
                </Typography>
                </DialogContentText>
                <DialogActions style={{ justifyContent: 'center' }}>
                    {
                        userAgencies && userAgencies.length > 1 ?
                            <>
                                <Button
                                    id="demo-positioned-button"
                                    aria-controls={open ? 'demo-positioned-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                    size='large'
                                    color={"primary"}
                                    style={{marginBottom:'30px'}}
                                >
                                    Switch to another workspace
                                </Button>
                                <Menu
                                    id="long-menu"
                                    MenuListProps={{
                                        'aria-labelledby': 'long-button',
                                    }}
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    PaperProps={{
                                        style: {
                                            maxHeight: 200,
                                            width: '20ch',
                                        },
                                    }}
                                >
                                    {userAgencies.map((item, key) => {
                                        if (item.agencyID !== agency_pref) {
                                            return (<MenuItem key={key} onClick={() => { updateUserAgency(item.agencyID) }}>
                                                {item.agency_name ? item.agency_name : ""}
                                            </MenuItem>)
                                        }
                                    })}
                                </Menu>
                            </>
                            : null
                    }

                    <Button variant="contained" color={"secondary"} title={'Logout'} size='large' onClick={() => logout()}></Button>

                </DialogActions>
            </Grid>
        </>
    )
};

// default props
UserErrorPopUp.defaultProps = {
    classes: {}
};

// prop types
UserErrorPopUp.propTypes = {
    classes: PropTypes.object
};

export default UserErrorPopUp;

import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import './index.css';
import './typography.css';

// import component
import SomethingWentWrong from './containers/ErrorPage/WentWrong';

import ErrorBoundary from './ErrorBoundary';
import App from './containers/App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { ThemeContextProvider } from './assets/theme/ThemeContextProvider';

import history from './config/history';

// Import Date Pickers Library
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { registerServiceWorker } from "./serviceWorker";

// Define Redux Store
import configureStore from "./redux/store";
export const store = configureStore();

// const helmetContext = {};

ReactDOM.render(
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <HelmetProvider>
      <Provider store={store}>
        <HashRouter history={history}>
          <React.StrictMode>
            <ErrorBoundary fallback={<SomethingWentWrong />}>
              <ThemeContextProvider>
                <App />
              </ThemeContextProvider>
            </ErrorBoundary>
          </React.StrictMode>
        </HashRouter>
      </Provider>
    </HelmetProvider>
  </MuiPickersUtilsProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: 
reportWebVitals();
registerServiceWorker();

import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { EditorState, convertFromRaw, ContentState } from 'draft-js';
import { createEditorStateWithText } from '@draft-js-plugins/editor';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin from '@draft-js-plugins/mention';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import createHashtagPlugin from '@draft-js-plugins/hashtag';
import { withStyles } from "@material-ui/core";
import className from 'classnames';

//Import Styles
import styles from "./styles";

//import component 
import TinyUrlMessage from "./TinyUrlMessage";

const TextMessage = (props) => {
    const { classes, message, handleHashTagClick, handleMentionClick, msgComponentType, handleUndo, undo, userId } = props;
    const editor = useRef(null);

    useEffect(() => {
        if (message?.message_raw_text && message?.is_edited) {
            try {
                if (message.is_infiniti_res === 1) {
                    notesContent = ContentState.createFromText(`${message?.message_text} - ${message?.infiniti_response}`)
                    // notesContent = convertFromRaw(infiniti_content)
                } else {
                    notesContent = message.message_raw_text ? convertFromRaw(JSON.parse(message.message_raw_text)) : ""
                }
                const initialState = notesContent ? EditorState.createWithContent(notesContent) : EditorState.createEmpty();
                setEditorState(initialState)
            } catch (err) {
                setEditorState(EditorState.createEmpty())
            }
        }
    }, [message?.message_raw_text])

    let notesContent = "";
    try {
        if (message.is_infiniti_res === 1) {
            notesContent = ContentState.createFromText(`${message?.message_text} - ${message?.infiniti_response}`)

            // notesContent = convertFromRaw(infiniti_content)
        } else {
            notesContent = message.message_raw_text ? convertFromRaw(JSON.parse(message.message_raw_text)) : ""
        }
    } catch (err) {
        notesContent = ""
    }
    const initialState = notesContent ? EditorState.createWithContent(notesContent) : EditorState.createEmpty();
    // const chatInitialText = createEditorStateWithText(message.message_text ? message.message_text : "");
    const [editorState, setEditorState] = useState(initialState);
    const messageLinks = message.message_links ? JSON.parse(message.message_links) : null;

    const urlData = messageLinks ? messageLinks.urlData[0] : null;
    const messageUrl = messageLinks ? messageLinks.url[0] : null;

    const onChange = (editorState) => {
        setEditorState(editorState);
    };

    const mentionPlugin = createMentionPlugin({
        classes: classes,
        mentionComponent: (mentionProps) => {
            let { mention, decoratedText } = mentionProps
            return (
                <a style={{ fontSize: "13px", fontWeight: '500', color: msgComponentType === 'sent' ? '#fff62d' : '#2563EB' }} className={`${className(classes.messageMention, msgComponentType)} messageMention`} onClick={() => handleMentionClick(mention)}> {decoratedText}</a >
            )
        }
    });

    const linkifyPlugin = createLinkifyPlugin({
        target: '_blank',
        classes: classes,
        component: (props) => {
            return <a  {...props} className={`${className(classes.messageLink, msgComponentType)} messageLink`} onClick={() => window.open(props.href, '_blank')} />
        },
    });

    const hashtagPlugin = createHashtagPlugin({
        classes: classes,
        hashtagComponent: (props) => {
            let { decoratedText } = props;
            return <a className={className(classes.messageHashTag, msgComponentType)} onClick={() => handleHashTagClick(decoratedText)} >{decoratedText}</a>
        },
    });

    const styleMap = {
        CODE: {
            backgroundColor: '#111',
            color: '#fff',
            fontSize: '1.5rem',
            padding: '2px',
        },
    };

    return (
        <>
            {
                messageLinks && messageUrl && urlData && messageUrl.schema === "https:" ?
                    <TinyUrlMessage classes={classes} urlData={urlData} messageUrl={messageUrl} /> : null
            }
            <div className={`${message.star && userId == message.star_user_id ? classes.textMsgBubbleStar : classes.textMsgBubble} ${messageLinks ? 'mb-1' : ''} disable-user-select`}>
                {!undo ? <Editor
                    customStyleMap={styleMap}
                    editorState={editorState}
                    onChange={onChange}
                    plugins={[linkifyPlugin, mentionPlugin, hashtagPlugin]}
                    ref={editor}
                    readOnly={true}
                /> :
                    <span>This message has been deleted
                        <a style={{ paddingLeft: "10px", color: "blue", fontWeight: "bold" }} className={"cursorPointer"} onClick={() => handleUndo(message.id)}>undo</a>
                    </span>
                }
            </div>
        </>

    )

}


// Default props
TextMessage.defaultProps = {
    classes: {},
    message: null,
    undo: false,
    handleHashTagClick: () => { },
    handleMentionClick: () => { }
};

// Prop types
TextMessage.propTypes = {
    classes: PropTypes.object,
    message: PropTypes.object,
    undo: PropTypes.bool,
    handleHashTagClick: PropTypes.func,
    handleMentionClick: PropTypes.func,
};

export default withStyles(styles)(TextMessage)

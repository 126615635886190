import { postRequest, putRequest, getRequest } from "../../utils";


async function saveCallHistory(body){
    const response = await postRequest(`videocall/save`, body)
    return response
}

async function getHistory(body){
    const response = await postRequest( `videocall/list`, body)
    return response
}


async function updateHistory(id, body){
    const response = await putRequest(`videocall/update/${id}`, body)
    return response
}

async function callInitalize(id, body){
    const response = await putRequest(`videocall/call/${id}`, body)
    console.log("🚀 ~ deleteHistory ~ id:", id)
    return response
}

async function callEnded(id, body){
    const response = await putRequest(`videocall/callEnd/${id}`, body)
    console.log("🚀 ~ deleteHistory ~ id:", id)
    return response
}

async function createFlozyRoom(body){
    const response = await postRequest(`videocall/create`, body)
    return response
}

async function deleteFlozyRoom(body){
    const response = await putRequest(`videocall/delete`, body)
    return response
}


export const videoCallService = {
    saveCallHistory,
    getHistory,
    updateHistory,
    callInitalize,
    callEnded,
    createFlozyRoom,
    deleteFlozyRoom,
}
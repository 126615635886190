const styles = (theme) => ({
    roots: {
      height: 36,
      '& .MuiOutlinedInput-root': {
        height: 36,
        minHeight: 36
      }
    },
    chromePicker: {
      '& .chrome-picker': {
        background: `${theme.palette.containers.card} !important`,
        '& input': {
          background: `${theme.palette.containers.bg1} !important`,
          color: `${theme.palette.primary.main} !important`,
          border: `1px solid ${theme.palette.primary.border1}`,
          outline: `none`
        }
      }
    }
  });
  
  export default styles;
  
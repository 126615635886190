import moment from "moment";
import { browserName, isMobile as isMobileDevice } from 'react-device-detect';
import { TIME_OPTIONS, TIME_OPTIONS_EVENT } from '../config/constants';
import { _api } from "../config/environment";
import { _envParams } from "../config/env_params";
const jwt = require('jwt-simple');
/**
 * Get Token
 */
export const getToken = () => {
  const token = localStorage.getItem("key");
  if (token) {
    return token;
  }
  return null;
};

/**
 * Set Token
 * @param data
 */
export const setToken = (data) => {
  localStorage.setItem("key", data);
};

/**
 * Remove Token
 */
export const removeToken = () => {
  localStorage.removeItem("key");
  localStorage.removeItem("staySignedIn");
  localStorage.removeItem("preferences");
};

export const setLocalStorage = (key, data) => {
  try {
    localStorage.setItem(key, data);
  } catch (err) {
    console.error(err);
  }
};

export const getLocalStorage = (key) => {
  try {
    const token = localStorage.getItem(key);
    return token ? token : null;
  } catch (err) {
    return null;
  }
};

export const removeLocalStorage = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (err) {
    console.error(err);
  }
};

export const addEllipsis = (input, charCount = 10) => {
  if (input.length > charCount) {
    return input.substring(0, charCount) + "...";
  }
  return input;
};

export const capitalizeFirst = (input) => {
  if (input) {
    return input.charAt(0).toUpperCase() + input.slice(1);
  } else {
    return input;
  }
};

export const isEmpty = (input) => {
  if (input) {
    return input?.trim()?.length === 0
  } else {
    return true;
  }
};

export const isEmail = (input) => {
  if (input) {
    const regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const result = regex.test(String(input).toLowerCase());
    return result ? true : false;
  } else {
    return false;
  }
};

export const isEmptyHTML = (html) => {
  try {
    if (!html || !html.replace(/(<([^>]+)>)/gi, "")) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    return true;
  }
};

// Email Validation
export const IsEmail = function (email) {
  if (email) {
    const regex =
      /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
  } else {
    return false;
  }
};

//generate logo from name
export const nameLogoGeneration = (name) => {
  try {
    let charString = name.replace(/ *\([^)]*\) */g, "");
    charString = charString.replace(/ *\"[^)]*\" */g, "");
    charString = charString.replace(/ *\'[^)]*\' */g, "");
    charString = charString.replace(/[0-9]/g, "");
    charString = charString.replace(/  +/g, " ");
    charString = charString.replace(/[^a-zA-Z ]/g, "");
    let charArr = charString.split(" ");

    let returnStr = ``;
    if (charArr.length > 0) {
      if (charArr[0] === "") {
        if (charArr[1] && charArr[1].charAt(0)) {
          returnStr = `${charArr[1].charAt(0)}`;
        }
        if (charArr[2] && charArr[2].charAt(0)) {
          if (
            charArr[2].charAt(0) === "-" &&
            charArr[3] &&
            charArr[3].charAt(0) &&
            charArr[3].charAt(0) !== "-"
          ) {
            returnStr = `${returnStr}${charArr[3].charAt(0)}`;
          } else {
            returnStr = `${returnStr}${charArr[2].charAt(0)}`;
          }
        }
      } else {
        if (charArr[0] && charArr[0].charAt(0)) {
          returnStr = `${charArr[0].charAt(0)}`;
        }
        if (charArr[1] && charArr[1].charAt(0)) {
          if (
            charArr[1].charAt(0) === "-" &&
            charArr[2] &&
            charArr[2].charAt(0) &&
            charArr[2].charAt(0) !== "-"
          ) {
            returnStr = `${returnStr}${charArr[2].charAt(0)}`;
          } else {
            returnStr = `${returnStr}${charArr[1].charAt(0)}`;
          }
        }
      }
      return returnStr;
    } else {
      return "";
    }
  } catch (err) {
    return "";
  }
};

export const JSONparser = (str) => {
  try {
    let resultStr = JSON.parse(str);
    return resultStr;
  } catch (err) {
    return [];
  }
};

export const getAccept = (type) => {
  if (type) {
    if (type === "media" || type === "group") {
      return "image/*, video/*";
    } else if (type === "doc") {
      return "application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.csv,.xls,.xlsx,.doc,.docx";
    } else {
      return "image/*, video/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.csv,.xls,.xlsx,.doc,.docx";
    }
  } else {
    return "image/*, video/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.csv,.xls,.xlsx,.doc,.docx";
  }
};

export const NewlineText = (props) => {
  const text = props.text;
  const newText = text.split("\n").map((str, key) => <p key={key}>{str}</p>);
  return newText;
};

const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export const HighLightText = (props) => {
  const message = props.message;
  const replaceText = props.text;
  let strArr = message
    ? message.split(new RegExp(`(${escapeRegExp(replaceText)})`, "ig"))
    : [];
  return strArr.length > 0
    ? strArr.map((msg, i) => {
      if (msg.toLowerCase() === replaceText.toLowerCase()) {
        return (
          <span key={`match${i}`} className="search-match">
            {msg}
          </span>
        );
      } else {
        return msg;
      }
    })
    : null;
};

export const getOnlyTime = (date) => {
  const result = moment.utc(date).local().format("LT");
  return result;
};

export const getDate = (date) => {
  const result = moment.utc(date).local().format("ll");
  return result;
};

export const getDateTime = (date) => {
  const result = moment.utc(date).local().format("lll");
  return result;
};

export const displayDateFormat = (date) => {
  const result = moment.utc(new Date(date)).local().format("DD-MM-YYYY");
  return result;
};
export const displaySlashDateFormat = (date) => {
  const result = moment.utc(new Date(date)).local().format("DD/MM/YYYY");
  return result;
};
export const dbDateFormat = (date) => {
  const result = moment.utc(new Date(date)).local().format("YYYY-MM-DD");
  return result;
};

export const invoiceDateFormat = (date) => {
  const result = moment.utc(new Date(date)).local().format("Do MMM, YYYY");
  return result;
};

export const docsDateFormat = (date) => {
  const result = moment.utc(new Date(date)).local().format("DD MMM, YYYY");
  return result;
}

export const formatCurrency = (amount, currency) => {
  try {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  } catch (err) {
    return null;
  }
};

export const getCurrencySymbol = (currency) => {
  try {
    let currencyObj = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency,
    }).formatToParts(0);
    return currencyObj.find((ele) => ele.type === "currency").value;
  } catch (err) {
    return null;
  }
};

export const isPasswordCriteria = (value) => {
  if (value.length < 6) {
    return "password length should be greater than 6";
  } else if (value.length > 50) {
    return "password length should be less than 50";
  } else if (value.search(/[!@#$^&*()_]/) === -1) {
    return "password should contain atleast one special character";
  } else if (value.search(/[a-z]/) === -1) {
    return "password should contain one lower case";
  } else if (value.search(/\d/) === -1) {
    return "password should contain one number";
  } else if (value.search(/[A-Z]/) === -1) {
    return "password should contain one upper case";
  } else {
    return true;
  }
};

export const getScheduleDates = async (schedule, start_date, end_date) => {
  let invoiceDatesArr = [];
  let startDate = new Date(start_date);
  let endDate = new Date(end_date);

  //Create Daily Schedule Dates
  if (schedule == 1) {
    while (moment(startDate).isBefore(endDate)) {
      invoiceDatesArr.push(moment(startDate).format("YYYY-MM-DD"));
      startDate = moment(startDate).add(1, "day");
    }
  }
  //Create Weekly Schedule Dates
  else if (schedule == 2) {
    while (moment(startDate).isBefore(endDate)) {
      invoiceDatesArr.push(moment(startDate).format("YYYY-MM-DD"));
      startDate = moment(startDate).add(7, "days");
    }
  }
  //Create Quarterly Schedule Dates
  else if (schedule == 4) {
    while (moment(startDate).isBefore(endDate)) {
      invoiceDatesArr.push(moment(startDate).format("YYYY-MM-DD"));
      startDate = moment(startDate).add(3, "months");
    }
  }
  //Create Weekly Schedule Dates
  else if (schedule == 5) {
    while (moment(startDate).isBefore(endDate)) {
      invoiceDatesArr.push(moment(startDate).format("YYYY-MM-DD"));
      startDate = moment(startDate).add(1, "year");
    }
  }
  //Create Monthly Schedule Dates
  else {
    while (moment(startDate).isBefore(endDate)) {
      invoiceDatesArr.push(moment(startDate).format("YYYY-MM-DD"));
      startDate = moment(startDate).add(1, "month");
    }
  }

  return invoiceDatesArr;
};

export const simpleDecrypt = (token) => {
  return jwt.decode(token, "coimTNIndiabatore");
};

export const userDisplayName = (userData, notification = null) => {
  if (userData && userData.hasOwnProperty("id") && userData.id) {
    let { first_name, last_name, username, email } = userData;
    return first_name
      ? last_name
        ? `${first_name} ${last_name}`
        : first_name
      : username
        ? username
        : email?.split("@")[0];
  } else if (notification && notification.notification_type === "message" && notification.notification_to === notification.notification_from) {
    return "Get Started Here"
  } else {
    return "No Name";
  }
};

export const isJson = (item) => {
  item = typeof item !== "string" ? JSON.stringify(item) : item;
  try {
    item = JSON.parse(item);
  } catch (e) {
    return false;
  }
  if (typeof item === "object" && item !== null) {
    return true;
  }
  return false;
};

/* Set User Preferences
 * @param data
 */
export const setUserPreferences = (data) => {
  localStorage.setItem("preferences", JSON.stringify(data));
};

/* Get User Preferences
 * @param data
 */
export const getUserPreferences = () => {
  try {
    return JSON.parse(localStorage.getItem("preferences")) || {};
  } catch {
    return {};
  }
};

/* Get User board filter Preferences
 * @param data
 */
export const getUserBoardFilterPreferences = (board_id) => {
  try {
    const data = JSON.parse(localStorage.getItem("preferences")) || {};
    if (data["board_filter"] && data["board_filter"][board_id]) {
      return data["board_filter"][board_id] || [];
    } else {
      return [];
    }
  } catch {
    return {};
  }
};

/* Get User board filter Preferences
 * @param data
 */
export const setUserBoardFilterPreferences = (board_id, filter_data) => {
  try {
    const data = JSON.parse(localStorage.getItem("preferences")) || {};
    data["board_filter"] = data["board_filter"] || {};
    data["board_filter"][board_id] = filter_data || [];
    setUserPreferences(data);
  } catch {
    return {};
  }
};

export const validURL = (userInput) => {
  var res = userInput.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
  if (res == null) return false;
  else return true;
};

export const parseSearchQuery = (queryString) => {
  var query = {};
  var pairs = queryString && queryString.length > 0 ? (
    queryString[0] === "?" ? queryString.substr(1) : queryString
  ).split("&") : [];
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split("=");
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
  }
  return query;
};

export const formatNum = (num, digits) => {
  let units = ["k", "M", "G", "T", "P", "E", "Z", "Y"];
  let floor = Math.floor(Math.abs(num).toString().length / 3);
  let value = +(num / Math.pow(1000, floor));
  return value.toFixed(value > 1 ? digits : 2) + units[floor - 1];
};

/* Get User Docs filter Preferences
 * @param data
 */
export const setUserDocPreferences = (docData) => {
  try {
    const data = JSON.parse(localStorage.getItem("preferences")) || {};
    data["docs"] = data["docs"] || {};
    data["docs"] = docData;
    setUserPreferences(data);
  } catch (err) {
    return {};
  }
};

/* Get User Docs filter Preferences
 * @param data
 */
export const getUserDocPreferences = () => {
  try {
    const data = JSON.parse(localStorage.getItem("preferences")) || {};
    return data["docs"] || {};
  } catch {
    return {};
  }
};

export const getAvatar = (type, data, currentUser) => {
  if (type === "teams") {
    let avatar_value = data?.group_avatar;
    let avatar_type = "avatar";
    if (currentUser && data?.isGroup == 0) {
      avatar_value = data?.users?.filter((f) => f.id != currentUser)[0]
        ?.avatar_filename;
    }
    return {
      avatar_type,
      avatar_value,
    };
  } else {
    return {
      avatar_type: data?.avatar_type || "color",
      avatar_value: data?.avatar || null,
    };
  }
};

export const getChatv2Preferences = (is_client) => {
  try {
    const data = is_client ? "clients" : "projects" || {};
    return data || {};
  } catch {
    return {};
  }
};

export const setChatv2Preferences = (chatv2Data, replaceAll = false) => {
  try {
    const data = JSON.parse(localStorage.getItem("preferences")) || {};
    data["chatv2"] = data["chatv2"] || {};
    data["chatv2"] = !replaceAll
      ? {
        ...data["chatv2"],
        ...chatv2Data,
      }
      : chatv2Data;
    setUserPreferences(data);
  } catch (err) {
    return {};
  }
};

export const getDefaultChatRoomPreference = (is_client) => {
  const storeChatv2Data = getChatv2Preferences(is_client) || {};
  return { type: storeChatv2Data, id: null };
};

export const isMobile = ({ id, queryParams }) => {
  return isMobileDevice && (id || queryParams?.id);
};

export const chatGroupSort = (a, b) => {
  if (
    (a.message_time || a.job_created_time) &&
    (b.message_time || b.job_created_time)
  ) {
    return (
      new Date(b?.message_time || b?.job_created_time).getTime() -
      new Date(a?.message_time || a?.job_created_time).getTime()
    );
  } else {
    return -1;
  }
};

export const isEditorFormat = (type) => {
  const ACCEPTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
  return ACCEPTED_FORMATS.indexOf(type) > -1;
};

export const jsonifyFormData = (formData) => {
  const jsonData = {};
  formData.forEach((value, key) => (jsonData[key] = value));
  return jsonData;
};

export const getFileExtension = (fileName) => {
  return fileName?.split(".").pop();
};

export const getUploadPreviewFiles = (files_data) => {
  try {
    const images = ["jpeg", "png", "jpg"];
    return JSON.parse(files_data)?.map((m) => {
      const file_type = getFileExtension(m?.path) || "jpeg";
      m.type = images.indexOf(file_type) > -1 ? "image" : "doc";
      return m;
    });
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const isInternalLink = (link) => {
  const linkOrigin = new URL(link);
  if (linkOrigin?.origin === window.location.origin) {
    const repl = new RegExp(linkOrigin?.origin + "/#", "gi");
    const finalPath = link.replaceAll(repl, "");
    return finalPath;
  } else {
    return false;
  }
};

export function getDraftMessages() {
  const draftJson = localStorage.getItem("draft_messages");
  let draftMessages = {};
  if (draftJson) {
    try {
      draftMessages = JSON.parse(draftJson);
    } catch { }
  }
  return draftMessages;
}

export function setDraftMessage(id, message) {
  const draftMessages = getDraftMessages();
  draftMessages[id] = message;
  localStorage.setItem("draft_messages", JSON.stringify(draftMessages));
}

export function getDraftReply() {
  const draftJson = localStorage.getItem("draft_replay");
  let draftMessages = {}
  if (draftJson) {
    try {
      draftMessages = JSON.parse(draftJson)
    } catch { }
  }
  return draftMessages
}

export function setDraftReply(id, message) {
  const draftMessages = getDraftReply();
  draftMessages[id] = message;
  localStorage.setItem("draft_replay", JSON.stringify(draftMessages))
}

export function getLocalVideoInfo() {
  const info_video = localStorage.getItem("infoVideo");
  let infoVideo = {};
  if (info_video) {
    try {
      infoVideo = JSON.parse(info_video);
    } catch { }
  }
  return infoVideo;
}

export function setLocalInfoVideo(key, value) {
  const infoVideo = getLocalVideoInfo();
  infoVideo[key] = value;
  localStorage.setItem("infoVideo", JSON.stringify(infoVideo));
}

export function getWeekOfMonth(date) {
  date = new Date(date);
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const dayOfWeek = firstDay.getDay();
  const dayOfMonth = date.getDate();
  const week = Math.ceil((dayOfMonth + dayOfWeek) / 7);
  return week;
}

export function getDefaultEditorContent() {
  const content = [
    {
      type: "paragraph",
      children: [{ text: "" }],
    },
  ];

  return JSON.stringify(content);
}


export function downloadFile(url) {
  const link = document.createElement("a");
  link.href = url;
  link.target = "_blank"; // Open the link in a new tab/window (important for some browsers)
  link.style.display = "none"; // Hide the link element
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const domainValidator = (str) => {
  const regex = /^(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/
  return regex.test(str);
}

export const copyToClipboard = (text = '') => {
  try {
    navigator?.clipboard?.writeText(text);
  } catch (err) {
    console.log(err)
  }
}

/**
 * convert base64 file  to blob file
 */

export const base64toBlob = (canvas, filename) => {
  return new Promise((resolve, reject) => {
    try {
      canvas.toBlob((Blob) => {
        let file = new File([Blob], `${filename}.jpg`, { type: "image/jpeg" });
        Object.assign(file, {
          url: URL.createObjectURL(Blob),
        });
        resolve(file);
      }, "image/jpeg");
    } catch (err) {
      reject(err);
    }
  });
};

export function b64toBlob(dataURI) {
  const byteString = atob(dataURI.split(",")[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: "image/png" });
}

export function parseEditorString(docString) {
  try {
    if (docString) {
      const data = JSON.parse(docString);
      return data;
    } else {
      return [];
    }
  } catch (err) {
    if (typeof docString === 'string') {
      return [
        {
          type: 'paragraph',
          children: [{ text: docString || '' }]
        }
      ]
    }
    console.log(err);
    return [];
  }
}

export function convertBase64(file) {
  return new Promise((resolve, reject) => {
    if (file) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    } else {
      resolve('')
    }
  });
};

export const getTimeOptions = (start_time = null, event) => {
  start_time = start_time?.value || moment().format('hh:mm A')
  let options = event == 'event' ? TIME_OPTIONS_EVENT : TIME_OPTIONS;
  const curTimeIndex = options.findIndex(f => (f.value === start_time));
  const TIME_OPTIONS_1 = [...options]
  if (curTimeIndex > -1) {
    return [
      ...TIME_OPTIONS_1.splice(curTimeIndex, TIME_OPTIONS_1.length - curTimeIndex),
      ...TIME_OPTIONS_1,
    ]
  } else {
    return TIME_OPTIONS_1
  }
}

export const getCustomTimeOptions = (task = {}, isStart = true) => {
  const options = [...TIME_OPTIONS];
  if (isStart) {
    return options
  } else {
    const isSameDate = task?.due_date === task?.start_date;
    const curTimeIndex = options.findIndex(f => (isSameDate ? ((f.value === task?.start_time)) : true));
    return curTimeIndex > -1 ? [...options.splice(curTimeIndex, options.length - curTimeIndex)] : options
  }
}

export const defaultTaskDate = () => {
  return {
    start_date: null,//moment().format("YYYY-MM-DD"),
    due_date: null,//moment().format("YYYY-MM-DD"),
    start_time: null,//moment().format("hh:00 A"),
    end_time: null,//moment().add(1, "hour").format("hh:00 A"),
    timezone: moment.tz.guess()
  }
}

// convert image to object part instead of base64 for better performance
// https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL
export const importFileandPreview = (file, revoke) => {
  return new Promise((resolve, reject) => {
    window.URL = window.URL || window.webkitURL;
    let preview = window.URL.createObjectURL(file);
    // remove reference
    if (revoke) {
      window.URL.revokeObjectURL(preview);
    }
    setTimeout(() => {
      resolve(preview);
    }, 100);
  });
}


/**
*
* @param videoFile {FIle} // the video file
* @param numberOfThumbnails {number} //number of thumbnails you want to generate
* @returns {string[]} // an array of base64 thumbnails images
*
* @abstract
* Idea taken from - https://codepen.io/aertmann/pen/mrVaPx
* The original functionality of getVideoThumbnail() function is customized as per working code
* If it didn't work in future then replace it with about links working example
*/
export const generateVideoThumbnails = async (videoFile, numberOfThumbnails) => {

  let thumbnail = [];
  let fractions = [];
  return new Promise(async (resolve, reject) => {
    if (!videoFile.type?.includes("video")) {
      reject("not a valid video file");
    }
    await getVideoDuration(videoFile).then(async (duration) => {
      // divide the video timing into particular timestamps in respective to number of thumbnails
      // ex if time is 10 and numOfthumbnails is 4 then result will be -> 0, 2.5, 5, 7.5 ,10
      // we will use this timestamp to take snapshots
      for (let i = 0; i <= duration; i += duration / numberOfThumbnails) {
        fractions.push(Math.floor(i));
      }
      // the array of promises
      let promiseArray = fractions.map((time) => {
        return getVideoThumbnail(videoFile, time)
      })
      await Promise.all(promiseArray).then((res) => {
        res.forEach((res) => {
          thumbnail.push(res);
        });
        resolve(thumbnail);
      }).catch((err) => {
        console.error(err)
      }).finally((res) => {
        resolve(thumbnail);
      })
    }).catch(err => {
    })
    reject("something went wront");
  });
};

const getVideoThumbnail = (file, videoTimeInSeconds) => {
  return new Promise((resolve, reject) => {
    if (file.type.match("video")) {
      importFileandPreview(file).then((urlOfFIle) => {
        var video = document.createElement("video");
        var timeupdate = function () {
          if (snapImage()) {
            video.removeEventListener("timeupdate", timeupdate);
            video.pause();
          }
        };
        video.addEventListener("loadeddata", function () {
          if (snapImage()) {
            video.removeEventListener("timeupdate", timeupdate);
          }
        });
        var snapImage = function () {
          var canvas = document.createElement("canvas");
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height);
          var image = canvas.toDataURL();
          var success = image.length > 100000;
          if (success) {
            URL.revokeObjectURL(urlOfFIle);
            resolve(image);
          }
          return success;
        };
        video.addEventListener("timeupdate", timeupdate);
        video.preload = "metadata";
        video.src = urlOfFIle;
        // Load video in Safari / IE11
        video.muted = true;
        video.playsInline = true;
        video.currentTime = videoTimeInSeconds;
        video.play();
      });
    } else {
      reject("file not valid");
    }
  });
};

/**
*
* @param videoFile {File}
* @returns {number} the duration of video in seconds
*/
export const getVideoDuration = (videoFile) => {
  return new Promise((resolve, reject) => {
    if (videoFile) {
      if (videoFile.type.match("video")) {
        importFileandPreview(videoFile).then((url) => {
          let video = document.createElement("video");
          video.addEventListener('error', (ex) => {
            reject(`error when loading video file ${ex}`);
          });
          video.addEventListener("loadeddata", function () {
            resolve(video.duration);
          });
          video.preload = "metadata";
          video.src = url;
          // Load video in Safari / IE11
          video.muted = true;
          video.playsInline = true;
          video.play();
          //  window.URL.revokeObjectURL(url);
        }).catch()
      }
    } else {
      reject(0);
    }
  });
};

export function getMessageDateandTime(date, is_chatBody = null) {
  const currentDate = moment(new Date()).format("YYYY-MM-DD")
  const messageDate = moment(new Date(date)).format("YYYY-MM-DD")
  const diffDays = moment(currentDate).diff(moment(messageDate), 'days');
  let result = ''
  if (!diffDays && is_chatBody) {
    result = "Today"
  } else if (!diffDays && !is_chatBody) {
    result = moment(new Date(date)).format('h:mm a')
  } else if (diffDays == 1) {
    result = 'Yesterday'
  } else if (diffDays > 1 && diffDays < 8) {
    result = moment(new Date(date)).format('dddd') || moment(new Date(date)).format('dddd')
  } else {
    result = moment(new Date(date)).format("DD/MM/YYYY")
  }
  return result
}

export function getAppHost() {
  return window.location.origin;
}

export function getIframeHost() {
  try {
    const { protocol, host } = new URL(_api.pageBuilder)
    return `${protocol}${host}`
  } catch (err) {
    return "*"
  }
}

export const editorBoardHideTools = [
  "button",
  "signature",
  "carousel",
  "topbanner",
  "docsUpload",
  "form",
  "page-settings",
  "accordion",
  "app-header",
  "embedScript",
  "addTemplate"
];

export const editorBriefHideTools = [
  "app-header",
  "form",
  "signature",
  "embedScript"
];

export const editorBrainHideTools = [
  "app-header",
  "form",
  "embedScript"
];

export const editorFunnelHideTools = ["embedScript"];

export const isFlozyMobileApp = () => {
  return (_envParams?.REACT_APP_BUILD_FOR === 'MOBILE')
}

export const getAppEnvironment = () => {
  if (browserName === 'WebKit') {
    return 'IOS'
  } else if (browserName.includes('WebView')) {
    return 'ANDROID'
  }
  return 'WEB'
}
export const layoutList = [
  {
    label: 'Large',
    value: '0'
  },
  {
    label: 'Medium',
    value: '1'
  },
  {
    label: 'Small',
    value: '2'
  },
  {
    label: 'App',
    value: '3'
  }
]


export const sortList = [
  {
    label: 'Latest',
    value: '0'
  },
  {
    label: 'Old',
    value: '1'
  },
  {
    label: 'User Order Asc',
    value: '2'
  },
  {
    label: 'User Order Desc',
    value: '3'
  }
]

export const getSystemThemePref = () => {
  const isDark = window.matchMedia('(prefers-color-scheme:dark)').matches;
  return isDark ? 'dark' : 'light'
}

export const isSubdomain = (url = '') => {
  const regex = new RegExp(/^([a-z]+\:\/{2})?([\w-]+\.[\w-]+\.\w+)$/);
  if (url) {
    const subdomainStr = url?.split('.')[0]
    const w = url.match(regex)
    if (!w || subdomainStr?.toLocaleLowerCase() === 'www') {
      return false
    } else {
      return true
    }
  }
  return false
}

export const isNumber = (value) => {
  return typeof value === 'number' && isFinite(value);
}

export const getFormatedDomainName = (domain_name = "") => {
  try {
    let dn = domain_name?.split(".");
    if (dn.length === 2) {
      return `www.${domain_name}`;
    } else if (dn.length === 3) {
      return domain_name;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const getBrainDocsView = () => {
  try {
    const BrainDocsViewTypes = ['boxView', 'tileView', 'listView']
    let lastOne = `${localStorage.getItem('brainDocsView')}`
    if (!BrainDocsViewTypes.includes(lastOne)) {
      return 'boxView'
    } else {
      return lastOne
    }
  } catch(err) {
    return 'boxView'
  }
}

export const getFlowDocsView = () => {
  try {
    const FlowDocsViewTypes = ['boxView', 'tileView', 'listView']
    let lastOne = `${localStorage.getItem('flowDocsView')}`
    if (!FlowDocsViewTypes.includes(lastOne)) {
      return 'boxView'
    } else {
      return lastOne
    }
  } catch (err) {
    return 'boxView'
  }
}

export const getClientDocsView = () => {
  try {
    const ClientDocsViewTypes = ['boxView', 'tileView', 'listView']
    let lastOne = `${localStorage.getItem('clientDocsView')}`
    if (!ClientDocsViewTypes.includes(lastOne)) {
      return 'boxView'
    } else {
      return lastOne
    }
  } catch (err) {
    return 'boxView'
  }
}

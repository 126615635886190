const styles = (theme) => ({
  infoVideo: {
    position: 'fixed',
    right: '0px',
    zIndex: 9999,
    bottom: '0px',
    '& .infoVideoClose': {
      position: 'fixed',
      right: '10px',
      bottom: '10px',
      zIndex: 9999,
    },
    '& .infoVideoContainer': {
      position: 'fixed',
      right: '10px',
      bottom: '15px',
      display: 'flex',
      alignItems: 'flex-start',
    },
    '& .infoClose': {
      // right: '10px',
      // top: '10px',
      flexShrink: '0',
      width: '24px',
      height: '24px',
      '& svg': {
        width: '10px',
        height: '10px',
        marginLeft: '2px'
      }
    },
    '& .infoReSizeBtn': {
      flexShrink: '0',
      width: '24px',
      height: '24px',
      '& svg': {
        width: '10px',
        height: '10px',
        marginLeft: '2px'
      }
    },
    '& .infoVideo': {
      padding: '6px 8px',
      background: '#fff',
      borderRadius: '10px',
      width: '238px',
      height: '134px',
      boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.25)',
      position: 'relative',
      transition: 'width 0.5s, height 0.5s',
      '& iframe': {
        borderRadius: '10px'
      },
      '& .customPlayBtn': {
        width: 'calc(100% - 14px)',
        height: 'calc(100% - 12px)',
        position: 'absolute',
        left: '7px',
        top: '6px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '6px',
        backgroundSize: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        '& svg': {
          cursor: 'pointer'
        }
      }
    },
    '& .controlContainer': {
      display: 'flex',
      flexDirection: 'column'
    }
  },
  infoDialogVideo: {
    position: "relative",
    "& .MuiPaper-root": {
      background: "transparent",
      backgroundColor: "transparent",
      boxShadow: 'none',
    },
    '& .MuiDialogContent-root': {
      padding: '8px 30px',
    },
    '& .closeBtn': {
      padding: 0,
      position: "absolute",
      right: 0,
      top: "8px",
      borderRadius: '50%',
      "& button": {
        background: theme.palette.primary.main,
        opacity: "50%",
        width: "24px",
        height: "24px"
      },
      "& svg": {
        fill: theme.palette.primary.bgColor,
        width: "20px",
        height: "20px"
      }
    },
    '& .infoVideo': {
      padding: '4px',
      background: '#fff',
      borderRadius: '14px',
      width: '750px',
      height: '450px',
      boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.25)',
      position: 'relative',
      transition: 'width 0.5s, height 0.5s',
      '& iframe': {
        borderRadius: '10px'
      },
      '& .customPlayBtn': {
        width: 'calc(100% - 14px)',
        height: 'calc(100% - 12px)',
        position: 'absolute',
        left: '7px',
        top: '6px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '6px',
        backgroundSize: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        '& svg': {
          cursor: 'pointer'
        }
      }
    },
    '& .controlContainer': {
      display: 'flex',
      flexDirection: 'column'
    }
  }

})
export default styles;
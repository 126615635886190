import { videoCallConstants } from "../constants/videoCallConstant";

export const meetingAction = {
  updateChannelStatus
}

function updateChannelStatus(msgData) {
  return {
    type: videoCallConstants.UPDATE_CHANNEL_STATUS,
    payload: msgData,
  };
}
import { useEffect, useState } from 'react';
import { Keyboard } from '@capacitor/keyboard';
import { isMobile } from 'react-device-detect';

const useCapacitorKeyboard = () => {
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);

  useEffect(() => {
    if (isMobile) {
      let showListener = null;
      let hideListener = null;
      const handleKeyboardShow = () => {
        setIsKeyboardVisible(true);
      };

      const handleKeyboardHide = () => {
        setIsKeyboardVisible(false);
      };

      const loadKeyboardEvent = async () => {
        try {
          showListener = await Keyboard.addListener('keyboardDidShow', handleKeyboardShow);
          hideListener = await Keyboard.addListener('keyboardDidHide', handleKeyboardHide);
        } catch (error) {
          try {
            showListener = await window.addEventListener('keyboardDidShow', handleKeyboardShow);
            hideListener = await window.addEventListener('keyboardDidHide', handleKeyboardHide);
          } catch (error) {
            console.log("🚀 ~ loadKeyboardEvent ~ error:", error)
          }
        }
      }

      loadKeyboardEvent();

      return async () => {
        if (showListener) showListener?.remove();
        if (hideListener) hideListener?.remove();
      };
    }
  }, []);

  return isKeyboardVisible;
};

export default useCapacitorKeyboard;

// Import API Helper
import { postRequest, getRequest, deleteRequest, putRequest } from "../../utils/apiHelper";

export const boardsService = {
    createBoard,
    createBoardList,
    editBoardList,
    deleteBoardList,
    getBoards,
    getBoardDetail,
    createBoardTask,
    cloneBoardTask,
    getBoardTask,
    updateBoardTaskOrder,
    updateBoardListOrder,
    getBoardTaskDetail,
    updateBoardTaskDetail,
    getAssignedUsers,
    deleteBoardTask,
    getBoardsTaskTags,
    getBoardMembers,
    addBoardMembers,
    modifyBoardMembers,
    deleteBoardMembers,
    getBoardTaskHistory,
    getBoardTaskComments,
    addBoardTaskComments,
    updateBoardTaskComments,
    deleteBoardTaskComments,
    updateBoardSettings,
    deleteBoard,
    getPublicKey,
    getPublicBoardDetail,
    getPublicBoardTask,
    getPublicBoardsTaskTags,
    getPublicBoardTaskDetail,
    getBoardId,
    setIsPublic,
    exportBoard,
    uploadSheet,
    importBoard
};

// create new board
async function createBoard(params) {
    const response = await postRequest("boards/create", params);
    return response;
}

// create new board list
async function createBoardList(params) {
    const response = await postRequest("boards/list/create", params);
    return response;
}

// edit board list
async function editBoardList(list_id, params) {
    const response = await postRequest(`boards/list/${list_id}`, params);
    return response;
}

// delete board list
async function deleteBoardList(list_id, params) {
    const response = await deleteRequest(`boards/list/${list_id}`, params);
    return response;
}

// get boards name and id
async function getBoards(params) {
    const response = await postRequest("boards", params);
    return response;
}

// get board detail list and task
async function getBoardDetail(board_id, params) {
    const response = await postRequest(`boards/${board_id}`, params);
    return response;
}

async function getPublicBoardDetail(board_id, params) {
    const response = await postRequest(`boards/public/${board_id}`, params);
    return response;
}

// create new board list
async function createBoardTask(listId, params) {
    const response = await postRequest(`boards/task/create/${listId}`, params);
    return response;
}

// get board task
async function getBoardTask(listId, params) {
    const response = await postRequest(`boards/task/list/${listId}`, params);
    return response;
}

// update board task order accross list
async function updateBoardTaskOrder(boardId, params) {
    const response = await postRequest(`boards/task/order/${boardId}`, params);
    return response;
}

// update board list order
async function updateBoardListOrder(boardId, params) {
    const response = await postRequest(`boards/list/order/${boardId}`, params);
    return response;
}

// get board task detail
async function getBoardTaskDetail(taskId, params) {
    const response = await postRequest(`boards/task/detail/${taskId}`, params);
    return response;
}

// update board task detail
async function updateBoardTaskDetail(taskId, params) {
    const response = await postRequest(`boards/task/update/${taskId}`, params);
    return response;
}

// get assigned users name for a task to use in avatar
async function getAssignedUsers(params) {
    const response = await postRequest(`boards/task/users`, params);
    return response;
}

// delete board task
async function deleteBoardTask(listId, taskId) {
    const response = await deleteRequest(`boards/task/${listId}/${taskId}`);
    return response;
}

// boards task tags
async function getBoardsTaskTags(boardId) {
    const response = await getRequest(`boards/task/tags/${boardId}`);
    return response;
}

// get board memebers based on agency_id
async function getBoardMembers(board_id) {
    const response = await getRequest(`boards/${board_id}/members`);
    return response;
}

// add / delete / update board members
async function addBoardMembers(board_id, params) {
    const response = await postRequest(`boards/${board_id}/members`, params);
    return response;
}

// modify board members
async function modifyBoardMembers(board_id, params) {
    const response = await putRequest(`boards/${board_id}/members`, params);
    return response;
}

// delete board members
async function deleteBoardMembers(board_id, params) {
    const response = await deleteRequest(`boards/${board_id}/members`, params);
    return response;
}

// get task activity history by task id
async function getBoardTaskHistory(task_id) {
    const response = await getRequest(`boards/task/${task_id}/history`);
    return response;
}

// get task comments
async function getBoardTaskComments(task_id) {
    const response = await getRequest(`boards/task/${task_id}/comments`);
    return response;
}

// add task comments
async function addBoardTaskComments(task_id, params) {
    const response = await postRequest(`boards/task/${task_id}/comments`, params, true);
    return response;
}

// update task comments
async function updateBoardTaskComments(task_id, comment_id, params) {
    const response = await putRequest(`boards/task/${task_id}/comments/${comment_id}`, params, true);
    return response;
}

// delete task comments
async function deleteBoardTaskComments(task_id, comment_id) {
    const response = await deleteRequest(`boards/task/${task_id}/comments/${comment_id}`);
    return response;
}

// update board settings
async function updateBoardSettings(board_id, params) {
    const response = await putRequest(`boards/${board_id}/settings`, params);
    return response;
}

// create new cloned board task
async function cloneBoardTask(listId, taskId, params) {
    const response = await postRequest(`boards/task/clone/${listId}/${taskId}`, params);
    return response;
}

// delete board
async function deleteBoard(board_id) {
    const response = await deleteRequest(`boards/${board_id}`);
    return response;
}

// Get Public Key 
async function getPublicKey(board_id) {
    const response = await postRequest(`boards/publicKey/${board_id}`);
    return response;
}

// Get BoardId 
async function getBoardId(key) {
    const response = await postRequest(`boards/boardId/${key}`);
    return response;
}
// get board task
async function getPublicBoardTask(listId, params) {
    const response = await postRequest(`boards/public/task/list/${listId}`, params);
    return response;
}
async function getPublicBoardsTaskTags(boardId) {
    const response = await getRequest(`boards/public/task/tags/${boardId}`);
    return response;
}

// get board task detail
async function getPublicBoardTaskDetail(taskId, params) {
    const response = await postRequest(`boards/public/task/detail/${taskId}`, params);
    return response;
}

async function setIsPublic(board_id , param) {
    const response = await postRequest(`boards/isPublic/${board_id}` , param);
    return response
}
async function exportBoard(board_id, params) {
    const response = await postRequest(`boards/export/${board_id}`, params, true)
    return response
}
// import/read sheet data
async function uploadSheet(board_id, params) {
    const response = await postRequest(`boards/upload-sheet/${board_id}`, params, true);
    return response;
}

// import/read sheet data
async function importBoard(board_id, params) {
    const response = await postRequest(`boards/import/${board_id}`, params);
    return response;
}

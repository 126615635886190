import { Avatar, Grid, Typography, withStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { AudioPlayIcon, PinIcon, UnPinIcon, UserAudioMuteIcon } from "../../assets/svg";
import style from "./style";

function VideoPlayer({
  user,
  classes,
  isFullWidth,
  joined,
  fullWidthUser,
  localTracks,
  localVideoTrack,
  remoteUser,
  isVideoMuted,
  showChat,
  screenTrack,
  screenShare,
  screenShareUsers,
  user_id,
  mutedUsers,
  userAvatar,
  screen,
  profile,
  videoWidth,
  videoHeight,
  PinUser,
  fullWidthUsers,
  unpinnedUsers,
  userDetails,
  pinnedUser,
  UnPinUser,
  allUsers,
  audioMuted,
  userName
}) {

  const videoRef = useRef();
  const screenShareRef = useRef();

  const screenShareVideo = useSelector(
    (state) => state.videocall.screenShareVideo
  );

  const muteVideo = useSelector((s) => s.videocall.MuteVideo);

  useEffect(() => {
    const playVideoTrack = (videoTrack, ref) => {
      if (ref.current && videoTrack && !videoTrack.closed) {
        if (typeof videoTrack.play === 'function') {
          videoTrack.play(ref.current);
        } else {
        }
        // if (videoTrack.setEnabled) {
        //   videoTrack.setEnabled(!isVideoMuted);
        // }
      }
    };


    if (screenShare) {
      if (screenTrack && !screenTrack.closed) {
        playVideoTrack(screenTrack, screenShareRef);
      }

      if (Array.isArray(screenShareVideo)) {
        screenShareVideo.forEach((shareUser) => {
          if (shareUser.screenTrack && !shareUser.screenTrack.closed) {
            playVideoTrack(shareUser.screenTrack, screenShareRef);
          }
        });
      }
    } else if (user && user.videoTrack) {
      playVideoTrack(user.videoTrack, videoRef);
    } else if (localVideoTrack && !localVideoTrack.closed && !screenTrack && !screenShare) {
      playVideoTrack(localVideoTrack, videoRef);
    } else if (localVideoTrack && !screenTrack && !screenShare) {
      playVideoTrack(localVideoTrack, videoRef);
    } else if (localTracks && !screenTrack && !screenShare) {
      playVideoTrack(localTracks, videoRef);
    }

    return () => {
      if (screenShare) {
        if (screenTrack && !screenTrack.closed) {
          screenTrack.stop();
          screenTrack.close();
        } else {
          if (localVideoTrack && !localVideoTrack.closed) {
            localVideoTrack.stop();
            if (localVideoTrack.setEnabled) {
              localVideoTrack.setEnabled(false);
            }
            localVideoTrack.close();
          }
        }


        if (Array.isArray(screenShareVideo) && screenShareVideo.length > 0) {
          screenShareVideo.forEach((shareUser) => {
            if (shareUser.screenTrack && !shareUser.screenTrack.closed) {
              shareUser.screenTrack.stop();
              shareUser.screenTrack.close();
            }
          });
        }
      }
    };

  }, [user, user?.videoTrack, isVideoMuted, localVideoTrack, screenShareVideo, screenShare, screenTrack]);


  const isCurrentVideoFullWidth =
    isFullWidth && joined && fullWidthUser === user?.uid;
  const isFullWidthVideo = isFullWidth && joined && fullWidthUsers && fullWidthUsers.includes(user?.uid);
  const isWideRemoteVideo = remoteUser && remoteUser.length > 2;
  const isUnpinnedUser = unpinnedUsers && unpinnedUsers.map((id) => id === user.uid)

  let videoClass;

  if (isFullWidthVideo) {
    videoClass = `${classes.RemoteVideoUser} ${user?.videoTrack && user._video_muted_ === true ? classes.videoMuted : ""
      }`;
  } else if (localVideoTrack && !screenTrack && !screenShare) {
    videoClass = `${classes.requestVideo} ${localVideoTrack.enabled === false ? classes.requestVideoMuted : ""
      }`;
  } else if (isWideRemoteVideo && !screenTrack && !screenShare) {
    videoClass = `${isWideRemoteVideo ? classes.RemoteVideo : classes.RemoteVideo
      } ${user?.videoTrack && user._video_muted_ === true ? classes.videoMuted : ""}`;
  } else if (pinnedUser && pinnedUser.length <= 1) {
    videoClass = classes.RemoteVideoUser;
  } else if (remoteUser && remoteUser.length <= 1) {
    videoClass = classes.RemoteVideoUser
  } else {
    videoClass = joined ? classes.RemoteVideo : classes.video

  }


  const isPinnedUser = pinnedUser && pinnedUser.includes(user.uid);


  const [showPin, setShowPin] = useState(false);

  const handleMouseEnter = () => {
    setShowPin(true);
  };

  const handleMouseLeave = () => {
    setShowPin(false);
  };

  //   const avatar = []
  // if(userAvatar){
  //  avatar = userAvatar.id === user.uid  ? userAvatar.avatar : ''

  // }
  let matchedUsers = [];

  if (remoteUser && remoteUser.length > 0 && allUsers && allUsers.length > 0) {
    remoteUser.forEach((participant) => {
      const matchedUser = allUsers.find((user) => user.id === participant.uid);
      if (matchedUser) {
        matchedUsers.push(matchedUser);
      }
    });
  }


  return (
    <div
      className={`${!screenShare ? classes.RemoteVideoStyle : classes.ScreenshareStyle}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {joined && showPin && remoteUser.length > 1 && (
        isPinnedUser ? <div className={`${unpinnedUsers ? classes.UnpinUserPinIcon : classes.PinIcon}`} onClick={() => UnPinUser(user.uid)}>
          <UnPinIcon />
        </div> : <div className={`${unpinnedUsers ? classes.UnpinUserPinIcon : classes.PinIcon}`} onClick={() => PinUser(user.uid)}>
          <PinIcon />
        </div>
      )}
      {screenShare && screenTrack ? (
        <video
          ref={screenShareRef}
          className={classes.ScreenShareVideo}
          autoPlay
        />
      ) : joined && user.uid === user_id && user.videoTrack._enabled === false ? (
        <>
          <Grid className={`${unpinnedUsers ? classes.UnpinUserMuteContainer : classes.MuteVideoContainer}`}>
            <Avatar
              className={`${unpinnedUsers ? classes.UnPinUserMuteAvatar : classes.MuteVideoAvatar}`}
              src={profile.avatar_filename}
            />
          </Grid>
          {
            allUsers && allUsers.map((userData) => {
              if (userData.id === user.uid) {
                return (
                  <div key={userData.id} className={`${unpinnedUsers ? classes.unpinRemoteUserName : classes.remoteUserName}`}>
                    <Typography style={{ color: "white" }}>
                      {userData.username}
                    </Typography>
                  </div>
                );
              } else {
                return (
                  <div key={userData.id} className={`${unpinnedUsers ? classes.unpinRemoteUserName : classes.remoteUserName}`}>
                    <Typography style={{ color: "white" }}>
                      {profile.id === user.uid ? profile.username : ''}
                    </Typography>
                  </div>
                )
              }
            })
          }
          {user.audioTrack._enabled === false ? <Grid className={classes.AudioMuteIcon}><UserAudioMuteIcon /></Grid> : <Grid className={classes.AudioMuteIcon}><AudioPlayIcon /></Grid>}

        </>
      ) : 

        joined && user._video_muted_ ? (
        <>
          {user_id ?
            allUsers && allUsers.map((userData) => {
              if (userData.id === user.uid) {
                return (
                  <Grid className={`${unpinnedUsers ? classes.UnpinUserMuteContainer : classes.MuteVideoContainer}`}>
                    <Avatar className={`${unpinnedUsers ? classes.UnPinUserMuteAvatar : classes.MuteVideoAvatar}`} src={userData.avatar_filename} />
                  </Grid>
                );
              }
            }) :
            <Grid className={`${unpinnedUsers ? classes.UnpinUserMuteContainer : classes.MuteVideoContainer}`}>
                    <Avatar className={`${unpinnedUsers ? classes.UnPinUserMuteAvatar : classes.MuteVideoAvatar}`} />
            </Grid>
          }
          {
            allUsers && allUsers.map((userData) => {
              if (userData.id === user.uid) {
                return (
                  <div key={userData.id} className={`${unpinnedUsers ? classes.unpinRemoteUserName : classes.remoteUserName}`}>
                    <Typography style={{ color: "white" }}>
                      {userData.username}
                    </Typography>
                  </div>
                );
              } else {
                return (
                  <div key={userData.id} className={`${unpinnedUsers ? classes.unpinRemoteUserName : classes.remoteUserName}`}>
                    <Typography style={{ color: "white" }}>
                      {profile.id === user.uid ? profile.username : ''}
                    </Typography>
                  </div>
                )
              }
            })
          }
          {user._audio_muted_ ? <Grid className={classes.AudioMuteIcon}><UserAudioMuteIcon /></Grid> : <Grid className={classes.AudioMuteIcon}><AudioPlayIcon /></Grid>}
        </>
      ) : (
        <>
          <video
            ref={videoRef}
            className={`${unpinnedUsers ? classes.unpinnedUser : videoClass}`}
            autoPlay
            style={{ objectFit: "contain" }}
            muted={isVideoMuted}
            key={user.uid}
          />
          {
            allUsers && allUsers.map((userData) => {
              if (userData.id === user.uid) {
                return (
                  <div key={userData.id} className={`${unpinnedUsers ? classes.unpinRemoteUserName : classes.remoteUserName}`}>
                    <Typography style={{ color: "white" }}>
                      {userData.username}
                    </Typography>
                  </div>
                );
              } else {
                return (
                  <div key={userData.id} className={`${unpinnedUsers ? classes.unpinRemoteUserName : classes.remoteUserName}`}>
                    <Typography style={{ color: "white" }}>
                      {profile.id === user.uid ? profile.username : ''}
                    </Typography>
                  </div>
                )
              }
            })
          }
          {user.audioTrack?._enabled === false || user._audio_muted_ ? <Grid className={classes.AudioMuteIcon}><UserAudioMuteIcon /></Grid> : <Grid className={classes.AudioMuteIcon}><AudioPlayIcon /></Grid>}
        </>
      )}
    </div>
  )
}


export default withStyles(style)(VideoPlayer);

import React from 'react';
import {
    Dialog, DialogActions,
    DialogContent, Button, withStyles,
} from '@material-ui/core'
import { Typography } from "../Typography"
import Style from './styles';

const DialogModal = (props) => {
    const {
        classes, title, onConfirm, onCancel,
        confirmText, cancelText, open, children, needActions, onClose, fullWidth, isUnblockUser
    } = props
    return (
        <Dialog open={open} onClose={onClose} fullWidth={fullWidth} className={classes.popUpBox}>
            <DialogContent className={classes.dialogContentOverrides}>
                <Typography
                    variant="body1"
                    align="center"
                    color="primary"
                    className={`${classes.welcomeBanTxt} welcomeBanTxt fw-500`}
                >
                    {title}
                </Typography>
                {children ? children : null}
                {needActions && <DialogActions align="center" className={`${classes.actionsWrapper} justifyCenter`}>
                    {cancelText ? <Button
                        variant="outlined"
                        color='secondary'
                        onClick={onCancel}
                        className={`mt-1 mr5 br-7 bgGrey colorWht ${isUnblockUser?classes.unblockUserButton:''}`}
                    >
                        {cancelText}
                    </Button> : null}
                    <Button
                        variant="contained"
                        onClick={onConfirm}
                        className={`blueBtn mt-1 ${isUnblockUser?classes.unBlockCancelButton:''}`}
                        color="primary"
                    >
                        {confirmText}
                    </Button>
                </DialogActions>}
            </DialogContent>
        </Dialog>
    )
}

DialogModal.defaultProps = {
    open: false,
    title: '',
    onConfirm: () => { },
    onCancel: () => { },
    onClose: () => { },
    confirmText: 'Ok',
    cancelText: 'Cancel',
    children: null,
    needActions: true,
    fullWidth: false,
    classes: {}
}
export default withStyles(Style)(DialogModal)


// Alert reducer types
export const agencyLoginConstant = {
    AGENCY_SIGNUP_R: "AGENCY_SIGNUP_R",
    AGENCY_SIGNUP_S: "AGENCY_SIGNUP_S",
    AGENCY_SIGNUP_F: "AGENCY_SIGNUP_F",

    EMAIL_AGENCY_GET_R: "EMAIL_AGENCY_GET_R",
    EMAIL_AGENCY_GET_S: "EMAIL_AGENCY_GET_S",
    EMAIL_AGENCY_GET_F: "EMAIL_AGENCY_GET_F",

    AGENCY_SIGNUP_PROCESS_R: "AGENCY_SIGNUP_PROCESS_R",
    AGENCY_SIGNUP_PROCESS_S: "AGENCY_SIGNUP_PROCESS_S",
    AGENCY_SIGNUP_PROCESS_F: "AGENCY_SIGNUP_PROCESS_F",

    GET_PLAN_DATA_R: "GET_PLAN_DATA_R",
    GET_PLAN_DATA_S: "GET_PLAN_DATA_S",
    GET_PLAN_DATA_F: "GET_PLAN_DATA_F",
    GET_PLAN_DATA_FLAG:'GET_PLAN_DATA_FLAG',

    GET_REFERRAL_DATA_R: "GET_REFERRAL_DATA_R",
    GET_REFERRAL_DATA_S: "GET_REFERRAL_DATA_S",
    GET_REFERRAL_DATA_F: "GET_REFERRAL_DATA_F"
};

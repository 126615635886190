import React, { useRef, useState } from "react"
import { CircularProgress, Grid } from "@material-ui/core"
import { PlayCircleOutline } from "@material-ui/icons"
import doc from "../../assets/icons/doc.svg"
import { getUploadPreviewFiles } from "../../utils"
import { Editor, EditorState } from 'draft-js';
import createMentionPlugin from '@draft-js-plugins/mention';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import createHashtagPlugin from '@draft-js-plugins/hashtag';
import className from 'classnames';
import { createEditorStateWithText } from '@draft-js-plugins/editor';

const styleMap = {
    CODE: {
        backgroundColor: '#111',
        color: '#fff',
        fontSize: '1.5rem',
        padding: '2px',
    },
};

export const UploadingFiles = (props) => {
    const { classes, message: { uploadPreviews}, handleHashTagClick, handleMentionClick, msgComponentType } = props || { message: {} }
    const { file_type, files_data } = uploadPreviews || {}
    const message_text=JSON?.parse(files_data)[0]?.message_text
    
    const editor = useRef(null);

    let notesContent = "";
    try {
        notesContent = message_text ? createEditorStateWithText(message_text?.trim()) : EditorState.createEmpty();
    } catch (err) {
        notesContent = EditorState.createEmpty()
    }
    // const initialState = notesContent;
    const [editorState, setEditorState] = useState(notesContent);

    const onChange = (editorState) => {
        setEditorState(editorState);
    };

    const mentionPlugin = createMentionPlugin({
        classes: classes,
        mentionComponent: (mentionProps) => {
            let { mention, decoratedText } = mentionProps
            return (
                <a className={`${className(classes.messageMention, msgComponentType)} messageMention`} onClick={() => handleMentionClick(mention)}> {decoratedText}</a >
            )
        }
    });

    const linkifyPlugin = createLinkifyPlugin({
        target: '_blank',
        classes: classes,
        component: (props) => {
            return <a  {...props} className={`${className(classes.messageLink, msgComponentType)} messageLink`} onClick={() => window.open(props.href, '_blank')} />
        },
    });

    const hashtagPlugin = createHashtagPlugin({
        classes: classes,
        hashtagComponent: (props) => {
            let { decoratedText } = props;
            return <a className={className(classes.messageHashTag, msgComponentType)} onClick={() => handleHashTagClick(decoratedText)} >{decoratedText}</a>
        },
    });

    const renderThumb = (m) => {
        let src = doc;

        if (m.thumb) {
            src = m.thumb;
        } else if (m.preview) {
            src = m.preview
        }
        return (
            <img
                className={classes.chatImage}
                src={src}
                alt={m?.name}
            />
        )
    }

    if (file_type?.indexOf('audio') > -1) {
        return (
            <div className={classes.uploadPreviewAudio}>
                <PlayCircleOutline className='play-image' color="#ccc" />
                <div className="audio-player-dummy" />
                <CircularProgress className="circular-progress" />
            </div>
        )
    } else {
        let previewFiles = getUploadPreviewFiles(files_data) || []
        return (
            <div>
                <div className={classes.uploadPreviewImage}>
                    {
                        previewFiles?.map((m) => {
                            return renderThumb(m)
                        })
                    }
                    <CircularProgress className="circular-progress" />
                </div>
                {
                    message_text &&
                    <Grid>
                        <div className={`${classes.textMsgBubble} disable-user-select p5`}>
                            <Editor
                                customStyleMap={styleMap}
                                editorState={editorState}
                                onChange={onChange}
                                plugins={[linkifyPlugin, mentionPlugin, hashtagPlugin]}
                                ref={editor}
                                readOnly={true}
                            />
                        </div>
                    </Grid>
                }
            </div>
        )
    }
}
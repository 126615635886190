const style = (theme) => ({
    profileDetail: {
        padding: '20px 25px 20px',
        borderRadius: 7,
        zIndex: 1,
        zIndex: 9999,
        background: '#fff',
        boxShadow: '15px 20px 50px rgba(15, 23, 42, 0.15)',
        // position: 'absolute',
        right: '-45px',
        top: '-28px',
        // display: 'none',
        maxWidth: 'max-content',
        minWidth: 208,
        maxWidth: 250,
        '& .designationContainer': {
            background: '#EDF2F8',
            height: 23,
            borderRadius: '4px',
            padding: '4px 8px',
            fontSize: '12px !important',
            display: 'inline-flex',
            color: '#64748B',
            alignItems: 'center'
        },
        '& .profileDetailMsgBtn': {
            borderRadius: '30px',
            border: '1px solid #2563EB',
            color: '#2563EB',
            background: theme.palette.containers.bg20,
            marginTop: '16px',
            padding: '8px 15px'
        },
        '& .MuiAvatar-root': {
            margin: 'auto',
            width: '100% !important',
            height: '100% !important',
        },
        '& .MuiAvatar-colorDefault': {
            fontSize: '36px !important'
        },
        '& .profileImg': {
            position: 'relative ',
            width: '68px',
            height: '68px',
            margin: 'auto'
        },
        '& .activeDot': {
            width: 20,
            height: 20,
            background: '#34ac19',
            borderRadius: '50%',
            position: 'absolute',
            bottom: '10px',
            right: '10px',
            border: '4px solid #fff',
        },
        // '&:hover': {
        //     display: 'block !important'
        // },
    }
})

export default style
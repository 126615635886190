/* eslint-disable react/prop-types */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid, withStyles, Avatar } from "@material-ui/core";

// Imports Styles
import styles from "./styles";
import { InfinityLogo } from "../../assets/svg";
import UserOnline from "../../containers/Chatv2/UserOnline";
import { isMobile } from "react-device-detect"

function TitleAvatarComponent(props) {
  const { type, value, classes, title, is_infiniti, userId, recipientId, smallIcon, variant, isSchedule} = props;
  const [loaded,setLoaded] = useState(false)
  return (
    <>
      {
        type === "avatar" && is_infiniti != 1 ?
        <Grid className={`${isSchedule ? classes.chosenSchedule : classes.chosenIcon} ${smallIcon && classes.smallIcon} chosenIcon avatarComp avatarDiv relative`}>
        
        {value ? (
            <>
                {!loaded && (
                    // Placeholder or loader if the image hasn't loaded
                    <Grid className={`${classes.avatarContainer} gradeBtn`}>
                    <span>{title && title.charAt(0).toUpperCase()}</span>
                </Grid>
                )}
                <Avatar 
                    variant={variant} 
                    title={title ? title : ""} 
                    alt={title ? title : ""} 
                    src={value ? value : `/static/images/avatar/1.jpg`}
                    className={`${isSchedule ? classes.avatar : ""}`}
                    onLoad={() => setLoaded(true)}
                    onError={() => setLoaded(false)}
                    style={{ display: loaded ? 'block' : 'none',marginRight:'10px' }} 
                />
            </>
        ) : (
            <Grid className={`${classes.avatarContainer} gradeBtn`}>
                <span>{title && title.charAt(0).toUpperCase()}</span>
            </Grid>
        )}
        {userId && recipientId && <UserOnline userId={userId} isUserOnline={recipientId} />}
    </Grid>
          
          :
          type === "color" && is_infiniti != 1 ? (
            <Grid 
              className={`${isSchedule ? classes.chosenScheduleColor : value ? classes.chosenColor : ""} ${smallIcon && classes.smallIcon} ${isMobile ? "mr-1" : ""} chosenColor avatarComp avatarDiv`}
              style={value ? {
                background: `${value}`,
                backgroundSize: 'contain',
                borderRadius: '50%'
              } : {}}
            >
              {!value && <Avatar style={{fontSize:'14px'}} variant={variant} title={title || ""} alt={title || ""} src={title || ""} className={classes.chosenColor}/>}
            </Grid>
          ) : 
            type === "emoji" && is_infiniti != 1 ?
              <Grid className={`${isSchedule ? `${classes.chosenSchedule} ${classes.chosenScheduleEmoji} " "` : `${classes.chosenEmoji} mr-1`} ${smallIcon && classes.extraSmallIcon} chosenEmoji`}>
                {value ? value : <Avatar variant={variant} title={title ? title : ""} alt={title ? title : ""} src={title ? title : ""} />}
              </Grid> :                                                                                             
              is_infiniti == 1 ? <Grid className={`${isSchedule ? classes.chosenSchedule : classes.chosenEmoji}  ${smallIcon ? "" : ""} chosenEmoji avatarDiv`}>
                <InfinityLogo />
              </Grid> :
                <Grid className={`${isSchedule ? classes.chosenSchedule : classes.chosenColor} ${smallIcon && classes.smallIcon} chosenColor avatarComp avatarDiv`}
                  style={{
                    background: `#437bfc`,
                    backgroundSize: 'contain'
                  }}>
                  <Avatar variant={variant} className={isSchedule && classes.chosenSchedule} title={title ? title : ""} alt={title ? title : ""} src={value ? value : ``} />
                </Grid>
      }
    </>
  );
}

// default props
TitleAvatarComponent.defaultProps = {
  classes: {},
  // type: "avatar",
  isSchedule: false,
  variant: "circular",
  is_infiniti: 0,
  value: null,
  title: "",
  smallIcon: false,
};

// prop types
TitleAvatarComponent.propTypes = {
  classes: PropTypes.object,
  type: PropTypes.string,
  variant: PropTypes.string,
  is_infiniti: PropTypes.number,
  isSchedule: PropTypes.bool,
  value: PropTypes.string,
  title: PropTypes.string,
  smallIcon: PropTypes.bool,
};

// export component
export const TitleAvatar = withStyles(styles)(TitleAvatarComponent);

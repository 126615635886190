// UI reducer types
export const uiConstant = {
  LOADING_TRUE: "LOADING_TRUE",
  LOADING_FALSE: "LOADING_FALSE",

  LAZY_LOADING_TRUE: "LAZY_LOADING_TRUE",
  LAZY_LOADING_FALSE: "LAZY_LOADING_FALSE",

  GLOBAL_LOADING_TRUE:"GLOBAL_LOADING_TRUE",
  GLOBAL_LOADING_FALSE:"GLOBAL_LOADING_FALSE",
  
  TEMPLATE_LOADING_TRUE: "TEMPLATE_LOADING_TRUE",
  TEMPLATE_LOADING_FALSE: "TEMPLATE_LOADING_FALSE"
};

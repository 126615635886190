import { agencyLoginConstant } from "../constants/agencyLoginConstant";

const initialState = {
  signup: {},
  emailAgency: {},
  agencyDetails: {},
  planDetails: {},
  referralLinkData: {},
  planFlag:{}
};

const agencyLoginReducer = (state = initialState, action) => {
  if (!action || (action && !action.type)) return state;

  switch (action.type) {

    case agencyLoginConstant.AGENCY_SIGNUP_R:
      return {
        ...state,
      };
    case agencyLoginConstant.AGENCY_SIGNUP_S:
      return {
        ...state,
        agencyDetails: action.data.data
      };
    case agencyLoginConstant.AGENCY_SIGNUP_F:
      return {
        ...state,
      };

    case agencyLoginConstant.AGENCY_SIGNUP_PROCESS_R:
      return {
        ...state,
      };
    case agencyLoginConstant.AGENCY_SIGNUP_PROCESS_S:
      return {
        ...state
      };
    case agencyLoginConstant.AGENCY_SIGNUP_PROCESS_F:
      return {
        ...state,
      };

    case agencyLoginConstant.EMAIL_AGENCY_GET_R:
      return {
        ...state,
        emailAgency: {}
      };
    case agencyLoginConstant.EMAIL_AGENCY_GET_S:
      return {
        ...state,
        emailAgency: action.data.data
      };
    case agencyLoginConstant.EMAIL_AGENCY_GET_F:
      return {
        ...state,
        emailAgency: {}
      };

    case agencyLoginConstant.GET_PLAN_DATA_R:
      return {
        ...state,
      };
    case agencyLoginConstant.GET_PLAN_DATA_S:
      return {
        ...state,
        planDetails: action.params.data
      };
    case agencyLoginConstant.GET_PLAN_DATA_FLAG:{
      return {
         ...state,
         planFlagdetails:action.params.data.listFlag
      };
    }
    case agencyLoginConstant.GET_PLAN_DATA_F:
      return {
        ...state,
      };

    case agencyLoginConstant.GET_REFERRAL_DATA_R:
      return {
        ...state,
      };
    case agencyLoginConstant.GET_REFERRAL_DATA_S:
      return {
        ...state,
        referralLinkData: action.params.data
      };
    case agencyLoginConstant.GET_REFERRAL_DATA_F:
      return {
        ...state,
      };

    default:
      return state;
  }
}

export default agencyLoginReducer;

import React from "react";
import PropTypes from 'prop-types';
import { Grid, Popover, withStyles } from "@material-ui/core";
import { DateRangePicker, defaultStaticRanges } from 'react-date-range';
import moment from "moment";

// components
import { Button } from "../../components";
import TaskStyles from "./TaskStyles";

// assets
import { CalendarIconNew } from "../../assets/svg";



const DateFilter = (props) => {

    // props
    const { classes, dateWidth, needOnlyIcon,openDatePicker,setopenDatePicker, applyFilter, dateRange,setDaterange, clearEnabled, clearFilter } = props;

    // state

    // apply date filter
    // const applyFilter = () => {
    //     const [{ startDate, endDate }] = dateRange;
    //     const stDate = startDate && new Date(startDate) != "Invalid Date" ? moment(startDate).format("YYYY-MM-DD") : null
    //     const edDate = endDate && new Date(endDate) != "Invalid Date" ? moment(endDate).format("YYYY-MM-DD") : null
    //     let tmpFilter = { ...taskFilter, due_date: [stDate, edDate] }
    //     if ((stDate === moment().subtract(4, "years").startOf("month").format("YYYY-MM-DD")) && (edDate === moment().add(2, "years").endOf("month").format("YYYY-MM-DD"))) {
    //         delete tmpFilter.due_date;
    //     }
    //     dispatch(updateAction.update(tasksConstant.UPDATE_TASK_FILTER, tmpFilter, () => {
    //         setopenDatePicker(false);
    //         setCallList(true)
    //     }))
    // }

    return (
        <>
            {
                needOnlyIcon ?
                    <div className="task-calander-new-icon dflex" onClick={(e) => setopenDatePicker(e.currentTarget)}>
                        <CalendarIconNew width={16} height={16} />
                    </div>
                    :
                    <Button
                        variant="outlined"
                        onClick={(e) => setopenDatePicker(e.currentTarget)}
                        color="secondary"
                        size="small"
                        className={`mob-w100 mobJustifyLeft`}
                        style={{ width: dateWidth ? dateWidth : null }}
                        startIcon={<CalendarIconNew width={16} height={16} />}
                        title={moment.utc().local().format('MMMM, DD YYYY')}
                    />
            }
            <Popover
                id={openDatePicker ? 'simple-popover' : undefined}
                open={Boolean(openDatePicker)}
                anchorEl={openDatePicker}
                onClose={() => { setopenDatePicker(false) }}
                className={`${classes.datePickrCls}`}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <DateRangePicker
                    onChange={(item) => { setDaterange([item.selection]) }}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={1}
                    ranges={dateRange}
                    direction="horizontal"
                    staticRanges={[
                        ...defaultStaticRanges,
                        {
                            label: "All",
                            range: () => ({
                                startDate: new Date(moment().subtract(4, "years").startOf("month").format("YYYY-MM-DD")),
                                endDate: new Date(moment().add(2, "years").endOf("month").format("YYYY-MM-DD"))
                            }),
                            isSelected(range) {
                                return ((range.startDate >= new Date(moment().subtract(4, "years").startOf("month").format("YYYY-MM-DD"))) && (range.endDate >= new Date(moment().add(2, "years").endOf("month").format("YYYY-MM-DD"))));
                            }
                        }
                    ]}
                />
                <Grid container style={{ justifyContent: 'right' }}>
                    {clearEnabled&&
                    <Grid item className="p-1">
                        <Button
                            variant="outlined"
                            onClick={() => { clearFilter() }}
                            color="primary"
                            className={"mb-1 mob-w100 mobJustifyLeft"}
                            size="small"
                            title="Clear Filter"
                        >
                        </Button>
                    </Grid>}
                    <Grid item className="p-1">
                        <Button
                            variant="contained"
                            onClick={() => { setopenDatePicker(false) }}
                            color="secondary"
                            className={"mb-1 mob-w100 mobJustifyLeft"}
                            size="small"
                            title="Cancel"
                        >
                        </Button>
                    </Grid>
                    <Grid item className="p-1">
                        <Button
                            variant="contained"
                            onClick={() => { applyFilter() }}
                            color="primary"
                            className={"mb-1 mob-w100 mobJustifyLeft blueBtn"}
                            size="small"
                            title="Apply"
                        >
                        </Button>
                    </Grid>
                </Grid>
            </Popover>
        </>
    )
}

// default props
DateFilter.defaultProps = {
    classes: {},
    setCallList: () => { },
    needOnlyIcon: false
};

// prop types
DateFilter.propTypes = {
    classes: PropTypes.object,
    setCallList: PropTypes.func,
    needOnlyIcon: PropTypes.bool,
};

export default withStyles(TaskStyles)(DateFilter)
import '@draft-js-plugins/emoji/lib/plugin.css';
const styles = (theme) => ({
  roots: {
    height: 36,
    '& .MuiOutlinedInput-root': {
      height: 36,
      minHeight: 36
    }
  },
  replyTile: {
    bottom: 0,
    left: 0,
    background: 'transparent',
    zIndex: 1,
    cursor: 'pointer',
  },
  replyTileBox: {
    width: "100%",
    margin: "3px 3px 0px 3px",
    background: theme.palette.containers.bg1,
    padding: '10px',
    borderRadius: '8px',
    cursor: 'pointer',
    // borderLeft: "5px solid #198cbf",
    boxShadow: '0 1px 0.5px rgb(0 0 0 / 8%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: "space-between"
  },
  closeIcon: {
    width: 24,
    height: 24
  },
  replyThumb: {
    width: "64px",
    height: "50px",
    maxHeight: "100px",
    display: "flex",
    objectFit: "cover"
  },
  replyCloseBtn: {
    width: "100%",
    height: "50px",
  },
  profileContainers: {
    display: "flex",
  },
  avatarBox: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center"
  }
});

export default styles;

import { Avatar, Grid, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types';
import React from 'react'
import { Button } from '../Button';
import { Typography } from '../Typography';
import style from './style'
import moment from 'moment';
import { MessageIcon2 } from '../../assets/svg';



function ProfileDetailComponent(props) {

    const { classes, profileName, data, lastSeen, lastSeenTime, bio, timeZoneValue, handleMessageClick, isOnline, teamusers } = props;
    const { avatar_filename, first_name, job_type, name, user_id } = data
    let showmessage = teamusers?.filter(usr=>usr.id == user_id).length > 0 ? true : false

    let utsOffset = "Australia/Sydney"
    if (timeZoneValue) {
        utsOffset = timeZoneValue
    }

    utsOffset = moment().tz(utsOffset).format("Do MMM hh:mm a")
    if (!utsOffset) {
        utsOffset = moment().tz('Asia/Calcutta').format("Do MMM hh:mm a")
    }

    const handleMessageRedirect = (userId) => {
        handleMessageClick(userId)
    }

    return (
        <Grid className={`${classes.profileDetail} ProfileDetail`}>
            <Grid item className="profileImg mb15">
                <Avatar src={avatar_filename ? avatar_filename : first_name} alt={job_type === 1 ? "Flozy" : name ? name : (first_name ? first_name : '')} />
                {/* <Avatar  alt={altvalue} src={avatarSrc}/> */}
                {isOnline ? <Grid className='activeDot'></Grid> : null}
            </Grid>
            <Grid item className=''>
                <Typography variant="body1" className='fw-700' align="center">{profileName}</Typography>
                {/* <Grid className='fs-14 fw-500 oneLineEllipText'>CEO</Grid> */}
            </Grid>
            <Grid item className='pt5' align="center">
                {bio && <Typography className="designationContainer">{bio ? bio : ""}</Typography>}
                {lastSeen &&
                    <Typography variant="body2" className='pt-1 fs-12' color="textSecondary" align="center">Last seen: {lastSeen === "No Date" ? "No Data" : (isOnline===true?'Just now':`${lastSeen} ago`)}</Typography>               
                    }
                {lastSeenTime &&
                    <Typography variant="body2" className='dflex alignCenter' color="textSecondary" >Local Time: {utsOffset}</Typography>
                }
            </Grid>
            {showmessage && 
            <Grid align="center">
                <Button className={"profileDetailMsgBtn"} variant='outlined' color="primary" title='Message' onClick={() => handleMessageRedirect(user_id)} startIcon={<MessageIcon2 />}></Button>
            </Grid>
            }
        </Grid>
    )
}

ProfileDetailComponent.defaultProps = {
    classes: {},
    profileName: "",
};

// prop types
ProfileDetailComponent.propTypes = {
    classes: PropTypes.object,
    profileName: PropTypes.string
};

export const ProfileDetail =  withStyles(style)(ProfileDetailComponent)
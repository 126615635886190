export const docsConstant = {
  // create doc
  CREATE_DOC_R: "CREATE_DOC_R",
  CREATE_DOC_S: "CREATE_DOC_S",
  CREATE_DOC_F: "CREATE_DOC_F",

  // get docs list
  GET_DOCS_LIST_R: "GET_DOCS_LIST_R",
  GET_DOCS_LIST_S: "GET_DOCS_LIST_S",
  GET_DOCS_LIST_F: "GET_DOCS_LIST_F",

  // for root folder selection
  SET_ROOT_FOLDER: "SET_ROOT_FOLDER",

  // for sub folder selection
  OPEN_SUB_FOLDER: "OPEN_SUB_FOLDER",

  // after add push doc to parent
  ADD_DOC_TO_PARENT: "ADD_DOC_TO_PARENT",

  // after delete remove doc from parent
  REMOVE_DOC_FROM_PARENT: "REMOVE_DOC_FROM_PARENT",

  // set editor doc
  SET_EDITOR_DOC_ID: "SET_EDITOR_DOC_ID",

  // get editor content detail
  GET_DOC_CONTENT_R: "GET_DOC_CONTENT_R",
  GET_DOC_CONTENT_S: "GET_DOC_CONTENT_S",
  GET_DOC_CONTENT_F: "GET_DOC_CONTENT_F",

  // get editor content detail
  UPDATE_DOC_CONTENT_R: "UPDATE_DOC_CONTENT_R",
  UPDATE_DOC_CONTENT_S: "UPDATE_DOC_CONTENT_S",
  UPDATE_DOC_CONTENT_F: "UPDATE_DOC_CONTENT_F",

  // get editor content detail
  DELETE_DOC_R: "DELETE_DOC_R",
  DELETE_DOC_S: "DELETE_DOC_S",
  DELETE_DOC_F: "DELETE_DOC_F",

  //Set Current Doc Patch
  UPDATE_CURRENT_PATH_S: "UPDATE_CURRENT_PATH_S",

  // Add / Remove public access
  ADD_DOC_PUBLIC_ACCESS_TOKEN_R: "ADD_DOC_PUBLIC_ACCESS_TOKEN_R",
  ADD_DOC_PUBLIC_ACCESS_TOKEN_S: "ADD_DOC_PUBLIC_ACCESS_TOKEN_S",
  ADD_DOC_PUBLIC_ACCESS_TOKEN_F: "ADD_DOC_PUBLIC_ACCESS_TOKEN_F",

  REMOVE_DOC_PUBLIC_ACCESS_TOKEN_R: "REMOVE_DOC_PUBLIC_ACCESS_TOKEN_R",
  REMOVE_DOC_PUBLIC_ACCESS_TOKEN_S: "REMOVE_DOC_PUBLIC_ACCESS_TOKEN_S",
  REMOVE_DOC_PUBLIC_ACCESS_TOKEN_F: "REMOVE_DOC_PUBLIC_ACCESS_TOKEN_F",

  GET_PUBLIC_DOCUMENT_R: "GET_PUBLIC_DOCUMENT_R",
  GET_PUBLIC_DOCUMENT_S: "GET_PUBLIC_DOCUMENT_S",
  GET_PUBLIC_DOCUMENT_F: "GET_PUBLIC_DOCUMENT_F",

  ADD_DOC_ACCESS_R: "ADD_DOC_ACCESS_R",
  ADD_DOC_ACCESS_S: "ADD_DOC_ACCESS_S",
  ADD_DOC_ACCESS_F: "ADD_DOC_ACCESS_F",

  GET_SHARE_MEMBERS_R: "GET_SHARE_MEMBERS_R",
  GET_SHARE_MEMBERS_S: "GET_SHARE_MEMBERS_S",
  GET_SHARE_MEMBERS_F: "GET_SHARE_MEMBERS_F",

  UPDATE_SHARE_MEMBERS_R: "UPDATE_SHARE_MEMBERS_R",
  UPDATE_SHARE_MEMBERS_S: "UPDATE_SHARE_MEMBERS_S",
  UPDATE_SHARE_MEMBERS_F: "UPDATE_SHARE_MEMBERS_F",

  // scroll to doc folder or document
  SCROLL_TO_DOC: "SCROLL_TO_DOC",

  // Reset Brain
  RESET_DOC_STATE: "RESET_DOC_STATE",

  // get shared docs list
  GET_SHARED_DOCS_LIST_R: "GET_SHARED_DOCS_LIST_R",
  GET_SHARED_DOCS_LIST_S: "GET_SHARED_DOCS_LIST_S",
  GET_SHARED_DOCS_LIST_F: "GET_SHARED_DOCS_LIST_F",

  // copy/duplicate doc
  COPY_DOC_R: "COPY_DOC_R",
  COPY_DOC_S: "COPY_DOC_S",
  COPY_DOC_F: "COPY_DOC_F",

  // copy/duplicate doc
  PIN_DOC_R: "PIN_DOC_R",
  PIN_DOC_S: "PIN_DOC_S",
  PIN_DOC_F: "PIN_DOC_F",

  // Get custom folder list
  GET_CUSTOM_FOLDERS_R: "GET_CUSTOM_FOLDERS_R",
  GET_CUSTOM_FOLDERS_S: "GET_CUSTOM_FOLDERS_S",
  GET_CUSTOM_FOLDERS_F: "GET_CUSTOM_FOLDERS_F",

  // Move document
  MOVE_DOC_R: "MOVE_DOC_R",
  MOVE_DOC_S: "MOVE_DOC_S",
  MOVE_DOC_F: "MOVE_DOC_F",

  // Update Root Folder
  UPDATE_ROOT_FOLDER: "UPDATE_ROOT_FOLDER",

  CLEAR_DOCS_FOLDER: "CLEAR_DOCS_FOLDER"
};

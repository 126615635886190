// Import API Helper
import { deleteRequest, getRequest, postRequest, putRequest } from "../../utils/apiHelper";

async function getWorkflow(params) {
    const response = await getRequest(`workflow/${params.id}`);
    return response;
}

async function getWorkflows(body) {
    const response = await postRequest(`workflow/list`, body);
    return response;
}

async function createWorkflow(body) {
    const response = await postRequest(`workflow`, body);
    return response;
}

async function updateWorkflow(params, body) {
    const response = await putRequest(`workflow/${params.id}`, body);
    return response;
}

async function deleteWorkflow(params) {
    const response = await deleteRequest(`workflow/${params.id}`);
    return response;
}

async function executeWorkflow(params) {
    const response = await getRequest(`workflow/execute/${params.resource_id}/${params.mode}`);
    return response;
}

async function getWorkflowsPublic(body) {
    const response = await postRequest(`workflow/public/list`, body);
    return response;
}

export const workflowService = {
    getWorkflow,
    getWorkflows,
    createWorkflow,
    updateWorkflow,
    deleteWorkflow,
    executeWorkflow,
    getWorkflowsPublic
};
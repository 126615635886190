import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid, IconButton, Tooltip, withStyles } from '@material-ui/core'

//import components
import { TextField } from "..";
import { ChatSearchIcon, SearchCloseIcon } from "../../assets/svg";
import styles from "./styles";
import { InputAdornment } from "@material-ui/core";

const SearchBarComponent = (props) => {
  const { 
      id, fullWidth, classes, grow, 
      className, placeholder, initSearch, autoFocus, openSearch, setOpenSearch , EventSearchIcon
  } = props;

  //Toggle button for list and chord view
  const toggleSearch = false
  const [value, setValue] = useState(toggleSearch === false ? '' : props.value);
  // const [openSearch, setOpenSearch] = useState(false);

  const handleSearchClear = () => {
    setOpenSearch(false)
    setValue("");
    initSearch("");
  }

  //update search and timeout
  useEffect(() => {
    const delaySet = setTimeout(() => {
      if (value !== props.value) {
        initSearch(value)
      }
    }, 600)
    return () => clearTimeout(delaySet)
  }, [value]);

  //update search value
  useEffect(() => {
    setValue(props.value)
  }, [props.value]);


  return (
    <>
    { openSearch &&
      // <Slide direction="left" in={true}>
        <Grid className={`${"searchBoxGrid"} ${classes.searchBoxGrid} dflex`}>
          <TextField
            id={id}
            variant="outlined"
            size="small"
            margin="none"
            style={{ grow: grow }}
            autoFocus={autoFocus}
            fullWidth={fullWidth}
            placeholder={placeholder}
            className={`${className} ${"searchBox"}`}
            value={value}
            onChange={(e) => { 
              setValue(e.target.value);
              if (!e.target.value) {
                handleSearchClear()
              } 
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end" onClick={() => handleSearchClear()}>
                <IconButton  className="p5" >
                <SearchCloseIcon className={"seachCloseIcon"} />
                </IconButton>
              </InputAdornment>,
            }}
          />
        </Grid>
      // </Slide>
      }
      { !openSearch &&
        <Tooltip title="Search">
        <IconButton
            className='mr-1 p-0 '
            onClick={() => {setOpenSearch(!openSearch)}}
        >
          {EventSearchIcon || <ChatSearchIcon/>}    
        </IconButton>
        </Tooltip>
      }

    </>
  );
};

// default props
SearchBarComponent.defaultProps = {
  id: "dxzc",
  fullWidth: true,
  autoFocus: false,
  classes: {},
  grow: '1',
  placeholder: "search",
  initSearch: () => { },
  clearSearch: () => { },
};

// prop types
SearchBarComponent.propTypes = {
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  classes: PropTypes.object,
  grow: PropTypes.string,
  placeholder: PropTypes.string,
  initSearch: PropTypes.func,
  clearSearch: PropTypes.func,
};

export const SlideSearchBar = withStyles(styles)(SearchBarComponent);

import axios from "axios";

// Import Environment Config
import { _api } from "../config/environment";

// Import History
import history from "../config/history";

// Import Helpers
import { getAppEnvironment, getToken } from "./appHelper";

const apiBases = {
  'default': _api.url,
  'pageBuilder': _api.pageBuilder,
  'customDomain': _api.customDomain,
  'puppeter': _api.puppeter
}

/**
 * GET request
 * @param {*} endpoint
 */
export const getRequest = async (endpoint, apiBase = 'default') => {
  return makeRequest(`${apiBases[apiBase]}/${endpoint}`, "GET");
};

/**
 * GET request text/html type
 * @param {*} endpoint
 */
export const getHTMLRequest = async (endpoint, apiBase = 'default') => {
  return makeRequest(`${apiBases[apiBase]}/${endpoint}`, "GET", null, false, 'blob', (response) => {
    return response
  });
};

/**
 * PUT requests
 * @param {* The put endpoint} endpoint
 * @param {* The request body data} body
 * @param {* <true> if the put method contains any images, <false> otherwise.} isFormData
 */
export const putRequest = async (endpoint, body, isFormData, apiBase = 'default') => {
  return makeRequest(`${apiBases[apiBase]}/${endpoint}`, "PUT", body, isFormData);
};

/**
 * POST request
 * @param {* The post endpoint} endpoint
 * @param {* The request body data} body
 * @param {* <true> if the post method contains any images, <false> otherwise.} isFormData
 */
export const postRequest = async (endpoint, body, isFormData, responseType, apiBase = 'default') => {
  return makeRequest(`${apiBases[apiBase]}/${endpoint}`, "POST", body, isFormData, responseType);
};

/**
 * DELETE request
 * @param {* The delete endpoint} endpoint
 */
export const deleteRequest = async (endpoint, body, apiBase = 'default') => {
  return makeRequest(`${apiBases[apiBase]}/${endpoint}`, "DELETE", body);
};

/**
 * api make request
 * @param {*} endpoint
 * @param {*} verb
 * @param {*} body
 * @param {*} isFormData
 */
const makeRequest = (endpoint, verb, data, isFormData = false, responseType, customHandleResponse = null) => {
  isFormData = isFormData || data instanceof FormData;
  const requestOptions = {
    method: verb,
    url: endpoint,
    headers: getHeaders(isFormData, endpoint),
    responseType: responseType,
    data
  };
  return axios(requestOptions)
    .then(customHandleResponse || handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * Handler for the response recieved from fetch request
 * @param {* The response recieved from fetch request} response
 */
const handleResponse = (response) => {
  if (response.status === 200) {
    if (response.data.http_code === 200) {
      return response.data;
    } else if (response.data.http_code === 307) {
      history.push({ pathname: "/resetpassword/" + response.data.hash + "/" + response.data.key });
      throw response.data.message;
    } else if (response.data.http_code === 503) {
      history.push('/under-maintenance');
      return;
    } else if (response.data.http_code === 403 && response.data.message === 'Please Update your Flozy App!!!') {
      history.push('/update-app');
      return
    } else {
      throw response.data.message;
    }
  } else {
    throw new Error("Server connection issue");
  }
};

/**
 * Prepares headers for the request
 * @param {* <true> if the request contains any images, <false> otherwise.} isFormData
 */
const getHeaders = (isFormData, endpoint, userIp) => {
  const token = getToken();
  const headers = {};
  headers.userip = userIp
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  if (!isFormData) {
    headers["Content-Type"] = "application/json";
  }

  const appHost = window.location.host;
  if (appHost) {
    headers["Workspace-Url"] = appHost;
    headers["app-environment"] = getAppEnvironment();
    headers["app-version"] = '5';
  }
  return headers;
};

/**
 * Prepares the query string based on the given input prams
 * @param {* The params used to prepare query string} params
 */
export const makeQueryString = (params) => {
  let queries = Object.keys(params).map((key) =>
    params[key] && params[key].length ? key + "=" + params[key] : ""
  );
  queries = queries.filter((query) => query.length > 0);
  return "?" + queries.join("&");
};

export const getIpData = async () => {
  const res = await axios.get("https://api.ipify.org/?format=json");
  return res?.data?.ip;
}



import { Avatar, Grid, Typography, withStyles } from "@material-ui/core";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { AudioPlayIcon, UserAudioMuteIcon } from "../../../assets/svg";
import style from "./style";


function VCParticipant({
  user,
  classes,
  joined,
  localVideoTrack,
  remoteUser,
  isVideoMuted,
  screenTrack,
  screenShare,
  user_id,
  profile,
  videoWidth,
  videoHeight,
  allUsers,
  channelStatus,
  localUser
}) {

  const videoRef = useRef();
  const screenShareRef = useRef();
  // const videoContainer = useRef(null);

  const screenShareVideo = useSelector(
    (state) => state.videocall.screenShareVideo
  );

  const playVideoTrack = (videoTrack, ref) => {
    if (ref.current && videoTrack && !videoTrack.closed) {
      if (typeof videoTrack.play === 'function') {
        videoTrack.play(ref.current);
      }
    }
  };

  useEffect(() => {
    if (screenShare && screenTrack) {
      if (screenTrack && !screenTrack.closed) {
        playVideoTrack(screenTrack, screenShareRef);
      }

      if (Array.isArray(screenShareVideo)) {
        screenShareVideo.forEach((shareUser) => {
          if (shareUser.screenTrack && !shareUser.screenTrack.closed) {
            playVideoTrack(shareUser.screenTrack, screenShareRef);
          }
        });
      }
    } else if (user && user.videoTrack) {
      playVideoTrack(user.videoTrack, videoRef);
    } else if (localVideoTrack && !localVideoTrack.closed && !screenTrack && !screenShare) {
      playVideoTrack(localVideoTrack, videoRef);
    } else if (localVideoTrack && !screenTrack && !screenShare) {
      playVideoTrack(localVideoTrack, videoRef);
    }

    return () => {
      if (screenShare) {
        if (screenTrack && !screenTrack.closed) {
          screenTrack.stop();
          screenTrack.close();
        } else {
          if (localVideoTrack && !localVideoTrack.closed) {
            localVideoTrack.stop();
            if (localVideoTrack.setEnabled) {
              localVideoTrack.setEnabled(false);
            }
            localVideoTrack.close();
          }
        }

        if (Array.isArray(screenShareVideo) && screenShareVideo.length > 0) {
          screenShareVideo.forEach((shareUser) => {
            if (shareUser.screenTrack && !shareUser.screenTrack.closed) {
              shareUser.screenTrack.stop();
              shareUser.screenTrack.close();
            }
          });
        }
      }
    };

  }, [user, user?.videoTrack, isVideoMuted, localVideoTrack, screenShareVideo, screenShare, screenTrack]);

  let matchedUsers = [];

  if (remoteUser && remoteUser.length > 0 && allUsers && allUsers.length > 0) {
    remoteUser.forEach((participant) => {
      const matchedUser = allUsers.find((user) => user.id === participant.uid);
      if (matchedUser) {
        matchedUsers.push(matchedUser);
      }
    });
  }

return (
  <div
    className={`${!screenShare ? classes.RemoteVideoStyle : classes.ScreenshareStyle}`}
    style={{width: videoWidth, height: videoHeight}}
  > 
      {
        // Show local screen when screen share is enabled
        screenShare && screenTrack &&
          <video
            ref={screenShareRef}
            className={`${classes.attendee} ${classes.ScreenShareVideo} ${classes.contain}}`}
            autoPlay
          />
      }
      {
        // Show User Video when  video is not muted and screen share is not enabled
        !isVideoMuted && !screenTrack &&
        <video
          ref={videoRef}
          className={`${classes.attendee} ${user['screenShare'] ? classes.contain : classes.cover}`}
          autoPlay
          muted={isVideoMuted}
          key={user.uid}
        />
      }
      {
        !screenShare && !screenTrack &&
        channelStatus && Object.keys(channelStatus).map(userId => {
          const userObj = channelStatus[userId];
            return Object.keys(userObj).map(id => {
            const userData = userObj[id];
            if (userData) {
              if (String(userData.id) === String(user.uid)) {
                return (
                  <div key={userData.id} className={classes.remoteUserName}>
                    <Typography style={{ color: "white" }}>
                      {userData.name}
                    </Typography>
                  </div>
                );
              }
            }
            return null;
          });
        })
      }

      {
        // Show only Avatar when video is muted and screen share is not enabled
        (user?.videoTrack?._enabled === false || user._video_muted_ ) && !screenShare && !screenTrack && !isVideoMuted &&
        channelStatus ? Object.keys(channelStatus).map(userId => {
          const userObj = channelStatus[userId]
         return Object.keys(userObj).map(id => {
           const userData = userObj[id]
           if (userData) {
            if (String(userData.id) === String(user.uid)) {
               return (
                <Grid key={userData.id} className={classes.MuteVideoContainer}>
                  <Grid className={classes.avatarContainer}>
                    <Avatar
                      className={classes.UnPinUserMuteAvatar}
                      src={userData.avatar}
                    />
                  </Grid>
                </Grid>
                );
              } 
            }
           
          })
        }) : !isVideoMuted && !screenShare && !screenTrack && localUser?.videoMuted === true ? 
          <Grid key={profile?.id} className={classes.MuteVideoContainer}>
            <Grid className={classes.avatarContainer}>
              <Avatar
                className={classes.UnPinUserMuteAvatar}
                src={profile?.avatar_filename}
              />
            </Grid>
        </Grid> : null
      }
        
      {(user?.audioMuted || user?._audio_muted_) ? <Grid className={classes.AudioMuteIcon}><UserAudioMuteIcon /></Grid> : <Grid className={classes.AudioMuteIcon}><AudioPlayIcon /></Grid>}
    </div>
  )
}

export default withStyles(style)(VCParticipant);

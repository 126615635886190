import React, { useEffect, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import Tap from "@tapfiliate/tapfiliate-js";
import { useSelector, useDispatch } from "react-redux";

//CSS
import "../assets/css/index.css";
import "../assets/css/fonts.css";
import "../assets/css/mobile.css";

// Import Theme
import {
  useThemeContext,
} from "../assets/theme/ThemeContextProvider";
import getOverrides from "../assets/theme/overrides";

// Import Components
import { PageLoader, SnackBar } from "../components";
import SlideShow from "../containers/SlideShow/SlideShow";
import Seo from "./Seo";

// Routes
import Routes from "../config/routeConfig";

import { featureFlagActions } from "../redux/actions";

// Import Custom Hooks
import AppPageTracking from "../hooks/AppPageTracking";

// Import config credential file
import { app_credentials } from "../config/app_credentials";
import CallRequest from "./VideoCall/CallRequest";
import { StatusBar, Style } from '@capacitor/status-bar';
import { getSystemThemePref, isFlozyMobileApp } from "../utils";
import AppUrlListener from "../components/AppUrlListener/AppUrlListener";

import VCCallRequest from "./VideoCallV2/VCCallRequest";
import { DEFAULT_BOOKING_PAGE_SETTINGS } from "../config/constants";
const {
  tabFiliate: { account_id },
} = app_credentials;

Tap.init(account_id);

function App() {
  // Define Dispatch
  const dispatch = useDispatch();
  const { videoCallRequest } = useSelector((state) => state.videocall);
  const { userData, loginUser } = useSelector((state) => state.auth);
  AppPageTracking();
  const [embedPageSettings, setEmbedPageSettings] = useState(DEFAULT_BOOKING_PAGE_SETTINGS);

  useEffect(() => {
    if (isFlozyMobileApp()) {
      setStatusBarStyleDark();
    }
  }, []);

  useEffect(() => {
    if(loginUser?.id){
      dispatch(featureFlagActions.getFlags());
    }
  }, [loginUser]);

  const setStatusBarStyleDark = async () => {
    const themeColor = localStorage.getItem("themeType") || getSystemThemePref()
    document.body.style.backgroundColor = `${theme.palette.containers.card}`;
    try {
      await StatusBar.setStyle({ style: themeColor === 'light' ? Style.Light : Style.Dark });
    } catch (err) {
      console.log(err)
    }
  };

  const { theme, mode } = useThemeContext();
  const overrides = getOverrides(theme);

  useEffect(() => {
    window.addEventListener('message', function (event) {
      if (event?.data?.pageSettings) {
        setEmbedPageSettings(event?.data?.pageSettings)
      }
    });
  }, [])

  return (
    <React.StrictMode>
      {
        isFlozyMobileApp() && <AppUrlListener />
      }
      <Seo />
      <CssBaseline />
      <ThemeProvider theme={{ ...theme, overrides, embedPage: embedPageSettings }}>
        <Routes />
        <SnackBar />
        <PageLoader />
        <SlideShow />
      </ThemeProvider>
      {
        videoCallRequest !== null &&
        <VCCallRequest />
      }
    </React.StrictMode>
  );
}

export default App;


export const chatv2Constant = {
    GET_CHATV2_PROJECTS_R: "GET_CHATV2_PROJECTS_R",
    GET_CHATV2_PROJECTS_S: "GET_CHATV2_PROJECTS_S",
    GET_CHATV2_PROJECTS_F: "GET_CHATV2_PROJECTS_F",

    GET_CHATV2_CLIENTS_R: "GET_CHATV2_CLIENTS_R",
    GET_CHATV2_CLIENTS_S: "GET_CHATV2_CLIENTS_S",
    GET_CHATV2_CLIENTS_F: "GET_CHATV2_CLIENTS_F",

    GET_CHATV2_TEAMS_R: "GET_CHATV2_TEAMS_R",
    GET_CHATV2_TEAMS_S: "GET_CHATV2_TEAMS_S",
    GET_CHATV2_TEAMS_F: "GET_CHATV2_TEAMS_F",

    UPDATE_APPROVE_USERS_S: "UPDATE_APPROVE_USERS_S",

    GET_ONE_PROJECT_CHAT_R: "GET_ONE_PROJECT_CHAT_R",
    GET_ONE_PROJECT_CHAT_S: "GET_ONE_PROJECT_CHAT_S",
    GET_ONE_PROJECT_CHAT_F: "GET_ONE_PROJECT_CHAT_F",

    GET_ONE_CLIENT_CHAT_R: "GET_ONE_CLIENT_CHAT_R",
    GET_ONE_CLIENT_CHAT_S: "GET_ONE_CLIENT_CHAT_S",
    GET_ONE_CLIENT_CHAT_F: "GET_ONE_CLIENT_CHAT_F",

    GET_ONE_TEAM_CHAT_R: "GET_ONE_TEAM_CHAT_R",
    GET_ONE_TEAM_CHAT_S: "GET_ONE_TEAM_CHAT_S",
    GET_ONE_TEAM_CHAT_F: "GET_ONE_TEAM_CHAT_F",

    GET_CHAT_FILES_R: 'GET_CHAT_FILES_R',
    GET_CHAT_FILES_S: 'GET_CHAT_FILES_S',
    GET_CHAT_FILES_F: 'GET_CHAT_FILES_F',

    GET_CHAT_STAR_R: 'GET_CHAT_STAR_R',
    GET_CHAT_STAR_S: 'GET_CHAT_STAR_S',
    GET_CHAT_STAR_F: 'GET_CHAT_STAR_F',

    GET_CHAT_SEARCH_R: 'GET_CHAT_SEARCH_R',
    GET_CHAT_SEARCH_S: 'GET_CHAT_SEARCH_S',
    GET_CHAT_SEARCH_F: 'GET_CHAT_SEARCH_F',

    SEND_NEW_MESSAGE_R: 'SEND_NEW_MESSAGE_R',
    SEND_NEW_MESSAGE_S: 'SEND_NEW_MESSAGE_S',
    SEND_NEW_MESSAGE_F: 'SEND_NEW_MESSAGE_F',

    ON_RECEIVE_MESSAGE_S: 'ON_RECEIVE_MESSAGE_S',

    UPLOAD_CHATV2_FILES_R: 'UPLOAD_CHATV2_FILES_R',
    UPLOAD_CHATV2_FILES_S: 'UPLOAD_CHATV2_FILES_S',
    UPLOAD_CHATV2_FILES_F: 'UPLOAD_CHATV2_FILES_F',
    UPLOAD_CHATV2_FILES_F: 'UPLOAD_CHATV2_FILES_FR',

    UPDATE_CHATV2_EMOJI_R: 'UPDATE_CHATV2_EMOJI_R',
    UPDATE_CHATV2_EMOJI_S: 'UPDATE_CHATV2_EMOJI_S',
    UPDATE_CHATV2_EMOJI_F: 'UPDATE_CHATV2_EMOJI_F',

    UPDATE_CHATV2_STARRED_MESSAGE_R: 'UPDATE_CHATV2_STARRED_MESSAGE_R',
    UPDATE_CHATV2_STARRED_MESSAGE_S: 'UPDATE_CHATV2_STARRED_MESSAGE_S',
    UPDATE_CHATV2_STARRED_MESSAGE_F: 'UPDATE_CHATV2_STARRED_MESSAGE_F',

    DELETE_CHATV2_MESSAGE_R: 'DELETE_CHATV2_MESSAGE_R',
    DELETE_CHATV2_MESSAGE_S: 'DELETE_CHATV2_MESSAGE_S',
    DELETE_CHATV2_MESSAGE_F: 'DELETE_CHATV2_MESSAGE_F',

    SET_CHATV2_DELIVERY_STATUS_R: 'SET_CHATV2_DELIVERY_STATUS_R',
    SET_CHATV2_DELIVERY_STATUS_S: 'SET_CHATV2_DELIVERY_STATUS_S',
    SET_CHATV2_DELIVERY_STATUS_F: 'SET_CHATV2_DELIVERY_STATUS_F',

    SET_CHATV2_READ_STATUS_R: 'SET_CHATV2_READ_STATUS_R',
    SET_CHATV2_READ_STATUS_S: 'SET_CHATV2_READ_STATUS_S',
    SET_CHATV2_READ_STATUS_F: 'SET_CHATV2_READ_STATUS_F',

    CHATV2_ADD_NOTIFICATION: 'CHATV2_ADD_NOTIFICATION',
    CHATV2_REMOVE_NOTIFICATION: 'CHATV2_REMOVE_NOTIFICATION',

    SET_CHANNEL: 'SET_CHANNEL',

    GET_ALL_CHATV2_USERS_R: 'GET_ALL_CHATV2_USERS_R',
    GET_ALL_CHATV2_USERS_S: 'GET_ALL_CHATV2_USERS_S',
    GET_ALL_CHATV2_USERS_F: 'GET_ALL_CHATV2_USERS_F',

    CHATV2_CREATE_GROUP_R: 'CHATV2_CREATE_GROUP_R',
    CHATV2_CREATE_GROUP_S: 'CHATV2_CREATE_GROUP_S',
    CHATV2_CREATE_GROUP_F: 'CHATV2_CREATE_GROUP_F',

    CHATV2_UPDATE_GROUP_R: 'CHATV2_UPDATE_GROUP_R',
    CHATV2_UPDATE_GROUP_S: 'CHATV2_UPDATE_GROUP_S',
    CHATV2_UPDATE_GROUP_F: 'CHATV2_UPDATE_GROUP_F',

    CHATV2_GET_MUTED_ROOMS_R: 'CHATV2_GET_MUTED_ROOMS_R',
    CHATV2_GET_MUTED_ROOMS_S: 'CHATV2_GET_MUTED_ROOMS_S',
    CHATV2_GET_MUTED_ROOMS_F: 'CHATV2_GET_MUTED_ROOMS_F',

    CHATV2_UPDATE_MUTED_ROOM_R: 'CHATV2_UPDATE_MUTED_ROOM_R',
    CHATV2_UPDATE_MUTED_ROOM_S: 'CHATV2_UPDATE_MUTED_ROOM_S',
    CHATV2_UPDATE_MUTED_ROOM_F: 'CHATV2_UPDATE_MUTED_ROOM_F',

    CHATV2_DELETE_USER_R: 'CHATV2_DELETE_USER_R',
    CHATV2_DELETE_USER_S: 'CHATV2_DELETE_USER_S',
    CHATV2_DELETE_USER_F: 'CHATV2_DELETE_USER_F',

    CHATV2_DELETE_GROUP_R: 'CHATV2_DELETE_GROUP_R',
    CHATV2_DELETE_GROUP_S: 'CHATV2_DELETE_GROUP_S',
    CHATV2_DELETE_GROUP_F: 'CHATV2_DELETE_GROUP_F',

    CHATV2_RESET_CHAT_R: 'CHATV2_RESET_CHAT_R',
    CHATV2_RESET_CHAT_S: 'CHATV2_RESET_CHAT_S',
    CHATV2_RESET_CHAT_F: 'CHATV2_RESET_CHAT_F',

    CHATV2_ADD_GROUP_USERS_R: 'CHATV2_ADD_GROUP_USERS_R',
    CHATV2_ADD_GROUP_USERS_S: 'CHATV2_ADD_GROUP_USERS_S',
    CHATV2_ADD_GROUP_USERS_F: 'CHATV2_ADD_GROUP_USERS_F',

    CHATV2_ONLINE_USER_S: 'CHATV2_ONLINE_USER_S',
    CHATV2_USER_TYPING_S: 'CHATV2_USER_TYPING_S',

    TEAM_CHAT_SEARCH_S:'TEAM_CHAT_SEARCH_S',

    CHATV2_GET_NEW_MESSAGES_R: 'CHATV2_GET_NEW_MESSAGES_R',
    CHATV2_GET_NEW_MESSAGES_S: 'CHATV2_GET_NEW_MESSAGES_S',
    CHATV2_GET_NEW_MESSAGES_F: 'CHATV2_GET_NEW_MESSAGES_F',
    CHATV2_UPDATE_NOTIFICATION_S: 'CHATV2_UPDATE_NOTIFICATION_S',
    CHATV2_SHOWN_NOTIFICATION: 'CHATV2_SHOWN_NOTIFICATION',
    CHATV2_GET_UNSEND_MESSAGES_R:'CHATV2_GET_UNSEND_MESSAGES_R',
    GET_SELECTED_ID:'GET_SELECTED_ID',

    CHATV2_GET_UNREAD_ROOMS_R: 'CHATV2_GET_UNREAD_ROOMS_R',
    CHATV2_GET_UNREAD_ROOMS_S: 'CHATV2_GET_UNREAD_ROOMS_S',
    CHATV2_GET_UNREAD_ROOMS_F: 'CHATV2_GET_UNREAD_ROOMS_F',

    CHATV2_UPDATE_CHAT_ROOM_READ_STATUS_R: 'CHATV2_UPDATE_CHAT_ROOM_READ_STATUS_R',
    CHATV2_UPDATE_CHAT_ROOM_READ_STATUS_S: 'CHATV2_UPDATE_CHAT_ROOM_READ_STATUS_S',
    CHATV2_UPDATE_CHAT_ROOM_READ_STATUS_F: 'CHATV2_UPDATE_CHAT_ROOM_READ_STATUS_F',
    CHAT_V2_REPLY_MESSAGE_OPEN:'CHAT_V2_REPLY_MESSAGE_OPEN',
    CHAT_V2_FORWARD_MESSAGE:'CHAT_V2_FORWARD_MESSAGE',
    
    UPDATE_CHATVIEW_TYPE: 'UPDATE_CHATVIEW_TYPE',

    'BULK_ONLOAD_READ_R': 'BULK_ONLOAD_READ_R',
    'BULK_ONLOAD_READ_S': 'BULK_ONLOAD_READ_S',
    'BULK_ONLOAD_READ_F': 'BULK_ONLOAD_READ_F',

    'NEW_ROOM_DETAIL_R':'NEW_ROOM_DETAIL_R',
    'NEW_ROOM_DETAIL_S':'NEW_ROOM_DETAIL_S',
    'NEW_ROOM_DETAIL_F':'NEW_ROOM_DETAIL_F',

    'UPDATE_CHAT_SCROLL_BT':'UPDATE_CHAT_SCROLL_BT',
    'SET_CHATV2_BULK_READ_STATUS_S': 'SET_CHATV2_BULK_READ_STATUS_S',

    EDIT_MESSAGE_R: 'EDIT_MESSAGE_R',
    EDIT_MESSAGE_S: 'EDIT_MESSAGE_S',
    EDIT_MESSAGE_F: 'EDIT_MESSAGE_F',
    CHAT_V2_EDIT_MESSAGE_OPEN: "CHAT_V2_EDIT_MESSAGE_OPEN",
    ON_RECEIVE_MESSAGE_CHANNEL_UPDATE: "ON_RECEIVE_MESSAGE_CHANNEL_UPDATE",
    OPEN_SELECT_MESSAGE: 'OPEN_SELECT_MESSAGE',
    CLEAR_CHAT_STATE: "CLEAR_CHAT_STATE",

    GROUP_FORWARD_MESSAGE_R: 'GROUP_FORWARD_MESSAGE_R',
    GROUP_FORWARD_MESSAGE_S: 'GROUP_FORWARD_MESSAGE_S',
    GROUP_FORWARD_MESSAGE_F: 'GROUP_FORWARD_MESSAGE_F',

    RELOAD_CHAT_TABS: 'RELOAD_CHAT_TABS'
};

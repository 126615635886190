import React from "react";

export default function FakeBar(props) {
  const { msgComponentType } = props;
  return (
    <div className="bar">
      <span className="bar__time start">{"00:00"}</span>
      <div
        className="bar__progress"
        style={msgComponentType === "sent" ? { background: `linear-gradient(to right, #f1f1f1 0%, #f1f1f161 0)` } : { background: `linear-gradient(to right, #3182FF 0%, #c5c5c5 0)` }}
      >
        <span
          className="bar__progress__knob"
          style={{ left: `0%` }}
        />
      </div>
      <span className="bar__time end">{"00:00"}</span>
    </div>
  );
}

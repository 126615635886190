import { useState } from "react";
import { createTheme } from "@material-ui/core";
import React from "react";
import { getPalette } from "./index";
import typography from "./typography";
import overrides from "./overrides";
import { getSystemThemePref } from "../../utils";

export const useColorTheme = () => {
  // const initialThemeType = localStorage.getItem('themeType') || 'system';
  // const [mode, setMode] = useState(initialThemeType === 'system' ? getSystemThemePref() : initialThemeType);
  // const [isPendingChanges, setIsPendingChanges] = useState(false);
  // const [selectedThemeType, setSelectedThemeType] = useState(initialThemeType);
  
  const initialThemeType = localStorage.getItem('themeType') || getSystemThemePref();
  const [mode, setMode] = useState(initialThemeType);
  const [isPendingChanges, setIsPendingChanges] = useState(false);
  const [selectedThemeType, setSelectedThemeType] = useState(localStorage.getItem('themeType') || 'system');
  

  const toggleColorMode = (newType) => {
    setSelectedThemeType(newType);
    setIsPendingChanges(true);
  };

  // const saveThemeChanges = () => {
  //   localStorage.setItem('themeType', selectedThemeType);
  //   // setMode(selectedThemeType);
  //   setMode(selectedThemeType === 'system' ? getSystemThemePref() : selectedThemeType);
  //   setIsPendingChanges(false);
  // };
  const saveThemeChanges = () => {
    let themeToSavelocal = selectedThemeType;
    if (selectedThemeType === 'system') {
      themeToSavelocal = getSystemThemePref(); 
    }
  
    localStorage.setItem('themeType', themeToSavelocal);
    setMode(themeToSavelocal);
    setIsPendingChanges(false);
  };
  

  const changeMode = (theme) => {
    setMode(theme);
  }

  // const toggleColorMode = () => {
  //   localStorage.setItem('themeType', mode)
  //   setMode(mode === "light" ? "dark" : "light");
  // }

  const modifiedTheme = React.useMemo(
    () => {
      return createTheme({
        palette: getPalette(mode),
        overrides,
        typography,
      })
    },
    [mode]
  );

  return {
    theme: modifiedTheme,
    mode,
    toggleColorMode,
    isPendingChanges,
    saveThemeChanges,
    selectedThemeType,
    changeMode
  };
};

import { eventsConstant } from "../constants/eventConstant";

const initialState = {
    eventList: [],
    tasksList: [],
    bookingEventsList: [],
    googleEventsList: [],
    eventData: {},
    eventView: 'day',
    dateRange: {
        start_date: new Date(),
        end_date: new Date()
    },
    emailAddress: '',
    currentView: "",
    authURL: '',
    tokens: {},
    googleMeetLink: null,
    event_date: null,
    agendaList: [],
    isLoading: false
}

export default function event(state = initialState, action) {
    switch (action.type) {
        /** GET EVENTS */
        case eventsConstant.GET_EVENT_R:
            return {
                ...state,
                googleMeetLink: initialState.googleMeetLink,
                eventList: initialState.eventList
            }
        case eventsConstant.GET_EVENT_S:
            return {
                ...state,
                googleMeetLink: initialState.googleMeetLink,
                eventList: (action?.data?.data?.events) || [],
                tasksList: (action?.data?.data?.tasks) || [],
                bookingEventsList: (action?.data?.data?.bookings) || [],
            }
        case eventsConstant.GET_EVENT_F:
            return {
                ...state,
                eventList: initialState.eventList,
                googleMeetLink: initialState.googleMeetLink,
                tasksList: initialState.tasksList,
                bookingEventsList: initialState.bookingEventsList
            }
        case eventsConstant.GET_AGENDA_EVENT_R:
            return {
                ...state,
                agendaList: initialState.agendaList,
                isLoading: true
            }
        case eventsConstant.GET_AGENDA_EVENT_S:
            return {
                ...state,
                agendaList: [
                    ...action?.data?.data?.events,
                    ...action?.data?.data?.tasks,
                    ...action?.data?.data?.bookings
                ] || [],
                isLoading: false
            }
        case eventsConstant.GET_AGENDA_EVENT_F:
            return {
                ...state,
                agendaList: initialState.agendaList,
                isLoading: false
            }
        case eventsConstant.GET_SELECTED_EVENT_R:
            return {
                ...state,
                eventData: initialState.eventData
            }
        case eventsConstant.GET_SELECTED_EVENT_S:
            return {
                ...state,
                eventData: action?.data?.data
            }
        case eventsConstant.GET_SELECTED_EVENT_F:
            return {
                ...state,
                eventData: initialState.eventData
            }
        case eventsConstant.GET_GOOGLE_ACCOUNT_R:
            return {
                ...state,
                googleEventsList: initialState.googleEventsList
            }
        case eventsConstant.GET_GOOGLE_ACCOUNT_S:
            // handle google disconnect on accounts page
            const respData = (action?.data?.data) || [];
            if (respData && Array.isArray(respData)) {
                return {
                    ...state,
                    googleEventsList: (action?.data?.data) || [],
                }
            } else if (respData?.error && respData?.error === "UNAUTHENTICATED") {
                localStorage.setItem('emailtoken', '')
                localStorage.setItem('emailAddress', '')
                return {
                    ...state,
                    googleEventsList: [],
                    emailAddress: ''
                }
            } else {
                return {
                    ...state,
                    googleEventsList: [],
                }
            }
        case eventsConstant.GET_GOOGLE_ACCOUNT_F:
            return {
                ...state,
                googleEventsList: initialState.googleEventsList
            }
        case eventsConstant.GET_GOOGLE_EMAIL_R:
            return {
                ...state,
                emailAddress: initialState.emailAddress
            }
        case eventsConstant.GET_GOOGLE_EMAIL_S:
            return {
                ...state,
                emailAddress: (action?.data) || '',
            }
        case eventsConstant.GET_GOOGLE_EMAIL_F:
            return {
                ...state,
                emailAddress: initialState.emailAddress
            }

        // update event
        case eventsConstant.UPDATE_EVENT_REDUCER:
            return {
                ...state,
                dateRange: action.data
            }

        case eventsConstant.UPDATE_EVENTVIEW_REDUCER:
            return {
                ...state,
                currentView: action.data
            }

        // update event view
        case eventsConstant.GET_EVENTVIEW_R:
            return {
                ...state,
                // eventView: initialState.eventView,
                // event_date:initialState.event_date
            }
        case eventsConstant.GET_EVENTVIEW_S:
            return {
                ...state,
                eventView: action?.data?.data.view,
                event_date: action?.data?.data.date,
            }
        case eventsConstant.GET_EVENTVIEW_F:
            return {
                ...state,
                // eventView: initialState.eventView,
                // event_date: initialState.event_date
            }

        // update Google Auth URL
        case eventsConstant.GET_GOOGLE_AUTH_URL_R:
            return {
                ...state,
                authURL: initialState.authURL
            }
        case eventsConstant.GET_GOOGLE_AUTH_URL_S:
            return {
                ...state,
                authURL: action?.data?.data?.authUrl
            }
        case eventsConstant.GET_GOOGLE_AUTH_URL_F:
            return {
                ...state,
                authURL: initialState.authURL
            }

        // update Google Token
        case eventsConstant.GET_GOOGLE_TOKEN_R:
            return {
                ...state,
                tokens: initialState.authURL
            }
        case eventsConstant.GET_GOOGLE_TOKEN_S:
            return {
                ...state,
                tokens: action?.data?.tokens
            }
        case eventsConstant.GET_GOOGLE_TOKEN_F:
            return {
                ...state,
                tokens: initialState.authURL
            }

        // Disconnect Google Account
        case eventsConstant.DISCONNECT_GOOGLE_ACCOUNT_S:
            return {
                ...state,
                googleEventsList: initialState.googleEventsList,
                emailAddress: initialState.emailAddress
            }

        case eventsConstant.GET_MEET_LINK:
            return {
                ...state,
                googleMeetLink: action.data
            }

        case eventsConstant.DELETE_EVENT_S:
             return {
                ...state,
                agendaList : state.agendaList.filter(item => (item.id !== action.data))
             }
                
        default:
            return state
    }
}
import React, { Fragment } from 'react';
import PropTypes from "prop-types";
import Autocomplete from '@material-ui/lab/Autocomplete';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';
import { withStyles, TextField, Typography } from "@material-ui/core";

//import styles
import styles from "./styles";

function AutoCompleteComponent(props) {

  const {
    suggestions,
    name,
    disabled,
    value,
    classes,
    showValidate,
    validationMsg,
    ...rest
  } = props;

  return (
    <Fragment >
      <Autocomplete
        blurOnSelect
        id={`ac-id${name}`}
        name={`${name}`}
        variant={"outlined"}
        options={suggestions}
        disabled={disabled}
        classes={{...classes, paper: classes.paper}}
        freesolo={true}
        hiddenLabel="true"
        disableClearable={true}
        autoHighlight={true}
        value={value && value.value ? value : { label: '', value: '' }}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, value) => option.value === value.value}
        onChange={(event, newValue) => { props.handleAutoComplete(newValue || { label: '', value: '' }, name) }}
        onInputChange={(value, eValue, reason) => { props.onInputChange(value, eValue, reason) }}
        renderOption={option => {
          return (
            <Fragment>
              <FiberManualRecordOutlinedIcon style={{ fill: option.color ? option.color : "#000000" }} />
              <Typography variant="subtitle1" color="textSecondary">
                {option.label}
              </Typography>
            </Fragment>
          );
        }
        }
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              value={value}
              variant="outlined"
              {...rest}
              error={showValidate ? true : false}
              helperText={showValidate ? validationMsg : ""}
            />
          )
        }}
        {...rest}
      />
    </Fragment>
  );
}

// default props
AutoCompleteComponent.defaultProps = {
  suggestions: [],
  value: "",
  disabled: false,
  name: "autocomplete",
  handleAutoComplete: () => { },
  onInputChange: () => { },
  showValidate: false,
  validationMsg: ''
};

// prop types
AutoCompleteComponent.propTypes = {
  suggestions: PropTypes.any,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  handleAutoComplete: PropTypes.func,
  onInputChange: PropTypes.func,
  showValidate: PropTypes.bool,
  validationMsg: PropTypes.string
};

/** Export Component */
export const AutoCompleteChip = withStyles(styles)(AutoCompleteComponent);

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';


//import components 
import { SlideShowComponent } from "../../components/SlideShow";

//import action
import { updateAction } from '../../redux/actions';

//import constant
import { slideShowConstant } from '../../redux/constants';

function SlideShow(props) {
    const dispatch = useDispatch();
    //get props
    const { files, open, activeFileIndex, star, previewData, data, isFromMedia, mediaFiles, directDownload } = useSelector(s => s.slideShow);
    const { id } = useSelector(s => s.auth.loginUser)
    const { profile: { avatar_filename } } = useSelector(s => s.profile)

    const handleClickOpen = () => {
        dispatch(updateAction.update(slideShowConstant.DISPLAY_STATUS_TRUE))
    };

    const handleClose = () => {
        dispatch(updateAction.update(slideShowConstant.DISPLAY_STATUS_FALSE))
    };

    return (
        <>
            <SlideShowComponent
                open={open}
                items={files}
                index={activeFileIndex}
                star={star}
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
                data={data}
                previewData={previewData}
                userId={id}
                userAvatar={avatar_filename}
                isFromMedia={isFromMedia}
                mediaFiles={mediaFiles}
                directDownload={directDownload}
            />
        </>
    )
}

// default props
SlideShow.defaultProps = {
    classes: {}
};

// prop types
SlideShow.propTypes = {
    classes: PropTypes.object
};

export default SlideShow;
/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { _envParams } from "../../config/env_params";

import PrivateLayoutWeb from "./PrivateLayoutWeb";
import PrivateLayoutMobile from "./PrivateLayoutMobile";
import ScriptComponent from "./Component/ScriptComponent";

const PrivateLayout = (props) => {

  const { profile } = useSelector(s => (s.profile));

  return (
    <>
      {profile && profile.hasOwnProperty('userEmail') && profile.userEmail ? <ScriptComponent /> : null}
      {_envParams.REACT_APP_BUILD_FOR === "WEB" ? <PrivateLayoutWeb {...props} /> : <PrivateLayoutMobile {...props} />}
    </>
  );
}

// Define Props Types
PrivateLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
  user: PropTypes.object.isRequired,
  access: PropTypes.bool.isRequired
};

// Default props
PrivateLayout.defaultProps = {
  classes: {},
  children: {},
  user: {},
  access: false
};

export default PrivateLayout;

import React, { Fragment } from 'react';
import PropTypes from "prop-types";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles, TextField, Avatar, Card, CardContent } from "@material-ui/core";

//import styles
import styles from "./styles";
import { Typography } from '../Typography';
import { TitleAvatar } from '../TitleAvatar';


function AutoCompleteComponent(props) {

  const {
    suggestions,
    name,
    disabled,
    value,
    classes,
    showValidate,
    validationMsg,
    fullWidth,
    placeholder,
    variant,
    className,
    ...rest
  } = props;

  return (
    <Fragment >
      <Autocomplete
        multiple
        id={`ac-id${name}`}
        name={`${name}`}
        variant={"outlined"}
        disabled={disabled}
        options={suggestions}
        fullWidth={fullWidth}
        hiddenLabel="true"
        className={className}
        classes={{ paper: classes.paper }}
        disableClearable={true}
        getOptionLabel={option => option.label}
        renderOption={option => {
          return (
            <Fragment>
              <Card className={classes.root} key={`jAC_${option.value}`}>
                {option && option.avatar ?
                  <TitleAvatar type={option.avatar_type ? option.avatar_type : 'color'} value={option.avatar ? option.avatar : null} title={option.label} />
                  :
                  <Avatar alt={option.label} src={option.label} />
                }
                <div className={classes.details}>
                  <CardContent className={classes.content}>
                    <Typography component="h5" variant="h5">
                      {option.label}
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                      {option.helperText}
                    </Typography>
                  </CardContent>
                </div>
              </Card>
            </Fragment>
          );
        }
        }
        filterSelectedOptions={true}
        value={value ? value : []}
        onChange={(event, newValue) => { props.handleAutoComplete(newValue || [], name) }}
        onInputChange={(value, eValue, reason) => { props.onInputChange(value, name, reason) }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={variant}
            placeholder={placeholder}
            {...rest}
            error={showValidate ? true : false}
            helperText={showValidate ? validationMsg : ""}

          />
        )}
        {...rest}
      />
    </Fragment>
  );
}

// default props
AutoCompleteComponent.defaultProps = {
  suggestions: [],
  value: "",
  disabled: false,
  className: '',
  variant: "outlined",
  name: "autocomplete",
  handleAutoComplete: () => { },
  onInputChange: () => { },
  showValidate: false,
  validationMsg: '',
  placeholder: 'Enter value',
};

// prop types
AutoCompleteComponent.propTypes = {
  suggestions: PropTypes.any,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  handleAutoComplete: PropTypes.func,
  onInputChange: PropTypes.func,
  showValidate: PropTypes.bool,
  validationMsg: PropTypes.string,
  fullWidth: PropTypes.bool,
  placeholder: PropTypes.string,
};

/** Export Component */
export const JobMultipleAutoComplete = withStyles(styles)(AutoCompleteComponent);

import { alertConstant, chatHubConstant, loaderConstant } from "../constants";
import { videoCallConstants } from "../constants/videoCallConstant";
import { videoCallService } from "../services/videoCallService";
import { baseAction } from "./baseAction";
import { chatAction } from "./chatAction";
import { chatHubAction } from "./chatHubAction";
const { request, success, failure } = baseAction;

export const videoCallAction = {
  sendRequest,
  joinRequest,
  joinRequestSuccess,
  receiveVideoCallRequest,
  setRemoteUsers,
  setScreenShareVideo,
  stopScreenShare,
  getScreenTrack,
  rejectRequest,
  saveCallHistory,
  getHistory,
  updateHistory,
  notifyUsers,
  rejectCall,
  callEnded,
  CallInitialize,
  CallEnd,
  muteUsers,
  createFlozyRoom,
  deleteFlozyRoom,
}


function sendRequest(body, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(videoCallConstants.JOIN_REQUEST_R, body));
    videoCallService.joinRequest(body).then(data => {
      dispatch(success(videoCallConstants.JOIN_REQUEST_S, data));
      callBack()
    }, error => {
      dispatch(failure(videoCallConstants.JOIN_REQUEST_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
    }
    );
  };
}

function joinRequest(msgData) {
  return (dispatch) => {
    const { requestId } = msgData;
    dispatch(success(videoCallConstants.SEND_REQUEST_S, { data: msgData }));
  };
}



function joinRequestSuccess(requestId) {
  return {
    type: videoCallConstants.SEND_REQUEST_S,
    data: { result: requestId },
  };
}


export function receiveVideoCallRequest(data) {
  return {
    type: videoCallConstants.RECEIVE_VIDEO_CALL_REQUEST_S,
    payload: data,
  };
}


export function setRemoteUsers(remoteUsers) {
  return {
    type: videoCallConstants.REMOTE_USERS_S,
    data: remoteUsers,
  }
}

function setScreenShareVideo(screenShareVideo) {
  return {
    type: videoCallConstants.SCREEN_SHARE_S,
    payload: screenShareVideo,
  };
}

function getScreenTrack(data) {
  return {
    type: videoCallConstants.GET_SCREEN_TRACK_S,
    data: data,
  };
}

function stopScreenShare(data) {
  return (dispatch) => {
    dispatch({
      type: videoCallConstants.STOP_SCREEN_SHARE_S,
      data: data
    });
  }
}

export function rejectRequest(data) {
  return (dispatch) => {
    dispatch({
      type: videoCallConstants.REJECT_REQUEST_S,
      payload: data,
    });

    dispatch(videoCallAction.receiveVideoCallRequest(null));

    switch (data.type) {
      case 'projects':
        dispatch(chatHubAction.getJobChat({ job_id: data.id }));
        break;
      case 'clients':
        dispatch(chatAction.getClientChat({ client_id: data.id }));
        break;
      case 'teams':
        dispatch(chatHubAction.getTeamChat({ group_id: data.id }));
        break;
      default:
        console.log('Unknown type:', data.type);
    }
  };
}


function saveCallHistory(body, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(videoCallConstants.SAVE_CALL_HISTORY_R, body));
    videoCallService.saveCallHistory(body).then(
      (data) => {
        dispatch(success(videoCallConstants.SAVE_CALL_HISTORY_S, data));
        callBack();
      },
      (error) => {
        dispatch(failure(videoCallConstants.SAVE_CALL_HISTORY_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
    dispatch(videoCallAction.notifyUsers(null))
  };
}

function getHistory(body, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(videoCallConstants.GET_CALL_HISTORY_R, body));
    videoCallService.getHistory(body).then(
      (data) => {
        dispatch(success(videoCallConstants.GET_CALL_HISTORY_S, data.data));
        callBack();
      },
      (error) => {
        dispatch(failure(videoCallConstants.GET_CALL_HISTORY_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}



function updateHistory(id, body, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(videoCallConstants.UPDATE_VIDEOCALL_R, id, body));
    videoCallService.updateHistory(id, body).then(
      (data) => {
        dispatch(success(videoCallConstants.UPDATE_VIDEOCALL_S, data));
        callBack();
      },
      (error) => {
        dispatch(failure(videoCallConstants.UPDATE_VIDEOCALL_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}



export function notifyUsers(data) {
  return {
    type: videoCallConstants.NOTIFY_USER_S,
    payload: data,
  };
}

export function muteUsers(data) {
  return {
    type: videoCallConstants.MUTE_USER_S,
    payload: data,
  };
}


export function rejectCall(data) {
  return (dispatch) => {
    dispatch({
      type: videoCallConstants.REJECT_CALL_S,
      payload: data,
    });

  };
}

export function callEnded(data) {
  return (dispatch) => {
    dispatch({
      type: videoCallConstants.CALL_ENDED_S,
      payload: data,
    });
    dispatch(videoCallAction.notifyUsers([]))
  };
}

function CallInitialize(id, body, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(videoCallConstants.CALL_INITIALIZE_R, id, body));
    videoCallService.callInitalize(id, body).then(
      (data) => {
        dispatch(success(videoCallConstants.CALL_INITIALIZE_S, data));
        callBack();
      },
      (error) => {
        dispatch(failure(videoCallConstants.CALL_INITIALIZE_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}

function CallEnd(id, body, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(videoCallConstants.CALL_END_R, id, body));
    videoCallService.callEnded(id, body).then(
      (data) => {
        dispatch(success(videoCallConstants.CALL_END_S, data));
        callBack();
      },
      (error) => {
        dispatch(failure(videoCallConstants.CALL_END_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}

function createFlozyRoom(body, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(videoCallConstants.CREATE_FLOZY_ROOM_R, body));
    videoCallService.createFlozyRoom(body).then(
      (data) => {
        dispatch(success(videoCallConstants.CREATE_FLOZY_ROOM_S, data.data));
        callBack();
      },
      (error) => {
        dispatch(failure(videoCallConstants.CREATE_FLOZY_ROOM_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}

function deleteFlozyRoom(body, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(videoCallConstants.DELETE_FLOZY_ROOM_R,body));
    videoCallService.deleteFlozyRoom(body).then(
      (data) => {
        dispatch(success(videoCallConstants.DELETE_FLOZY_ROOM_S, data.data));
        callBack();
      },
      (error) => {
        dispatch(failure(videoCallConstants.DELETE_FLOZY_ROOM_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}

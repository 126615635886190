import React, { useEffect, Suspense } from "react";
import { Route, Switch, useLocation, Redirect } from "react-router-dom";
import AuthRoute from "../auth_routes/AuthRoute";
import PublicRoute from "../auth_routes/PublicRoute";
import PrivateRoute from "../auth_routes/PrivateRoute";
import PaymentRoute from "../auth_routes/PaymentRoute";

import { PageLoader } from '../components';

import history from "./history";
import RedirectTo from "../containers/RedirectTo";

import { isFlozyMobileApp } from "../utils/appHelper.js";

const Page404 = React.lazy(() => import("../containers/ErrorPage/Page404/Page404"))
const Users = React.lazy(() => import("../containers/Users/Users"))
const Login = React.lazy(() => import("../containers/Login/Login"))
const SignupRouter = React.lazy(() => import("../containers/SignUp/SignUp.Router"))
const ForgotPassword = React.lazy(() => import("../containers/Login/ForgotPassword"))
const ResetPassword = React.lazy(() => import("../containers/Login/ResetPassword"))
const ProjectsRouter = React.lazy(() => import("../containers/Projects/Projects.Route"))
const ClientsRouter = React.lazy(() => import("../containers/Clients/Clients.Route"))
const InvoiceRouter = React.lazy(() => import("../containers/Invoices/Invoice.Route"))
const DashboardRouter = React.lazy(() => import("../containers/Dashboard/Dashboard.Route"))
const Invite = React.lazy(() => import("../containers/Projects/Invite"))
const InviteClient = React.lazy(() => import("../containers/Clients/Invite"))
const InviteUser = React.lazy(() => import("../containers/Teams/Invite"))
const TeamsRouter = React.lazy(() => import("../containers/Teams/Teams.Route"))
const AgencySignUp = React.lazy(() => import("../containers/SignUp/AgencySignup"))
const TaskRouter = React.lazy(() => import("../containers/Tasks/Tasks.Route"))
const Payment = React.lazy(() => import("../containers/Payment/Payment"))
const NoAccess = React.lazy(() => import("../containers/NoAccess"))
const AgencyAdd = React.lazy(() => import("../containers/AgencyAdd/AgencyAdd.Router"))
const TermsAndCondition = React.lazy(() => import("../containers/Terms/TermsAndCondition"))
const PrivacyPolicy = React.lazy(() => import("../containers/PrivacyPolicy/PrivacyPolicy"))
const InvoiceDocument = React.lazy(() => import("../containers/Payment/PaymentPDF/InvoiceDocument"))
const AgencySignUpRoute = React.lazy(() => import("../containers/MasterMind/AgencySignup.Router"))
const Plans = React.lazy(() => import("../containers/UpdatePlan"))
const MyBillsRouter = React.lazy(() => import("../containers/MyBillsNew/MyBills.Route"))
const Boards = React.lazy(() => import("../containers/Boards/Boards"))
const SettingsRouter = React.lazy(() => import("../containers/Settings/Settings.Route"))
const Docs = React.lazy(() => import("../containers/Docs"))
const PublicPage = React.lazy(() => import("../containers/Docs/PublicPage"))
const Chatv2Router = React.lazy(() => import("../containers/Chatv2/Chatv2.Route"))
const SignUpProcess = React.lazy(() => import("../containers/SignUp/SignUpProcess"))
const PaymentProcess = React.lazy(() => import("../containers/SignUp/PaymentNEW/PaymentForm"))
const WorkspaceSharing = React.lazy(() => import("../containers/WorkspaceSharing"))
const ProcessValidate = React.lazy(() => import("../containers/ProcessValidate"))
const Referrals = React.lazy(() => import("../containers/Referrals"))
const AppPayments = React.lazy(() => import("../containers/AppPayments"))
const SomethingWentWrong = React.lazy(() => import("../containers/ErrorPage/WentWrong"))
const NotificationList = React.lazy(() => import("../containers/Notification/NotificationList"))
const Unsubscription = React.lazy(() => import("../containers/UnSubscribe"))
const CalendarRouter = React.lazy(() => import("../containers/Calendar/Calendar.Route"))
const PageBuilderRouter = React.lazy(() => import("../containers/PageBuilder/PageBuilder.Route"))
const ClientOnBoarding = React.lazy(() => import("../containers/Clients/OnBoarding"))
const NoRoute = React.lazy(() => import("../auth_routes/NoRoute"))
const BookingRouter = React.lazy(() => import("../containers/Booking/Booking.Router"))
const UserBooking = React.lazy(() => import("../containers/Booking/UserBooking/index"))
const FunnelRouter = React.lazy(() => import("../containers/Funnel/Funnel.Route"))
const EndUserLayout = React.lazy(() => import("../containers/Funnel/EndUserComponents/EndUserFlow"))
const PublicBoard = React.lazy(() => import("../containers/Boards/PublicBoard"))
const UnderMaintenance = React.lazy(() => import("../containers/UnderMaintenance/UnderMaintenance.jsx"));
const UpdateRequired = React.lazy(() => import("../containers/UpdateRequired/UpdateRequired.jsx"));


const Routes = (props) => {

    // const { id: userId, email: authEmail } = useSelector(s => (s.auth.loginUser));

    //redirect to page on login
    const isSignedIn = localStorage.getItem('staySignInAG');

    //current url
    const pathName = history.location.pathname;
    const location = useLocation();

    // Added to remove the session data to clear the reload count to fix mention trigger issue in chat
    useEffect(() => {
        if (!isFlozyMobileApp()) {
            if (!pathName?.includes("chat") && !pathName?.includes("projects/detail") && !pathName?.includes('clients/detail')) {
                sessionStorage.removeItem('reloadCount');
            }
        }
    }, [])
    // search query params
    const { search, origin } = window.location
    const searchparams = new URLSearchParams(search)
    if (searchparams.get("ref")) {
        let queryString = location && location.search ? location.search + search.replace("?", "&") : search;
        window.location.replace(`${origin}/#/signup${queryString}`)
    }

    return (
        <Suspense fallback={<PageLoader isLoading={true} />}>
            <Switch>
                <AuthRoute exact path="/" component={Login} />
                {/* END USER FUNNEL */}

                <Route exact path="/funnel/:id" component={EndUserLayout} />
                {/* <PublicRoute exact path="/payment" component={PaymentOne} /> */}
                <PaymentRoute exact path="/pay/:id/:mode?" component={Payment} />
                <PaymentRoute exact path="/payPdf/:id" component={InvoiceDocument} />

                <AuthRoute exact path="/login" component={Login} />
                <AuthRoute exact path="/loginUser" component={Login} />
                <AuthRoute exact path="/signup" component={SignupRouter} />
                <PrivateRoute exact path="/process_validate" component={ProcessValidate} />
                <PublicRoute exact path="/signup/process" component={SignUpProcess} />
                <PublicRoute exact path="/signup/payment" component={PaymentProcess} />
                <PublicRoute exact path={["/bookings/:event_url", "/bookings/:event_url/:type/:booking_id"]} component={UserBooking} />

                {/* App Payment Links */}
                <PublicRoute path="/enroll/:type" component={AgencySignUpRoute} />

                {/* Application Payment Links
                Defined Payment types type: plan, addon, referral
                */}
                <PublicRoute path="/payments" component={AppPayments} />
                {/* Custom Payment Links - Referral and others */}
                <PublicRoute path="/referral/:code" component={AppPayments} />
                <PublicRoute path="/educate/:code" component={AppPayments} />

                {/* Public Board Link */}
                <PublicRoute path="/publicBoard/:publicLink" component={PublicBoard} />



                {/* <AuthRoute exact path="/PaymentProcess" component={PaymentProcess} /> */}
                <AuthRoute exact path="/forgotPassword" component={ForgotPassword} />
                <AuthRoute exact path="/resetPassword/:hash/:key" component={ResetPassword} />

                {/* Invite Link with password Old One */}
                <AuthRoute exact path="/invite/:token/:password" component={Invite} />
                <AuthRoute exact path="/inviteClient/:token/:password" component={InviteClient} />
                <AuthRoute exact path="/inviteUser/:token/:password" component={InviteUser} />

                {/* Invite Link without passwords New Links */}
                <AuthRoute exact path="/invite/:token" component={Invite} />
                <AuthRoute exact path="/inviteClient/:token" component={InviteClient} />
                <AuthRoute exact path="/inviteUser/:token" component={InviteUser} />


                {/* Private Routes */}
                <PrivateRoute exact path="/process_validate" component={ProcessValidate} />
                <PrivateRoute exact path="/createAgency" component={AgencySignUp} />
                <PrivateRoute path="/projects" component={ProjectsRouter} />
                <PrivateRoute path="/clients" component={ClientsRouter} />
                <PrivateRoute exact path="/dashboard" component={DashboardRouter} />
                <PrivateRoute path="/mybills" component={MyBillsRouter} />
                <PrivateRoute path="/teams" component={TeamsRouter} />
                <PrivateRoute path="/tasks" component={TaskRouter} />
                <PrivateRoute path="/invoices" component={InvoiceRouter} />
                <PrivateRoute path="/calendar" component={CalendarRouter} />
                <PrivateRoute path="/NoAccess" component={NoAccess} />
                <PrivateRoute path="/agencyAdd" component={AgencyAdd} />
                <PrivateRoute path="/boards" component={Boards} />
                <PrivateRoute path="/upgradePlan" component={Plans} />

                <PrivateRoute path="/settings" component={SettingsRouter} />
                <PrivateRoute path="/brain" component={Docs} />
                <PrivateRoute path="/booking" component={BookingRouter} />
                <PrivateRoute path="/funnel" component={FunnelRouter} />

                {/* Chatv2 */}
                <PrivateRoute path="/chatv2" component={() => <Redirect to="/chat" />} />
                <PrivateRoute path="/chat" component={Chatv2Router} />
                <PrivateRoute path="/wsm" component={WorkspaceSharing} />
                <PrivateRoute path="/redirect" component={RedirectTo} />
                <PrivateRoute path="/referral" component={Referrals} />
                <PrivateRoute path="/sww" component={SomethingWentWrong} />
                <PrivateRoute exact path="/notification" component={NotificationList} />

                {/* Video Call Route */}
                {/* <PublicRoute exact path="/meet/:meetingId" component={videocall} /> */}


                {/** Page Builder */}
                <PrivateRoute path="/page-builder" component={PageBuilderRouter} />
                
                {/* Public Routes */}
                {/* <PublicRoute exact path="/afpayment" component={PublicPayment} /> */}
                <PublicRoute exact path="/termsandconditions" component={TermsAndCondition} />
                <PublicRoute exact path="/privacypolicy" component={PrivacyPolicy} />
                <PublicRoute exact path="/users" component={Users} />
                <PublicRoute exact path="/document/:token" component={PublicPage} />
                <PublicRoute exact path="/unsubscribe/:token" component={Unsubscription} />
                <NoRoute exact path="/client-onboarding/:invite" component={ClientOnBoarding} />
                <NoRoute exact path="/under-maintenance" needLogo={false} component={UnderMaintenance} />
                <NoRoute exact path="/update-app" needLogo={false} component={UpdateRequired} />
                {isSignedIn ? <PrivateRoute component={Page404} /> : <PublicRoute component={Page404} />}
            </Switch>
        </Suspense>
    );
};

export default Routes;

import { baseAction } from "./baseAction";
import { chatv2Constant, loaderConstant, alertConstant, uiConstant } from "../constants";
import { profileAction } from "./profileAction";
import { chatHubService, chatService } from "../services";
import {
    addNewMessage, getOldChat, updateNewMessages,
    updateChannelRecentMessage,
    addOrRemoveUser, deleteChatv2Group,
    changeMessageReadAt, changeMultipleMessageReadAt,
    updateMessageEdit,
    changeMultipleMessageDeliveredAt, updateChannelEditedMessage,
    updateNotificationeCount
} from "../helpers/chatv2Helper";
import { sendMessage } from "../../utils/socketv2";
import history from "../../config/history";
import { getDefaultChatRoomPreference, setChatv2Preferences } from "../../utils";
import { isMobile } from "react-device-detect";

const { request, success, failure } = baseAction;

export const chatv2Action = {
    getProjectsChatList,
    getClientsChatList,
    getTeamsChatList,
    getProjectChatHistory,
    getClientChatHistory,
    getTeamChatHistory,
    getStarHistory,
    getChatFiles,
    getSearchResult,
    sendNewMessage,
    onReceiveMessage,
    uploadChatFiles,
    updateEmojiReactions,
    updateStarredMessage,
    deleteChat,
    setChannel,
    setChatDeliveryStatus,
    setChatReadStatus,
    removeNotification,
    getAllUsers,
    createGroup,
    getMutedChats,
    updateMutedChat,
    updateGroupData,
    deleteTeamMember,
    deleteGroup,
    resetChatMessages,
    socketGroupDelete,
    socketUserDelete,
    addGroupUsers,
    socketUsersAdd,
    socketUserOnline,
    updateChatReadStatus,
    updateReadStatusTrack,
    socketUserTyping,
    getNewMessages,

    getMessageInfo,
    updateDraftMessage,
    getUnReadChat,
    updateChatRoomReadStatus,
    setReplyMessage,
    handleForwardMessage,
    updateApproveUsers,
    onLoadMessageReadStatus,
    getNewRoomDetail,
    readStatusUpdateBulk,
    setEditMessage,
    editMessage,
    onEditChat,
    deliveredStatusUpdateBulk,
    forwardMultipleMessage,
    reloadChatTabs
}

function goToDefaultChatRoom(params, list = []) {
    if (!params.id && list[0]?.id && !params.forward) {
        // check for stored data
        const { id } = getDefaultChatRoomPreference()
        const redirect_room_id = list.find(f => f.id == id)?.id || list[0]?.id
        if (redirect_room_id && !params.navigate && !isMobile) {
            history.replace(`/chat/${params.type}?id=${redirect_room_id}`)
        }
    }
}

function getProjectsChatList(params, needLoader = true) {
    return (dispatch) => {
        if (!params.hasOwnProperty("forward")) {
            dispatch(request(chatv2Constant.GET_CHATV2_PROJECTS_R, ""));
        }
        if (needLoader) {
            dispatch(request(loaderConstant.LOADING_TRUE));
        }
        chatHubService.getProjects().then(data => {
            dispatch(success(chatv2Constant.GET_CHATV2_PROJECTS_S, { ...data, forward: params.forward }));
            dispatch(request(loaderConstant.LOADING_FALSE));
            goToDefaultChatRoom(params, data?.data)
        }, error => {
            dispatch(failure(chatv2Constant.GET_CHATV2_PROJECTS_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        });
    };
}

function updateApproveUsers(data) {
    return (dispatch) => {
        dispatch(success(chatv2Constant.UPDATE_APPROVE_USERS_S, data));
    }
}

function getClientsChatList(params,needLoader) {
    return (dispatch) => {
        dispatch(request(chatv2Constant.GET_CHATV2_CLIENTS_R, params));
        if (needLoader) {
            dispatch(request(loaderConstant.LOADING_TRUE));
        }
        chatHubService.getClients().then(data => {
            dispatch(success(chatv2Constant.GET_CHATV2_CLIENTS_S, { ...data, forward: params.forward }));
            dispatch(request(loaderConstant.LOADING_FALSE));
            goToDefaultChatRoom(params, data?.data)
        }, error => {
            dispatch(failure(chatv2Constant.GET_CHATV2_CLIENTS_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        });
    };
}

function getTeamsChatList(params, needLoader) {
    return (dispatch) => {
        if (!params.hasOwnProperty("forward")) {
            dispatch(request(chatv2Constant.GET_CHATV2_TEAMS_R, ""));
        }
        if (needLoader) {
            dispatch(request(loaderConstant.LOADING_TRUE));
        }
        chatHubService.getTeamUsers().then(data => {
            dispatch(success(chatv2Constant.GET_CHATV2_TEAMS_S, { ...data, forward: params.forward }));
            dispatch(request(loaderConstant.LOADING_FALSE));
            let chatList = data?.data || []
            if(chatList) {
                chatList = chatList.filter(c => c.message_present)
            }
            goToDefaultChatRoom(params, chatList)
        }, error => {
            dispatch(failure(chatv2Constant.GET_CHATV2_TEAMS_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        });
    };
}

function getProjectChatHistory(params,callback) {
    return (dispatch, getState) => {
        dispatch(request(uiConstant.LAZY_LOADING_TRUE))
        dispatch(request(chatv2Constant.GET_ONE_PROJECT_CHAT_R, params));
        // Update the notification count as soon as user views the chat
        updateNotificationeCount(getState()?.profile || {}, params, "jobMsgCount")

        chatService.getJobChat(params).then(data => {
            const page = data.data && data.data.page ? data.data.page : { skip: 0, limit: 20, count: 0 }
            const oldChat = getOldChat(getState(), params) || [];
            const newData = data?.data?.data || [];
            const mergedChat = [...oldChat, ...newData].sort((a, b) => b.id - a.id);
            const uniqueIds = new Set();
            const chat = mergedChat.filter(message => {
                if (!uniqueIds.has(message.id)) {
                    uniqueIds.add(message.id);
                    return true;
                }
                    return false;
                });
            const result = {
                room_id: params.room_id,
                chats: chat,
                page: page
            };
            // update flag 
            result.chats = result.chats.map(m => {
                m.already_read = true
                return m
            })
            dispatch(success(chatv2Constant.GET_ONE_PROJECT_CHAT_S, result));
            dispatch(success(chatv2Constant.GET_SELECTED_ID, params.job_id))
            //dispatch(success(chatv2Constant.PROJECT_CHAT_SEARCH_S, params.searchId,params.group_id))
            dispatch(request(uiConstant.LAZY_LOADING_FALSE))
            dispatch(profileAction.updateFromChatListNotifications({
                job_id: params.room_id,
                notification_type: 'message',
                invite_type: 1
            }, () => {
                callback(params?.searchId || params?.nextId || params?.bottom)
                // callback(params.nextId)
                // callback(params.bottom)
                dispatch(onLoadMessageReadStatus({
                    message_id: result.chats[0]?.id,
                    type: 'project',
                    group_id: params.room_id
                }))
            }))
        }, error => {
            dispatch(failure(chatv2Constant.GET_ONE_PROJECT_CHAT_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    };
}

function getClientChatHistory(params,callback) {
    return (dispatch, getState) => {
        dispatch(request(uiConstant.LAZY_LOADING_TRUE))
        dispatch(request(chatv2Constant.GET_ONE_CLIENT_CHAT_R, params));
        // Update the notification count as soon as user views the chat
        updateNotificationeCount(getState()?.profile || {}, params, "clientMsgCount")

        chatService.getClientChat(params).then(data => {
            const page = data.data && data.data.page ? data.data.page : { skip: 0, limit: 20, count: 0 }
            const oldChat = getOldChat(getState(), params) || [];
            const newData = data?.data?.data || [];
            const mergedChat = [...oldChat, ...newData].sort((a, b) => b.id - a.id);
            const uniqueIds = new Set();
            const chat = mergedChat.filter(message => {
                if (!uniqueIds.has(message.id)) {
                    uniqueIds.add(message.id);
                    return true;
                }
                    return false;
                });
            const result = {
                room_id: params.room_id,
                chats: chat,
                page: page
            };
            // update flag 
            result.chats = result.chats.map(m => {
                m.already_read = true
                return m
            })
            dispatch(success(chatv2Constant.GET_ONE_CLIENT_CHAT_S, result));
            dispatch(success(chatv2Constant.GET_SELECTED_ID, params.client_id))
            //dispatch(success(chatv2Constant.CLIENT_CHAT_SEARCH_S, params.searchId,params.group_id))
            dispatch(request(uiConstant.LAZY_LOADING_FALSE))
            dispatch(profileAction.updateFromChatListNotifications({
                job_id: params.room_id,
                notification_type: 'message',
                invite_type: 2
            }, () => {   
                // bulk update
                callback(params?.searchId || params?.nextId || params?.bottom)
                // callback(params.nextId)
                // callback(params.bottom)
                dispatch(onLoadMessageReadStatus({
                    message_id: result.chats[0]?.id,
                    type: 'client',
                    group_id: params.room_id
                }))
            }))
        }, error => {
            dispatch(failure(chatv2Constant.GET_ONE_CLIENT_CHAT_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    };
}

function getTeamChatHistory(params, callback) {
    return (dispatch, getState) => {
        dispatch(request(uiConstant.LAZY_LOADING_TRUE))
        dispatch(request(chatv2Constant.GET_ONE_TEAM_CHAT_R, params));
        // Update the notification count as soon as user views the chat
        updateNotificationeCount(getState()?.profile || {}, params, "teamMsgCount")
        chatService.getTeamChat(params).then(data => {

            const page = data.data && data.data.page ? data.data.page : { skip: 0, limit: 20, count: 0 }
            const oldChat = getOldChat(getState(), params) || [];
            const newData = data?.data?.data || [];
            const mergedChat = [...oldChat, ...newData].sort((a, b) => b.id - a.id);
            const uniqueIds = new Set();
            const chat = mergedChat.filter(message => {
                if (!uniqueIds.has(message.id)) {
                    uniqueIds.add(message.id);
                    return true;
                }
                    return false;
                });
            const result = {
                room_id: params.room_id,
                chats: chat,
                page: page
            };
            // update flag 
            result.chats = result.chats.map(m => {
                m.already_read = true
                return m
            })
            dispatch(success(chatv2Constant.GET_ONE_TEAM_CHAT_S, result));
            dispatch(success(chatv2Constant.GET_SELECTED_ID, params.group_id))
            dispatch(request(uiConstant.LAZY_LOADING_FALSE))
            dispatch(success(chatv2Constant.TEAM_CHAT_SEARCH_S, params.searchId,params.group_id))
            dispatch(profileAction.updateFromChatListNotifications({
                job_id: params.room_id,
                notification_type: 'message',
                invite_type: 3
            }, () => {
                callback(params?.searchId || params?.nextId || params?.bottom)
                // callback(params.nextId)
                // callback(params.bottom)
                dispatch(onLoadMessageReadStatus({
                    message_id: result.chats[0]?.id,
                    type: 'team',
                    group_id: params.room_id
                }))
            }))
        }, error => {
            dispatch(failure(chatv2Constant.GET_ONE_TEAM_CHAT_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    };
}

function getChatFiles(params, serviceName, callBack = () => { }) {
    return (dispatch) => {
        dispatch(request(chatv2Constant.GET_CHAT_FILES_R, params));
        try {
            chatService[serviceName](params).then(data => {
                const result = {
                    type: params.type,
                    room_id: params.room_id,
                    result: data.data.result,
                    media_type: data.data.media_type
                }
                dispatch(success(chatv2Constant.GET_CHAT_FILES_S, result));
                callBack(result)
            }, error => {
                dispatch(failure(chatv2Constant.GET_CHAT_FILES_F, error));
                dispatch(failure(alertConstant.ALERT_ERROR, error));
            })
        } catch {
            alert('Service not exists')
        }
    };
}

function getSearchResult(params, serviceName) {
    return (dispatch) => {
        dispatch(request(chatv2Constant.GET_CHAT_SEARCH_R, params));
        try {
            chatService[serviceName](params).then(data => {
                const result = {
                    type: params.type,
                    room_id: params.room_id,
                    result: data.data
                }
                dispatch(success(chatv2Constant.GET_CHAT_SEARCH_S, result));
            }, error => {
                dispatch(failure(chatv2Constant.GET_CHAT_SEARCH_F, error));
                dispatch(failure(alertConstant.ALERT_ERROR, error));
            })
        } catch {
            alert('Service not exists')
        }
    };
}

function sendNewMessage(messageObj, { params, users }, serviceName, is_infiniti_room) {
    return (dispatch, getState) => {
        const { chatMessageObj, tempMessage } = messageObj
        let is_infiniti = is_infiniti_room

        const currentState = getState();
        const lastMessage = {
            message_category: tempMessage?.message_category,
            message_from: tempMessage?.message_from,
            message_id: tempMessage?.id,
            message_text: chatMessageObj?.message?.chatText,
            created_on: new Date(),
            undo: 0
        }
        const { latestChanelData } = updateChannelRecentMessage(currentState, lastMessage, params)

        const formatedStoreData = {
            room_id: params.room_id,
            result: addNewMessage(getState(), tempMessage, params),
            type: params.cur_type,
            channelData: latestChanelData
        };

        dispatch(request(chatv2Constant.SEND_NEW_MESSAGE_R, formatedStoreData));
        try {
            chatService[serviceName](chatMessageObj).then(data => {
                if(data?.data?.deleted) {
                    dispatch(failure(alertConstant.ALERT_ERROR, `This ${params.cur_type === "clients" ? "client" : params.cur_type === "projects" ? "flow" : 'group'} has been deleted`));
                } else {
                    sendMessage({
                        users: users,
                        message: data.data,
                        params: {
                            room_id: params.room_id,
                            cur_type: params.cur_type,
                            isNewMsg: true,
                            sentBy:tempMessage.message_from,
                            
                        },
                        emitType: 'chat:notify'
                    })
                }
            }, error => {
                dispatch(failure(chatv2Constant.SEND_NEW_MESSAGE_F, error));
                dispatch(failure(alertConstant.ALERT_ERROR, error));
            })
        } catch {
            alert('Service not exists')
        }
    };
}

function forwardMultipleMessage( type, messageIds, forwardTo, serviceName) {
    return (dispatch, getState) => {
        // const { chatMessageObj, tempMessage } = messageObj
        // let is_infiniti = is_infiniti_room
        const formatedStoreData = {
            messageIds: messageIds,
            forwardTo: forwardTo,
            type: type
        };

        try {
            dispatch(request(chatv2Constant.GROUP_FORWARD_MESSAGE_R, formatedStoreData));
            chatService[serviceName]({forwardTo, messageIds, type}).then(data => {
            }, error => {
                dispatch(failure(chatv2Constant.SEND_NEW_MESSAGE_F, error));
                dispatch(failure(alertConstant.ALERT_ERROR, error));
            })
        } catch {
            alert('Service not exists')
        }
    };
}

function editMessage(messageObj, { users }, serviceName) {
    return (dispatch, getState) => {
        dispatch(request(chatv2Constant.EDIT_MESSAGE_R, {}));
        try {
            chatService[serviceName](messageObj).then(data => {
                sendMessage({
                    users: users,
                    message: messageObj,
                    params: {
                        room_id: messageObj.id,
                        cur_type: messageObj.type,
                        isNewMsg: true
                    },
                    emitType: 'chat:is_edited'
                })
            }, error => {
                dispatch(failure(chatv2Constant.EDIT_MESSAGE_F, error));
                dispatch(failure(alertConstant.ALERT_ERROR, error));
            })
        } catch {
            alert('Service not exists')
        }
    };
}

// this method will get called on socket type:notify
function onReceiveMessage(msgData) {
    return (dispatch, getState) => {
        const { message, params, ignoreUsers } = msgData
        const currentState = getState();
        const current_user_id = currentState.auth.loginUser.id;
        const targetIndex = ignoreUsers ? ignoreUsers.findIndex(usr_id => current_user_id === usr_id) : -1;
        if (targetIndex === -1) {
            const { id, type } = currentState.chatv2?.selected_channel || {}
            if (params.room_id != id || params.cur_type != type) {
                message.already_read = true
            }
            const latestChat = updateNewMessages(currentState, message, params)
            let lastMessage = latestChat?.chats[0]
            if(lastMessage?.deleted === 1) {
                lastMessage = (latestChat?.chats || []).find(msg => msg?.deleted === 0) || latestChat?.chats[1]
            }
            let reacted_message = null
            if (params?.is_emoji && params?.is_emoji?.message_id) {
                reacted_message = latestChat?.chats?.find((m) => m.id == params?.is_emoji?.message_id) || {}
                reacted_message["is_emoji"] = params?.is_emoji?.emoji || ""
            }

            if (lastMessage?.id && (lastMessage?.user_id)) {
                const { latestChanelData } = updateChannelRecentMessage(currentState, lastMessage, params, reacted_message)
                const formatedStoreData = {
                    room_id: params.room_id,
                    result: latestChat,
                    type: params.cur_type,
                    // channelData: lastMessage?.user_id === currentState?.auth?.loginUser?.id ? currentState.chatv2?.channel : latestChanelData
                    channelData: latestChanelData
                };
                if (formatedStoreData.result.chats.length && formatedStoreData.result.chats[0].agency_id == currentState.auth.loginUser.agency_pref) {
                    dispatch(success(chatv2Constant.ON_RECEIVE_MESSAGE_S, formatedStoreData));
                } else {
                    dispatch(success(chatv2Constant.ON_RECEIVE_MESSAGE_CHANNEL_UPDATE, formatedStoreData));
                }
                // do not add notification for same user
                if (params.sentBy != current_user_id && params.sentBy !== undefined) {
                    if (params?.is_emoji && params?.is_emoji?.message_id) {
                        // notification_msg = latestChat?.chats?.find((m)=> m.id == params?.is_emoji?.message_id) || notification_msg
                        // notification_msg["is_emoji"] = params?.is_emoji?.emoji || ""
                    } else {
                        dispatch(success(chatv2Constant.CHATV2_ADD_NOTIFICATION, lastMessage));
                    }
                }
            }
        }
    }
}

// this method will get called on socket type:notify
function onEditChat(msgData) {
    return (dispatch, getState) => {
        const { message, params } = msgData
        const currentState = getState();

        const { latestChanelData } = updateChannelEditedMessage(currentState, message, params)
        const formatedStoreData = {
            room_id: params.room_id,
            result: updateMessageEdit(currentState, message, params),
            type: params.cur_type,
            channelData: latestChanelData
        };
        dispatch(request(chatv2Constant.EDIT_MESSAGE_S, formatedStoreData));
    }
}

function uploadChatFiles(payload, { params, users }, serviceName) {
    return (dispatch, getState) => {

        const currentState = getState();
        const lastMessage = {
            message_category: params?.preview_msg?.tempMessage?.message_category,
            message_from: params?.preview_msg?.tempMessage?.message_from,
            message_id: params?.preview_msg?.tempMessage?.id,
            message_text: "",
            created_on: new Date(),
            undo: 0
        }
        const { latestChanelData } = updateChannelRecentMessage(currentState, lastMessage, params)

        const formatedStoreData = {
            room_id: params.room_id,
            result: addNewMessage(getState(), params.preview_msg.tempMessage, params),
            type: params.cur_type,
            isNewMsg: true,
            channelData: latestChanelData
        };
        dispatch(request(chatv2Constant.SEND_NEW_MESSAGE_R, formatedStoreData));
        dispatch(request(chatv2Constant.UPLOAD_CHATV2_FILES_R, params));
        try {
            chatService[serviceName](payload).then(data => {
                dispatch(request(chatv2Constant.UPLOAD_CHATV2_FILES_S, params));
                // send to sockets
                sendMessage({
                    users: users,
                    message: data.data,
                    params: {
                        room_id: params.room_id,
                        cur_type: params.cur_type,
                        removeMsgKey: params?.preview_msg?.tempMessage?.msgKey,
                        isNewMsg: true
                    },
                    emitType: 'chat:notify'
                })
            }, error => {
                dispatch(failure(chatv2Constant.UPLOAD_CHATV2_FILES_F, error));
                dispatch(failure(alertConstant.ALERT_ERROR, error));
            })
        } catch {
            alert('Service not exists')
        }
    };
}

function updateEmojiReactions(payload, { params, users }) {
    return (dispatch, getState) => {
        const { profile: { profile: { avatar_filename, first_name, last_name, username } } } = getState()
        dispatch(request(chatv2Constant.UPDATE_CHATV2_EMOJI_R, { ...params, ...payload, avatar_filename: avatar_filename, name: `${first_name || ''} ${last_name || ''}`, username: username }));
        try {
            chatService.updateEmojiReactions(payload).then(data => {
                // send to sockets
                sendMessage({
                    users: users,
                    message: data.data,
                    params: {
                        room_id: params.room_id,
                        cur_type: params.cur_type,
                        defaultKey: 'id',
                        sentBy: params?.sentBy,
                        is_emoji: payload
                    },
                    emitType: 'chat:notify'
                })
            }, error => {
                dispatch(failure(chatv2Constant.UPDATE_CHATV2_EMOJI_F, error));
                dispatch(failure(alertConstant.ALERT_ERROR, error));
            })
        } catch {
            alert('Service not exists')
        }
    };
}

function getStarHistory(params) {
    return (dispatch) => {
        dispatch(request(chatv2Constant.GET_CHAT_STAR_R, params));
        try {
            chatService.getStarChat(params).then(data => {
                const result = {
                    type: params.type,
                    room_id: params.room_id,
                    result: data.data.data
                }
                dispatch(success(chatv2Constant.GET_CHAT_STAR_S, result))
            }, error => {
                dispatch(failure(chatv2Constant.GET_CHAT_STAR_F, error))
            })
        } catch {
            alert('Service not exists')
        }
    }
}

function updateStarredMessage(payload, { params }, message_id = null) {
    return (dispatch, getState) => {
        dispatch(request(chatv2Constant.UPDATE_CHATV2_STARRED_MESSAGE_R, params));
        try {
            const { media, selected_channel } = getState().chatv2
            if (message_id) {
                const chatMedias = media?.[selected_channel.type]?.[selected_channel.id] || [];
                let updatedSeletecItem = chatMedias.find((item) => message_id === item.id);
                const id = updatedSeletecItem.group_id || updatedSeletecItem.client_id || updatedSeletecItem.job_id

                payload["job_id"] = id
                payload["message_id"] = message_id
            }

            chatService.starMessage(payload).then(() => {
                const result = {
                    message: {
                        id: payload.message_id,
                        message : payload.message
                    },
                    params: {
                        room_id: params.room_id,
                        cur_type: params.cur_type,
                        defaultKey: 'id',
                        updateProps: {
                            star: payload.star,
                            message : payload.message
                        }
                    }
                }
                dispatch(success(chatv2Constant.UPDATE_CHATV2_STARRED_MESSAGE_S,result))
                dispatch(onReceiveMessage(result))
            }, error => {
                dispatch(failure(chatv2Constant.UPDATE_CHATV2_STARRED_MESSAGE_F, error));
                dispatch(failure(alertConstant.ALERT_ERROR, error));
            })
        } catch {
            alert('Service not exists')
        }
    };
}

function deleteChat(payload, { params, users }) {
    return (dispatch) => {
        dispatch(request(chatv2Constant.DELETE_CHATV2_MESSAGE_R, params));
        chatService.deleteChatMessage(payload).then(data => {
            const result = {
                users,
                message: {
                    id: payload.message_id
                },
                params: {
                    room_id: params.room_id,
                    cur_type: params.cur_type,
                    defaultKey: 'id',
                    deletedBy: params.deletedBy,
                    deleteStatus: payload.deleteStatus,
                    updateProps: {
                        deleted: payload.deleteStatus
                    }
                }
            }
            dispatch(onReceiveMessage(result))
        }, error => {
            dispatch(failure(chatv2Constant.DELETE_CHATV2_MESSAGE_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    };
}

function setChatDeliveryStatus(params) {
    return (dispatch) => {
        dispatch(success(chatv2Constant.CHATV2_SHOWN_NOTIFICATION, params))
        chatHubService.updateChatDeliveredStatus(params).then(data => {
            sendMessage({
                users: params?.users || [],
                message: {
                    id: data?.data?.message?.message_id
                },
                params: {
                    room_id: params.room_id,
                    cur_type: params.cur_type,
                    defaultKey: 'id',
                    updateProps: {
                        delivered_status: data?.data?.isDelivered ? 1 : 0
                    }
                },
                emitType: 'chat:notify'
            })
        }, error => {
            console.error(error)
        });
    };
}

function setChatReadStatus(params) {
    return () => {
        chatHubService.updateChatReadStatus(params).then(data => {
        }, error => {
            console.error(error)
        });
    };
}

function setChannel(data) {
    return (dispatch) => {
        dispatch(success(chatv2Constant.SET_CHANNEL, data))
        if (data?.id && data?.type) {
            setChatv2Preferences({
                selected_channel: data
            })
        }
    };
}

function removeNotification(data) {
    return (dispatch) => {
        dispatch(success(chatv2Constant.CHATV2_REMOVE_NOTIFICATION, data))
    };
}

function getAllUsers(params) {
    return (dispatch) => {
        dispatch(request(chatv2Constant.GET_ALL_CHATV2_USERS_R, ""));
        // dispatch(request(loaderConstant.LOADING_TRUE));
        chatHubService.getAllUsers(params).then(data => {
            dispatch(success(chatv2Constant.GET_ALL_CHATV2_USERS_S, data?.data || []));
            // dispatch(request(loaderConstant.LOADING_FALSE));
        }, error => {
            dispatch(failure(chatv2Constant.GET_ALL_CHATV2_USERS_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            // dispatch(request(loaderConstant.LOADING_FALSE));
        });
    };
}

function createGroup(params, cb) {
    return (dispatch) => {
        dispatch(request(chatv2Constant.CHATV2_CREATE_GROUP_R, ""));
        dispatch(request(loaderConstant.LOADING_TRUE));
        chatHubService.createNewGroup(params).then(data => {
            dispatch(success(chatv2Constant.CHATV2_CREATE_GROUP_S, data.data));
            dispatch(request(loaderConstant.LOADING_FALSE));
            if (data?.data?.grpID) {
                history.push(`/chat/teams?id=${data?.data?.grpID}`)
                cb()
            }
        }, error => {
            dispatch(failure(chatv2Constant.CHATV2_CREATE_GROUP_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        });
    };
}

function getMutedChats(params) {
    return (dispatch) => {
        dispatch(request(chatv2Constant.CHATV2_GET_MUTED_ROOMS_R));
        chatService.getMutedChats(params).then(data => {
            const result = data.data;
            dispatch(success(chatv2Constant.CHATV2_GET_MUTED_ROOMS_S, result));
        }, error => {
            dispatch(failure(chatv2Constant.CHATV2_GET_MUTED_ROOMS_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    };
}

function updateMutedChat(params) {
    return (dispatch) => {
        dispatch(request(chatv2Constant.CHATV2_UPDATE_MUTED_ROOM_R, params));
        chatHubService.updateMuteNotification(params).then(data => {
            dispatch(success(chatv2Constant.CHATV2_UPDATE_MUTED_ROOM_S, data));
            dispatch(getMutedChats())
        }, error => {
            dispatch(failure(chatv2Constant.CHATV2_UPDATE_MUTED_ROOM_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    };
}

function updateGroupData(params) {
    return (dispatch) => {
        dispatch(request(chatv2Constant.CHATV2_UPDATE_GROUP_R, params));
        dispatch(request(loaderConstant.LOADING_TRUE));
        chatHubService.updateGroupData(params).then(() => {
            dispatch(success(chatv2Constant.CHATV2_UPDATE_GROUP_S, params));
            dispatch(request(loaderConstant.LOADING_FALSE));
        }, error => {
            dispatch(failure(chatv2Constant.CHATV2_UPDATE_GROUP_F, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    };
}

function deleteTeamMember(payload, { params, users }) {
    return (dispatch) => {
        dispatch(request(loaderConstant.LOADING_TRUE));
        chatHubService.deleteTeamMember(payload).then(() => {
            dispatch(request(loaderConstant.LOADING_FALSE));
            sendMessage({
                users: users,
                message: {},
                params: {
                    room_id: params.room_id,
                    cur_type: params.cur_type,
                    user: payload
                },
                emitType: 'chat:user_delete'
            })
        }, error => {
            dispatch(request(loaderConstant.LOADING_FALSE));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    };
}

function deleteGroup(payload, { params, users }) {
    return (dispatch) => {
        dispatch(request(chatv2Constant.CHATV2_DELETE_GROUP_R, params));
        dispatch(request(loaderConstant.LOADING_TRUE));
        chatHubService.updateGroupData(payload).then(() => {
            dispatch(request(loaderConstant.LOADING_FALSE));
            sendMessage({
                users: users,
                message: {},
                params: {
                    room_id: params.room_id,
                    cur_type: params.cur_type
                },
                emitType: 'chat:group_delete'
            })
        }, error => {
            dispatch(failure(chatv2Constant.CHATV2_DELETE_GROUP_F, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    };
}

function resetChatMessages(payload, { params }) {
    return (dispatch) => {
        dispatch(request(chatv2Constant.CHATV2_RESET_CHAT_R, params));
        chatHubService.resetChatMessages(payload).then(data => {
            dispatch(success(chatv2Constant.CHATV2_RESET_CHAT_S, params));
        }, error => {
            dispatch(failure(chatv2Constant.CHATV2_RESET_CHAT_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    };
}

function socketUserDelete(msgData) {
    return (dispatch, getState) => {
        const { params } = msgData
        const loggedInUserId = getState()?.auth?.loginUser?.id
        if (loggedInUserId == params?.user?.id) {
            const { result } = deleteChatv2Group(getState(), params)
            dispatch(success(chatv2Constant.CHATV2_DELETE_GROUP_S, { result, ...params }));
        } else {
            const { result } = addOrRemoveUser(getState(), params?.user || {}, params)
            dispatch(success(chatv2Constant.CHATV2_DELETE_USER_S, result))
        }
    }
}

function socketGroupDelete(msgData) {
    return (dispatch, getState) => {
        const { params } = msgData
        const { result } = deleteChatv2Group(getState(), params)
        dispatch(success(chatv2Constant.CHATV2_DELETE_GROUP_S, { result, ...params }));
    }
}

function addGroupUsers(payload, { params, users }) {
    return (dispatch) => {
        dispatch(request(chatv2Constant.CHATV2_ADD_GROUP_USERS_R, params));
        dispatch(request(loaderConstant.LOADING_TRUE));
        chatHubService.updateGroupData(payload).then(() => {
            dispatch(request(loaderConstant.LOADING_FALSE));
            sendMessage({
                users: users,
                message: {},
                params: {
                    room_id: params.room_id,
                    cur_type: params.cur_type,
                    newUsers: payload.newUsers || []
                },
                emitType: 'chat:users_add'
            })
        }, error => {
            dispatch(failure(chatv2Constant.CHATV2_ADD_GROUP_USERS_F, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    };
}

function socketUsersAdd(msgData) {
    return (dispatch, getState) => {
        const { params } = msgData
        const { result } = addOrRemoveUser(getState(), null, params)
        dispatch(success(chatv2Constant.CHATV2_ADD_GROUP_USERS_S, { result, ...params }));
    }
}

function socketUserOnline(msgData, join = false) {
    return (dispatch) => {
        const { online_user } = msgData
        dispatch(success(chatv2Constant.CHATV2_ONLINE_USER_S, { result: online_user, join: join }));
    }
}

//updating read status
function updateChatReadStatus(params) {
    return (dispatch, getState) => {
        const { pre_calls } = getState()?.chatv2
        const isapicalled = (pre_calls || []).find(f => JSON.stringify(f) === JSON.stringify(params))
        // do not call duplicate api
        if (isapicalled) {
            return
        }
        dispatch(request(chatv2Constant.SET_CHATV2_READ_STATUS_R, params))
        chatHubService.updateChatReadStatus(params).then((data) => {
            const result = changeMessageReadAt(getState(), { ...params })
            dispatch(success(chatv2Constant.SET_CHATV2_READ_STATUS_S, {
                result,
                ...params,
                rm_precall: { ...params }
            }))
            if (data?.data) {
                dispatch(success(chatv2Constant.CHATV2_REMOVE_NOTIFICATION, params?.message_id))
                sendMessage({
                    users: params?.users || [],
                    message: {
                        id: params?.message_id
                    },
                    params: {
                        room_id: params.room_id,
                        cur_type: params.cur_type,
                        defaultKey: 'id',
                        updateProps: {
                            read_status: data?.data?.isRead ? 1 : 0
                        }
                    },
                    emitType: 'chat:notify'
                })
            }
        }, error => {
            console.error(error)
        });
    };
}

function updateReadStatusTrack(params) {
    return () => {
        chatHubService.updateReadStatusTrack(params).then(() => {
        })
    }
}

function socketUserTyping(msgData) {
    return (dispatch) => {
        const { typing_user } = msgData
        dispatch(success(chatv2Constant.CHATV2_USER_TYPING_S, { result: typing_user }));
    }
}

function getNewMessages(params) {
    return (dispatch) => {
        dispatch(request(chatv2Constant.CHATV2_GET_NEW_MESSAGES_R, params));
        chatHubService.getNewMessages(params).then(data => {
            dispatch(success(chatv2Constant.CHATV2_GET_NEW_MESSAGES_S, data));
        }, error => {
            dispatch(failure(chatv2Constant.CHATV2_GET_NEW_MESSAGES_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    }
}

//message info 

function getMessageInfo(params, callBack = () => { }) {
    return (dispatch) => {
        chatHubService.getMessageInfo(params).then((data) => {
            callBack(data.data)
        });
    }
}

function updateDraftMessage(params) {
    return {
        type: chatv2Constant.CHATV2_GET_UNSEND_MESSAGES_R,
        data: params
    }

}

//get unread chatroom 

function getUnReadChat(params) {
    return (dispatch) => {
        dispatch(request(chatv2Constant.CHATV2_GET_UNREAD_ROOMS_R));
        chatHubService.getUnReadChat(params).then(data => {
            const result = data.data;
            dispatch(success(chatv2Constant.CHATV2_GET_UNREAD_ROOMS_S, result));
        }, error => {
            dispatch(failure(chatv2Constant.CHATV2_GET_UNREAD_ROOMS_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    };
}

// update chatroom read status 

function updateChatRoomReadStatus(params) {
    return (dispatch) => {
        dispatch(request(chatv2Constant.CHATV2_UPDATE_CHAT_ROOM_READ_STATUS_R));
        chatHubService.updateChatRoomReadStatus(params).then(data => {
            dispatch(success(chatv2Constant.CHATV2_UPDATE_CHAT_ROOM_READ_STATUS_S, data));
            dispatch(getUnReadChat())
        }, error => {
            dispatch(failure(chatv2Constant.CHATV2_UPDATE_CHAT_ROOM_READ_STATUS_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        })
    }
}

function setReplyMessage(selectedItem, message_id = null) {
    return (dispatch, getState) => {
        const { media, selected_channel } = getState().chatv2
        if (message_id) {
            const chatMedias = media?.[selected_channel.type]?.[selected_channel.id] || [];
            let updatedSeletecItem = chatMedias.find((item) => message_id === item.id);
            selectedItem = updatedSeletecItem
        }
        dispatch(request(chatv2Constant.CHAT_V2_REPLY_MESSAGE_OPEN, selectedItem));
    }
}

function setEditMessage(selectedItem) {
    return (dispatch) => {
        dispatch(request(chatv2Constant.CHAT_V2_EDIT_MESSAGE_OPEN, selectedItem));
    }
}


function handleForwardMessage(selectedItem, message_id = null) {
    return (dispatch, getState) => {
        const { media, selected_channel } = getState().chatv2
        if (message_id) {
            const chatMedias = media?.[selected_channel.type]?.[selected_channel.id] || [];
            let updatedSeletecItem = chatMedias.find((item) => message_id === item.id);
            selectedItem = updatedSeletecItem
        }
        dispatch(request(chatv2Constant.CHAT_V2_FORWARD_MESSAGE, selectedItem));
    }
}

function onLoadMessageReadStatus(params) {
    return (dispatch, getState) => {
        const { bulkReadUpdate, channel } = getState().chatv2
        let channelType = params.type
        if (channelType) {
            channelType = `${channelType}s`
        }
        const activeHubData = (channel[channelType] || []).find(f => f.id == params.group_id)
        const { users } = activeHubData || {}
        if (bulkReadUpdate[params?.type] !== 'loading' && params.message_id) {
            dispatch(request(chatv2Constant.BULK_ONLOAD_READ_R, params));
            chatHubService.onLoadMessageReadStatus(params).then(data => {
                sendMessage({
                    users: users,
                    message: data.data,
                    params: {
                        room_id: params.group_id,
                        cur_type: params.cur_type,
                        isNewMsg: true
                    },
                    emitType: 'chat:notify_bulk'
                })
                dispatch(profileAction.clearNotifications('message'));
                dispatch(success(chatv2Constant.BULK_ONLOAD_READ_S, data));
                if (data.data?.getNotification) {
                    dispatch(profileAction.getNotifications())
                }
            }, error => {
                dispatch(success(chatv2Constant.BULK_ONLOAD_READ_F, params));
                dispatch(failure(alertConstant.ALERT_ERROR, error));
            })
        }
    }
}

function getNewRoomDetail(params) {
    return (dispatch, getState) => {
        if (params?.serviceName && params?.id) {
            chatHubService[params.serviceName]().then(data => {
                const channel = getState().chatv2.channel
                const listData = channel[params?.type] || []
                const result = listData.map(m => {
                    if (m?.id == params.id) {
                        const newData = data?.data?.find(f => f.id == params?.id)
                        return newData || m
                    }
                    return m
                })
                dispatch(success(chatv2Constant.NEW_ROOM_DETAIL_S, { result, ...params }));
            }, error => {
                dispatch(failure(alertConstant.ALERT_ERROR, error));
            })
        }
    }
}

function readStatusUpdateBulk(msgData) {
    return (dispatch, getState) => {
        const updatedMsgIds = (msgData.message.read_messages || [])
        let chat_type = msgData.message.type
        if (chat_type) {
            chat_type = `${chat_type}s`
        }
        if (updatedMsgIds.length) {
            let result = changeMultipleMessageReadAt(getState(), msgData)
            dispatch(success(chatv2Constant.SET_CHATV2_BULK_READ_STATUS_S, {
                result,
                ...msgData.params,
                ...{ chat_type: chat_type },
                rm_precall: { ...msgData.params }
            }))
        }
    }
}


function deliveredStatusUpdateBulk(msgData) {
    return (dispatch, getState) => {
        msgData?.messages.forEach((message) => {
            const updatedMsgIds = (message.delivered_messages || [])
            let chat_type = message.type
            if (chat_type) {
                chat_type = `${chat_type}s`
            }
            if (updatedMsgIds.length) {
                let result = changeMultipleMessageDeliveredAt(getState(), { ...message, chat_type: chat_type })
                dispatch(success(chatv2Constant.SET_CHATV2_BULK_READ_STATUS_S, {
                    result,
                    chat_type: chat_type,
                }))
            }
        })
    }
}

function reloadChatTabs() {
    return (dispatch) => {
        dispatch(success(chatv2Constant.RELOAD_CHAT_TABS))
    }
}
